import {Wifi, WifiOff} from "lucide-react";
import {cn} from "#lib/utils";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "#ui/tooltip";
import {Trans} from "react-i18next";

export const WsConnected = ({isConnected, className, onClick}) => {
  const text = isConnected ? <Trans>Connected</Trans> :
    <Trans i18nKey={'ConnectionIsLost'}>Connection is lost. Please check your internet connection.</Trans>;
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <button onClick={() => !!onClick ? onClick() : window.location.reload()}
                  className={cn('gap-2 items-center bg-success text-success-foreground  inline-block p-1 rounded-md', {'bg-destructive text-destructive-foreground': !isConnected}, className)}>
            {isConnected ? <Wifi className={'w-6 h-6'}/> : <WifiOff className={'w-6 h-6'}/>}
          </button>
        </TooltipTrigger>
        <TooltipContent>
          {text}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>

  );
}