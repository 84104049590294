import {Trans} from "react-i18next";
import CurrencyIcon from "#ui/crypto/CurrencyIcon";
import {Button} from "#ui/button";
import {Plus} from "lucide-react";
import {Link} from "react-router-dom";

export const StakeHeader = ({stake}) => (
  <div className={'flex items-center gap-2 justify-between mb-5'}>
    <div className={'flex items-center gap-1'}>
      <CurrencyIcon symbol={stake.symbol} size={'medium'}/>
      {stake.name}
    </div>
    <Button size={'sm'} variant={'outline'} asChild>
      <Link to={'/wallet/receive/' + stake.symbol}>
        <Trans>Deposit</Trans>
        <Plus/>
      </Link>

    </Button>
  </div>
);