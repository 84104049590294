import {cn} from "#lib/utils";
import TextGradient from "#ui/TextGradient";
import {Trans} from "react-i18next";
import FadeIn from "#ui/FadeIn";

const STATS_DATA = [{number: "6+", label: <Trans>Years Experience</Trans>}, {
  number: "50+",
  label: <Trans>Team Members</Trans>
}, {number: "100K+", label: <Trans>Active Users</Trans>}, {number: "24/7", label: <Trans>Support</Trans>},];

export const CompanyStats = () => {
  return (<FadeIn>
      <div className="grid grid-cols-2 md:grid-cols-4 mt-20 rounded-card overflow-hidden">
        {STATS_DATA.map((stat, i) => (<div
            key={i}
            className={cn("p-7 text-center bg-card/20 backdrop-blur-2xl border-r border-b md:border-b-0 border-border/50 last:border-0", {"border-r-0 md:border-r": i === 1}, {"border-b-0": i === 2})}
          >
            <TextGradient asChild>
              <div className="text-4xl font-bold from-foreground to-primary/70 mb-2">
                {stat.number}
              </div>
            </TextGradient>
            <div className="text-muted-foreground">{stat.label}</div>
          </div>))}
      </div>
    </FadeIn>);
};