import React, {memo, useId} from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "#ui/select";
import {Trans, useTranslation} from "react-i18next";
import {Label} from "#ui/label";
import {useRef} from 'react';

const SelectDocumentType = ({value, onChange}) => {
  const {t} = useTranslation();
  const id = useId();
  const triggerRef = useRef(null);
  const containerRef = useRef(null);
  const documentTypes = [
    {value: 'passport', label: <Trans>Passport</Trans>},
    {value: 'driverLicense', label: <Trans>Driver License</Trans>},
    {value: 'residencePermit', label: <Trans>Residence Permit</Trans>},
    {value: 'governmentIdCard', label: <Trans>Government ID Card</Trans>}
  ]

  return (
    <div ref={containerRef}>
      <Label className={'mb-1.5'}>
        <Trans>Document type</Trans>
      </Label>
      <Select
        id={id}
        value={value || ''}
        onValueChange={onChange}
      >
        <SelectTrigger ref={triggerRef}>
          <SelectValue placeholder={t('Select document type')}/>
        </SelectTrigger>
        <SelectContent container={containerRef.current}>
          <SelectGroup>
            {documentTypes.map(({value, label}, i) => (
              <SelectItem
                key={i}
                value={value}
                className="cursor-default select-none focus:bg-accent focus:text-accent-foreground"
              >
                {label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
};

export default memo(SelectDocumentType);