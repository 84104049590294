import {useState, useEffect, useCallback, useRef} from 'react';

const useCountdown = (initialSeconds, onComplete, isStart = true) => {
    const [countdown, setCountdown] = useState(initialSeconds);
    const timerRef = useRef(null);

    useEffect(() => {
      if (isStart) {
        timerRef.current = setInterval(() => {
          setCountdown((prevCountdown) => {
            if (prevCountdown <= 1) {
              clearInterval(timerRef.current);
              onComplete();
              return 0;
            }
            return prevCountdown - 1;
          });
        }, 1000);
      }

      return () => clearInterval(timerRef.current);
    }, [isStart, onComplete]);

    const resetCountdown = useCallback(() => {
      setCountdown(initialSeconds);
    }, [initialSeconds]);

    const stopCountdown = useCallback(() => {
      clearInterval(timerRef.current);
    }, []);


    return {countdown, resetCountdown, stopCountdown};
  }
;

export default useCountdown;