import * as React from "react";
import {memo, useCallback, useEffect, useState} from "react";
import {ButtonIcon} from "#ui/button";
import {Loader2, SendHorizontal} from "lucide-react";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "#ui/tooltip";
import {Trans} from "react-i18next";
import {OTP_EXPIRATION} from "#src/constants/auth";

import useResendOtp from "#hooks/otp/useResendOtp";

const secondsToMinutesAndSeconds = (seconds) => {
  const minutes = Math.floor(seconds / 60)
  const formattedSeconds = seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60
  return {
    minutes: minutes,
    seconds: formattedSeconds
  }
}

const ResendOtpTimer = ({lastResend, setLastResend}) => {
  const [timer, setTimer] = useState(() => {
    const remainingTime = OTP_EXPIRATION - Math.floor((Date.now() - lastResend) / 1000)
    return remainingTime > 0 ? remainingTime : 0
  })

  const clearLastResend = useCallback(() => {
    setLastResend(0)
  }, [setLastResend])

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => prev - 1)
    }, 1000)

    return () => clearInterval(interval)
  }, []);

  // Окремий useEffect для перевірки закінчення таймера
  useEffect(() => {
    if (timer <= 0) {
      clearLastResend()
    }
  }, [timer, clearLastResend]);

  if (timer <= 0) return null;

  const {minutes, seconds} = secondsToMinutesAndSeconds(timer)
  return (
    <>
      <TooltipTrigger asChild><span className="text-sm text-gray-500">{minutes}:{seconds}</span></TooltipTrigger>
      <TooltipContent>
        <Trans i18nKey={'auth_otp_resend_timer'}>
          You can send the code
        </Trans>
      </TooltipContent>
    </>)
}

const ResendOtp = ({isLoading,type, userId, data}) => {
  const {
    lastResend,
    setLastResend,
    canResend,
    handleResend,
    isLoading: isResendLoading
  } = useResendOtp()
  if (!canResend) {
    return <ResendOtpTimer
      lastResend={lastResend}
      setLastResend={setLastResend}
    />
  }


  return (
    <>
      <TooltipTrigger asChild>
        <ButtonIcon
          disabled={isLoading || isResendLoading}
          onClick={() => handleResend(type, userId, data)}
          className="p-5"
          variant="secondary"
        >
          {isLoading ? <Loader2 className="animate-spin"/> : <SendHorizontal/>}
        </ButtonIcon>
      </TooltipTrigger>
      <TooltipContent>
        <Trans
          i18nKey={'auth_otp_resend_button'}
        >
          Resend code
        </Trans>
      </TooltipContent>
    </>
  )
}


export default memo((props) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <ResendOtp {...props}/>
      </Tooltip>
    </TooltipProvider>
  )

});