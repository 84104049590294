// SwapField.jsx
import {memo, useRef} from "react";
import AmountField from "#components/wallet/AmountField";
import SwapAccountSelector from "./SwapAccountSelector";
import BalanceDisplay from "./BalanceDisplay";
import {useTranslation} from "react-i18next";
import SwapAccountTrigger from "#pages/wallet/Swap/components/SwapAccountTrigger";
import {Skeleton} from "#ui/skeleton";
import {cn} from "#lib/utils";
import errorHandlers from "#lib/errorHandlers";

const SwapFieldSkeleton = () => (
  <div className="rounded-md py-4 block border border-input shadow-sm relative">
    <div className="px-3 mb-3 flex justify-between">
      <Skeleton className="h-5 w-16"/>
      <Skeleton className="h-5 w-32"/>
    </div>
    <div className="px-3">
      <Skeleton className="h-8 w-full"/>
    </div>
  </div>
);

const SwapField = memo(({
                          value,
                          onChange,
                          label,
                          selectedAccount,
                          onAccountSelect,
                          isLoading,
                          error
                        }) => {
  const {t} = useTranslation();
  const labelRef = useRef(null);

  const handleFocus = () => {
    labelRef.current?.classList.add('ring-1');
  };

  const handleBlur = () => {
    labelRef.current?.classList.remove('ring-1');
  };

  if (isLoading && !selectedAccount) {
    return <SwapFieldSkeleton/>;
  }
  const isError = error && error !== 'OK';
  const errorMessage = errorHandlers({
    status: error, amount: selectedAccount.balance
  }, selectedAccount?.symbol).message;
  return (
    <SwapAccountSelector
      selectedAccount={selectedAccount}
      onSelect={onAccountSelect}
    >
      <label
        ref={labelRef}
        className={cn(
          'rounded-md px-3 py-4 block border border-input shadow-sm relative',
          {'border-destructive text-destructive': isError}
        )}
      >
        <div className={'mb-3 text-sm flex items-end justify-between gap-2'}>
          <span>{label}</span>
          {isLoading ? (
            <Skeleton className="h-5 w-32"/>
          ) : (
            <BalanceDisplay
              onClick={onChange}
              balance={selectedAccount?.balance}
              precision={selectedAccount?.precision}
              symbol={selectedAccount?.symbol}
            />
          )}
        </div>
        <AmountField
          value={value}
          placeholder={
            t(
              'wallet_swap_enterAmount',
              `Enter {{symbol}} amount`,
              {symbol: selectedAccount?.symbol}
            )
          }
          isError={isError}
          messageProps={{
            variant: 'error',
            message: errorMessage
          }}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          autoComplete={'off'}
          className={'!text-xl lg:!text-2xl rounded-none h-auto p-0 border-0 focus-visible:ring-0 shadow-none'}
          decimals={selectedAccount?.precision ?? 8}
          disabled={isLoading}
          after={
            <SwapAccountTrigger symbol={selectedAccount?.symbol}/>
          }
        />
      </label>
    </SwapAccountSelector>
  );
});

export default SwapField;