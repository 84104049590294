import {ArrowDown, ArrowRightLeft, ArrowUp, Layers, UserPlus} from "lucide-react";
import {cva} from "class-variance-authority";

const statusIconVariants = cva('rounded-md p-2', {
  variants: {
    variant: {
      'default': 'bg-primary text-primary-foreground',
      'pending': 'bg-muted text-muted-foreground',
      'failed': 'bg-destructive text-destructive-foreground',
    }
  },
  defaultVariants: {
    variant: 'default'
  }
});
export const TransactionStatusIcon = ({ transaction }) => {
  const icons = {
    'deposit': ArrowDown,
    'bonus': ArrowDown,
    'withdraw': ArrowUp,
    'exchange': ArrowRightLeft,
    'referral': UserPlus,
    'staking': Layers,
    'futures': ArrowRightLeft,
  };
  const Icon = icons[transaction?.gateway];
  const variant = transaction?.status === 'pending' ? 'pending' :
                 transaction?.status === 'failed' ? 'failed' : 'default';

  return (
    <span className={statusIconVariants({variant})}>
      <Icon size={20}/>
    </span>
  );
};

export default TransactionStatusIcon;