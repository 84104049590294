import {usePrice} from "#src/contexts/SocketContext";
import {useMemo} from 'react';
import {cn} from "#lib/utils";
import formatDecimal from "#lib/formatDecimal";
import {usePriTickerStatus} from "#hooks/usePriTickerStatus";


export const PriceTicker = ({defaultPrice, precision, className}) => {
  const newPrice = usePrice();
  const priceColor = usePriTickerStatus(defaultPrice, newPrice);

  const maxPriceStr = useMemo(() => {
    const wholeNumberLength = Math.max(
      String(Math.ceil(Number(defaultPrice))).length + 1, // Поточна довжина + 1 для запасу
      5 // Мінімальна довжина
    );
    const integerPart = '9'.repeat(wholeNumberLength);
    const decimalPart = "9".repeat(precision);
    return formatDecimal(Number(`${integerPart}.${decimalPart}`), precision);
  }, [defaultPrice, precision]);

  const currentPrice = newPrice || defaultPrice;

  return (

    <div className={cn(
      'relative inline-block',
      priceColor,
      className,
    )}>
      <div className="invisible" aria-hidden="true">
        {maxPriceStr}
      </div>
      <p className={'absolute inset-0'}>
        {formatDecimal(currentPrice, precision)}
      </p>
    </div>
  );
};