import {Trans} from "react-i18next";
import {Label} from "#ui/label";
import {PlansButtons} from "#pages/wallet/Staking/components/PlansButtons";

export const StakePlanSelector = ({stake, selectedPlan, onSelectPlan,disabled}) => {
  return (
    <div className={'mt-5'}>
      <Label className={'mb-2 block'}>
        <Trans>Plan</Trans>
      </Label>
      <PlansButtons
        className={'gap-2 text-base'}
        plans={stake.plans}
        selectedPlan={selectedPlan}
        onSelectPlan={onSelectPlan}
        isShowInterest={true}
      />
      {disabled && (<small className={'text-destructive'}>
        <Trans>
          You have already used all plans
        </Trans>
      </small>)}
    </div>
  )
};