import WalletLayout from "#layouts/WalletLayout";
import {Accounts, Dashboard, History, Receive, Referral, Send, StakingPage, Swap, UserStakesPage} from "#pages/wallet";
import {ErrorRoute} from "./ErrorBoundary";

const walletRouter = {
  path: "/wallet",
  element: <WalletLayout/>,
  errorElement: <ErrorRoute/>,
  children: [
    {
      index: true,
      element: <Dashboard/>
    },
    {
      path: 'accounts',
      element: <Accounts/>
    },
    {
      path: "history",
      element: <History/>,
    },
    {
      path: "send/:symbol?",
      element: <Send/>
    },
    {
      path: "receive/:symbol?",
      element: <Receive/>
    },
    {
      path: "swap/:symbol?",
      element: <Swap/>
    },
    {
      path: 'referral',
      element: <Referral/>
    },
    {
      path: 'staking',
      children: [
        {
          index: true,
          element: <StakingPage/>
        },
        {
          path: ":type/:id?",
          element: <UserStakesPage/>
        }
      ]
    }
  ]
}

export default walletRouter;