import {Trans} from "react-i18next";
import {IdCard, Languages, UserCircle, Lock} from "lucide-react";
import {
  ChangePassword,
  VerificationDisplay,
  AvatarChangeDialog
} from "#pages/profile/Profile/components";
import LanguageChange from "#components/common/LanguageChange";

const useGetProfileSections = (user) => {
  return [
    {
      icon: UserCircle,
      title: <Trans>Profile picture</Trans>,
      description: <Trans>Personalize your profile picture</Trans>,
      component: <AvatarChangeDialog/>
    },
    {
      icon: Lock,
      title: <Trans>Password</Trans>,
      description: <Trans>Keep your account secure</Trans>,
      component: <ChangePassword userId={user?.id}/>
    },
    {
      icon: Languages,
      title: <Trans>Language</Trans>,
      description: <Trans>Choose your preferred language</Trans>,
      component: <LanguageChange/>
    },
    {
      icon: IdCard,
      title: <Trans>Verification</Trans>,
      description: <Trans>Verify your identity</Trans>,
      component: <VerificationDisplay
        verification={user?.verification}
        defaultCountry={user?.country}
      />
    }
  ];
};

export default useGetProfileSections;
