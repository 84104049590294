// components/withdraw/MemoInput.jsx
import PasteFormField from "#components/common/PasteFormField";

const MemoInput = ({
  isDisabled,
  memo,
  isMemoValid,
  onMemoChange
}) => (
  <PasteFormField
    disabled={isDisabled}
    isError={memo && !isMemoValid}
    value={memo}
    label={'Memo'}
    placeholder={'Enter Memo'}
    onChange={(e) => onMemoChange(e.target.value)}
    onPaste={onMemoChange}
  />
);

export default MemoInput;