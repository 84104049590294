import {CommandItem} from "#ui/command";
import AccountInfo from "./AccountInfo";

const AccountItem = ({account, onSelect}) => {
  return (
    <CommandItem
      key={account.id}
      value={account.id.toString()}
      onSelect={() => onSelect(account)}
    >
      <AccountInfo account={account}/>
    </CommandItem>
  )
}

export default AccountItem;