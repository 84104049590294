import {useMemo} from "react";
import {useSearchParams} from "react-router-dom";

export const useResourceApi = (useGetItemsQuery, {
  defaultLimit,
  resource,
  initialFilters = {},
  skip = false,
  refetchOnMountOrArgChange = false,
  params = {}
}) => {
  const [searchParams] = useSearchParams(); // тепер тільки читаємо з URL

  const filters = useMemo(() => {
    const params = {};
    searchParams.forEach((value, key) => {
      if (key !== 'page') {
        params[key] = value;
      }
    });
    return {...initialFilters, ...params};
  }, [searchParams, initialFilters]);

  const page = parseInt(searchParams.get('page')) || 1;
  const {data, isLoading, isFetching, isError, error, refetch} = useGetItemsQuery({
    page,
    limit: defaultLimit,
    ...filters,
    ...params
  }, {
    skip,
    refetchOnMountOrArgChange
  });

  if (error) {
    throw error;
  }

  const {[resource]: items = [], totalItems = 0, hasNextPage = false, hasPrevPage = false, ...rest} = data || {};

  return {
    items,
    totalItems,
    hasNextPage,
    hasPrevPage,
    isLoading,
    isFetching,
    isError,
    filters, // тільки для читання
    refetch,
    ...rest
  };
};