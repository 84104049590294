import {InputOTP, InputOTPGroup, InputOTPSlot} from "#ui/input-otp";
import {OTP_LENGTH} from "#src/constants/auth";
import {REGEXP_ONLY_DIGITS} from "input-otp";

const OtpInput = ({value, onChange}) => (
  <InputOTP
    maxLength={OTP_LENGTH}
    value={value}
    onChange={onChange}
    pattern={REGEXP_ONLY_DIGITS}
  >
    <InputOTPGroup>
      {[...Array(OTP_LENGTH)].map((_, index) => (
        <InputOTPSlot key={index} index={index}/>
      ))}
    </InputOTPGroup>
  </InputOTP>
);

export default OtpInput;