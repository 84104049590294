import {memo, useCallback, useRef} from "react";
import {Trans} from "react-i18next";
import {FormLabel} from "#ui/form-field";
import {Select, SelectContent, SelectItemWithOutItemText, SelectTrigger} from "#ui/select";
import {isAddressValidByNetwork} from "#lib/wallet.utils";
import {AlertCircle} from "lucide-react";
import {useFavNetwork} from "#hooks/useFavNetwork";

export const NoNetworksMessage = memo(({children}) => (
  <div
    className="rounded-md bg-highlight/10 p-3 text-sm text-highlight text-center flex flex-col justify-center items-center gap-2">
    <AlertCircle size={50}/>
    <span>
      {children}
    </span>
  </div>
));

NoNetworksMessage.displayName = 'NoNetworksMessage';

const NetworkOption = memo(({name, withdrawalCode}) => (
  <div className={'flex items-center gap-2'}>
    <span>{withdrawalCode}</span>
    <small className={'text-muted-foreground'}>{name}</small>
  </div>
));

NetworkOption.displayName = 'NetworkOption';

const NetworkItemInfo = memo(({fee, isNetworkMatchToAddress}) => (
  <div>
    {!isNetworkMatchToAddress && (
      <p className={'text-right rtl:text-left'}>
        <Trans>Unmatched</Trans>
      </p>
    )}
    <small className={'text-muted-foreground'}>
      <Trans
        i18nKey={'withdrawalFee'}
        values={{fee}}
      >
        {`Withdrawal Fee: {{fee}}`}
      </Trans>
    </small>
  </div>
));

const NetworkItem = memo(({
                            name,
                            withdrawalCode,
                            withdrawalFee,
                            depositMin,
                            isWithdrawalEnabled,
                            isDepositEnabled,
                            isNetworkMatchToAddress,
                            type
                          }) => (
  <div className={'flex items-end justify-between w-full gap-2'}>
    <div>
      <p>{withdrawalCode}</p>
      <small className={'text-muted-foreground'}>{name}</small>
    </div>
    {type === 'withdrawal' ? (
      isWithdrawalEnabled ? (
        <NetworkItemInfo
          isNetworkMatchToAddress={isNetworkMatchToAddress}
          fee={withdrawalFee}
          type="withdrawal"
        />
      ) : (
        <small className={'text-muted-foreground'}>
          <Trans>Temporarily disabled</Trans>
        </small>
      )
    ) : (
      isDepositEnabled ? (
        <small className={'text-muted-foreground'}>
          <Trans
            i18nKey={'depositMin'}
            values={{depositMin}}
          >
            {`Min: {{depositMin}}`}
          </Trans>
        </small>
      ) : (
        <small className={'text-muted-foreground'}>
          <Trans>Temporarily disabled</Trans>
        </small>
      )
    )}
  </div>
));

NetworkItem.displayName = 'NetworkItem';

const isNetworkEnabled = (network, address, type) => {
  if (!address) return true;
  if (type === 'withdrawal' && !network.isWithdrawalEnabled) return false;
  if (type === 'deposit' && !network.isDepositEnabled) return false;
  return isAddressValidByNetwork(network, address);
};

export const NetworkSelect = memo(({
                                     networks = [],
                                     network,
                                     setNetwork,
                                     isLoading,
                                     address,
                                     type = 'withdrawal', // 'withdrawal' | 'deposit'
                                   }) => {
  const selectRef = useRef(null);
  const {setFavNetwork} = useFavNetwork(type)

  const handleNetworkSelect = useCallback((networkId) => {
    const selectedNetwork = networks.find(({id, isWithdrawalEnabled, isDepositEnabled}) => {
      if (type === 'withdrawal') {
        return id === networkId && isWithdrawalEnabled;
      }
      return id === networkId && isDepositEnabled;
    });
    setNetwork(selectedNetwork);
    setFavNetwork(networkId);
  }, [networks, setFavNetwork, setNetwork, type]);

  const getPlaceholderText = () => (
    type === 'withdrawal' ? (
      <Trans>Select withdrawal network</Trans>
    ) : (
      <Trans>Select deposit network</Trans>
    )
  );

  const getInfoText = () => (
    type === 'withdrawal' ? (
      <Trans i18nKey={
        'withdrawalNetworkInfo'
      }>
        Please ensure your receiving platform supports the token and network you are
        withdrawing. If you are unsure, kindly check with the receiving platform first.
      </Trans>
    ) : (
      <Trans
        i18nKey={'depositNetworkInfo'}
      >
        Please ensure you are depositing from a platform that supports this network.
        If you are unsure, kindly check with the sending platform first.
      </Trans>
    )
  );

  return (
    <div
      className={'space-y-1.5 relative [&>div[data-radix-popper-content-wrapper]]:!m-0'}
      ref={selectRef}
    >
      <FormLabel label={<Trans>Network</Trans>}/>
      <Select
        value={network?.id}
        defaultValue={network?.id}
        onValueChange={handleNetworkSelect}
        disabled={isLoading}
      >
        <SelectTrigger disabled={isLoading} className="w-full py-5">
          {!network?.id ? (
            getPlaceholderText()
          ) : (
            <NetworkOption
              name={network.name}
              withdrawalCode={network.withdrawalCode}
            />
          )}
        </SelectTrigger>

        {!isLoading && (
          <SelectContent
            style={{
              width: selectRef?.current?.offsetWidth + 'px'
            }}
            align={'start'}
            container={selectRef?.current}
            className={'m-0'}
          >
            <div
              className={'rounded-md bg-highlight mb-2 text-highlight-foreground p-3 text-sm leading-none'}
            >
              <small>{getInfoText()}</small>
            </div>

            {networks?.map(({id, ...network}) => (
              <SelectItemWithOutItemText
                key={id}
                className={'w-full'}
                disabled={!isNetworkEnabled(network, address, type)}
                value={id}
              >
                <NetworkItem
                  disabled={type === 'withdrawal' ? !network?.isWithdrawalEnabled : !network?.isDepositEnabled}
                  isNetworkMatchToAddress={isNetworkEnabled(network, address, type)}
                  type={type}
                  id={id}
                  {...network}
                />
              </SelectItemWithOutItemText>
            ))}
          </SelectContent>
        )}
      </Select>
    </div>
  );
});

NetworkSelect.displayName = 'NetworkSelect';