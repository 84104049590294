import {
  ResponsiveDialog,
  ResponsiveDialogContent,
  ResponsiveDialogDescription,
  ResponsiveDialogHeader,
  ResponsiveDialogTitle
} from "#ui/responsive-dialog";
import OtpForm from "#components/common/OtpForm/index";
import {memo, useCallback} from "react";
import {Trans} from "react-i18next";


const OtpModal = ({open, setOpen, title, description,...props}) => {
  const onClose = useCallback((is) => {
    !is && setOpen(false);
  }, [setOpen]);

  title = title || <Trans>Verify your email</Trans>;
  description = description || <Trans i18nKey={
    'otp_description'
  }>
    Please enter the OTP code sent to your email address.
    Check your spam folder if you can't find it.
  </Trans>
  return (
    <ResponsiveDialog className={'z-[999999999]'} open={open} onOpenChange={onClose}>
      <ResponsiveDialogContent>
        <ResponsiveDialogHeader>
          <ResponsiveDialogTitle>
            {title}
          </ResponsiveDialogTitle>
          <ResponsiveDialogDescription>
            {description}
          </ResponsiveDialogDescription>
        </ResponsiveDialogHeader>
        <div className={'pb-10 px-5 md:p-0'}>
          <OtpForm {...props}/>
        </div>
      </ResponsiveDialogContent>
    </ResponsiveDialog>
  );
};

export default memo(OtpModal);