import {useLogin} from "#hooks/auth/useLogin";
import {LinkButton} from "#ui/link-button";
import {Trans, useTranslation} from "react-i18next";
import {
  AuthForm,
  AuthFormField,
  AuthFormFooter,
  AuthFormSubmit,
  AuthPasswordFormField
} from "#components/common/AuthForm";

const Login = () => {
  const {data, isLoading, isError, handleSubmit, handleChange} = useLogin();
  const {t} = useTranslation();

  return (<AuthForm
      title={t("auth_login_title", "Login to wallet")}
      description={<Trans i18nKey="auth_login_description">Enter your email and password to access your wallet</Trans>}
    >
      <form onSubmit={handleSubmit}>
        <div className="grid w-full items-center gap-4">
          <AuthFormField
            name="email"
            label={<Trans i18nKey="auth_email">Email</Trans>}
            type="email"
            placeholder="eg. email@email.com"
            isError={isError}
            onChange={handleChange}
            value={data?.email || ""}
            required
          />

          <AuthPasswordFormField
            name="password"
            label={<Trans i18nKey="auth_login_password">Password</Trans>}
            type="password"
            placeholder="•••••••••••"
            isError={isError}
            onChange={handleChange}
            value={data?.password || ""}
            required
            labelAfter={<LinkButton to="/auth/forgot-password" variant="muted">
              <Trans i18nKey="forgot_password">Forgot password?</Trans>
            </LinkButton>}
          />

          <AuthFormSubmit loading={isLoading}>
            <Trans i18nKey="auth_login_button">Login to wallet</Trans>
          </AuthFormSubmit>
        </div>
      </form>

      <AuthFormFooter to="/auth/register">
        <Trans i18nKey="auth_login_no_account">
          Don't have an account? Register
        </Trans>
      </AuthFormFooter>
    </AuthForm>);
};


export default Login;