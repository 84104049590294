import {Trans} from "react-i18next";
import {
  AuthForm,
  AuthFormField,
  AuthFormFooter,
  AuthFormSubmit,
  AuthPasswordFormField,
  AuthPasswordMessage,
  AuthTermsCheckboxes
} from "#components/common/AuthForm";
import useRegister from "#hooks/auth/useRegister";


const Register = () => {
  const {data, isLoading, errorFields, handleSubmit, handleChange} = useRegister();
  console.log(data)
  return (<AuthForm
    title={<Trans i18nKey="auth_register_title">Create an account</Trans>}
    description={<Trans i18nKey="auth_register_description">Enter your details to create your account</Trans>}
  >
    <form onSubmit={handleSubmit}>
      <div className="grid w-full items-center gap-4">
        <AuthFormField
          name="email"
          label={<Trans i18nKey="auth_email">Email</Trans>}
          type="email"
          placeholder="eg. email@email.com"
          isError={!!errorFields?.email}
          onChange={handleChange}
          message={errorFields?.email}
          messageVariant={"error"}
          value={data?.email || ""}
          required
        />

        <AuthPasswordFormField
          name="password"
          label={<Trans i18nKey="auth_register_password">Password</Trans>}
          type="password"
          placeholder="•••••••••••"
          isError={!!errorFields?.password}
          onChange={handleChange}
          value={data?.password || ""}
          messageProps={{
            messageVariant: !!errorFields?.password ? "error" : "default",
            message: errorFields?.password || <AuthPasswordMessage/>
          }}
          required
        />
        <AuthFormField
          name="referralCode"
          label={<Trans i18nKey={
            "auth_register_promo_code"
          }>
            Promo code (optional)
          </Trans>}
          type="text"
          placeholder="eg. 123456"
          isError={!!errorFields?.referralCode}
          onChange={handleChange}
          message={errorFields?.referralCode}
          messageVariant={"error"}
          value={data?.referralCode || ""}
        />
        <AuthTermsCheckboxes checked={data?.terms} isError={!!errorFields?.terms}
                             onCheckedChange={handleChange}>
          <AuthFormSubmit loading={isLoading}>
            <Trans i18nKey="auth_register_button">Create account</Trans>
          </AuthFormSubmit>
        </AuthTermsCheckboxes>
      </div>
    </form>

    <AuthFormFooter to="/auth">
      <Trans i18nKey="auth_register_have_account">
        Already have an account? Login
      </Trans>
    </AuthFormFooter>
  </AuthForm>);
};

export default Register;