import {useEffect, useMemo, useState} from 'react';
import {useGetThemeQuery} from '#services/api/common';
import {useDispatch, useSelector} from "react-redux";
import {selectTheme, setParsedColors, setTheme} from "#store/slices/brandSlice";
import useThemeMode from "#hooks/useThemeMode";
import {generateThemeColors} from "#lib/colors";

const applyTheme = (currentTheme, isDark) => {
  try {
    const colors = generateThemeColors({
      primary: currentTheme.primary,
      secondary: currentTheme.secondary,
      highlight: currentTheme.highlight,
      background: currentTheme.background,
      foreground: currentTheme.foreground,
      destructive: currentTheme.destructive,
      success: currentTheme.success,
      chartColors: currentTheme.chartColors || [],
    });

    const themeColors = isDark ? colors.dark : colors.light;

    const root = document.documentElement;

    Object.entries(themeColors).forEach(([key, value]) => {
      root.style.setProperty(`--${key}`, value);
    });
    root.style.setProperty('--radius', currentTheme.radius || '0.5rem');

    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    if (metaThemeColor) {
      metaThemeColor.setAttribute('content', `${isDark ? currentTheme.foreground : currentTheme.background}`);
    } else {
      const meta = document.createElement('meta');
      meta.name = 'theme-color';
      meta.content = isDark ? currentTheme.foreground : currentTheme.background
      document.head.appendChild(meta);
    }
    return themeColors
  } catch (err) {
    console.error('Error applying theme:', err);
    return null
  }
}

const useTheme = () => {
  const currentTheme = useSelector(selectTheme);
  const dispatch = useDispatch();
  const {theme: mode} = useThemeMode();
  const isDark = mode === 'dark';
  const [isThemeLoading, setIsThemeLoading] = useState(true);
  const skipQuery = Boolean(currentTheme);
  const {data} = useGetThemeQuery({}, {
    skip: skipQuery,
    refetchOnMountOrArgChange: true
  });

  useEffect(() => {
    if (data) {
      dispatch(setTheme(data));
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (!currentTheme) return;
    const colors = applyTheme(currentTheme, isDark)
    dispatch(setParsedColors(colors))
    setIsThemeLoading(false);
  }, [currentTheme, dispatch, isDark, setIsThemeLoading]);

  return useMemo(() => ({
    theme: currentTheme, mode, isDark, isLoading: isThemeLoading,

  }), [currentTheme, isDark, isThemeLoading, mode])
};

export default useTheme;