import {useGetAssetQuery, useGetAssetsQuery} from "#services/api/futures";
import {Navigate, useNavigate, useParams} from "react-router-dom";
import FullScreenLoading from "#components/common/FullScreenLoading";
import {useLocalStorage} from "@uidotdev/usehooks";
import {
  AssetInfo,
  AssetSelector,
  Chart,
  MobileOrderForm,
  OrderForm,
  Orders,
  PriceTicker,
  TimeFrameSelector
} from "#pages/Futures/components";
import {useSubscribe} from "#src/contexts/SocketContext";
import {memo} from "react";
import {SkeletonWrapper} from "#ui/skeleton-wrapper";
import {useIsMobile} from "#hooks/use-mobile";
import Error404 from "#src/router/Error404";


const SUBSCRIPTION_TYPES = ['PRICE', 'BOOK_TICKER'];
const SubscriptionManager = memo(({symbol}) => {
  useSubscribe(SUBSCRIPTION_TYPES, symbol);
  return null;
}, (prev, next) => {
  // Перевірка чи реально змінився символ
  return prev.symbol === next.symbol;
});

export const Futures = memo(() => {
  const isMobile = useIsMobile()
  const {symbol} = useParams();
  const navigate = useNavigate();
  const [favSymbol, setFavSymbol] = useLocalStorage('favFuturesSymbol', '');
  const {data: assets, isLoading} = useGetAssetsQuery();
  const {data: currentAsset, isLoading: isAssetLoading, isFetching} = useGetAssetQuery(symbol, {
    skip: !symbol,
    refetchOnMountOrArgChange: true,
  });

  const isAssetLoadingOrFetching = isAssetLoading || isFetching;
  if (isLoading) {
    return <FullScreenLoading variant="primary"/>
  }
  if (!currentAsset) {
    const defaultSymbol = assets?.find(asset => asset.symbol === favSymbol) || assets?.[0] || {};
    if (defaultSymbol) {
      return <Navigate to={`/futures/${defaultSymbol?.symbol ?? ''}`}/>
    } else {
      return <Error404/>
    }
  }


  const changeAsset = (symbol) => {
    setFavSymbol(symbol);
    navigate(`/futures/${symbol}`);
  }
  return (
    <>
      {!isAssetLoadingOrFetching && <SubscriptionManager key={currentAsset.symbol} symbol={currentAsset.symbol}/>}
      <section className={'border-b border-border/50 py-2'}>
        <div className={'px-4 mx-auto flex flex-col lg:flex-row items-center justify-stretch lg:justify-between gap-3'}>
          <div className={'flex items-center gap-3 w-full lg:w-1/2 justify-between sm:justify-start'}>
            <div className={'flex items-center gap-3'}>
              <AssetSelector assets={assets} value={currentAsset} onChange={changeAsset}/>
              <SkeletonWrapper className={'h-6 w-24'} visible={isAssetLoadingOrFetching}>
                <PriceTicker
                  className={'text-lg lg:text-xl'}
                  defaultPrice={currentAsset.price}
                  precision={currentAsset.pricePrecision}
                />
              </SkeletonWrapper>
            </div>

            <AssetInfo asset={currentAsset}/>
          </div>
        </div>
      </section>

      <section className={'px-4 flex gap-4 items-stretch'}>
        <div className={'w-full lg:w-2/3 py-3 relative'}>
          <div className={'flex items-center justify-between w-full mb-2'}>
            <p className={'text-muted-foreground leading-none text-base lg:text-lg'}>
              {currentAsset.name} <span className={'hidden sm:inline-block'}>({currentAsset.symbol})</span>
            </p>
            <TimeFrameSelector/>
          </div>
          <Chart
            symbol={currentAsset.symbol}
            isLoading={isAssetLoadingOrFetching}
            pricePrecision={currentAsset.pricePrecision}
            isOpenEqClose={currentAsset?.isOpenEqClose}
          />
        </div>
        {!isMobile &&
          <div className={'border-l border-border/50 flex-1 pl-4 py-3 w-1/3'}>
            <OrderForm asset={currentAsset}/>
          </div>
        }

      </section>
      <Orders/>
      <MobileOrderForm asset={currentAsset}/>
    </>
  );
})