import {Button} from "#ui/button";
import {Moon, Sun} from "lucide-react";
import useThemeMode from "#hooks/useThemeMode";
import {useTranslation} from "react-i18next";

const ThemeModeSwitch = ({as: El = Button, variant = 'outline', size = 'icon', className}) => {
  const {t} = useTranslation();
  const {theme, toggleTheme} = useThemeMode();
  const isDark = theme === 'dark';
  const isIcon = size === 'icon';
  const icon = isDark ? <Moon/> : <Sun/>
  const themeText = isDark ? t('dark', "Dark") : t('light', "Light");
  if (variant === 'plain') {
    return (<El onClick={toggleTheme}>
      {icon} {themeText}
    </El>)
  }
  return (
    <El id={'loh'} className={className} onClick={toggleTheme} variant={variant} size={size}>
      {icon}
      {!isIcon && themeText}
    </El>
  );
};

export default ThemeModeSwitch;