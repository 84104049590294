import Header from "#components/common/Header";
import {Outlet} from "react-router-dom";
import Container from "#ui/container";
import Footer from "#components/common/Footer";
import React from "react";

const DocsLayout = () => {
  return (<>
    <Header/>
    <Container className={'mt-4'}>
      <Outlet/>
    </Container>
    <Footer/>

  </>);
};

export default DocsLayout;