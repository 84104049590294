// components/OrderForm/OrderForm.js
import {OrderSubscription} from './OrderSubscription';
import {LeverageSelector} from "./LeverageSelector";
import {OrderTypeSelector} from "./OrderTypeSelector";
import {LimitOrderInput} from "./LimitOrderInput";
import {SizeOrderInput} from "./SizeOrderInput";
import {TakeProfitStopLoss} from "./TakeProfitStopLoss";
import {OrderInfo} from "./OrderInfo";
import {OrderButton} from "./OrderButton";
import {useOrderForm} from "#hooks/api/useOrderForm";
import {FuturesBalance} from "#pages/Futures/components";

export const OrderForm = ({asset}) => {
  const {
    leverageProps,
    orderTypeProps,
    tpSlProps,
    formData,
    updateFormField,
    minMaxData,
    isLimitOrder,
    createPositionHandler,
    isSending, errors
  } = useOrderForm(asset);

  const {minSize, maxSize: {LONG: maxLongSize, SHORT: maxShortSize} = {}} = minMaxData;

  return (
    <div className="flex flex-col gap-4">
      <FuturesBalance/>

      <OrderSubscription
        symbol={asset.symbol}
        leverage={leverageProps.currentLeverage}
      />

      <LeverageSelector {...leverageProps} />
      <OrderTypeSelector {...orderTypeProps} />

      {isLimitOrder && (
        <LimitOrderInput
          error={errors?.entryPrice}
          quoteAsset={asset.quoteAsset}
          precision={asset.pricePrecision}
          entryPrice={formData.entryPrice ?? ''}
          isLimitOrder={isLimitOrder}
          onChange={updateFormField('entryPrice')}
        />
      )}

      <SizeOrderInput
        error={errors?.size}
        baseAsset={asset.baseAsset}
        precision={asset.precision}
        size={formData.size ?? ''}
        onChange={updateFormField('size')}
        setUseTpSl={tpSlProps.setUseTpSl}
        useTpSl={tpSlProps.useTpSl}
        minSize={minSize}
      />

      <TakeProfitStopLoss
        stopLoss={formData.stopLoss ?? ''}
        takeProfit={formData.takeProfit ?? ''}
        onStopLossChange={updateFormField('stopLoss')}
        onTakeProfitChange={updateFormField('takeProfit')}
        isTpSlVisible={tpSlProps.useTpSl}
        asset={asset.quoteAsset}
        precision={asset.precision}
      />

      <OrderInfo
        asset={asset}
        defaultMaxLongSize={maxLongSize}
        defaultMaxShortSize={maxShortSize}
        onClick={updateFormField('size')}
      />

      <OrderButton
        onBuy={() => createPositionHandler('LONG')}
        onSell={() => createPositionHandler('SHORT')}
        isLoading={isSending}/>
    </div>
  );
};