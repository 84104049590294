import {memo} from "react";
import {Avatar, AvatarFallback, AvatarImage} from "#ui/avatar";

const UserAvatar = memo(({avatar = null, user, className}) => {  // Перейменували на UserAvatar
  const fallback = user?.email?.slice(0, 2).toUpperCase();
  avatar = avatar || user?.avatar;
  return (
    <Avatar className={className}>
      <AvatarImage className={'object-cover'} src={avatar} alt={user?.email}/>
      <AvatarFallback>{fallback}</AvatarFallback>
    </Avatar>
  )
})
export default UserAvatar;