export default function localeNumberSeparators() {
  const locale = navigator.language;

  const formatter = new Intl.NumberFormat(locale);

  const parts = formatter.formatToParts(1000.1);

  const thousandSeparator = parts.find(part => part.type === 'group')?.value || ' ';
  const decimalSeparator = parts.find(part => part.type === 'decimal')?.value || ',';

  return {
    thousandSeparator,
    decimalSeparator
  };
}