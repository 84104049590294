import {useTranslation} from "react-i18next";
import {useToast} from "#hooks/use-toast";
import {useCallback, useMemo} from "react";
import {
  ErrorNameSpaces,
  forgotPasswordErrors,
  futuresErrors,
  loginErrors,
  otpErrors,
  registerErrors,
  stakeErrors,
  withdrawalErrors
} from "#src/constants/errors";

const parseMessageValues = (message, values) => {
  return message.replace(/{{(.*?)}}/g, (match, p1) => {
    return values?.[p1] || match;
  });
}


const useErrorMessages = (nameSpace, defaultMessage, defaultTitle) => {
  const {t} = useTranslation()
  const {toast} = useToast();
  defaultMessage = defaultMessage || t('error_unknown', 'Unknown error')
  defaultTitle = defaultTitle || t('error_title', "Uh oh! Something went wrong.")

  const errors = useMemo(() => ({
    [ErrorNameSpaces.OTP]: otpErrors(t),
    [ErrorNameSpaces.LOGIN]: loginErrors(t),
    [ErrorNameSpaces.REGISTER]: registerErrors(t),
    [ErrorNameSpaces.FORGOT_PASSWORD]: forgotPasswordErrors(t),
    [ErrorNameSpaces.WITHDRAWAL]: withdrawalErrors(t),
    [ErrorNameSpaces.STAKE]: stakeErrors(t),
    [ErrorNameSpaces.FUTURES]: futuresErrors(t)
  }), [t])

  const getError = useCallback((key) => {
    return errors[nameSpace][key] || defaultMessage;

  }, [defaultMessage, errors, nameSpace])
  const showError = useCallback((key, values = {}) => {
    const message = parseMessageValues(errors?.[nameSpace]?.[key] || defaultMessage, values)
    return toast({
      variant: "destructive",
      title: defaultTitle,
      description: message
    })
  }, [defaultMessage, defaultTitle, errors, nameSpace, toast])
  return {
    getError, showError
  }
}


export default useErrorMessages;