import React, {memo} from "react";
import {Clock9, CircleCheckBig} from "lucide-react";
import {Trans} from "react-i18next";
import {cn} from "#lib/utils";
import {SendVerificationDialog} from "#pages/profile/Profile/components/index";

export const VerificationDisplayItem = ({as: El = 'div', className, ...props}) => {
  return (<El
    className={cn('w-full rounded-md py-2 px-4 text-sm flex items-center justify-center gap-2', className)} {...props}/>)
};


const VerificationDisplay = ({verification, defaultCountry}) => {
  const isRejected = verification === 'rejected';
  switch (verification) {
    case 'pending':
      return (
        <VerificationDisplayItem
          className={'bg-muted text-muted-foreground'}>
          <Clock9 className={'w-4 h-4'}/>
          <Trans>Verification is pending</Trans>
        </VerificationDisplayItem>
      );
    case 'approved':
      return (
        <VerificationDisplayItem
          className={'bg-success text-success-foreground'}>
          <CircleCheckBig className={'w-4 h-4'}/>
          <Trans>Verification is verified</Trans>
        </VerificationDisplayItem>
      );
    default:
      return <SendVerificationDialog
        isRejected={isRejected}
        defaultCountry={defaultCountry}
      />
  }

};

export default memo(VerificationDisplay);