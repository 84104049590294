import {Card} from "#ui/card";
import {Eye, Star, Target} from "lucide-react";
import {Trans} from "react-i18next";
import FadeIn from "#ui/FadeIn";

const VALUES_DATA = [
  {
    icon: <Target/>,
    title: <Trans>Our Mission</Trans>,
    description:
      <Trans
        i18nKey={'missionDescription'}
      >Expand boundaries and set new records. Create the future together and rightfully enter the top 1 best
        communities in the world</Trans>,
  },
  {
    icon: <Eye/>,
    title: <Trans>Our Vision</Trans>,
    description:
      <Trans
        i18nKey={'visionDescription'}
      >We always prioritize user convenience and maximum asset security. Our users really appreciate it.</Trans>,
  },
  {
    icon: <Star/>,
    title: <Trans>Our Values</Trans>,
    description:
      <Trans
        i18nKey={'valuesDescription'}
      >The use of advanced technologies and reliable infrastructure ensure high transaction speeds and low fees,
        making the earning process stable and highly profitable.</Trans>,
  },
];

export const CompanyValues = () => {
  return (
    <div className="grid md:grid-cols-3 gap-8 mb-20">
      {VALUES_DATA.map((item, i) => (
        <FadeIn asChild key={i}>
          <Card
            className="p-6 bg-card/5 relative backdrop-blur-2xl border border-accent/20"
          >
            <div className="text-4xl rounded-md p-2 inline-block bg-highlight/20 text-highlight">
              {item.icon}
            </div>
            <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
            <p className="text-muted-foreground">{item.description}</p>
          </Card>
        </FadeIn>
      ))}
    </div>
  );
};