import {memo, useMemo, useRef, useState} from "react";
import {Popover, PopoverContent} from "#ui/popover";
import {Command} from "#ui/command";
import SearchInput from "#components/wallet/AccountSelector/SearchInput";
import AccountsList from "#components/wallet/AccountSelector/AccountsList";
import {useDebounce} from "@uidotdev/usehooks";
import {useGetAccountsQuery} from "#services/api/accounts";

const AccountSelectorPopover = ({selectedAccount, onSelect, children, align = 'start',alignOffset}) => {
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const debounceSearch = useDebounce(searchQuery, 300);
  const containerRef = useRef(null);

  const handleSelect = (account) => {
    setOpen(false);
    onSelect(account);
  }

  // Запит для пошуку при відкритому селекторі
  const {data: searchData = {}, isLoading, isFetching} = useGetAccountsQuery({
    page: 1,
    limit: 20,
    search: debounceSearch || null
  }, {
    refetchOnMountOrArgChange: true
  });

  // Використовуємо різні дані залежно від стану
  const accounts = useMemo(() => {
    if (open) {
      return searchData?.accounts || [];
    }
  }, [open, searchData?.accounts]);

  return (
    <div ref={containerRef}>
      <Popover open={open} onOpenChange={setOpen}>
        {children}
        <PopoverContent style={{
          width: containerRef?.current?.offsetWidth + 'px'
        }} container={containerRef?.current} alignOffset={alignOffset} align={align} className="p-0 w-full">
          <Command shouldFilter={false}>
            <SearchInput
              value={searchQuery}
              onChange={setSearchQuery}
            />
            <AccountsList
              accounts={accounts || []}
              isLoading={isLoading || isFetching}
              selectedAccount={selectedAccount}
              onSelect={handleSelect}
            />
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default memo(AccountSelectorPopover);