import { memo } from "react";
import { Trans } from "react-i18next";
import { Card } from "#ui/card";
import { DetailItem } from "#components/common/DetailItem";
import CurrencyFormat from "#ui/currency-format";
import Section from "#ui/section";
import Decimal from "decimal.js";
const minusBalance = (balance, amount) => new Decimal(balance).minus(amount).toNumber();
const plusBalance = (balance, amount) => new Decimal(balance).plus(amount).toNumber();

const BalanceAfterExchange = memo(({ fromAccount, toAccount, fromAmount, toAmount}) => (
  <Section header={<Trans>Balances after exchange</Trans>}>
    <Card>
      <DetailItem title={<Trans>Balance {{symbol: fromAccount.symbol}}</Trans>}>
        <CurrencyFormat
          value={minusBalance(fromAccount.balance, fromAmount)}
          decimalScale={fromAccount.precision}
        /> {fromAccount.symbol}
      </DetailItem>
      <DetailItem title={<Trans>Balance {{symbol: toAccount.symbol}}</Trans>}>
        <CurrencyFormat
          value={plusBalance(toAccount.balance, toAmount)}
          decimalScale={toAccount.precision}
        /> {toAccount.symbol}
      </DetailItem>
    </Card>
  </Section>
));

BalanceAfterExchange.displayName = 'BalanceAfterExchange';

export default BalanceAfterExchange;
