import {useSelector} from "react-redux";
import {selectParsedColors} from "#store/slices/brandSlice";
import {useEffect, useRef} from "react";
import {createChart} from "lightweight-charts";
import {hslStringToHex} from "#lib/colors";

const formatTimeToSeconds = (timeInMs) => {
  return Math.floor(Number(timeInMs) / 1000);
};

// Функція для форматування даних свічок
const formatCandleData = (candle) => ({
  time: formatTimeToSeconds(candle.time),
  open: parseFloat(candle.open),
  high: parseFloat(candle.high),
  low: parseFloat(candle.low),
  close: parseFloat(candle.close),
});
const TradingViewChart = ({data, kline, pricePrecision = 2}) => {
  const colors = useSelector(selectParsedColors);
  const chartContainerRef = useRef();
  const chart = useRef();
  const candleSeries = useRef(); // Зберігаємо серію свічок
  useEffect(() => {
    if (!chartContainerRef.current) return;
    chart.current = createChart(chartContainerRef.current, {
      layout: {
        background: {color: hslStringToHex(colors.background)},
        textColor: hslStringToHex(colors.foreground),
      },
      grid: {
        vertLines: {color: hslStringToHex(colors.muted)},
        horzLines: {color: hslStringToHex(colors.muted)},
      },
      crosshair: {
        mode: 0,
      },
      timeScale: {
        borderColor: hslStringToHex(colors.border),
        timeVisible: true,
        secondsVisible: true,

      },
      rightPriceScale: {
        borderColor: hslStringToHex(colors.border),
      },
      rightOffset: 100,
      watermark: {
        visible: false,
      },
    });

    candleSeries.current = chart.current.addCandlestickSeries({
      upColor: hslStringToHex(colors.success),
      downColor: hslStringToHex(colors.destructive),
      borderDownColor: hslStringToHex(colors.destructive),
      borderUpColor: hslStringToHex(colors.success),
      wickDownColor: hslStringToHex(colors.destructive),
      wickUpColor: hslStringToHex(colors.success),
      priceFormat: {
        type: 'price',
        precision: pricePrecision,
        minMove: 1 / Math.pow(10, pricePrecision),
      },
    });

    const formattedData = data.map(formatCandleData);

    // Встановлення початкових даних
    candleSeries.current.setData(formattedData);

    const resizeObserver = new ResizeObserver(() => {
      if (chart.current) {
        chart.current.applyOptions({
          width: chartContainerRef.current.clientWidth,
          height: chartContainerRef.current.clientHeight
        });
      }
    });

    resizeObserver.observe(chartContainerRef.current);

    return () => {
      resizeObserver.disconnect();
      if (chart.current) {
        chart.current.remove();
      }
    };
  }, [colors.background, colors.border, colors.destructive, colors.foreground, colors.muted, colors.success, data, pricePrecision]);

  // Обробка нових Kline через WebSocket
  useEffect(() => {
    if (!kline || !candleSeries.current) return;

    try {
      const newCandle = formatCandleData(kline);
      candleSeries.current.update(newCandle);
    } catch (error) {
      console.error('Error updating candle:', error);
    }
  }, [kline]);

  return <div ref={chartContainerRef} className="w-full h-full flex-1"/>;
};

export default TradingViewChart;
