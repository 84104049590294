import {memo} from "react";
import {CardTitle} from "#ui/card";
import {Badge} from "#ui/badge";
import {Trans} from "react-i18next";
import {getIconOrRandom} from "#lib/getRandomIcon";
import {cn} from "#lib/utils";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "#ui/tooltip";
import {Progress} from "#ui/progress";
import CurrencyFormat from "#ui/currency-format";


const StatusProgress = memo(({progress, range, currencies}) => {
  if (progress === 100) {
    return (<small className={'text-muted-foreground'}>
      <Trans>Status passed</Trans>
    </small>)
  }
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Progress value={parseFloat(progress)}/>
        </TooltipTrigger>
        <TooltipContent>
          <Trans
            i18nKey="statusRangeTooltip"
            components={{
              currencyFormat: <CurrencyFormat value={range}/>
            }}
            values={{
              symbols: currencies?.map(currency => currency).join('/')
            }}>
            {"<currencyFormat></currencyFormat> {{symbols}} left until this status"}
          </Trans>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
})

const StatusIcon = memo(({icon, isCurrent}) => {
  const Icon = getIconOrRandom(icon);
  return (<span className={cn('block bg-secondary text-primary p-1.5 rounded-md',
    {'bg-highlight/20 text-highlight': isCurrent})}>
    <Icon size={20}/>
  </span>)
})

const StatusHeader = memo(({status}) => (
  <div className={'flex items-center gap-2 justify-between '}>
    <div className="flex gap-1 items-center">
      <StatusIcon icon={status.icon} isCurrent={status?.current}/>
      <CardTitle>
        {status.name}
      </CardTitle>
    </div>
    <div className={'w-1/2 flex items-center justify-end'}>
      {status.current ? (
        <Badge variant={'secondary'}>
          <Trans>Current</Trans>
        </Badge>
      ) : (
        <StatusProgress
          progress={status.progress}
          currencies={status?.currencies || []}
          range={status.range}
        />
      )}
    </div>

  </div>
));

StatusHeader.displayName = 'StatusHeader';

export default StatusHeader;
