import {createSlice} from '@reduxjs/toolkit';
import {findNetworkByAddressRegex, isAddressValidByNetwork, isMemoValidByNetwork} from "#lib/wallet.utils";

const initialState = {
  address: '',
  memo: '',
  amount: '',
  isMemoValid: false,
  isAddressValid: false,
  account: null,
  network: null,
  transactionId: null,
  openOtp: false,
  networks: [],
};

export const sendSlice = createSlice({
  name: 'send',
  initialState,
  reducers: {
    setAccount: (state, action) => {
      const prevId = state.account?.id;
      const newId = action.payload?.id;
      if (prevId && newId && (prevId !== newId)) {
        state.address = '';
        state.network = null;
        state.isAddressValid = false;
        state.memo = '';
        state.amount = '';
      }
      state.account = action.payload;
    },
    setAddress: (state, action) => {
      const {address, networks} = action.payload;
      state.address = address
      const addressNetwork = findNetworkByAddressRegex(networks, address);
      if (addressNetwork) state.network = addressNetwork;
      state.isAddressValid = isAddressValidByNetwork(addressNetwork, address);
    },
    setMemo: (state, action) => {
      state.memo = action.payload;
      state.isMemoValid = isMemoValidByNetwork(state?.network, action.payload);
    },
    setNetwork: (state, action) => {
      state.network = action.payload;
      if (state.address) {
        state.isAddressValid = isAddressValidByNetwork(action.payload, state.address);
      }
    },
    setNetworks: (state, action) => {
      state.networks = action.payload;
    },
    setAmount: (state, action) => {
      state.amount = action.payload;
    },
    clearAddress: (state) => {
      state.address = '';
      state.network = null;
      state.message = null;
      state.isAddressValid = false;
    },
    setIsAddressValid: (state, action) => {
      state.isAddressValid = action.payload;
    },
    clearState: (state) => {
      state.address = ''
      state.memo = ''
      state.amount = ''
      state.isMemoValid = false
      state.isAddressValid = false
      state.account = null
      state.network = null
      state.transactionId = null
      state.openOtp = false
      state.networks = []
    },
    onAccountChange: (state) => {
      state.address = '';
      state.network = null;
      state.isAddressValid = false;
      state.memo = '';
      state.amount = '';
    },
    setTransactionId: (state, action) => {
      state.transactionId = action.payload;
    },
    setOpenOtp: (state, action) => {
      state.openOtp = action.payload;
    }
  },
});


export const {
  setAddress,
  setNetwork,
  setNetworks,
  setAmount,
  clearAddress,
  setIsAddressValid,
  setAccount,
  clearState,
  setMemo,
  onAccountChange,
  setTransactionId,
  setOpenOtp
} = sendSlice.actions;

export default sendSlice;