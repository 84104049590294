import React from 'react';
import { Card } from "#ui/card";
import { ArrowUpRight } from "lucide-react";
import CurrencyIcon from "#ui/crypto/CurrencyIcon";
import CurrencyFormat from "#ui/currency-format";
import { cn } from "#lib/utils";

export const CurrencyCard = ({ currency }) => {
  return (
    <div className="mx-2">
      <Card className="group px-5 py-3 bg-card/50 backdrop-blur-xl border border-accent/20
        hover:border-accent/40 transition-all duration-300 hover:scale-105">
        <div className="flex items-center justify-between gap-2">
          <CurrencyIcon symbol={currency?.symbol} size={'small'} />
          <span className="font-semibold text-card-foreground">{currency.name}</span>
          <div
            className={
              cn("flex items-center font-semibold text-card-foreground",
                {
                  'text-success': currency.rateStatus === 'rise',
                  'text-destructive': currency.rateStatus === 'fall'
                }
              )
            }>
            <CurrencyFormat
              value={currency.rate}
              precision={currency.precision}
            />
            <ArrowUpRight className={cn(
              'w-5 h-5',
              {
                'rotate-90': currency.rateStatus === 'fall',
                'hidden': currency.rateStatus === 'stable'
              }
            )} />
          </div>
        </div>
      </Card>
    </div>
  );
};