import {Tabs, TabsList, TabsTrigger} from "#ui/tabs";
import {Trans} from "react-i18next";

export const OrderTypeSelector = ({value, onValueChange}) => {

  return (<Tabs defaultValue={value} onValueChange={onValueChange}>
    <TabsList className="grid w-full grid-cols-3">
      <TabsTrigger value="MARKET">
        <Trans>
          Market
        </Trans>
      </TabsTrigger>
      <TabsTrigger value="LIMIT">
        <Trans>
          Limit
        </Trans>
      </TabsTrigger><TabsTrigger value="STOP_LIMIT">
      <Trans>
        Stop Limit
      </Trans>
    </TabsTrigger>
    </TabsList>
  </Tabs>)
}