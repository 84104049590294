import {
  ResponsiveDialog,
  ResponsiveDialogClose,
  ResponsiveDialogContent,
  ResponsiveDialogDescription,
  ResponsiveDialogFooter,
  ResponsiveDialogHeader,
  ResponsiveDialogTitle,
  ResponsiveDialogTrigger
} from "#ui/responsive-dialog";
import {Button} from "#ui/button";
import {Trans, useTranslation} from "react-i18next";
import {Pencil} from "lucide-react";
import formatDecimal from "#lib/formatDecimal";
import {Spinner} from "#ui/spinner";
import {useUpdatePositionMutation} from "#services/api/futures";
import {OrderFormInput} from "#pages/Futures/components/OrderForm/OrderFormInput";
import {useState} from "react";
import {useToast} from "#hooks/use-toast";
import useErrorMessages from "#hooks/useErrorMessages";
import {ErrorNameSpaces} from "#src/constants/errors";

const formatTpSl = (value, precision) => {
  return !!value ? formatDecimal(value, precision) : '--';
}

export const EditTPSLModal = ({position}) => {
  const {takeProfit = 0, stopLoss = 0, precision} = position;
  const [data, setData] = useState({takeProfit, stopLoss});
  const [update, {isLoading}] = useUpdatePositionMutation();
  const handleChange = (key, value) => {
    setData(prev => ({...prev, [key]: value}));
  }
  const {toast} = useToast();
  const {t} = useTranslation();
  const {showError} = useErrorMessages(
    ErrorNameSpaces.FUTURES,
    t("Error"),
    t("Failed to update position")
  );

  const updatePosition = async () => {
    try {
      await update({
        id: position.id,
        takeProfit: data.takeProfit,
        stopLoss: data.stopLoss
      }).unwrap();
      toast({
        title: t('Position updated'),
        variant: 'success',
        description: t('Position updated successfully')
      })
    } catch (e) {
      console.error('Close position error:', e);
      showError(e?.data?.code, e?.data?.data);
    }
  }

  return (<ResponsiveDialog>
    <ResponsiveDialogTrigger asChild>
      <button
        className={'text-highlight underline flex items-center gap-1'}>
        {`${formatTpSl(takeProfit, precision)}/${formatTpSl(stopLoss, precision)}`}
        <Pencil size={14}/>
      </button>
    </ResponsiveDialogTrigger>

    <ResponsiveDialogContent>
      <ResponsiveDialogHeader>
        <ResponsiveDialogTitle>
          <Trans>
            Edit Take Profit/Stop Loss
          </Trans>
        </ResponsiveDialogTitle>
        <ResponsiveDialogDescription>
          <Trans>
            Set take profit and stop loss for this position
          </Trans>
        </ResponsiveDialogDescription>
      </ResponsiveDialogHeader>
      <div className={'px-4 md:px-0  flex flex-col gap-4'}>
        <OrderFormInput
          label={<Trans>
            Take Profit
          </Trans>}
          value={data.takeProfit}
          onChange={value => handleChange('takeProfit', value)}
          precision={precision}
        />
        <OrderFormInput
          label={<Trans>
            Stop Loss
          </Trans>}
          value={data.stopLoss}
          onChange={value => handleChange('stopLoss', value)}
          precision={precision}
        />

      </div>
      <ResponsiveDialogFooter>
        <ResponsiveDialogClose asChild>
          <Button
            onClick={updatePosition}
            className={'w-full'}
          >
            <Trans>Change</Trans>
            {isLoading && (
              <Spinner size="sm" className="text-primary-foreground"/>
            )}
          </Button>
        </ResponsiveDialogClose>

      </ResponsiveDialogFooter>
    </ResponsiveDialogContent>
  </ResponsiveDialog>)
}