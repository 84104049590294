import {configureStore} from "@reduxjs/toolkit";
import api from "#services/api/base";
import {setupListeners} from "@reduxjs/toolkit/query";
import {
  accountsSlice,
  authSlice,
  brandSlice,
  receiveSlice,
  sendSlice,
  stakingSlice,
  swapConverterSlice,
  websocketSlice
} from "#store/slices";

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [brandSlice.name]: brandSlice.reducer,
    [authSlice.name]: authSlice.reducer,
    [accountsSlice.name]: accountsSlice.reducer,
    [receiveSlice.name]: receiveSlice.reducer,
    [sendSlice.name]: sendSlice.reducer,
    [stakingSlice.name]: stakingSlice.reducer,
    [swapConverterSlice.name]: swapConverterSlice.reducer,
    [websocketSlice.name]: websocketSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
  devTools: true,
})

setupListeners(store.dispatch)


export default store; // export store