import PageHeader from "#components/common/PageHeader";
import {useDomain} from "#hooks/useDomain";


export const Terms = () => {
  const brand = useDomain();
  return (<>
    <PageHeader title={'Terms of Service'}/>
    <div className={'text-sm'}>
      <p>{brand}, and/or its affiliates (&ldquo;we,&rdquo; &ldquo;our,&rdquo; or &ldquo;us&rdquo;) provides its software
        services through its website located at {brand} and related mobile applications and products (collectively
        the &ldquo;Services&rdquo; or &ldquo;{brand}&rdquo;). Before using our Services, please read the Terms of
        Service (the &ldquo;Terms&rdquo;) carefully, along with any other policies or notices on our website or mobile
        applications.
      </p>
      <br/>
      <h3 className="font-bold">Agreement to Terms</h3>
      <p>By accessing or using any or all of the Services, you expressly acknowledge that (i) you have read and
        understood these Terms; (ii) you agree to be bound by these Terms; and (iii) you are legally competent to enter
        into these Terms. If you do not agree to be bound by these Terms or any updates or modifications to these Terms,
        you may not access or use our Services. WE DO NOT PROVIDE INVESTMENT OR FINANCIAL ADVICE OR CONSULTING SERVICES.
        WE ARE SOLELY THE PROVIDER OF {brand} ANDWE DO NOT ADVISE OR MAKE RECOMMENDATIONS ABOUT ENGAGING IN DIGITAL
        ASSET TRANSACTIONS OR OPERATIONS. DECISIONS TO ENGAGE IN TRANSACTIONS OR PERFORM OPERATIONS INVOLVING DIGITAL
        ASSETS SHOULD BE TAKEN ON YOUR OWN ACCORD.
      </p>
      <br/>
      <h3 className="font-bold">Eligibility</h3>
      <p>To be eligible to use {brand}: (i) you must be at least eighteen (18) years old and legally competent to enter
        into these Terms; (ii) you must not be a resident of sanctioned jurisdictions according to any trade embargoes,
        UN Security Council Resolutions (&ldquo;UNSCR&rdquo;) or HM Treasury&rsquo;s financial sanctions regime; and
        (iii) you must not be currently the subject of or subject to economic sanctions such as the United Nations
        Security Council Sanctions List, the list of specially designated nationals maintained by OFAC, the denied
        persons or entity list of the U.S. Department of Commerce or any similar list maintained by any other relevant
        sanctions authority. If you are using our Services on behalf of a legal entity, you further represent and
        warrant that: (iv) the legal entity is duly organized and validly existing under the applicable laws of the
        jurisdiction of its organization; and (v) you are duly authorized by such legal entity to act on its behalf. You
        can only use our Services if permitted under the laws of your jurisdiction. For the avoidance of doubt, you may
        not use our Services if you are located in, or a citizen or resident of any state, country, territory or other
        jurisdiction where your use of our Services would be illegal or otherwise violate any applicable laws. Please
        make sure that these Terms are in compliance with all laws, rules, and regulations that apply to you. You agree
        that you are only using our Services with legally-obtained funds that rightfully belong to you. By using
        T{brand}, you represent and warrant that you meet all eligibility requirements that we outline in these Terms.
        We may still refuse to let certain people access or use {brand}, however, and we reserve the right to change our
        eligibility criteria at any time.
      </p>
      <br/>
      <h3 className="font-bold">Services</h3>
      <p>{brand} is a non-custodial wallet software, for digital assets such as cryptocurrencies, virtual commodities
        meaning you are solely in control of and responsible for your Digital Assets and private keys, and accordingly
        you can authorize transactions from your wallet address. You expressly acknowledge and agree that as {brand} is
        a non-custodial wallet software, you are solely responsible for your activity and any risk of loss at all
        times. {brand} allows you to:
      </p>
      <p>&nbsp;- generate wallet addresses and associated private keys that you may use to send and receive digital
        assets;
      </p>
      <p>&nbsp;- browse and access third party decentralized application(s) and third party decentralized exchanges
        (&ldquo;DEX&rdquo;) through the mobile application&rsquo;s web browser;
      </p>
      <p>
        &nbsp;- swap/trade digital assets through functionality made available by third party service provider(s);
      </p>
      <p>&nbsp;- stake certain digital assets in a third party &lsquo;proof of stake&rsquo; network through staking
        services (&ldquo;Staking Service&rdquo;);
      </p>
      <p>
        &nbsp;- view digital asset price information made available by third party service provider(s); and
      </p>
      <p>&nbsp;- broadcast Digital Asset Transaction data to various blockchains supported by {brand} without requiring
        you to download or install the associated blockchain-based software to your local device.
      </p>
      <br/>
      <h3 className="font-bold">Wallet Address, Private Key, and Backup Capabilities</h3>
      <p>An encrypted backup of certain information associated with your wallet can be stored on eligible devices. The
        private key is associated with the wallet address and, together, they can be used to authorize the transfer of
        Digital Assets to and from that wallet address. You are solely responsible for the retention and security of
        your private key and any mnemonic phrase (&ldquo;Secret Phrase&rdquo;) associated with your wallet. You must
        keep your wallet address, Secret Phrase, and private key access information secure. It is very important that
        you backup your private keys, backup phrases or passwords. Failure to do so may result in the loss of control of
        Digital Assets associated with your wallet. You acknowledge and agree that we do not receive or store your
        wallet password, encrypted private key, unencrypted private key, or Secret Phrase associated with your wallet.
        We cannot generate a new password for your wallet if you fail to remember your original password. If you have
        not safely stored a backup of any wallet address and private key pairs maintained in your wallet, you accept and
        acknowledge that any Digital Assets you have associated with such wallet address will become inaccessible.
        Accordingly, we shall have no responsibility or liability whatsoever in the event you are unable to access your
        wallet for any reason including without limitation your failure to keep your wallet address, Secret Phrase and
        private key information secure.
      </p>
      <br/>
      <h3 className="font-bold">Use of Staking Service</h3>
      <p>
        If you access or use the Staking Service, a third party may stake certain Digital Assets on your behalf
        (&ldquo;Staked Digital Assets&rdquo;), acting as a transaction validator on the applicable blockchain network.
        If a block of transactions is successfully validated using such Staked Digital Assets, a reward is granted by
        that network (&ldquo;Staking Reward&rdquo;). You acknowledge and agree that {brand} is not responsible for: (i)
        your use of the Staking Service and shall have no liability whatsoever in connection with your use of the
        Staking Service; (ii) ensuring Staking will occur on a continuous basis; and (iii) ensuring any particular
        Digital Asset will be staked or will continue to be staked.
      </p>
      <br/>
      <h3 className="font-bold">Digital Asset Transactions</h3>
      <p>In order for all proposed Digital Asset transactions to be completed, they must be confirmed and recorded in
        the Digital Asset&rsquo;s associated public blockchain. Such networks are decentralized, peer-to-peer networks
        supported by independent third parties, which we do not own, control, or operate. We have no control over the
        blockchain networks and, therefore, cannot and do not ensure that any transaction details that you submit via
        our Services will be confirmed and processed. By using {brand}, you acknowledge and agree that: (i) we do not
        have the ability to cancel or otherwise modify your transaction; (ii) the transaction details you submit may not
        be completed, or may be substantially delayed, by the applicable blockchain networks; (iii) we do not store,
        send, or receive Digital Assets; and (iv) any transfer that occurs in relation to any Digital Asset occurs on
        the relevant blockchain network and not on a network owned by us and therefore we do not guarantee the transfer
        of title or right in any Digital Asset.
      </p>
      <br/>
      <h3 className="font-bold">Accuracy of Information Provided by User</h3>
      <p>You represent and warrant that any information you provide via the Services is accurate and complete. You
        accept and acknowledge that we are not responsible for any errors or omissions that you make in connection with
        any Digital Asset transaction initiated via the Services. We strongly encourage you to review your transaction
        details carefully before attempting to transfer a Digital Asset.
      </p>
      <br/>
      <h3 className="font-bold">Wallet Registration and Security</h3>
      <p>You must either import or create a wallet in order to use {brand}. When you create a wallet, you will be
        assigned a private key. You agree to immediately notify us of any unauthorized use of your private key or any
        other breach of security of your wallet. Notwithstanding the foregoing, you acknowledge and agree that you shall
        assume all risks related to the use of the Services and you shall be solely responsible for maintaining the
        confidentiality and security of your private key. When you create a wallet, you are strongly advised to take
        precautions in order to avoid loss of access to and/or control over your wallet. Suggested measures include, but
        are not limited to, the following: (a) creating a strong unique password that you do not use for any other
        purpose (i.e. different to your phone password or any other passwords you use for websites, online services,
        etc.) and leveraging biometric authentication if available; (b) do not store the private key and Secret Phrase
        in plain text online or in an unsecured physical location; (c) limiting access to your devices and your wallet;
        (d) taking all necessary precautions against malware on your devices and networks; and (e) promptly notifying us
        if you discover or otherwise suspect any security breaches related to your wallet. Notwithstanding anything to
        the contrary herein, we shall have no liability whatsoever in connection with activities that occur on your
        wallet with or without your authorization.
      </p>
      <br/>
      <h3 className="font-bold">Transaction Fees</h3>
      <p>There may be transaction fees (e.g. mining fees) associated with your virtual currency transactions that are
        required by the virtual currency system or blockchain network that you engage with. You must ensure that you
        have an adequate balance in your wallet and/or &ldquo;gas&rdquo; to complete transactions before initiating a
        transaction. You acknowledge and agree that we will not be liable for any failed transactions or losses you
        incur due to incorrectly set transaction fees (i.e. too low or too high) or due to insufficient funds or gas
        associated with your wallet address. You further acknowledge and agree that we do not have access to your or
        anyone else&rsquo;s transactions.
      </p>
      <br/>
      <h3 className="font-bold">Taxes</h3>
      <p>It is your responsibility to determine what, if any, taxes apply to the transactions that you have submitted
        transaction details for via the Services, and it is your responsibility to report and remit the correct tax to
        the appropriate tax authority. You agree that we are not responsible for determining whether taxes apply to your
        transactions or for collecting, reporting, withholding, or remitting any taxes arising from any Digital
        Asset-related transactions.
      </p>
      <br/>
      <h3 className="font-bold">Third Party Services and Content</h3>
      <p>In no event shall a description or reference to a third party&rsquo;s product or service (including, but not
        limited to, providing a description or reference via hyperlink) be construed as an endorsement or promotion of
        such third party products or services by us. We retain the exclusive right to add to, modify, or cancel the
        availability of any Third Party Service. You may agree to receive push notifications from Third Party Content
        providers. In order to receive push notifications, you must opt in to the service. Push notifications will not
        be automatically enabled on your device for Third Party Content. We do not control, endorse, or adopt any Third
        Party Content shared through push notifications, and will have no responsibility for Third Party Content
        including, but not limited to, token availability and/or sales. If, to the extent permitted by {brand}, you
        grant express permission to a third party to access or connect to your {brand} account, either through the third
        party&rsquo;s product or service or through {brand}, you acknowledge that granting permission to a third party
        to take specific actions on your behalf does not relieve you of any of your responsibilities under these Terms.
        You are fully responsible for all acts or omissions of any third party with access to your wallet.
      </p>
      <br/>
      <h2 className="font-bold">Intellectual Property</h2>
      <br/>
      <h3 className="font-bold">License</h3>
      <p>As long as you agree to and comply with the present Terms, we grant you a non-exclusive, non-sublicensable and
        non-transferable license to use {brand} for your personal use or internal business use only. Except as otherwise
        expressly permitted in these Terms, you will not: (a) reproduce, modify, adapt or create derivative works of any
        part of {brand}; (b) rent, lease, distribute, sell, sublicense, transfer, or provide access to {brand}; (c)
        use {brand} for the benefit of any third party; (d) incorporate {brand} into a product or service you provide to
        a third party without our prior written consent; (d) circumvent mechanisms in {brand} intended to limit your
        use; (f) reverse engineer, disassemble, decompile, translate, or otherwise seek to obtain or derive the source
        code, including images and texts, underlying ideas, algorithms, file formats or non-public APIs to {brand},
        except to the extent expressly permitted by applicable law (and then only upon advance notice to us); (g) remove
        or obscure any proprietary or other notices contained in {brand} (h) use {brand} for competitive analysis, as
        part of any other software or project of any kind or to build competitive products.
      </p>
      <br/>
      <h3 className="font-bold">Feedback</h3>
      <p>From time to time, you may choose to submit feedback to us. We may, in connection with {brand}, freely use,
        copy, disclose, license, distribute and exploit any feedback in any manner without any obligation, royalty or
        restriction based on intellectual property rights or otherwise. No feedback will be considered your Confidential
        Information, and nothing in this Agreement limits our right to independently use, develop, evaluate, or market
        products or services, whether incorporating feedback or otherwise.
      </p>
      <br/>
      <h3 className="font-bold">Content</h3>
      <p>{brand} may contain copyrighted material and trademarks including, but not limited to, text and graphics
        (the &ldquo;Content&rdquo;), which is protected by copyright law, registered and unregistered trademarks, and
        other intellectual property rights. Unless otherwise provided, we exclusively own the Content. Your use
        of {brand} does not grant you any right, title, or interest in the Content. You agree that you will not copy,
        reproduce, modify, republish, upload, post, transmit, distribute, collect, sell, license, create derivative
        works from, or, in any other way, whether manual or automated, exploit any of the Content, in whole or in part.
      </p>
      <br/>
      <h3 className="font-bold">Your Use of {brand}</h3>
      <p>You agree that you will not violate any laws when using our Services. This includes any local, provincial,
        state, federal, national, or international laws that may apply to you. You agree that you will not use our
        Services to pay for, support, or otherwise engage in any illegal activities including, but not limited to,
        illegal or otherwise prohibited trade, illegal gambling, fraud, money laundering, or terrorist activities. For
        the avoidance of doubt, if we discover that you have violated these Terms and/or any applicable laws or
        regulatory requirements, including, but not limited to, the Bank Secrecy Act, we reserve all of our rights and
        remedies under these Terms and at law and will take all necessary actions against you. You further agree that:
        (i) you will not encourage or induce any third party to engage in any of the activities prohibited under this
        Section; (ii) you will not impersonate someone or use or attempt to use another user&rsquo;s wallet without
        authorization or use our Services in any manner that could interfere, disrupt, negatively affect, or inhibit
        other users from fully enjoying it; (iii) you will not distribute any virus or other harmful computer code
        through {brand}; (iv) you will not take any action that may impose an unreasonable or disproportionately large
        load on our or any of our third party providers&rsquo; infrastructure; (v) you will not reverse engineer or
        bypass, circumvent, or attempt to bypass or circumvent any measures that we may use to prevent or restrict
        access to the Services including, without limitation, other accounts, computer systems, or networks connected to
        the Services; and (vi) you will not violate, misappropriate or infringe the rights of {brand}, our users, or
        others, including privacy, publicity, intellectual property, or other proprietary rights. Although we have no
        obligation to monitor any user content, we have absolute discretion to take any necessary actions any time and
        for any reason without notice in the event you breach these Terms. Any use of {brand} other than as specifically
        authorized in these Terms, without our prior written permission, is strictly prohibited and we shall have the
        right to terminate your license to use {brand} immediately without notice. You acknowledge and accept that by
        using the Services, you may be exposed to content that is offensive, indecent, or objectionable. We take no
        responsibility and assume no liability for any user content, including any loss or damage to any of your user
        content.
      </p>
      <br/>
      <h3 className="font-bold">Limitation of Liability &amp; Disclaimer of Warranties</h3>
      <p>You acknowledge and agree that we have no control over, and no duty to take any action regarding: (i) failures,
        disruptions, errors, or delays in the processing of Digital Assets that you may experience while using the
        Services; (ii) the risk of failure of hardware, software, and Internet connections; (iii) the risk of malicious
        software being introduced or found in the software underlying {brand}; (iv) the risk that third parties may
        obtain unauthorized access to information stored within your wallet, including, but not limited to your wallet
        address, private key, and Secret Phrase; and (v) the risk of unknown vulnerabilities in or unanticipated changes
        to the the applicable blockchain networks. You release us from all liability related to any losses, damages, or
        claims arising from: (a) user error such as forgotten passwords, incorrectly constructed transactions, or
        mistyped Digital Asset addresses; (b) server failure or data loss; (c) unauthorized access to
        the {brand} application; (d) bugs or other errors in the {brand} software; and (e) any unauthorized third party
        activities, including, but not limited to, the use of viruses, phishing, brute forcing, or other means of attack
        against {brand}. We make no representations concerning any Third Party Content contained in or accessed through
        our Services. Any other terms, conditions, warranties, or representations associated with such content, are
        solely between you and such organizations and/or individuals.
      </p>
      <br/>
      <h3 className="font-bold">Limitation of Liability</h3>
      <p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL WE, OR OUR AFFILIATES, OR ANY OF OUR
        RESPECTIVE SHAREHOLDERS, MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES, ATTORNEYS, AGENTS, REPRESENTATIVES, SUPPLIERS
        OR CONTRACTORS BE LIABLE FOR ANY DIRECT DAMAGES OR INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE, CONSEQUENTIAL OR
        SIMILAR DAMAGES OR LIABILITIES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF DATA, INFORMATION,
        REVENUE, PROFITS OR OTHER BUSINESSES OR FINANCIAL BENEFITS) WHETHER UNDER CONTRACT, TORT, NEGLIGENCE, STATUTE,
        STRICT LIABILITY OR OTHER THEORY EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, ARISING OUT OF
        YOUR ACCESS OR USE OF THE SERVICES, INCLUDING, BUT NOT LIMITED TO: ANY UNAUTHORIZED USE OF YOUR WALLET ADDRESS
        AND/OR PRIVATE KEY DUE TO YOUR FAILURE TO MAINTAIN THE CONFIDENTIALITY OF YOUR WALLET, ANY INTERRUPTION OR
        CESSATION OF TRANSMISSION TO OR FROM THE SERVICES OR ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT ARE
        FOUND IN THE {brand} SOFTWARE OR THAT MAY BE TRANSMITTED TO OR THROUGH OUR SERVICES BY ANY THIRD PARTY
        (REGARDLESS OF THE SOURCE OF ORIGINATION); ANY ACTIONS WE TAKE OR FAIL TO TAKE AS A RESULT OF COMMUNICATIONS YOU
        SEND TO US; HUMAN ERRORS; TECHNICAL MALFUNCTIONS; FAILURES, INCLUDING PUBLIC UTILITY OR TELEPHONE OUTAGES;
        OMISSIONS, INTERRUPTIONS, LATENCY, DELETIONS OR DEFECTS OF ANY DEVICE OR NETWORK, PROVIDERS, OR SOFTWARE
        (INCLUDING, BUT NOT LIMITED TO, THOSE THAT DO NOT PERMIT PARTICIPATION IN OUR SERVICES); ANY INJURY OR DAMAGE TO
        COMPUTER EQUIPMENT; INABILITY TO FULLY ACCESS OUR WEBSITE, {brand} OR THE SERVICES OR ANY OTHER WEBSITE; THEFT,
        TAMPERING, DESTRUCTION, OR UNAUTHORIZED ACCESS TO, IMAGES OR OTHER CONTENT OF ANY KIND; DATA THAT IS PROCESSED
        LATE OR INCORRECTLY OR IS INCOMPLETE OR LOST; TYPOGRAPHICAL, PRINTING OR OTHER ERRORS, OR ANY COMBINATION
        THEREOF; OR ANY OTHER MATTER RELATING TO THE WEBSITE, {brand} APPLICATION OR ANY OTHER ASPECT OF THE SERVICES.
        SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL
        DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IN NO EVENT SHALL OUR LIABILITY TO YOU FOR ALL DAMAGES
        (OTHER THAN MAY BE REQUIRED BY APPLICABLE LAW IN CASES INVOLVING PERSONAL INJURY) EXCEED THE AMOUNT OF ONE
        HUNDRED U.S. DOLLARS ($USD100.00) OR ITS EQUIVALENT IN THE LOCAL CURRENCY OF THE APPLICABLE JURISDICTION.
      </p>
      <br/>
      <h3 className="font-bold">Warranty Disclaimer</h3>
      <p>{brand} IS PROVIDED &quot;AS IS&quot; AND &ldquo;AS AVAILABLE&rdquo;, AND WITHOUT WARRANTY OF ANY KIND. TO THE
        MAXIMUM EXTENT PERMITTED BY LAW, WE DISCLAIM ALL REPRESENTATIONS AND WARRANTIES, EXPRESS OR IMPLIED, RELATING TO
        THE SERVICES AND UNDERLYING SOFTWARE OR ANY ASPECT OF THE INFORMATION, CONTENT, OR THE SERVICES, WHETHER
        PROVIDED OR OWNED BY US, OR BY ANY THIRD PARTY OR CONTAINED IN ANY THIRD PARTY MATERIALS OR ON ANY THIRD PARTY
        WEBSITES ACCESSIBLE OR LINKED TO OUR WEBSITE OR THE SERVICES, INCLUDING WITHOUT LIMITATION, WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, FREEDOM FROM COMPUTER VIRUS, AND ANY
        IMPLIED WARRANTIES ARISING FROM COURSE OF DEALING, COURSE OF PERFORMANCE, OR USAGE IN TRADE, ALL OF WHICH ARE
        EXPRESSLY DISCLAIMED. IN ADDITION, WE DO NOT REPRESENT OR WARRANT THAT THE CONTENT ACCESSIBLE VIA THE SERVICES
        IS ACCURATE, COMPLETE, AVAILABLE, CURRENT, FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE RESULTS OF
        USING THE SERVICES WILL MEET YOUR REQUIREMENTS. WE DO NOT MAKE ANY REPRESENTATIONS OR GIVE WARRANTIES THAT THE
        ACCESS TO {brand} OR USE OF THE SERVICES AND THE FUNCTIONALITY THEREOF WILL BE CONTINUOUS, UNINTERRUPTED, TIMELY
        OR ERROR-FREE. SOME STATES DO NOT ALLOW THE DISCLAIMER OF IMPLIED WARRANTIES, SO THE FOREGOING DISCLAIMERS MAY
        NOT APPLY TO YOU.
      </p>
      <br/>
      <h3 className="font-bold">Indemnity</h3>
      <p>To the extent permitted by applicable law, you agree to defend, indemnify, and hold harmless us, our
        affiliates, and our respective shareholders, members, directors, officers, employees, attorneys, agents,
        representatives, suppliers and contractors, from and against any and all claims, damages, obligations, losses,
        liabilities, tort, costs or debt, and expenses (including, but not limited to, attorney&rsquo;s fees) arising
        from: (a) your use of and access to the Services; (b) any feedback or submissions you provide to us
        concerning {brand}; (c) violation of any Third Party Content (d) your violation of the Terms; or (e) your
        violation of any law, rule, or regulation, or the rights of any third party.
      </p>
      <br/>
      <h3 className="font-bold">The Limitation on Claims</h3>
      <p>You agree that any claim you may have arising out of or related to your relationship with us must be filed
        within one year after such claim arises, otherwise, your claim is permanently barred.
      </p>
      <br/>
      <h3 className="font-bold">Governing Law</h3>
      <p>
        No matter where you&rsquo;re located, the laws of the EU will govern these Terms and the
        parties&rsquo; relationship as if you signed these Terms in EU, without regard to EU conflicts of laws rules. If
        any provisions of these Terms are inconsistent with any applicable law, those provisions will be superseded or
        modified only to the extent such provisions are inconsistent. The parties agree to submit to the federal or
        state courts in EU for exclusive jurisdiction of any dispute arising out of or related to your use of the
        Services or your breach of these Terms. You waive any objection based on lack of personal jurisdiction, place of
        residence, improper venue, or forum non conveniens in any such action.
      </p>
      <br/>
      <h3 className="font-bold">Note to International Users</h3>
      <p>
        No matter where you&rsquo;re located, the laws of the EU will govern these Terms and the
        parties&rsquo; relationship as if you signed these Terms in EU, without regard to EU conflicts of laws rules. If
        any provisions of these Terms are inconsistent with any applicable law, those provisions will be superseded or
        modified only to the extent such provisions are inconsistent. The parties agree to submit to the federal or
        state courts in EU for exclusive jurisdiction of any dispute arising out of or related to your use of the
        Services or your breach of these Terms. You waive any objection based on lack of personal jurisdiction, place of
        residence, improper venue, or forum non conveniens in any such action.
      </p>
      <br/>
      <h3 className="font-bold">Termination</h3>
      <p>In the event of termination concerning your license to use {brand}, your obligations under this Agreement will
        still continue. Your access to the funds in your Wallet after termination will depend on your access to your
        backup of your Wallet address and private key.
      </p>
      <br/>
      <h3 className="font-bold">Discount of Services</h3>
      <p>We may, in our sole discretion and without cost to you, with or without prior notice, and at any time, modify
        or discontinue, temporarily or permanently, any portion of our Services. You are solely responsible for storing
        outside of the Services a backup of any wallet address and private key pair that you maintain in your wallet.
        Maintaining an external backup of any wallet address and private key pairs associated with your wallet will
        allow you to access the blockchain network upon which your wallet is secured. Such a backup will allow you to
        fully restore your wallet at any time without cost or loss of your Digital Assets. If you do not maintain a
        backup of your wallet data outside of the Services, you will not be able to access the Digital Assets associated
        with your wallet. {brand} shall not be held responsible or liable for any loss of Digital Assets in the event
        that we discontinue all or any part of the Services.
      </p>
      <br/>
      <h3 className="font-bold">No Waiver</h3>
      <p>Our failure to exercise or delay in exercising any right, power, or privilege under this Agreement shall not
        operate as a waiver; nor shall any single or partial exercise of any right, power, or privilege preclude any
        other or further exercise thereof. The waiver of any such right or provision will be effective only if in
        writing and signed by a duly authorized representative of us. Except as expressly set forth in this Agreement,
        the exercise by either party of any of its remedies under this Agreement will be without prejudice to its other
        remedies under this Agreement or otherwise.
      </p>
      <br/>
      <h3 className="font-bold">Severability</h3>
      <p>If it turns out that any part of this Agreement is invalid, void, or for any reason unenforceable, that term
        will be deemed severable and limited or eliminated to the minimum extent necessary. The limitation or
        elimination of the term shall not affect or impair the validity or enforceability of any remaining part of that
        term, clause or provision or any other terms, clauses or provisions of these Terms.
      </p>
      <br/>
      <h3 className="font-bold">Arbitration &amp; Waiver of Class Action</h3>
      <p>The parties agree to arbitrate any dispute arising from this Agreement or your use of the Services on an
        individual basis. ARBITRATION PREVENTS YOU FROM SUING IN COURT OR FROM HAVING A JURY TRIAL. THE PARTIES HEREBY
        EXPRESSLY WAIVE TRIAL BY JURY. The parties agree that: (a) any arbitration will occur in EU and (b) the
        arbitration will be conducted confidentially by a single arbitrator in accordance with the rules of American
        Arbitration Association for arbitration of consumer-related disputes, in the English language, and with limited
        discovery. At your request, hearings may be conducted in person or by telephone and the arbitrator may provide
        for submitting and determining motions on briefs, without oral hearings. Other than class procedures and
        remedies discussed below, the arbitrator has the authority to grant any remedy that would otherwise be available
        to a court or other tribunal. THE PREVAILING PARTY IN ANY ACTION OR PROCEEDING TO ENFORCE THESE TERMS SHALL BE
        ENTITLED TO COSTS AND ATTORNEYS&rsquo; FEES. THE ARBITRAL DECISION MAY BE ENFORCED IN ANY COURT. WHETHER THE
        DISPUTE IS HEARD IN ARBITRATION OR IN COURT, YOU AND {brand} WILL NOT COMMENCE AGAINST THE OTHER A CLASS ACTION,
        CLASS ARBITRATION, OR REPRESENTATIVE ACTION OR PROCEEDING.
      </p>
      <br/>
      <h3 className="font-bold">Force Majeure</h3>
      <p>We shall not be held liable for any delays, failure in performance, or interruptions of service which result
        directly or indirectly from any cause or condition beyond our reasonable control, including but not limited to:
        any delay or failure due to any acts of God, acts of civil or military authorities, acts of terrorism, civil or
        industrial disturbances, blockages, embargoes, war, strikes or other labor disputes, fire, earthquakes, storms
        or other nature-related events, interruption in electrical telecommunications or Internet services or network
        provider services, failure of hardware equipment and/or software or other utility failures, smart contract bugs
        or weaknesses, technological changes, changes in interest rates or other monetary conditions, and, for the
        avoidance of doubt, changes to any blockchain-related protocol, other catastrophe, or any other occurrences
        which are beyond our reasonable control, and shall not affect the validity and enforceability of any remaining
        provisions. If we are unable to perform our Services outlined in the Terms due to factors beyond our control,
        including, but not limited to, the aforementioned force majeure events or changes in applicable laws and/or
        sanctions policies, we shall not be liable for the Services provided under these Terms during the time period
        coincident with the event in question.
      </p>
      <br/>
      <h3 className="font-bold">Assignment</h3>
      <p>You agree that we may assign any of our rights and/or transfer, sub-contract, or delegate any of our
        obligations under these Terms without any notice or consent from you. These Terms will bind and inure to the
        benefit of the parties, their successors and permitted assigns. Your agreement to these Terms is personal to you
        and you may not transfer or assign it to any third party.
      </p>
      <br/>
      <h3 className="font-bold">No Third Part Baneficiaries</h3>
      <p>You agree that, except as otherwise expressly provided in these Terms, there shall be no third party
        beneficiaries to the Terms.
      </p>
      <br/>
      <h3 className="font-bold">Entire Agreement</h3>
      <p>This Agreement sets forth the entire understanding and agreement as to the subject matter hereof and supersedes
        any and all prior discussions, agreements, and understandings of any kind (including, without limitation, any
        prior versions of the Terms) and every nature between us. Except as provided for above, any modification to
        these Terms must be in writing and must be signed by both parties.
      </p>
      <br/>
      <h3 className="font-bold">Notices</h3>
      <p>Any notices or other communications provided by us under these Terms, including those regarding modifications
        to it will be given by posting to the Services and/or through other electronic communication. You agree and
        consent to receive electronically all communications, agreements, documents, notices and disclosures that we
        provide in connection with your account and your use of the Services.
      </p>
      <br/>
      <p><strong>Questions or Comments</strong></p>
      <p>If you have any questions relating to these Terms, your rights and obligations arising from these Terms and/or
        your use of {brand} and our Services or any other matter please send us a message on our contact page at {brand}.
      </p>
    </div>
  </>);
};

