import base from "./base";

const settingsApi = base.injectEndpoints({
  endpoints: (builder) => ({
    getStatuses: builder.query({
      query: () => {
        return {
          url: '/api/statuses',
          method: 'GET'
        }
      },
    }),
    getFaqs: builder.query({
      query: () => {
        return {
          url: '/api/faqs',
          method: 'GET'
        }
      },
    }),
    setUserLanguage: builder.mutation({
      query: (id) => {
        return {
          url: `/api/set-language/${id}`,
          method: 'POST',
        }
      },
    }),
    getLanguages: builder.query({
      query: () => {
        return {
          url: '/languages',
          method: 'GET'
        }
      },
    }),
    setLanguage: builder.mutation({
      query: (id) => {
        return {
          url: `/languages/${id}`,
          method: 'POST',
        }
      },
    }),

  })
})

export const {
  useGetStatusesQuery,
  useGetLanguagesQuery,
  useSetUserLanguageMutation,
  useSetLanguageMutation,
  useGetFaqsQuery
} = settingsApi;