import {createContext, useCallback, useContext, useEffect} from "react";
import {useResourceApi} from "#hooks/api/useResoureApi";
import {useGetPositionsQuery} from "#services/api/futures";
import {useToast} from "#hooks/use-toast";
import {useTranslation} from "react-i18next";
import {usePositions} from "#src/contexts/SocketContext";

const OrdersContext = createContext({});

const useRemovePosition = (refetch, status, initPositions) => {
  const positions = usePositions();
  const {toast} = useToast();
  const {t} = useTranslation();
  const getCloseReasons = useCallback((position) => {
    const {closeReason, assetSymbol, type} = position;

    const closeReasons = {
      LIQUIDATION: {
        title: t('Liquidation'),
        description: t(`Position in {{assetSymbol}} of type {{type}} has been liquidated`, {assetSymbol, type}),
        variant: 'destructive',
      },
      STOP_LOSS: {
        title: t('Stop loss'),
        description: t(`Position in {{assetSymbol}} of type {{type}} has been closed by stop loss`, {
          assetSymbol,
          type
        }),
        variant: 'destructive',
      },
      TAKE_PROFIT: {
        title: t('Take profit'),
        description: t(`Position in {{assetSymbol}} of type {{type}} has been closed by take profit`, {
          assetSymbol,
          type
        }),
        variant: 'success',
      },
      DEFAULT: {
        title: t('Position closed'),
        description: t(`Position in {{assetSymbol}} of type {{type}} has been closed`, {assetSymbol, type}),
      }
    };

    return closeReasons[closeReason] || closeReasons.DEFAULT;
  }, [t]);
  useEffect(() => {
    for (const position of Object.values(positions) || []) {
      const {isClosed} = position;

      if (isClosed && status === 'OPEN') {
        refetch({status});
        const currentPosition = initPositions.find(({id}) => id === position.id);
        const {title, description, variant} = getCloseReasons({...currentPosition, ...position});
        toast({
          title,
          description,
          variant,
        });
      }
    }
  }, [getCloseReasons, initPositions, positions, refetch, status, t, toast]);
}

const OrdersProvider = ({children, status}) => {

  const {
    isLoading, isFetching, hasPrevPage, hasNextPage, refetch, items: positions,
  } = useResourceApi(useGetPositionsQuery, {
    defaultLimit: 5, resource: 'positions', refetchOnMountOrArgChange: true, initialFilters: {status},
  })
  useRemovePosition(refetch, status, positions);
  const value = {
    positions, isLoading, isFetching, hasPrevPage, hasNextPage,
  }
  return (<OrdersContext.Provider value={value}>
    {children}
  </OrdersContext.Provider>)
}
export const useOrders = () => {
  const context = useContext(OrdersContext);
  if (context === undefined) {
    throw new Error('useOrders must be used within a OrdersProvider');
  }
  return context;
}

export {OrdersContext, OrdersProvider};