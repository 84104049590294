import useSend from "#hooks/api/useSend";
import {useGetOftenUsedWithdrawalAddressesQuery} from "#services/api/transactions";
import {Badge} from "#ui/badge";
import {memo} from "react";
import {formatWallet} from "#lib/wallet.utils";

const OftenUsed = ({networks, isDisabled}) => {
  const {account, sendApiRef} = useSend();
  const {data, isLoading, isFetching} = useGetOftenUsedWithdrawalAddressesQuery(account?.id, {
    skip: !account?.id
  });
  const {transactions} = data || {};
  if (isLoading || isFetching || !transactions?.length) return null;

  return (
    <div className={'flex items-center gap-2 mt-1'}>
      {transactions?.map((trx) =>
        (<Badge
          disabled={isDisabled}
          key={trx.id}
          onClick={() => !isDisabled && sendApiRef.setAddressHandler(trx?.withdrawAddress, networks)}
          className={'cursor-pointer'}
          variant={'secondary'}>{formatWallet(trx?.withdrawAddress, {
          startCount: 4,
          endCount: 6
        })}
        </Badge>)
      )}
    </div>
  );
};

export default memo(OftenUsed);