import {useDispatch, useSelector} from "react-redux";
import {selectShowOtp, setShowOtp} from "#store/slices/authSlice";
import {useCallback, useEffect, useMemo, useState} from "react";
import useResendOtp from "#hooks/otp/useResendOtp";
import useOtpHandler from "#hooks/otp/useOtpHandler";
import {OTP_TYPES} from "#src/constants/auth";

const useActivation = (user) => {
  const showOtp = useSelector(selectShowOtp)
  const dispatch = useDispatch();
  const [open, setOpen] = useState(showOtp)
  const {setLastResend, handleResend, canResend, isLoading} = useResendOtp();
  const {typeCode} = useOtpHandler(OTP_TYPES.EMAIL_VERIFICATION);
  const closeHandler = useCallback((is) => {
    if (!is) {
      setOpen(false)
      dispatch(setShowOtp(false));
    }
  }, [dispatch, setOpen]);
  const openHandler = useCallback(() => {
    setOpen(true)
    if (!canResend) return;
    handleResend(typeCode, user?.id)
  }, [canResend, handleResend, typeCode, user?.id]);

  useEffect(() => {
    if (showOtp) {
      setLastResend();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(() => ({
    openHandler, closeHandler, open, isLoading, canResend
  }), [closeHandler, isLoading, open, openHandler, canResend]);
}

export default useActivation;