import {TableCell, TableRow} from "#ui/table";
import {Skeleton} from "#ui/skeleton";
import {cn} from "#lib/utils";


export const StakingRowSkeleton = ({isMobile}) => {
  return (
    <TableRow>
      {/* Currency/Name Cell */}
      <TableCell>
        <div className="flex items-center gap-2">
          <Skeleton className="w-6 h-6 rounded-full"/>
          <Skeleton className={cn('h-4', isMobile ? 'w-8' : 'w-16')}/>
        </div>
      </TableCell>

      {/* Plans Buttons - Only show if not mobile */}
      {!isMobile && (
        <TableCell>
          <div className="flex gap-2">
            <Skeleton className="w-10 h-8"/>
            <Skeleton className="w-10 h-8"/>
            <Skeleton className="w-10 h-8"/>
          </div>
        </TableCell>
      )}

      {/* Interest Rate Cell */}
      <TableCell>
        <Skeleton className="w-12 h-4"/>
      </TableCell>

      {/* Min-Max Range Cell */}
      <TableCell>
        <Skeleton className="w-24 h-4"/>
      </TableCell>

      {/* UserStakeModalButton Cell */}
      <TableCell>
        <div className="flex justify-end">
          <Skeleton className={cn('rounded-md', isMobile ? 'w-8 h-8' : 'w-32 h-10')}/>
        </div>
      </TableCell>
    </TableRow>
  );
};

export const StakingTableSkeleton = ({rowsCount = 3, isMobile}) => (
  <>
    {Array.from({length: rowsCount}).map((_, index) => (
      <StakingRowSkeleton key={index} isMobile={isMobile}/>
    ))}
  </>
);