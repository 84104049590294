import {usePrice} from "#src/contexts/SocketContext";
import formatDecimal from "#lib/formatDecimal";
import {OrderFormInput} from "./OrderFormInput";
import {Trans} from "react-i18next";

export const LimitOrderInput = ({entryPrice, onChange, quoteAsset, precision,error}) => {
  const price = usePrice()
  return (
    <OrderFormInput
      afterLabel={
        <button onClick={() => onChange(price)} className={'text-highlight underline'}>
          {formatDecimal(price, precision)}
        </button>}
      precision={precision}
      label={<Trans>Price</Trans>}
      value={entryPrice}
      onChange={onChange}
      asset={quoteAsset}
      error={error}
    />
  )
}