import {ResponsiveDialog, ResponsiveDialogContent, ResponsiveDialogTrigger} from "#ui/responsive-dialog";
import {OrderForm} from "./OrderForm";
import {useIsMobile} from "#hooks/use-mobile";
import Container from "#ui/container";
import {OrderButton} from "#pages/Futures/components/OrderForm/OrderButton";

export const MobileOrderForm = ({asset}) => {
  const isMobile = useIsMobile()
  if (!isMobile) return null
  return (<ResponsiveDialog>
    <ResponsiveDialogTrigger asChild>
      <div role={'button'} className={'w-full fixed-bottom bg-background border-t fixed bottom-0 left-0 z-50'}>
        <div className={'flex p-4'}>
          <OrderButton/>
        </div>
      </div>
    </ResponsiveDialogTrigger>
    <ResponsiveDialogContent>
      <Container className="py-5 md:p-0">
        <OrderForm asset={asset}/>
      </Container>
    </ResponsiveDialogContent>
  </ResponsiveDialog>)
}