import {Skeleton} from "#ui/skeleton";
import {CalendarClock, MoveRight} from "lucide-react";
import FormatDatetime from "#components/common/format-datetime";

export const TimeRange = ({stake, isLoading}) => {
  if (isLoading) {
    return (<div className={'flex items-center gap-2'}>
      <Skeleton as={'span'} className={'h-6 w-6'}/>
      <Skeleton as={'span'} className={'h-4 w-36'}/>
    </div>)
  }
  return (
    <div className={'flex items-center gap-2 '}>
      <CalendarClock size={20} className={'text-highlight'}/>
      <span className={'text-base text-muted-foreground inline-flex gap-1 items-center'}>
          <span><FormatDatetime date={stake?.startDate}/></span>
          <MoveRight size={14}/>
          <span> <FormatDatetime date={stake?.endDate}/></span>
        </span>
    </div>
  )
}