import {Outlet} from "react-router-dom";
import Header from "#components/wallet/WalletHeader";
import Footer from "#components/common/Footer";

const FuturesLayout = () => {
  return (<div className="min-h-screen flex flex-col">
    <Header isFull={true}/>
    <main className={'flex-1'}>
      <Outlet/>
    </main>
    <Footer isFull={true}/>
  </div>);
};

export default FuturesLayout;