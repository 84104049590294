import router from "#src/router";
import {RouterProvider} from "react-router-dom";
import {Provider} from "react-redux";
import store from "#src/store";
import AppSetup from "#components/AppSetup";
import {I18nextProvider} from "react-i18next";
import i18n from "#src/locale/i18n";
import React, {Suspense} from "react";
import LanguageLoading from "#src/locale/LanguageLoading";


function App() {

  return (<Provider store={store}>
    <AppSetup>
      <I18nextProvider i18n={i18n}>
        <Suspense fallback={<LanguageLoading/>}>
          <RouterProvider router={router}/>
        </Suspense>
      </I18nextProvider>
    </AppSetup>
  </Provider>)
}

export default App;


