import {useCallback, useMemo, useRef} from "react";
import {useDispatch} from "react-redux";
import {useResourceApi} from "#hooks/api/useResoureApi";
import {useGetAccountsQuery} from "#services/api/accounts";
import {resetAccounts} from "#store/slices/accountsSlice";

export const useAccounts = (options = {}) => {
  const dispatch = useDispatch();
  const {filters = {}, skip = false, defaultLimit = 10} = options;
  const accountsApiRef = useRef({})
  const resourceApi = useResourceApi(useGetAccountsQuery, {
    defaultLimit: defaultLimit,
    resource: "accounts",
    skip,
    initialFilters: filters
  });

  const {
    items,
    isLoading,
    isFetching,
    isError,
    error,
    loadMore: resourceLoadMore,
    updateFilters: resourceUpdateFilters,
    nextPageHandler,
    prevPageHandler,
    refetch,
    totalItems,
    hasNextPage,
    hasPrevPage,
    filters: currentFilters
  } = resourceApi;



  const loadMore = useCallback(() => {
    if (hasNextPage && !isLoading) {
      resourceLoadMore();
    }
  }, [hasNextPage, isLoading, resourceLoadMore]);

  const updateFilters = useCallback((newFilters) => {
    dispatch(resetAccounts());
    resourceUpdateFilters(newFilters);
  }, [dispatch, resourceUpdateFilters]);

  const resetHandler = useCallback(() => {
    dispatch(resetAccounts());
  }, [dispatch]);
  accountsApiRef.current = {
    loadMore,
    updateFilters,
    resetHandler,
    prevPageHandler,
    nextPageHandler
  };

  return useMemo(() => ({
      accounts: items,
      totalItems,
      hasNextPage,
      hasPrevPage,
      currentPage: currentFilters?.page || 1,
      isLoading,
      isFetching,
      isError,
      error,
      loadMore,
      updateFilters,
      refetch,
      resetHandler,
      accountsApiRef,
    }),
    [items, totalItems, hasNextPage, hasPrevPage, currentFilters?.page, isLoading, isFetching, isError, error, loadMore, updateFilters, refetch, resetHandler]);
};