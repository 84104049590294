import {
  Drawer,
  DrawerContent, DrawerDescription, DrawerTitle,
} from "#ui/drawer";
import ActionsButtonsNavigation from "#components/common/ActionsButtonsNavigation";
import {useIsMobile} from "#hooks/use-mobile";

const CryptoAccountsDrawer = ({account, onClose}) => {
  const isMobile = useIsMobile()
  if (!isMobile) return null
  const closeDrawer = (status) => !status && onClose(null)
  return (
    <Drawer open={!!account} onOpenChange={closeDrawer}>
      <DrawerTitle/>
      <DrawerDescription/>
      <DrawerContent>
        <div className={'p-10'}>
          <ActionsButtonsNavigation symbol={account?.symbol} compact variant={'default'} accountId={account?.id}/>
        </div>
      </DrawerContent>
    </Drawer>
  );
};

export default CryptoAccountsDrawer;