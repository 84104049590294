import { memo } from "react";
import { CarouselItem } from "#ui/carousel";
import { Card, CardContent, CardDescription, CardHeader } from "#ui/card";
import { cn } from "#lib/utils";
import StatusHeader from './StatusHeader';
import StatusDetails from './StatusDetails';
import StatusFeatures from './StatusFeatures';
import StatusUpgradeButton from './StatusUpgradeButton';

const StatusCarouselItem = memo(({ index, activeIndex, status }) => (
  <CarouselItem className={"basis-full md:basis-1/2 lg:basis-1/3"}>
    <Card
      className={cn('flex flex-col h-full', {
        'border-highlight': index === activeIndex,
      })}
    >
      <CardHeader className={'p-5'}>
        <StatusHeader status={status} />
        <CardDescription>{status.description}</CardDescription>
      </CardHeader>

      <CardContent className={'flex flex-col p-5 pt-0 flex-1 justify-between'}>
        <div className={'flex-1'}>
          <StatusDetails status={status} />
          <StatusFeatures features={status.features} />
        </div>

        {!status.current && (
          <StatusUpgradeButton currency={status.currencies?.[0]} />
        )}
      </CardContent>
    </Card>
  </CarouselItem>
));

StatusCarouselItem.displayName = 'StatusCarouselItem';

export default StatusCarouselItem;
