import CurrencyFormat from "#ui/currency-format";
import {useTranslation} from "react-i18next";
import {Card} from "#ui/card";
import {DetailItem} from "#components/common/DetailItem";
import {Skeleton} from "#ui/skeleton";

const DetailSkeleton = () => {
  return (<Card>
    {[1, 2, 3].map((i) => (<DetailItem key={i} title={<Skeleton as={'span'} className={'h-4 w-20 inline-block'}/>}>
      <Skeleton as={'span'} className={'h-6 w-36 inline-block'}/>
    </DetailItem>))}
  </Card>)
}

export const DetailsList = ({stake, isLoading}) => {
  const {t} = useTranslation();
  if (isLoading) return <DetailSkeleton/>;
  const details = [{
    title: t('Stake amount'),
    children: <> <CurrencyFormat value={stake?.amount} decimalScale={stake?.precision}/> {stake?.symbol}</>
  }, {
    title: t('Interest rate'), children: `${parseFloat(stake?.interest).toFixed(2)}%`,
  }, {
    title: t('Stake period'), children: t('stakeCountDays', '{{count}} days', {count: stake?.period}),
  },];
  return (
    <Card>
      {details.map((item, i) => (<DetailItem key={i} {...item} />))}
    </Card>
  );
};

