import {useDepositMutation, useWithdrawMutation} from "#services/api/futures";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {ErrorNameSpaces} from "#src/constants/errors";
import useErrorMessages from "#hooks/useErrorMessages";
import {useToast} from "#hooks/use-toast";

export const useTransferFunds = (initialIsDeposit = true) => {
  const [isDeposit, setIsDeposit] = useState(initialIsDeposit);
  const [amount, setAmount] = useState(null);
  const [accountId, setAccountId] = useState(null);

  const {toast} = useToast();
  const {t} = useTranslation();
  const {showError} = useErrorMessages(
    ErrorNameSpaces.FUTURES,
    t("Error"),
    t("Failed to transfer funds")
  );

  const useTransferMutation = isDeposit ? useDepositMutation : useWithdrawMutation;
  const [transfer, {isLoading: isSending}] = useTransferMutation();

  const handleTransfer = async () => {
    if (!amount || !accountId) return false;

    try {
      await transfer({accountId, amount}).unwrap();

      toast({
        title: t("Transfer successful"),
        variant: "success",
        description: t("Your transfer has been successfully completed"),
      });

      setAmount(null);
      setAccountId(null);

      return true;
    } catch (error) {
      console.error('Transfer error:', error);
      showError(error?.data?.code, error?.data?.data);
      return false;
    }
  };

  const switchTransferType = () => {
    setIsDeposit(prev => !prev);
    setAmount(null);
  };

  const canTransfer = Boolean(amount && accountId);

  return {
    isDeposit,
    amount,
    accountId,
    isSending,
    canTransfer,
    setAmount,
    setAccountId,
    switchTransferType,
    handleTransfer
  };
};