import CurrencyFormat from "#ui/currency-format";
import {cn} from "#lib/utils";

const TransactionValue = ({ transaction }) => {
  const plus = transaction?.type === 'deposit' && '+';
  const isFailed = transaction.status === 'failed';

  return (
    <span className={cn(
      {'text-success': !!plus},
      {'text-destructive': isFailed},
    )}>
      {plus} <CurrencyFormat
        value={transaction?.amount}
        symbol={transaction?.currency}
        as={'span'}
        decimalScale={transaction.precision}
      />
    </span>
  );
};


export default TransactionValue;