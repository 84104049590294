import {memo} from 'react'
import ActionsButtonsNavigation from "./ActionsButtonsNavigation";
import SimplePageHeader from "./SimplePageHeader";


const HeaderWIthActions = ({children, symbol}) => {
  return (
    <SimplePageHeader action={<ActionsButtonsNavigation symbol={symbol} variant={'link'} className={'gap-1'}/>}>
      {children}
    </SimplePageHeader>
  );
};

export default memo(HeaderWIthActions);