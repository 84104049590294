import {memo, useCallback, useEffect, useRef} from "react";
import {useNavigate, useParams} from "react-router-dom";
import AccountTriggerButton from "#components/wallet/AccountSelector/AccountTriggerButton";
import {useGetAccountsQuery} from "#services/api/accounts";
import AccountSelectorPopover from "#components/wallet/AccountSelector/AccountSelectorPopover";
import {useFavoriteCurrency} from "#hooks/useFavoriteCurrency";

function SelectAccount({selectedAccount, onSelect, path, disabled}) {
  const [favoriteCurrency, setFavoriteCurrency] = useFavoriteCurrency()
  const {symbol} = useParams();
  const navigate = useNavigate();
  const isInitialLoadRef = useRef(!selectedAccount);

  // Окремий запит для початкового завантаження
  const {data: initialData = {}, isLoading: isInitLoading} = useGetAccountsQuery({
    page: 1,
    limit: 20,
    search: symbol || favoriteCurrency || null
  }, {
    skip: !isInitialLoadRef.current,
    refetchOnMountOrArgChange: true
  });


  useEffect(() => {
    if (isInitialLoadRef.current && initialData?.accounts?.length > 0) {
      const account = initialData.accounts.find(acc => acc.symbol === symbol) || initialData.accounts[0];
      onSelect(account);
      isInitialLoadRef.current = false;
    }

  }, [initialData.accounts, symbol, onSelect, selectedAccount]);

  const handleSelect = useCallback((account) => {
    navigate(`${path}${account.symbol}`);
    onSelect(account);
    setFavoriteCurrency(account.symbol);
  }, [navigate, onSelect, path, setFavoriteCurrency]);

  return (
    <AccountSelectorPopover selectedAccount={selectedAccount} onSelect={handleSelect}>
      <AccountTriggerButton
        disabled={disabled}
        selectedAccount={selectedAccount}
        isLoading={isInitLoading}
      />
    </AccountSelectorPopover>

  );
}

export default memo(SelectAccount);