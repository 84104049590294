import {useLocation, useNavigate} from "react-router-dom";
import {useCallback} from "react";
import useLastResend from "#hooks/otp/useLastResend";

export const useOtpNavigation = (path = "/auth/otp") => {
  const navigate = useNavigate();
  const location = useLocation();
  const {setLastResend} = useLastResend(Date.now());

  return useCallback(({id, type, ...data}) => {
    setLastResend();
    navigate(path, {
      state: {
        from: location?.pathname,
        id,
        type,
        ...data,
      },
      replace: true
    });
  }, [path, location, navigate, setLastResend]);
};