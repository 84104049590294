import {Popover, PopoverContent, PopoverTrigger,} from "#ui/popover";
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "#ui/command";
import {Button} from "#ui/button";
import {Check, ChevronsUpDown} from "lucide-react";
import {useState} from "react";
import {cn} from "#lib/utils";

export const AssetSelector = ({value, onChange, assets}) => {
  const [open, setOpen] = useState(false)
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          size={'lg'}
          aria-expanded={open}
          className="text-base lg:text-lg justify-between border-border/70"
        >
          {value
            ? (<span>{value.symbol}</span>)
            : "Select framework..."}
          <ChevronsUpDown className="opacity-50"/>
        </Button>
      </PopoverTrigger>
      <PopoverContent align={'start'} className="p-0">
        <Command>
          <CommandInput placeholder="Search..." className="h-9"/>
          <CommandList>
            <CommandEmpty>
              No results found
            </CommandEmpty>
            <CommandGroup>
              {assets.map((asset) => (
                <CommandItem
                  key={asset.symbol}
                  value={asset.symbol}
                  onSelect={(currentValue) => {
                    onChange(currentValue)
                    setOpen(false)
                  }}
                >
                  <p className={'flex items-center gap-2 justify-between w-full'}>
                    <span>{asset.symbol}</span>
                    <small className={'text-muted-foreground'}>
                      {asset.name}
                    </small>
                  </p>
                  <Check
                    className={cn(
                      "ml-auto",
                      value.symbol === asset.symbol ? "opacity-100" : "opacity-0"
                    )}
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )

}