import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from "#ui/alert-dialog";
import {Trans, useTranslation} from "react-i18next";
import {useClosePositionMutation} from "#services/api/futures";
import {useToast} from "#hooks/use-toast";
import useErrorMessages from "#hooks/useErrorMessages";
import {ErrorNameSpaces} from "#src/constants/errors";
import {Spinner} from "#ui/spinner";

export const CloseModal = ({positionId, trigger, title, description, buttonText}) => {

  const [closePosition, {isLoading}] = useClosePositionMutation();
  const {toast} = useToast();
  const {t} = useTranslation();
  const {showError} = useErrorMessages(
    ErrorNameSpaces.FUTURES,
    t("Error"),
    t("Failed to close position")
  );

  const handleConfirm = async () => {
    try {
      await closePosition(positionId).unwrap();
      toast({
        title: t('Position closed'),
        variant: 'success',
        description: t('The position has been closed successfully'),
      });
    } catch (e) {
      console.error('Close position error:', e);
      showError(e?.data?.code, e?.data?.data);
    }
  }

  return (
    <AlertDialog>
      {trigger}
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {title}
          </AlertDialogTitle>
          <AlertDialogDescription>
            {description}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>
            <Trans>
              Cancel
            </Trans>
          </AlertDialogCancel>
          <AlertDialogAction onClick={handleConfirm} variant={'destructive'}>
            {isLoading ? <Spinner className={'text-destructive-foreground'}/> : buttonText}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}