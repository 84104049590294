import {useCallback, useMemo, useState} from "react";
import {TableCell, TableRow} from "#ui/table";
import CurrencyIcon from "#ui/crypto/CurrencyIcon";
import {Button} from "#ui/button";
import {Trans} from "react-i18next";
import {ChevronRight} from "lucide-react";
import {PlansButtons} from "./PlansButtons";
import {ChoosePlan} from "#pages/wallet/Staking/components/ChoosePlan";

export const StakingRow = ({staking, isMobile}) => {
  const lastPlan = useMemo(() => {
    return [...staking?.plans].reverse()[0]
  }, [staking.plans])
  const [selectedPlan, setSelectedPlan] = useState(lastPlan);
  const [open, setOpen] = useState(false)
  const onChooseHandler = useCallback(() => setOpen(true), [setOpen])
  return (
    <>
      <TableRow className={'cursor-pointer'} onClick={onChooseHandler}>
        <TableCell>
          <div className={'flex items-center gap-1'}>
            <CurrencyIcon symbol={staking.symbol} size={'small'}/>
            {isMobile ? staking.symbol : staking.name}
          </div>

        </TableCell>
        {!isMobile &&
          <TableCell>
            <PlansButtons
              plans={staking.plans}
              selectedPlan={selectedPlan}
              onSelectPlan={setSelectedPlan}
              disabled={false}
            />
          </TableCell>}
        <TableCell>
          {(selectedPlan || lastPlan)?.interest}%
        </TableCell>
        <TableCell>
          {staking.min} - {staking.max} {staking.symbol}
        </TableCell>

        <TableCell>

          <div className={'flex justify-end'}>
            <Button size={isMobile ? 'icon' : 'default'}>
              {!isMobile &&
                <Trans>
                  Choose plan
                </Trans>
              }
              <ChevronRight/>
            </Button>
          </div>
        </TableCell>
      </TableRow>
      <ChoosePlan
        open={open}
        stake={staking}
        onClose={setOpen}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
      />

    </>
  )
}