import {Navigate, Outlet} from "react-router-dom";
import Container from "#ui/container";

import Logo from "#components/common/Logo";
import {useSelector} from "react-redux";
import {selectUser} from "#store/slices/authSlice";
import LanguageChange from "#components/common/LanguageChange";


const AuthLayout = () => {
  const user = useSelector(selectUser)
  if (user) {
    return <Navigate to={'/wallet'} replace/>
  }
  return (
    <Container>
      <section className={'flex h-lvh items-center justify-center'}>
        <div className={'w-full md:w-2/3 lg:w-1/3'}>
          <div className={'flex items-center justify-between mb-5'}>
            <Logo/>
            <div>
              <LanguageChange/>
            </div>
          </div>
          <Outlet/>
        </div>
      </section>
    </Container>
  );
};

export default AuthLayout;