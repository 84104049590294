import {Outlet} from "react-router-dom";
import {Toaster} from "#ui/toaster";
import CookieConsent from "#components/CookieConsent";
import useReferralCode from "#hooks/auth/useReferralCode";
import {useEffect} from "react";

const MainLayout = () => {
  useReferralCode();
  useEffect(() => {
    // Додаємо клас 'android' до body для CSS-таргетингу
    if (/android/i.test(navigator.userAgent)) {
      document.documentElement.classList.add('android');
    }

    // Додаємо мета-тег viewport, якщо він ще не існує
    let viewportMeta = document.querySelector('meta[name="viewport"]');
    if (!viewportMeta) {
      viewportMeta = document.createElement('meta');
      viewportMeta.name = 'viewport';
      document.head.appendChild(viewportMeta);
    }
    viewportMeta.content = 'width=device-width, initial-scale=1.0, viewport-fit=cover';

    // Додаємо CSS для safe area
    const style = document.createElement('style');
    document.head.appendChild(style);

    return () => {
      // Видаляємо стиль при розмонтуванні, якщо потрібно
      if (document.head.contains(style)) {
        document.head.removeChild(style);
      }
    };
  }, []);
  return (<>
      <Outlet/>
      <Toaster/>
      <CookieConsent/>
    </>);
};

export default MainLayout;