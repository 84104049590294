import {useDispatch, useSelector} from "react-redux";

import {useCallback, useMemo, useRef} from "react";
import {
  setAccount,
  setNetwork,
  clearState,
  selectReceiveState,
} from "#store/slices/receiveSlice";

const useReceive = () => {
  const dispatch = useDispatch();
  const receiveApiRef = useRef({
    setAccount: useCallback((account) => dispatch(setAccount(account)), [dispatch]),
    setNetwork: useCallback((network) => dispatch(setNetwork(network)), [dispatch]),
    clearState: useCallback(() => dispatch(clearState()), [dispatch]),
  });
  const {
    account,
    network,
  } = useSelector(selectReceiveState);

  const apiRef = useMemo(() => ({
    ...receiveApiRef.current
  }), []);

  return useMemo(() => ({
    account,
    network,
    receiveApiRef: apiRef,
  }), [account, network, apiRef]);

}

export default useReceive;