import {Info} from "lucide-react";

const InfoNoteList = ({title, notes}) => {
  return (<div className="space-y-4 pt-2 mt-3">
    <p className="font-medium border-b pb-2 px-3">
      {title}
    </p>

    <div className="space-y-2 text-xs text-muted-foreground px-3">
      {notes.map((text, index) => (<div key={index} className="flex gap-2 items-center">
        <Info className="h-4 w-4 flex-shrink-0"/>
        <p>{text}</p>
      </div>))}
    </div>
  </div>);
};

export default InfoNoteList;