import {memo} from "react";
import {Card} from "#ui/card";
import {DetailItem} from "#components/common/DetailItem";
import {Trans} from "react-i18next";
import CurrencyFormat from "#ui/currency-format";
import {useSelector} from "react-redux";
import {selectBrandHasExchangeLimit} from "#store/slices/brandSlice";

const StatusDetails = memo(({status}) => {
  const hasExchangeLimit = useSelector(selectBrandHasExchangeLimit)
  const isSetExchangeLimit = status.exchangeLimit && status.exchangeLimitPeriod;


  return (
    <Card className={'shadow-none mb-3'}>
      <DetailItem title={<Trans>Total deposits</Trans>}>
        <CurrencyFormat value={status.minDeposit}/> {status?.currencies?.map(currency => currency).join('/')}
      </DetailItem>
      {(hasExchangeLimit && isSetExchangeLimit) && (
        <DetailItem title={<Trans>Limit per period</Trans>}>
          <Trans
            i18nKey={'exchangeLimit'}
            values={{
              exchangeLimit: status.exchangeLimit,
              exchangeLimitPeriod: status.exchangeLimitPeriod
            }}
          >
            {`{{exchangeLimit}} / {{exchangeLimitPeriod}} hours`}
          </Trans>
        </DetailItem>
      )}
    </Card>
  );
});

StatusDetails.displayName = 'StatusDetails';

export default StatusDetails;
