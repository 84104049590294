import {ButtonWithLoading} from "#ui/button";
import {Trans, useTranslation} from "react-i18next";
import {useCreateStakingMutation} from "#services/api/staking";
import {useToast} from "#hooks/use-toast";
import {useNavigate} from "react-router-dom";
import {ErrorNameSpaces} from "#src/constants/errors";
import useErrorMessages from "#hooks/useErrorMessages";


export const ChoosePlanButton = ({planId, amount, loading, ...props}) => {
  const [repeatStake, {isLoading}] = useCreateStakingMutation();
  const {toast} = useToast()
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {showError} = useErrorMessages(ErrorNameSpaces.STAKE)
  const submitHandler = async () => {
    try {
      const {id} = await repeatStake({
        planId, amount,
      }).unwrap();
      navigate('/wallet/staking/active/' + id);
      toast({
        title: t('Success'),
        description: t('Staking has been successfully created'),
        variant: 'success'
      })
    } catch (e) {
      showError(e.data?.code, e.data?.data || {});
    }
  }
  return (
    <ButtonWithLoading
      onClick={submitHandler}
      className={'w-full py-6 mt-2'}
      size={'lg'}
      loading={loading || isLoading}
      {...props}
    >
      <Trans>Choose plan</Trans>
    </ButtonWithLoading>
  );
};

