import {Card, CardContent, CardDescription, CardHeader, CardTitle} from "#ui/card";
import {FormField} from "#ui/form-field";
import {ButtonIcon, ButtonWithLoading} from "#ui/button";
import {LinkButton} from "#ui/link-button";
import React, {useCallback, useState} from "react";
import {EyeIcon, EyeOffIcon} from "lucide-react";
import {Trans} from "react-i18next";
import {CheckboxWithLabel} from "#ui/checkbox-with-label";
import {cn} from "#lib/utils";


export const AuthTermsCheckboxes = ({checked, onCheckedChange, children, isError}) => {
  const [error, setError] = useState(isError)
  const components = {
    terms: (<a href="/terms" className="text-primary hover:underline">
      Terms of Service
    </a>), privacy: (<a href="/privacy" className="text-primary hover:underline">
      Privacy Policy
    </a>), aml: (<a href="/aml" className="text-primary hover:underline">
      AML/KYC Policy
    </a>)
  }
  const onCheckedChangeHandler = useCallback((value) => {
    onCheckedChange({target: {name: 'terms', value}})
    setError(false)
  }, [onCheckedChange])
  return (<>
    <CheckboxWithLabel
      checked={checked}
      onCheckedChange={onCheckedChangeHandler}
    >
      <Trans i18nKey={'authTermsCheckboxes'} components={components}>
        {'By creating an account, I agree to <terms/>, <privacy/> and <aml/>'}
      </Trans>
    </CheckboxWithLabel>
    {error && (<p className={'text-sm text-destructive'}>
      <Trans>You must accept the terms to continue</Trans>
    </p>)}
    <div className={cn('relative')}>
      {!checked &&
        <span onClick={() => setError(true)} className={'absolute inset-0 cursor-pointer w-full h-full z-10'}/>}
      {children}
    </div>
  </>);
};


export const AuthForm = ({
                           title, description, children
                         }) => {
  return (<Card>
    <CardHeader>
      <CardTitle className="text-2xl">
        {title}
      </CardTitle>
      {description && (<CardDescription>
        {description}
      </CardDescription>)}
    </CardHeader>
    <CardContent>
      {children}
    </CardContent>
  </Card>);
};


export const AuthFormField = ({
                                name,
                                label,
                                type = "text",
                                placeholder,
                                isError,
                                onChange,
                                value,
                                required = false,
                                labelAfter,
                                message,
                                messageVariant,

                              }) => {
  return (<FormField
    name={name}
    label={label}
    type={type}
    placeholder={placeholder}
    isError={isError}
    onChange={onChange}
    value={value}
    required={required}
    labelAfter={labelAfter}
    messageProps={{
      message, variant: messageVariant
    }}
  />);
};

export const AuthPasswordFormField = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword((prev) => !prev);
  return (<FormField
    {...props}
    type={showPassword ? "text" : "password"}
    after={<ButtonIcon
      onClick={toggleShowPassword}
      variant="secondary"
      type="button"
      className="p-5"
    >
      {showPassword ? <EyeOffIcon/> : <EyeIcon/>}
    </ButtonIcon>}
  />)

}


export const AuthPasswordMessage = () => {
  return (<small className={'leading-none'}><Trans i18nKey="auth_register_password_message">
    Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one
    number, and one special character
  </Trans></small>);
}

export const AuthFormSubmit = (props) => {
  return (<div className="flex flex-col space-y-1.5">
    <ButtonWithLoading
      {...props}
      size="lg"
      className="py-6"
      type="submit"
    />
  </div>);
};


export const AuthFormFooter = ({
                                 to, children
                               }) => {
  return (<div className="flex items-center justify-center mt-2 text-sm">
    <LinkButton to={to} variant="muted">
      {children}
    </LinkButton>
  </div>);
};