import {cn} from "#lib/utils"

function Skeleton({
                    as: Component = "div",
                    className,
                    ...props
                  }) {
  return (
    (<Component
      className={cn("animate-pulse rounded-xs bg-primary/20", className)}
      {...props} />)
  );
}

export {Skeleton}
