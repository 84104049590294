import React from "react";
import {Trans} from "react-i18next";
import {Link} from "react-router-dom";
import Container from "#ui/container";
import {Button} from "#ui/button";
import Logo from "#components/common/Logo";

const Error404 = () => {
  return (
    <>
      <Container className={'h-screen flex flex-col gap-3 items-center justify-center'}>
        <Logo/>
        <h1 className={'text-center font-semibold text-xl mt-5'}>
          <Trans>
            Oops!
          </Trans>
        </h1>
        <p className={'text-center'}>
          <Trans>
            The requested resource was not found.
          </Trans>
        </p>
        <Button variant={'outline'} className={'mt-2'} size={'lg'} asChild>
          <Link to={'/'}>
            <Trans>
              Go home
            </Trans>
          </Link>
        </Button>
      </Container>

    </>
  );
};

export default Error404;