import {useEffect, useRef, useState} from "react";
import {cn} from "#lib/utils";

export const usePriTickerStatus = (defaultPrice, price) => {
  const [priceColor, setPriceColor] = useState('');
  const prevPriceRef = useRef(defaultPrice);
  const timeoutRef = useRef(null);
  useEffect(() => {
    const currentPrice = price || defaultPrice;
    if (!currentPrice || currentPrice === prevPriceRef.current) return;

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (currentPrice > prevPriceRef.current) {
      setPriceColor('growing');
    } else if (currentPrice < prevPriceRef.current) {
      setPriceColor('falling');
    }

    prevPriceRef.current = currentPrice;

    timeoutRef.current = setTimeout(() => {
      setPriceColor('');
    }, 1000);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [price, defaultPrice]);

  return cn(
      {'text-success': priceColor === 'growing'},
      {'text-destructive': priceColor === 'falling'},
    );
}