import {OrderFormInput} from "./OrderFormInput";
import {Trans} from "react-i18next";
import {Switch} from "#ui/switch";
import {Label} from "#ui/label";
import Decimal from "decimal.js";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger,} from "#ui/tooltip";
import formatDecimal from "#lib/formatDecimal";

export const SizeOrderInput = ({size, onChange, baseAsset, precision, useTpSl, setUseTpSl, minSize,error}) => {
  const isShowTooltip = !!size && new Decimal(size).lt(new Decimal(minSize));
  const input = (
    <OrderFormInput
      error={error}
      precision={precision}
      label={<Trans>Size</Trans>}
      afterLabel={
        <div className="flex items-center text-muted-foreground text-sm space-x-2">
          <Switch
            size={'sm'}
            checked={useTpSl}
            onCheckedChange={setUseTpSl}
            id="use-tp-sl"
          />
          <Label htmlFor="use-tp-sl">
            <Trans>
              Use TP/SL
            </Trans>
          </Label>
        </div>}
      value={size}
      onChange={onChange}
      asset={baseAsset}
    />
  );


  return (
    <TooltipProvider>
      <Tooltip open={isShowTooltip}>
        <TooltipTrigger asChild>
          {input}
        </TooltipTrigger>
        <TooltipContent>
          <p>
            <Trans values={{
              minSize: formatDecimal(minSize, precision),
              baseAsset: baseAsset,
            }}>
              {'Minimum size is {{minSize}} {{baseAsset}}'}
            </Trans>
          </p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};