import Decimal from "decimal.js";


export function formatWallet(input, options = {}) {
  if (typeof input !== 'string') {
    return '';
  }
  const startCount = options?.startCount || 2;
  const endCount = options?.endCount || 4;
  const start = input.slice(0, startCount);
  const end = input.slice(-endCount);
  const middle = '...';
  return start + middle + end;
}

export function convertToDecimal(str) {
  return str.replace(/^0+/, '0.').replace(/^0\.?$/, '0');
}


export const isAddressValidByNetwork = (network, address) => {
  if (!network) {
    return false;
  }
  const regex = new RegExp(network.walletRegex);
  return regex.test(address);
}

export const isMemoValidByNetwork = (network, memo) => {
  if (!network) {
    return false;
  }
  const regex = new RegExp(network.memoRegex);
  return regex.test(memo);
}

export const findNetworkByAddressRegex = (networks, address) => {
  return networks.find((network) => isAddressValidByNetwork(network, address));
}


export const filterTransactionsByCurrencyAndGateway = (transactions, currency, gateway) => {
  return transactions.filter((transaction) => {
    return transaction.currency === currency && transaction.gateway === gateway
  })
}


export const convertCurrency = (fromCurrency, toCurrency, amount) => {
  if (fromCurrency.symbol === toCurrency.symbol || amount === '') {
    return amount;
  }
  const fromRate = fromCurrency.price;
  const toRate = toCurrency.price;
  const crossRate = fromRate / toRate;
  return amount * crossRate;
};


export const calculateRate = (fromCurrencyRate, toCurrencyRate) => {
  return new Decimal(fromCurrencyRate).div(toCurrencyRate).toDecimalPlaces(8).toString();
}


