import {useState} from "react";
import {useForgotPasswordMutation} from "#services/api/auth";
import useErrorMessages from "#hooks/useErrorMessages";
import {ErrorNameSpaces} from "#src/constants/errors";
import {useOtpNavigation} from "#hooks/otp/useOtpNavigation";
import {OTP_TYPES} from "#src/constants/auth";

const useForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [forgotPassword, {isLoading}] = useForgotPasswordMutation();
  const {showError} = useErrorMessages(ErrorNameSpaces.FORGOT_PASSWORD);
  const navigationToOTP = useOtpNavigation(
    "/auth/reset-password"
  );

  async function handleSubmit(e) {
    e.preventDefault()
    try {
      const {id} = await forgotPassword({email}).unwrap()
      navigationToOTP({id, type: OTP_TYPES.PASSWORD_RESET})
    } catch (e) {
      const error = e?.data?.code;
      showError(error);
    }
  }

  const handleChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  }

  return {email, isLoading, handleSubmit, handleChange};
}

export default useForgotPassword;