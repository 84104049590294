import React, {memo} from "react";
import {ResponsiveDialogClose, ResponsiveDialogTrigger} from "#ui/responsive-dialog";
import {Button, ButtonWithLoading} from "#ui/button";
import {Trans} from "react-i18next";
import {ArrowRight, CircleOff, MoveRight} from "lucide-react";

export const StartVerificationButton = memo(() => (
  <ResponsiveDialogTrigger asChild>
    <Button variant="outline" className="w-full">
      <Trans>Start verification</Trans>
      <MoveRight className="w-4 h-4"/>
    </Button>
  </ResponsiveDialogTrigger>
));

export const ResendVerificationButton = memo(() => (
  <ResponsiveDialogTrigger asChild>
    <Button variant="destructive" className="w-full">
      <CircleOff className="w-4 h-4"/>
      <Trans>Resend verification</Trans>
    </Button>
  </ResponsiveDialogTrigger>
));

export const StepActions = memo(({type, onSubmit, canSubmit, onNext, canNext, isLoading, ...props}) => {
  switch (type) {
    case 'submit':
      return (
        <ButtonWithLoading
          loading={isLoading}
          className="w-full"
          onClick={onSubmit}
          disabled={!canSubmit}
          {...props}
        >
          <Trans>Submit</Trans>
          <ArrowRight className="w-4 h-4"/>
        </ButtonWithLoading>
      );

    case 'next':
      return (
        <Button
          className="w-full"
          onClick={onNext}
          disabled={!canNext}
          {...props}
        >
          <Trans>Next</Trans>
          <ArrowRight className="w-4 h-4"/>
        </Button>
      );

    case 'close':
      return (
        <ResponsiveDialogClose asChild>
          <Button variant={'outline'} className="w-full" {...props}>
            <Trans>Close</Trans>
          </Button>
        </ResponsiveDialogClose>
      );

    default:
      return null;
  }
})

StepActions.displayName = 'StepActions';

