import PageHeader from "#components/common/PageHeader";
import {useTranslation} from "react-i18next";

export const StakingHeader = ({children}) => {
  const {t} = useTranslation()
  return (
    <PageHeader title={t('Staking liquidity')}
                description={t(
                  "stakingDescr",
                  "Is a simple yet effective crypto lending tool – it helps you to get a profit by lending your crypto assets. " +
                  "Choose the best plan for you depending on its rate, period, and lending amount and start earning right now."
                )}>
      {children}
    </PageHeader>)
};
