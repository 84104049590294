import {darken as darkenColor, guard, parseToHsla, parseToRgba} from "color2k";

const isHexOrRgb = (color) => {
  return color.startsWith('#') || color.startsWith('rgb');
}

const hsl = (hue, saturation, lightness) => {
  return `${(hue % 360).toFixed()} ${guard(
    0,
    100,
    saturation * 100
  ).toFixed()}% ${guard(0, 100, lightness * 100).toFixed()}%`;
}

export const toHslStr = (color) => {
  const [hue, saturation, lightness] = parseToHsla(color);
  return hsl(hue, saturation, lightness);
}


export const lighten = (color, amount) => {
  const [hue, saturation, lightness] = parseToHsla(color);
  return hsl(hue, saturation, lightness + amount);
}

export function hslStringToHex(hslString) {
  // Розбиваємо рядок та видаляємо символи %
  const [h, s, l] = hslString.split(' ').map(v => v.replace('%', ''));

  // Створюємо правильний HSL формат з комами
  const hslColor = `hsl(${h}, ${s}%, ${l}%)`;

  // Конвертуємо в RGBA
  const [r, g, b] = parseToRgba(hslColor);

  // Конвертуємо RGB в HEX
  const toHex = (n) => {
    const hex = Math.round(n).toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

export const darken = (color, amount) => {
  const [hue, saturation, lightness] = parseToHsla(color);
  return hsl(hue, saturation, lightness - amount);
}

// Функція для перевірки світлості кольору
export function isLightColor(hexColor) {
  const rgba = parseToRgba(hexColor);
  if (!rgba) return true;
  const [r, g, b] = rgba;
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.5;
}

export function isLightColorHSLA(hslaString) {
  // Витягуємо значення з hsla(240, 11%, 0%, 1)
  const values = hslaString.match(/hsla?\(([^)]+)\)/)[1].split(',').map(v => parseFloat(v));
  const l = values[2];
  // Спрощений варіант - перевіряємо тільки lightness
  return l > 50;
}

// Функція для валідації кольору
export function isValidHexColor(color) {
  if (!color) return false;
  return /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(color);
}

export function generateThemeColors({
                                      primary = '#000000',
                                      secondary = '#FFFFFF',
                                      highlight = '#FFFFFF',
                                      background = '#FFFFFF',
                                      foreground = '#000000',
                                      destructive = '#FF0000',
                                      success = '#00FF00',
                                      chartColors = []
                                    }) {
  // Перевіряємо валідність кольорів
  if (!isValidHexColor(primary)) primary = '#000000';
  if (!isValidHexColor(secondary)) secondary = '#FFFFFF';
  if (!isValidHexColor(highlight)) highlight = '#FFFFFF';
  if (!isValidHexColor(background)) background = '#FFFFFF';
  if (!isValidHexColor(foreground)) foreground = '#000000';
  if (!isValidHexColor(destructive)) destructive = '#FF0000';
  if (!isValidHexColor(success)) destructive = '#00FF00';

  // Фільтруємо невалідні кольори з chartColors
  const validChartColors = chartColors.filter(isValidHexColor);
  const secondaryColor = secondary
  const secondaryColorForeground = isLightColor(secondary) ? darken(secondary, 0.8) : lighten(secondary, 0.8)
  const common = {
    primary: primary,
    ring: primary,
    'primary-foreground': isLightColor(primary) ? darken(primary, 0.8) : lighten(primary, 0.8),
    secondary: secondaryColor,
    'secondary-foreground': secondaryColorForeground,
    accent: secondaryColor,
    'accent-foreground': secondaryColorForeground,
    success: success,
    'success-foreground': isLightColor(success) ? darken(success, 0.8) : lighten(success, 0.8),
    highlight: highlight,
    'highlight-foreground': isLightColor(highlight) ? darken(highlight, 0.8) : lighten(highlight, 0.8),
    destructive: destructive,
    'destructive-foreground': isLightColor(destructive) ? darken(destructive, 0.8) : lighten(destructive, 0.8), ...validChartColors.reduce((acc, color, index) => ({
      ...acc, [`chart-${index + 1}`]: color
    }), {})
  }

  // Генеруємо світлу тему
  const light = {
    ...common,
    background: background,
    foreground: foreground,
    card: background,
    'card-foreground': foreground,
    popover: background,
    'popover-foreground': foreground,
    muted: darken(background, 0.1),
    'muted-foreground': lighten(foreground, 0.3),
    border: darken(background, 0.2),
    input: darken(background, 0.3),
  };

  // Генеруємо темну тему
  const dark = {
    ...common,
    background: foreground,
    foreground: background,
    card: lighten(foreground, 0.09),
    'card-foreground': background,
    popover: darken(foreground, 0.9),
    'popover-foreground': background,
    muted: darken(background, 0.85),
    'muted-foreground': lighten(foreground, 0.7),
    border: lighten(foreground, 0.2),
    input: lighten(foreground, 0.4),
  };
  if (isLightColor(secondary)) {
    const darkenSecondary = darkenColor(secondary, 0.70);
    dark.secondary = toHslStr(darkenSecondary);
    dark['secondary-foreground'] = isLightColorHSLA(darkenSecondary) ? darken(darkenSecondary, 0.8) : lighten(darkenSecondary, 0.8);
    dark.accent = dark.secondary;
    dark['accent-foreground'] = dark['secondary-foreground'];
  }

  const themes = {
    light,
    dark
  };
  for (let theme of Object.values(themes)) {
    for (let [key, value] of Object.entries(theme)) {
      if (isHexOrRgb(value)) {
        theme[key] = toHslStr(value);
      }
    }
  }
  return themes;
}

export const getThemeFromStorage = () => {
  const theme = localStorage.getItem('ui-theme');
  return theme ? JSON.parse(theme).themeStyle : 'default';
}


export const getCssVar = (name) => {
  return getComputedStyle(document.documentElement).getPropertyValue(name);
}