import {Spinner} from "#ui/spinner";
import {useEffect, useState} from "react";

const texts = [
  "Please wait while we load the content...",
  "Veuillez patienter pendant que nous chargeons le contenu...",
  "Bitte warten Sie, während wir den Inhalt laden...",
  "Por favor, espere mientras cargamos el contenido...",
  "Attendere prego mentre carichiamo il contenuto...",
  "Por favor, aguarde enquanto carregamos o conteúdo...",
  "Lütfen içeriği yüklerken bekleyin...",
  "请稍候，我们正在加载内容...",
  "コンテンツを読み込んでいる間、お待ちください...",
  "콘텐츠를 로드하는 동안 기다려주십시오..."
];

const LanguageLoading = (props) => {
  const [text, setText] = useState(texts[0]);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const showTimer = setTimeout(() => setIsVisible(true), 200);

    const interval = setInterval(() => {
      setText(texts[Math.floor(Math.random() * texts.length)]);
    }, 500);

    return () => {
      clearInterval(interval);
      clearTimeout(showTimer);
    };
  }, []);

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-background flex flex-col text-center items-center justify-center">
      <Spinner size="lg" variant={'primary'} {...props} />
      <p className="mt-4 text-base animate-fade-in">
        {text}
      </p>
    </div>
  );
};

export default LanguageLoading;