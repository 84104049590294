import {Card} from "#ui/card";
import {ArrowRightLeft, Gem, Shield, Wallet} from "lucide-react";
import {Trans} from "react-i18next";
import React, {memo, useRef} from "react";
import Container from "#ui/container";
import FadeIn from "#ui/FadeIn";
import {SectionHeader} from "#pages/Home/components/SectionHeader";


export const features = [{
  Icon: Wallet,
  title: <Trans>Multi-Currency Wallet</Trans>,
  description: <Trans>Store and manage 10+ cryptocurrencies in one secure place</Trans>
}, {
  Icon: ArrowRightLeft,
  title: <Trans>Instant Exchange</Trans>,
  description: <Trans>Swap your assets with minimal fees and maximum speed</Trans>
}, {
  Icon: Shield,
  title: <Trans>Bank-Grade Security</Trans>,
  description: <Trans>Your assets are protected with military-grade encryption</Trans>
}, {
  Icon: Gem,
  title: <Trans>Premium Features</Trans>,
  description: <Trans>Access advanced trading tools and real-time analytics</Trans>
}];
export const Features = memo(() => {
  const containerRef = useRef(null)
  return (<section ref={containerRef} className="pb-20 pt-10 relative z-10">
    <div className="absolute inset-0 bg-gradient-to-b from-transparent to-accent/80"/>
    <Container>
      <div className="text-center mb-16 space-y-4">
        <SectionHeader
          title={
            <Trans
              i18nKey={'firstHomeOneWallet'}
            >
              One Wallet. Endless Possibilities.
            </Trans>}
          description={
            <Trans>
              Everything you need to manage your digital assets
            </Trans>
          }
        />
      </div>

      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
        {features.map(({Icon, ...feature}, i) => {
          return (<FadeIn key={i}
                          className={'duration-1000'}>
              <Card
                className="group w-full h-full relative overflow-hidden bg-card hover:bg-accent/50
                border border-accent transition-colors duration-300"
              >
                <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r
                  from-primary/0 via-highlight/50 to-highlight/0
                  transform scale-x-0 group-hover:scale-x-100 transition-transform duration-500"
                />
                <div className="p-6 space-y-4">
                  <div className="text-primary group-hover:text-highlight transition-colors duration-300">
                    <Icon strokeWidth={1} className="w-8 h-8"/>
                  </div>
                  <h3 className="text-xl font-semibold">{feature.title}</h3>
                  <p className="text-muted-foreground">{feature.description}</p>
                </div>
              </Card>
            </FadeIn>

          );
        })}
      </div>
    </Container>
  </section>);
})