import {NavigationMenu, NavigationMenuItem, NavigationMenuList} from "#ui/navigation-menu";
import {Button} from "#ui/button";
import {Archive, ArrowRightLeft, ArrowUp, Plus} from "lucide-react";
import {cn} from "#lib/utils";
import {Link, useLocation} from "react-router-dom";
import {Trans} from "react-i18next";

const ActionNavigationButton = ({label, size, variant, path, icon: Icon, isMobile, symbol}) => {
  size = isMobile ? 'icon' : size
  const linkBuilder = (path) => {
    if (!symbol) {
      return path
    }
    return `${path}/${symbol}`
  }

  return (<NavigationMenuItem asChild className={cn({'flex flex-col items-center justify-center gap-1': isMobile},)}>
    <Link to={linkBuilder(path)}>
      <Button size={size} className={cn({'p-3.5 w-auto h-auto': isMobile})} variant={variant}>
        <Icon/> {!isMobile && label}
      </Button>
      {isMobile && label}
    </Link>
  </NavigationMenuItem>);
};

const HistoryNavigationButton = ({size, isMobile, accountId, isLink = false}) => {
  const path = !accountId ? '/wallet/history' : `/wallet/history?accountId=${accountId}`
  const buttonProps = {
    size, variant: isLink ? 'link' : 'ghost', path,
    icon: Archive, label: <Trans>History</Trans>, isMobile
  }
  return <ActionNavigationButton {...buttonProps}/>
}

const ActionsButtonsNavigation = ({
                                    compact = false,
                                    variant = 'default',
                                    symbol,
                                    size = 'lg',
                                    accountId,
                                    className
                                  }) => {
  const currentPath = useLocation().pathname
  const buttons = [
    {
      label: <Trans>Send</Trans>, path: '/wallet/send', variant: variant, icon: ArrowUp
    },
    {
      label: <Trans>Deposit</Trans>, path: '/wallet/receive', variant: variant, icon: Plus
    },
    {
      label: <Trans>Convert</Trans>, path: '/wallet/swap', variant: variant, icon: ArrowRightLeft
    },
  ]
  return (<NavigationMenu className={cn("justify-end", {'!w-full max-w-full [&>*:first-child]:w-full': compact})}>
    <NavigationMenuList className={cn('space-x-0 gap-3 justify-between', className)}>
      {
        buttons.map((button, index) => {
          if (currentPath.startsWith(button.path)) return null
          return (
            <ActionNavigationButton
              size={size}
              key={index}
              isMobile={compact}
              symbol={symbol}
              {...button}
            />)
        })
      }
      <HistoryNavigationButton isLink={variant === 'link'} size={size} isMobile={compact} accountId={accountId}/>
    </NavigationMenuList>
  </NavigationMenu>);
};

export default ActionsButtonsNavigation;