import {Checkbox} from "#ui/checkbox";
import {Label} from "#ui/label";
import React from "react";

const CheckboxWithLabel = React.forwardRef(({children, ...props}, ref) => {
  return (<Label
    ref={ref}
    className="text-sm flex gap-2 items-center cursor-pointer  peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
  >
    <Checkbox
      {...props}
    />
    <p>
      {children}
    </p>
  </Label>);
})

export {CheckboxWithLabel};