import React from 'react';
import {Card} from "#ui/card";
import {Trans} from "react-i18next";
import {useSelector} from "react-redux";
import {selectUser} from "#store/slices/authSlice";
import UserAvatar from "#components/common/UserAvatar";
import Container from "#ui/container";
import useCardVariants from "#hooks/useCardVariants";
import {cn} from "#lib/utils";
import {ProfileSection,} from "./components";
import useGetProfileSections from "#pages/profile/Profile/useGetProfileSections";

const Profile = () => {
  const user = useSelector(selectUser);
  const cardVariant = useCardVariants()
  const sections = useGetProfileSections(user);

  return (
    <Container className="py-4">
      <div className="mx-auto space-y-8">
        <Card
          className={cn(cardVariant, "border-none px-6 py-10")}>
          <div className="flex flex-col items-center space-y-4">
            <UserAvatar
              user={user}
              className="w-24 h-24 border-4 border-background shadow-xl"
            />
            <div className="text-center">
              <h2 className="text-2xl font-bold">{user.email}</h2>
              <p>
                <Trans>Account Settings</Trans>
              </p>
            </div>
          </div>
        </Card>

        <div className="grid gap-4 md:grid-cols-4">
          {sections.map((section, index) => (
            <ProfileSection
              key={index}
              icon={section.icon}
              title={section.title}
              description={section.description}
            >
              {section.component}
            </ProfileSection>
          ))}

        </div>
      </div>
    </Container>
  );
};

export default Profile;