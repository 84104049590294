import {Card} from "#ui/card";
import {RatingStars} from "./RatingStars";

export const TestimonialCard = ({testimonial}) => (
  <Card className="p-6 w-[400px] shrink-0 bg-card/50 backdrop-blur-2xl border border-accent/20">
    <div className="flex items-start justify-between gap-4">
      <div className="flex gap-2">
        <img
          src={testimonial.avatar}
          alt={testimonial.name}
          className="w-12 h-12 rounded-full object-cover"
        />
        <div>
          <div className="font-semibold">{testimonial.name}</div>
          <div className="text-sm text-muted-foreground mb-2">{testimonial.role}</div>
        </div>
      </div>

      <RatingStars rating={testimonial.rating}/>
    </div>
    <p className="text-muted-foreground">{testimonial.content}</p>
  </Card>
);