// import {memo, useEffect, useMemo, useRef, useState, useCallback} from "react";
// import {useNavigate, useParams} from "react-router-dom";
// import {useDebounce} from "@uidotdev/usehooks";
// import {Popover, PopoverContent} from "#ui/popover";
// import AccountTriggerButton from "#components/wallet/AccountSelector/AccountTriggerButton";
// import SearchInput from "#components/wallet/AccountSelector/SearchInput";
// import AccountsList from "#components/wallet/AccountSelector/AccountsList";
// import {useGetAccountsQuery} from "#services/api/accounts";
// import {Command} from "#ui/command";
//
// function SelectAccount({selectedAccount, onSelect, path}) {
//   const {symbol} = useParams();
//
//   const [open, setOpen] = useState(false);
//   const [searchQuery, setSearchQuery] = useState(symbol);
//   const navigate = useNavigate();
//   const debounceSearch = useDebounce(searchQuery, 300);
//   const containerRef = useRef(null);
//   const isInitialLoadRef = useRef(!selectedAccount);
//
//
//   // Окремий запит для початкового завантаження
//   const {data: initialData = {}, isLoading: isInitLoading} = useGetAccountsQuery({
//     page: 1,
//     limit: 20,
//     search: symbol || null
//   }, {
//     skip: !isInitialLoadRef.current,
//     refetchOnMountOrArgChange: true
//   });
//
//   // Запит для пошуку при відкритому селекторі
//   const {data: searchData = {}, isLoading, isFetching} = useGetAccountsQuery({
//     page: 1,
//     limit: 20,
//     search: debounceSearch || null
//   }, {
//     skip: !open,
//     refetchOnMountOrArgChange: true
//   });
//
//   // Використовуємо різні дані залежно від стану
//   const accounts = useMemo(() => {
//     if (open) {
//       return searchData?.accounts || [];
//     }
//     return initialData?.accounts || [];
//   }, [open, searchData?.accounts, initialData?.accounts]);
//
//
//   // Ефект для початкової вибірки акаунта
//   useEffect(() => {
//     if (isInitialLoadRef.current && initialData?.accounts?.length > 0) {
//       const account = initialData.accounts.find(acc => acc.symbol === symbol) || initialData.accounts[0];
//       onSelect(account);
//       isInitialLoadRef.current = false;
//     }
//
//   }, [initialData.accounts, symbol, onSelect, selectedAccount]);
//
//     const handleSelect = useCallback((account) => {
//     navigate(`${path}${account.symbol}`);
//     setOpen(false);
//     onSelect(account);
//   }, [navigate, onSelect, path]);
//   return (
//     <div ref={containerRef}>
//       <Popover open={open} onOpenChange={setOpen}>
//         <AccountTriggerButton
//           selectedAccount={selectedAccount}
//           isLoading={isLoading || isFetching || isInitLoading}
//         />
//         <PopoverContent style={{
//           width: containerRef?.current?.offsetWidth + 'px'
//         }} container={containerRef?.current} align={'start'} className="p-0 w-full">
//           <Command shouldFilter={false}>
//             <SearchInput
//               value={searchQuery}
//               onChange={setSearchQuery}
//             />
//             <AccountsList
//               accounts={accounts}
//               isLoading={isLoading}
//               selectedAccount={selectedAccount}
//               onSelect={handleSelect}
//             />
//           </Command>
//         </PopoverContent>
//       </Popover>
//     </div>
//   );
// }
//
// export default memo(SelectAccount);

import AccountSelector from "./AccountSelector";
export default AccountSelector;