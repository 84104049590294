import React from 'react';
import {FormField} from "#ui/form-field";
import {Button} from "#ui/button";
import {Trans} from "react-i18next";
import {XIcon} from "lucide-react";
import {cn} from "#lib/utils";

export const PasteButton = ({onPaste, disabled}) => {
  const handlePaste = async () => {
    try {
      const text = await navigator.clipboard.readText();
      onPaste?.(text);
    } catch (error) {
      console.error('Failed to read clipboard:', error);
    }
  };
  return (<Button
    onClick={handlePaste}
    disabled={disabled}
    className="absolute top-1/2 -translate-y-1/2 right-1"
    variant="ghost"
  >
    <Trans>
      Paste
    </Trans>
  </Button>)
}

export const ClearButton = ({onClick, disabled}) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      className="absolute top-1/2 -translate-y-1/2 right-1"
      variant="ghost"
      size={'icon'}
    >
      <XIcon/>
    </Button>)
}

const PasteFormField = ({
                        className,
                        onPaste,
                        disabled,
                        ...props
                      }) => {
  const isClearable = !disabled && props.value;

  return (
    <FormField
      {...props}
      disabled={disabled}
      className={cn('pr-10',className)}
      after={
        isClearable ? <ClearButton disabled={disabled} onClick={() => onPaste('')}/> :
          <PasteButton onPaste={onPaste} disabled={disabled}/>
      }
    />
  );
};

export default PasteFormField;