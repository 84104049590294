import { memo } from "react";
import { Button } from "#ui/button";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";

const StatusUpgradeButton = memo(({ currency }) => (
  <Button size={'lg'} className={'w-full py-6 mt-3'} asChild>
    <Link to={'/wallet/receive/' + currency}>
      <Trans>Upgrade</Trans>
    </Link>
  </Button>
));

StatusUpgradeButton.displayName = 'StatusUpgradeButton';

export default StatusUpgradeButton;
