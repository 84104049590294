// components/transactions/TransactionDetailsModalSkeleton.jsx
import { SheetHeader, SheetTitle } from "#ui/sheet";
import { Card } from "#ui/card";
import Section from "#ui/section";
import { Skeleton } from "#ui/skeleton";
import { Trans } from "react-i18next";

const DetailItemSkeleton = () => (
  <div className={'px-3 py-1 first:pt-2 last:pb-2 border-b last:border-0'}>
    <small className={'text-muted-foreground'}>
      <Skeleton className="h-4 w-16" />
    </small>
    <div>
      <Skeleton className="h-5 w-32" />
    </div>
  </div>
);

const SectionSkeleton = ({ count = 3 }) => (
  <Section header={<Trans>Payment details</Trans>}>
    <Card>
      {Array(count).fill(null).map((_, i) => (
        <DetailItemSkeleton key={i} />
      ))}
    </Card>
  </Section>
);

const ExchangeDetailsSkeleton = () => (
  <Section>
    <Card>
      <DetailItemSkeleton />
      <DetailItemSkeleton />
    </Card>
  </Section>
);

const ExplorerLinkSkeleton = () => (
  <Section>
    <Skeleton className="h-11 w-full" />
  </Section>
);

const TransactionDetailsModalContentSkeleton = () => {
  return (
    <>
      <SheetHeader>
        <SheetTitle className={'mb-3'}>
          <div className={'flex items-center gap-2'}>
            {/* Icon skeleton */}
            <Skeleton className="h-8 w-8 rounded-md" />
            {/* Transaction info skeleton */}
            <Skeleton className="h-5 w-48" />
          </div>
        </SheetTitle>
      </SheetHeader>
      <div className={'flex flex-col gap-3'}>
        <SectionSkeleton count={4} />
        <ExchangeDetailsSkeleton />
        <ExplorerLinkSkeleton />
      </div>
    </>
  );
};

export default TransactionDetailsModalContentSkeleton;
