import {Avatar, AvatarFallback, AvatarImage} from "#ui/avatar";
import {cva} from "class-variance-authority";

const sizeVariants = cva('', {
  variants: {
    size: {
      small: 'h-6 w-6',
      medium: 'h-8 w-8',
      large: 'h-10 w-10',
    }
  },
  defaultVariants: {
    size: 'medium'
  }
})
const CurrencyIcon = ({symbol, size}) => {
  let iconPath;
  const symbolFormatted = symbol?.toLowerCase()?.trim();
  try {
    iconPath = require(`cryptocurrency-icons/128/color/${symbolFormatted}.png`);
  } catch (e) {
    iconPath = `https://nowpayments.io/images/coins/${symbolFormatted}.svg`;
  }
  return (
    <Avatar className={sizeVariants({size})}>
      <AvatarImage src={iconPath} alt={symbol}/>
      <AvatarFallback>{symbol?.slice(0, 2)}</AvatarFallback>
    </Avatar>
  );
};

export default CurrencyIcon;