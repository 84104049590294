import {useDebounce} from "@uidotdev/usehooks";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";

const useSearch = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(() => searchParams.get('search') || null);
  const debounceSearch = useDebounce(search, 300);

  useEffect(() => {
    if (debounceSearch === null) return;

    setSearchParams(prev => {
      const params = new URLSearchParams(prev);
      const oldSearch = params.get('search');
      
      // Перевіряємо, чи змінився саме пошуковий запит
      if (oldSearch !== debounceSearch) {
        params.set('search', debounceSearch.toString());
        if (debounceSearch === '') {
          params.delete('search');
        }
        // Скидаємо сторінку тільки якщо змінився пошук
        const page = parseInt(params.get('page'));
        if (page && page > 1) {
          params.set('page', '1');
        }
      }

      return params;
    });
  }, [debounceSearch, setSearchParams]);

  return [search, setSearch];
};

export default useSearch;