import {useCallback} from "react";
import {Trans} from "react-i18next";
import {Frown} from "lucide-react";
import {Button} from "#ui/button";

const EmptyCryptoAccounts = () => {
  const reloadHandler = useCallback(() => {
    window.location.reload();
  }, [])
  return (
    <div className="flex flex-col gap-4 items-center py-10 justify-center min-h-40">
      <Frown className="w-12 h-12 text-primary"/>
      <div className={'text-center'}>
        <p className="text-xl">
          <Trans i18nKey={'no_accounts'}>
            Accounts not loaded
          </Trans>
        </p>
        <p className={'text-muted-foreground'}>
          <Trans>
            Please reload the page or contact support
          </Trans>
        </p>
      </div>

      <div className={'flex items-center gap-2 justify-center'}>
        <Button variant={'outline'} onClick={reloadHandler}>
          <Trans>
            Reload Page
          </Trans>
        </Button>
      </div>
    </div>
  )
}
export default EmptyCryptoAccounts;