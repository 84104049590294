import {Trans} from "react-i18next";

const Section = ({header, children, ...props}) => {
  return (
    <div {...props}>

      <p className={'text-muted-foreground mb-1 inline-block font-semibold'}>
        {header ? header : <Trans>Payment details</Trans>}
      </p>

      {children}
    </div>
  );
};

export default Section;