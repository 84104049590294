import {cn} from "#lib/utils";
import CurrencyIcon from "#ui/crypto/CurrencyIcon";
import CurrencyFormat from "#ui/currency-format";

const AccountInfo = ({account, className}) => {
  return (
    <div className={cn("flex  gap-2 w-full", className)}>
      <CurrencyIcon symbol={account?.symbol} size="small"/>
      <div className={'flex flex-col gap-1 w-full'}>
        <div className={'flex items-center justify-between'}>
          <span>{account?.symbol}</span>
          <span className="font-bold ml-auto">
            <CurrencyFormat value={account.balance}/>
          </span>
        </div>
        <div className={'flex items-center justify-between text-xs'}>
          <span className="text-muted-foreground">{account?.name}</span>
          <span className="text-muted-foreground ml-auto">
           ≈ $<CurrencyFormat value={account?.fiatBalance}/>
          </span>
        </div>

      </div>

    </div>
  )
}
export default AccountInfo;