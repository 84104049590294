import {cn} from "#lib/utils";
import useCardVariants from "#hooks/useCardVariants";

const PageHeader = ({title, description, children, className}) => {
  const cardVariant = useCardVariants();
  return (
    <div
      className={cn(
        cardVariant,
        'text-center md:text-left md:rtl:text-right mb-5 p-5 md:p-7 rounded-lg flex flex-col md:flex-row gap-5 items-center md:items-end justify-between',
        className
      )}>
      <div>
        <h1 className={cn('text-4xl  font-bold', {'mb-3': !!description})}>
          {title}
        </h1>
        {description && (<p className={'text-sm md:text-lg opacity-90 lg:max-w-screen-md'}>
          {description}
        </p>)}
      </div>
      {children}
    </div>
  );
};

export default PageHeader;