import {Trans} from "react-i18next";
import formatDecimal from "#lib/formatDecimal";
import {useIsMobile} from "#hooks/use-mobile";
import {Popover, PopoverContent, PopoverTrigger} from "#ui/popover";
import {Button} from "#ui/button";
import {Info} from "lucide-react";

export const AssetInfoItem = ({label, value, precision}) => {
  if (!value) return null;
  return (
    <div className={'flex flex-col text-xs shrink-0'}>
      <small className={'text-muted-foreground'}>{label}</small>
      <span>{formatDecimal(value, precision)}</span>
    </div>
  );
}

const DesktopAssetInfo = ({data, quantityPrecision}) => (
  <div className={'flex items-center gap-3'}>
    {data.map((item, index) => (
      <AssetInfoItem key={index} {...item} precision={quantityPrecision}/>
    ))}
  </div>
)

const MobileAssetInfo = ({data, quantityPrecision}) => (
  <Popover>
    <PopoverTrigger asChild>
      <Button variant="outline" size={'icon'}><Info/></Button>
    </PopoverTrigger>
    <PopoverContent className="w-80">
      <div className={'flex flex-col gap-2'}>
        {data.map((item, index) => (
          <AssetInfoItem key={index} {...item} precision={quantityPrecision}/>
        ))}
      </div>
    </PopoverContent>
  </Popover>
)


export const AssetInfo = ({asset}) => {
  const isMobile = useIsMobile();
  const {
    volume24,
    volumePerMarketCap,
    athPrice,
    atlPrice,
    quantityPrecision,
  } = asset;
  const data = [
    {
      label: <Trans>24h Volume</Trans>,
      value: volume24,
    },
    {
      label: <Trans i18nKey={'VolMarketCap'}>Vol. / Market Cap</Trans>,
      value: volumePerMarketCap,
    },
    {
      label: <Trans>ATH Price</Trans>,
      value: athPrice,
    },
    {
      label: <Trans>ATL Price</Trans>,
      value: atlPrice,
    },
  ]
  const hasValues = data.some(({value}) => value);
  if (!hasValues) return null;
  return isMobile ? <MobileAssetInfo data={data} quantityPrecision={quantityPrecision}/> : <DesktopAssetInfo data={data} quantityPrecision={quantityPrecision}/>

}