import {WithBackgroundGrid} from "#components/common/BackgroundGrid";
import {GradientOverlay} from "#components/common/CryptoCarousel/GradientOverlay";
import Container from "#ui/container";
import testimonials, {splitTestimonials} from "./testimonials-data";
import {TestimonialHeader} from "./TestimonialHeader";
import {MarqueeRow} from "./MarqueeRow";
import FadeIn from "#ui/FadeIn"

export const Testimonials = () => {
  const [first, second] = splitTestimonials(testimonials);
  return (<WithBackgroundGrid className="py-24 relative min-h-full">
    <Container>
      <TestimonialHeader/>
    </Container>
    <div className={'relative'}>
      <GradientOverlay/>
      <GradientOverlay direction="right"/>
      <FadeIn>
        <MarqueeRow testimonials={first}/>
      </FadeIn>
      <FadeIn>
        <MarqueeRow testimonials={second} direction="reverse"/>
      </FadeIn>
    </div>
  </WithBackgroundGrid>);
};