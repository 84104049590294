import { memo } from 'react';
import { Skeleton } from "#ui/skeleton";

const ReceiveDirectionSkeleton = memo(() => {
  return (
    <div>
      <Skeleton className="h-12 mb-2" />
      
      <div className="flex flex-col sm:flex-row items-center gap-2">
        <div>
          <Skeleton className="w-32 h-32" />
        </div>
        
        <div className="flex-1 w-full space-y-2">
          <div className="space-y-1.5">
            <Skeleton className="h-4 w-24" />
            <Skeleton className="h-10" />
          </div>
          
          <div className="space-y-1.5">
            <Skeleton className="h-4 w-16" />
            <Skeleton className="h-10" />
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between gap-2 mt-2">
        <Skeleton className="h-4 w-28" />
        <Skeleton className="h-4 w-32" />
      </div>

      <Skeleton className="h-4 w-full mt-2" />
    </div>
  );
});

ReceiveDirectionSkeleton.displayName = 'ReceiveDirectionSkeleton';

export default ReceiveDirectionSkeleton;
