import React from 'react';
import {Card} from '#components/ui/card';
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "#ui/table";
import {useIsMobile} from "#hooks/use-mobile";
import {Skeleton} from "#ui/skeleton";

const DEFAULT_LIMIT = 10;
// <Card className="p-4 flex gap-3">
//         <CurrencyIcon symbol={stake.symbol} size="large"/>
//       <div className={'flex-1 flex items-center justify-between'}>
//         <div className={'h-full flex flex-col text-left justify-between'}>
//           <small className={'block text-muted-foreground'}>
//             <Trans>Amount</Trans>
//           </small>
//           <CurrencyFormat value={stake.amount} decimalScale={stake.precision}/> {stake.symbol}
//         </div>
//
//         <div className={'text-sm text-right h-full flex flex-col justify-between'}>
//           <p>
//             <Trans values={{period: stake.period}}>
//               {`{{period}} days`}
//             </Trans>
//           </p>
//           <p>
//             {parseFloat(stake.fixedInterest)?.toFixed(2)}%
//           </p>
//         </div>
//       </div>
//       {isActive ? (
//         <CircularProgress value={stake.progress} size={'lg'}>
//           <small className="text-muted-foreground">
//             {stake.progress}%
//           </small>
//         </CircularProgress>
//       ) : (
//         <div className={'text-right h-full flex flex-col justify-between'}>
//           <small className={'block text-muted-foreground'}>
//             <Trans>
//               Earned
//             </Trans>
//           </small>
//           <p className={cn({
//             'text-success': stake.status === 'completed',
//             'text-destructive': stake.status === 'cancelled',
//           })}>
//           {stake.status === 'completed' && '+ '}
//             <CurrencyFormat
//               value={stake.earnedInterest}
//               decimalScale={stake.precision}
//             />{" "}
//             {stake.symbol}
//         </p>
//         </div>)}
//
//     </Card>
const MobileStakingSkeleton = ({limit = DEFAULT_LIMIT}) => {
  return (
    <div className="space-y-4">
      {Array.from({length: limit}, (_, index) => (
        <Card key={index} className="p-4 flex gap-3">
          <div className="flex items-center">
            <Skeleton className="h-10 w-10 rounded-full"/>
          </div>
          <div className={'flex-1 flex items-center justify-between'}>
            <div className={'h-full flex flex-col items-start justify-between'}>
              <Skeleton className="h-3 w-24"/>
              <Skeleton className="h-4 w-24"/>
            </div>
            <div className={'h-full flex flex-col items-end justify-between'}>
              <Skeleton className="h-3 w-24"/>
              <Skeleton className="h-4 w-24"/>
            </div>

            <div className={'h-full flex flex-col items-end justify-between'}>
              <Skeleton className="h-3 w-24"/>
              <Skeleton className="h-4 w-24"/>
            </div>
          </div>

        </Card>
      ))}
    </div>
  );
};

const TableStakingSkeleton = ({limit = DEFAULT_LIMIT}) => {
  return (
    <div className="rounded-sm border shadow font-medium">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead><Skeleton className="h-4 w-24"/></TableHead>
            <TableHead><Skeleton className="h-4 w-24"/></TableHead>
            <TableHead><Skeleton className="h-4 w-24"/></TableHead>
            <TableHead><Skeleton className="h-4 w-24"/></TableHead>
            <TableHead><Skeleton className="h-4 w-24"/></TableHead>
            <TableHead><Skeleton className="h-4 w-24"/></TableHead>
            <TableHead />
          </TableRow>
        </TableHeader>
        <TableBody>
          {Array.from({length: limit}, (_, index) => (
            <TableRow key={index}>
              <TableCell>
                <div className="flex items-center gap-1">
                  <Skeleton className="h-6 w-6 rounded-full"/>
                  <Skeleton className="h-4 w-24"/>
                </div>
              </TableCell>
              <TableCell>
                <Skeleton className="h-4 w-32"/>
              </TableCell>
              <TableCell>
                <Skeleton className="h-4 w-24"/>
              </TableCell>
              <TableCell>
                <Skeleton className="h-4 w-16"/>
              </TableCell>
              <TableCell>
                <Skeleton className="h-4 w-12"/>
              </TableCell>
              <TableCell>
                <div className="flex items-center gap-2">
                  <Skeleton className="h-2 w-full max-w-32"/>
                  <Skeleton className="h-4 w-8"/>
                </div>
              </TableCell>
              <TableCell className="flex justify-end">
                <Skeleton as="button" className="h-8 w-8 rounded-md"/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export const UserStakingSkeleton = ({header, limit = DEFAULT_LIMIT}) => {
  const isMobile = useIsMobile();
  return isMobile ? (
    <MobileStakingSkeleton limit={limit}/>
  ) : (
    <TableStakingSkeleton header={header} limit={limit}/>
  );
};

export {MobileStakingSkeleton, TableStakingSkeleton};