import {cva} from "class-variance-authority";

const bannerVariants = cva('sm:flex-row flex-col text-sm flex items-center justify-center p-2 gap-0 sm:gap-3', {
  variants: {
    variant: {
      default: 'bg-primary text-primary-foreground',
      success: 'bg-success text-success-foreground',
      warning: 'bg-highlight text-highlight-foreground',
      error: 'bg-destructive text-destructive-foreground',
    }
  },
  defaultVariant: {
    variant: 'default'
  }
})

const Banner = ({Icon, title, children, variant, className}) => {
  return (<div
    className={bannerVariants({variant, className})}>
    <strong className={'flex items-center gap-1'}>
      {Icon && <Icon className="h-4 w-4"/>}
      {title && title}
    </strong>
    {children}
  </div>);
};

export default Banner;