import CryptoAccountsHeader from "./CryptoAccountsHeader";
import CryptoAccountsList from "#components/wallet/CryptoAccountsList";
import {useAccounts} from "#hooks/api/useAccounts";
import {DASHBOARD_DEFAULT_LIMIT} from "#src/constants/wallet";
import {Button} from "#ui/button";
import {Link} from "react-router-dom";
import {Trans} from "react-i18next";
import {ChevronRight} from "lucide-react";
import EmptyCryptoAccounts from "#components/wallet/EmptyCryptoAccounts";

const CryptoAccounts = () => {
  const {
    accounts, isLoading, totalItems
  } = useAccounts({
    defaultLimit: DASHBOARD_DEFAULT_LIMIT,
  });
  if (accounts.length === 0 && !isLoading) {
    return <EmptyCryptoAccounts/>
  }
  return (
    <section>
      <CryptoAccountsHeader totalItems={totalItems}/>
      <CryptoAccountsList accounts={accounts} isLoading={isLoading}/>
      <div className={'mt-5 flex items-center justify-center'}>
        <Button asChild variant={'outline'} className={'p-5 w-full md:w-auto'}>
          <Link to="/wallet/accounts">
            <Trans i18nKey={'go_to_all_accounts'} values={{
              totalItems
            }}>
              {'Go to all Accounts ({{totalItems}})'}
            </Trans>
            <ChevronRight/>
          </Link>
        </Button>
      </div>
    </section>
  );
};

export default CryptoAccounts;