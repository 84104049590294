import InfoStepList from "#components/wallet/InfoStepList";
import {Trans} from "react-i18next";
import InfoNoteList from "#components/wallet/InfoNoteList";

const steps = [
  <Trans i18nKey="selectExchangeFunction">Select the Exchange Function</Trans>,
  <Trans i18nKey="chooseCurrenciesToExchange">Choose Currencies to Exchange</Trans>,
  <Trans i18nKey="reviewFees">Review Fees</Trans>,
  <Trans i18nKey="confirmTheExchange">Confirm the Exchange</Trans>,
  <Trans i18nKey="waitForProcessing">Wait for Processing</Trans>,
  <Trans i18nKey="verifyTheResult">Verify the Result</Trans>
];
const notes = [
  <Trans i18nKey="navigateToExchangeSection">Navigate to the "Exchange", "Swap", or "Convert" section (depending on your
    wallet’s interface). Ensure the
    exchange feature supports the cryptocurrencies you want to swap (e.g., BTC → ETH or USDT → BNB).</Trans>,
  <Trans i18nKey="reviewAllExchangeParameters">Review all exchange parameters in the confirmation window (currencies,
    amount, final balance).</Trans>,
  <Trans i18nKey="confirmTheOperation">If everything is correct, confirm the operation.</Trans>,
  <Trans i18nKey="exchangeMayTakeTime">The exchange may take a few seconds to a few minutes, depending on the blockchain
    and network traffic. Once
    completed, you’ll receive a notification or see the updated balance in your wallet.</Trans>,
  <Trans i18nKey="verifyExchangeSuccess">Go to the "Transaction History" or "Activity" section to ensure the exchange
    was successful. Verify that the
    funds have been credited in full, minus the fees.</Trans>
];

const SwapInfo = () => {
  return (<div className={'flex-1'}>
    <InfoStepList
      title={<Trans>
        To swap cryptocurrencies, you need to:
      </Trans>}
      steps={steps}
    />
    <InfoNoteList
      title={<Trans>Note:</Trans>}
      notes={notes}
    />
  </div>);
};

export default SwapInfo;