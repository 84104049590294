import {memo} from "react";
import {CheckCheck} from "lucide-react";
import {Trans} from "react-i18next";

const SuccessStep = () => {
  return (
    <div className={'flex flex-col p-4 gap-6 items-center justify-center'}>
      <div className={'bg-success p-3 text-success-foreground rounded-md'}>
        <CheckCheck size={50}/>
      </div>
      <h3 className={'text-lg font-bold'}>
        <Trans>
          Success! Your verification is pending approval
        </Trans>
      </h3>
    </div>
  );
};

export default memo(SuccessStep);