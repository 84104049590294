import InfoStepList from "#components/wallet/InfoStepList";
import {Trans} from "react-i18next";
import InfoNoteList from "#components/wallet/InfoNoteList";

const steps = [
  <Trans i18nKey="receive_steps_selectCryptocurrency">
    Select the cryptocurrency you want to add to your wallet balance.
  </Trans>,
  <Trans i18nKey="receive_steps_copyAddress">
    Copy the address for the cryptocurrency you wish to deposit.
  </Trans>,
  <Trans i18nKey="receive_steps_ensureNetworkMatches">
    Ensure the network matches your wallet’s network (e.g., ERC-20, BEP-20).
  </Trans>,
  <Trans i18nKey="receive_steps_accessExternalWallet">
    Access your external wallet or exchange from which you plan to send the funds.
  </Trans>,
  <Trans i18nKey="receive_steps_pasteCopiedAddress">
    Paste the copied wallet address.
  </Trans>,
  <Trans i18nKey="receive_steps_confirmCorrectNetwork">
    Confirm that you have selected the correct network.
  </Trans>,
  <Trans i18nKey="receive_steps_enterTransferAmount">
    Enter the transfer amount, including the network fee.
  </Trans>,
  <Trans i18nKey="receive_steps_confirmDeposit">
    Confirm the deposit via 2FA, email, or other verification methods.
  </Trans>,
  <Trans i18nKey="receive_steps_ensureFundsCredited">
    Ensure the funds are credited to your wallet.
  </Trans>
];
const notes = [
  <Trans i18nKey="receive_notes_dailyDepositLimit">
    Daily deposit limit: The remaining daily deposit limit is 999,999 USDT. The maximum deposit limit is 999,999 USDT.
  </Trans>,
  <Trans i18nKey="receive_notes_processingTime">
    Processing time: We will process your deposit request within 30 minutes. The time it takes for the assets to arrive
    in your wallet depends on the selected network.
  </Trans>,
  <Trans i18nKey="receive_notes_security">
    Security: For enhanced security, large deposits may be processed manually.
  </Trans>,
  <Trans i18nKey="receive_notes_depositPriority">
    Deposit priority: The funds will first be credited to your Funding Account.
  </Trans>
];
const ReceiveInfo = () => {
  return (<div className={'flex-1'}>
    <InfoStepList
      title={<Trans>
        To deposit funds, you need to:
      </Trans>}
      steps={steps}
    />
    <InfoNoteList
      title={<Trans>Note:</Trans>}
      notes={notes}
    />
  </div>);
};

export default ReceiveInfo;