import {Label} from "#ui/label";
import {Input} from "#ui/input";
import {useId} from "react";
import React from "react";
import {cva} from "class-variance-authority";
import {cn} from "#lib/utils";

const formFieldMessageVariants = cva(
  'text-sm',
  {
    variants: {
      variant: {
        default: 'text-muted-foreground',
        success: 'text-success',
        error: 'text-destructive',
        warning: 'text-highlight',
      }
    },
    defaultVariant: {
      variant: 'default'
    }
  }
)
export const FormFieldMessage = ({message, className, variant}) => {
  if (!message) return null;
  return (
    <small className={formFieldMessageVariants({variant, className})}>
      {message}
    </small>
  )
}

export const FormLabel = ({label, labelAfter, htmlFor, isError, className, ...props}) => {
  if (!label) return null;
  return (
    <div className={cn('flex items-center justify-between gap-2', className)}>
      <Label htmlFor={htmlFor} isError={isError} {...props}>
        {label}
      </Label>
      {labelAfter}
    </div>
  )
}


const FormField = React.forwardRef(({label, isError, id, labelAfter, messageProps, after, ...props}, ref) => {
  const _id = useId();
  return (
    <div className={'flex flex-col space-y-1.5'}>
      <FormLabel label={label} labelAfter={labelAfter} htmlFor={id || _id} isError={isError}/>
      <div className={'flex items-center justify-between gap-2 relative'}>
        <Input id={id || _id} isError={isError} ref={ref} {...props}/>
        {after && after}
      </div>
      <FormFieldMessage {...messageProps}/>
    </div>
  );
})


export {FormField}