import {memo, useRef} from 'react';
import {
  Carousel,
  CarouselContent,
} from '#ui/carousel';

import StatusCarouselItem from "./StatusCarouselItem";
import AutoHeight from "embla-carousel-auto-height";

const StatusCarousel = memo(({
  statuses,
  activeIndex,
  currentStatusIndex,
  setApi
}) => {
  const plugin = useRef(AutoHeight());
  return (
    <Carousel
      plugins={[plugin.current]}
      setApi={setApi}
      opts={{
        align: "center",
        startIndex: currentStatusIndex,
      }}
      className="w-full"
    >
      <CarouselContent>
        {statuses.map((status, index) => (
          <StatusCarouselItem
            key={status.id}
            index={index}
            activeIndex={activeIndex}
            status={status}
          />
        ))}
      </CarouselContent>
    </Carousel>
  )
});

StatusCarousel.displayName = 'StatusCarousel';

export default StatusCarousel;
