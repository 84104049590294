import {useCallback, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {useDebounceCallback} from "#hooks/useDebounceCallback";
import {useConvertAmountMutation} from "#services/api/accounts";
import {
  setAmountByKey,
  setBothAmounts,
  setConvertError,
  setIsConverting,
  setRate
} from "#store/slices/swapConverterSlice";


const useConvertCurrencies = () => {
  const dispatch = useDispatch();
  const [convertAmount] = useConvertAmountMutation();
  const requestRef = useRef(null);

  const [debouncedConvert, cancelDebouncedConvert] = useDebounceCallback(
    async ({amount, isFromAmount, fromAccountId, toAccountId, maxLimit}) => {
      if (!fromAccountId || !toAccountId || !amount) {
        dispatch(setIsConverting(false));
        return;
      }

      if (requestRef.current) {
        requestRef.current.abort();
      }
      const request = convertAmount({
        [isFromAmount ? 'fromAmount' : 'toAmount']: amount,
        fromAccountId,
        toAccountId
      });
      requestRef.current = request;

      try {
        const result = await request.unwrap();
        const outPutAmountKey = isFromAmount ? 'toAmount' : 'fromAmount';
        if (result.status !== 'OK') {
          dispatch(setConvertError(
            result.status
          ));
        } else {
          dispatch(setConvertError(null));
        }
        const outPutAmount = result[outPutAmountKey];
        if (outPutAmount <= maxLimit) {
          dispatch(setAmountByKey({[outPutAmountKey]: parseFloat(outPutAmount)}));
        } else {
          dispatch(setConvertError('CONVERSION_MAX_LIMIT'));
        }
        dispatch(setRate(parseFloat(result.rate)));
      } catch (error) {
        if (error.name !== 'AbortError') {
          throw error;
        }
      } finally {
        dispatch(setIsConverting(false));
      }
    },
    200
  );

  return useCallback(({amount, isFromAmount, fromAccountId, toAccountId, maxLimit}) => {
    if (!amount) {
      if (requestRef.current) {
        cancelDebouncedConvert();
        requestRef.current.abort();
      }
      dispatch(setBothAmounts({fromAmount: '', toAmount: ''}));
      dispatch(setIsConverting(false));
      dispatch(setConvertError(null));
      return;
    }

    const formattedAmount = {[isFromAmount ? 'fromAmount' : 'toAmount']:parseFloat(amount)};
    dispatch(setAmountByKey(formattedAmount));
    dispatch(setIsConverting(true));
    debouncedConvert({amount, isFromAmount, fromAccountId, toAccountId, maxLimit});
  }, [dispatch, debouncedConvert, cancelDebouncedConvert]);
};

export default useConvertCurrencies;