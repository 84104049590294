import {useDispatch} from "react-redux";
import {useLogoutMutation} from "#services/api/auth";
import {clearAuth} from "#store/slices/authSlice";
import {clearTokens} from "#lib/tokenStorage";

const useLogout = () => {
  const dispatch = useDispatch()
  const [logout] = useLogoutMutation()
  return async () => {
    try {
      await logout().unwrap()
      dispatch(clearAuth())
      clearTokens()
    } catch (e) {
      console.error(e)
    }
  }
}

export default useLogout