import {Trans} from "react-i18next"
import InfoNoteList from "#components/wallet/InfoNoteList";
import InfoStepList from "#components/wallet/InfoStepList";


const steps = [
  <Trans i18nKey="selectDesiredCoin">
    Select the desired coin
  </Trans>,
  <Trans i18nKey="specifyWalletAddress">
    Specify the wallet address
  </Trans>,
  <Trans i18nKey="selectTransferNetwork">
    Select the transfer network
  </Trans>,
  <Trans i18nKey="specifyWithdrawalAmount">
    Specify the withdrawal amount
  </Trans>,
  <Trans i18nKey="clickSend">
    Click send
  </Trans>
];
const notes = [
  <Trans i18nKey="dailyWithdrawalAmount">
    Daily withdrawal amount: The remaining daily withdrawal amount is 999999 USDT. The maximum withdrawal amount is
    999999 USDT.
  </Trans>,
  <Trans i18nKey="doNotWithdrawToICO">
    Do not withdraw to an ICO or crowd funding address.
  </Trans>,
  <Trans i18nKey="processWithdrawalTime">
    We will process your withdrawal within 30 minutes. The time it takes for the assets to transfer to your wallet
    depends on the selected network.
  </Trans>,
  <Trans i18nKey="largeWithdrawalsSecurity">
    To enhance the security of your assets, large withdrawals may be processed manually.
  </Trans>,
  <Trans i18nKey="withdrawalSource">
    Withdrawal will first come from assets in your Funding Account, then your Trading Account.
  </Trans>
];


const WithdrawInfo = () => {
  return (<div className={'flex-1'}>
      <InfoStepList
        title={<Trans>
          To make a withdrawal, you need to:
        </Trans>}
        steps={steps}
      />
      <InfoNoteList
        title={<Trans>Note:</Trans>}
        notes={notes}
      />
    </div>
  );
};

export default WithdrawInfo;