import {useLocalStorage} from "@uidotdev/usehooks";

export const useFavNetwork = (type) => {
  const [
    favNetworkId,
    setFavNetwork,
  ] = useLocalStorage('favNetwork_' + type, null);
  return{
    favNetworkId,
    setFavNetwork
  }
}