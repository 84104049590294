import {Trans} from "react-i18next";
import {SectionHeader} from "#pages/Home/components";

export const TestimonialHeader = () => (<div className="text-center mb-16">
  <SectionHeader
    title={<Trans>What Our Users Say</Trans>}
    description={<Trans>Trusted by traders and investors worldwide</Trans>}
  />

</div>);
