// Helper function to get random item from array
const getRandomItem = (arr) => arr[Math.floor(Math.random() * arr.length)];

// Helper function to generate random amount
const getRandomAmount = (max = 10, min = 0.1) => {
  return (Math.random() * (max - min) + min).toFixed(4);
};

const getCreateAt = (i) => {
  const date = new Date();
  date.setMinutes(date.getMinutes() - (i * 2)); // Віднімає ХВИЛИНИ
  date.setSeconds(Math.floor(Math.random() * 60)); // Встановлює секунди
  return date.toISOString();
};

// Helper function to generate transaction
const generateTransaction = (cryptoList, i = 0) => {
  if (cryptoList.length===0) return
  const fromCrypto = getRandomItem(cryptoList);
  let toCrypto = getRandomItem(cryptoList);

  // Ensure we don't exchange to the same currency
  while (toCrypto.symbol === fromCrypto.symbol) {
    toCrypto = getRandomItem(cryptoList);
  }

  const amount = getRandomAmount();
  const toAmount = (amount * (fromCrypto.rate / toCrypto.rate)).toFixed(4);
  return {
    id: Math.random().toString(36).substr(2, 9),
    type: 'deposit',
    status: 'completed',
    gateway: 'exchange',
    amount: amount,
    currency: fromCrypto.symbol,
    toCurrency: toCrypto.symbol,
    toAmount: toAmount,
    precision: fromCrypto.precision,
    createdAt: getCreateAt(i),
  };
};

// Main function to generate array of transactions
export const generateTransactionsList = (cryptoList, count = 10) => {
  return Array.from({length: count}, (_, i) => generateTransaction(cryptoList, i));
};