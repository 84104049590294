import {useBookTicker, useMaxSize} from "#src/contexts/SocketContext";
import formatDecimal from "#lib/formatDecimal";
import {usePriTickerStatus} from "#hooks/usePriTickerStatus";


const InfoItem = ({label, value, className, valueClassName,onClick}) => (
  <div className={className}>
    <p className={'text-muted-foreground text-xs'}>
      {label}
    </p>
    <p className={valueClassName} onClick={onClick && onClick}>
      {value}
    </p>
  </div>
)
const MinMax = ({defaultLong, defaultShort, precision,onClick}) => {
  const {LONG, SHORT} = useMaxSize() ?? {
    LONG: defaultLong,
    SHORT: defaultShort
  }
  return (
    <div className={'flex gap-4 justify-between'}>
      <InfoItem label={'Max'} valueClassName={'underline text-highlight cursor-pointer'} onClick={()=>onClick(LONG)} value={formatDecimal(LONG, precision)}/>
      <InfoItem label={'Max'} className={'text-right'} valueClassName={'underline text-highlight cursor-pointer'} onClick={()=>onClick(SHORT)} value={formatDecimal(SHORT, precision)}/>
    </div>
  )
}

const BidAsk = ({bid, ask, precision}) => {
  const {bidPrice, askPrice} = useBookTicker();
  const bidColor = usePriTickerStatus(bid, bidPrice);
  const askColor = usePriTickerStatus(ask, ask);

  return (
    <div className={'flex gap-4 justify-between'}>
      <InfoItem label={'Bid'} value={formatDecimal(bidPrice || bid, precision)} valueClassName={bidColor}/>
      <InfoItem label={'Ask'} className={'text-right'} value={formatDecimal(askPrice || ask, precision)}
                valueClassName={askColor}/>
    </div>
  )
}

export const OrderInfo = ({defaultMaxShortSize, defaultMaxLongSize, asset,onClick}) => {
  return <div className={'flex flex-col gap-2'}>
    <BidAsk ask={asset.askPrice} bid={asset.bidPrice} precision={asset.pricePrecision}/>
    <MinMax defaultLong={defaultMaxLongSize} defaultShort={defaultMaxShortSize} onClick={onClick} precision={asset.precision}/>
  </div>
}