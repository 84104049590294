import TextGradient from "#ui/TextGradient";

const SimplePageHeader = ({children, action}) => {
  return (
    <div className={'flex flex-col  md:flex-row items-center justify-between gap-2 mb-5'}>
      <TextGradient asChild>
        <h1 className={'text-3xl'}>
          {children}
        </h1>
      </TextGradient>
      {action}
    </div>
  );
};

export default SimplePageHeader;