import CurrencyIcon from "#ui/crypto/CurrencyIcon";
import {Card, CardContent, CardHeader} from "#ui/card";
import ActionsButtonsNavigation from "#components/common/ActionsButtonsNavigation";
import CryptoAccountsDrawer from "#components/wallet/CryptoAccountsDrawer";
import {useIsMobile} from "#hooks/use-mobile";
import {useState} from "react";
import {cn} from "#lib/utils";
import CurrencyFormat from "#ui/currency-format";
import {Trans} from "react-i18next";
import CryptoAccountsListSkeleton from "#components/skeletons/CryptoAccountsListSkeleton";
import {DASHBOARD_DEFAULT_LIMIT} from "#src/constants/wallet";
import {ChevronDown} from "lucide-react";


const CryptoAccountCardContent = ({account}) => (<div className="flex items-end justify-between">
  <div>
    <small className={'text-muted-foreground'}>
      <Trans i18nKey={'balance'}>
        Balance
      </Trans>
    </small>
    <p className={'flex gap-1 items-baseline'}>
      <CurrencyFormat value={account.balance} decimalScale={account.precision}/>
      <small className={'text-muted-foreground'}>
        {account.symbol}
      </small>
    </p>
  </div>
  <p className={'text-muted-foreground'}>
    ≈$ <CurrencyFormat value={account.fiatBalance} decimalScale={2}/>
  </p>

</div>)

export const CryptoAccountCard = ({account, onSelectAccount, isMobile}) => (
  <Card onClick={() => isMobile && onSelectAccount(account)}
        className={cn("overflow-hidden group relative", {'cursor-pointer ': isMobile})}>
    <CardHeader className={cn("pb-2", {'pt-3 px-4 pb-1': isMobile})}>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <CurrencyIcon symbol={account.symbol}/>
          <h3>{account.name}</h3>
          {isMobile && <span className={'bg-primary/20 dark:text-highlight p-0.5 dark:bg-highlight/20 rounded-md'}>
            <ChevronDown size={16}/>
          </span>}
        </div>
        <div className={'flex gap-1 items-center'}>
          <p>$<CurrencyFormat value={account.rate}/></p>
        </div>
      </div>
    </CardHeader>
    <CardContent className={cn({'pb-3 px-4 pt-0': isMobile})}>
      <CryptoAccountCardContent account={account}/>
    </CardContent>
    {!isMobile && <div
      className="absolute p-5 left-0 rounded-b-xl right-0 bg-gradient-to-t from-card via-card to-card/60 -bottom-12 opacity-0 group-hover:opacity-100 group-hover:bottom-0 ease-in-out transition-all duration-300">
      <ActionsButtonsNavigation compact symbol={account.symbol} accountId={account.id}/>
    </div>}
  </Card>)


const CryptoAccountsListRender = ({accounts, isMobile, isLoading, onSelectAccount}) => {
  if (isLoading) {
    const length = accounts?.length ? accounts.length : DASHBOARD_DEFAULT_LIMIT;
    return <CryptoAccountsListSkeleton length={length}/>
  }
  return accounts.map((account, index) => (
    <CryptoAccountCard isMobile={isMobile} key={index} account={account} onSelectAccount={onSelectAccount}/>))
}

const CryptoAccountsList = (props) => {
  const isMobile = useIsMobile()
  const [account, setAccount] = useState(null)

  return (<>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      <CryptoAccountsListRender  {...props} isMobile={isMobile} onSelectAccount={setAccount}/>
    </div>
    <CryptoAccountsDrawer account={account} onClose={setAccount}/>
  </>)
};

export default CryptoAccountsList;