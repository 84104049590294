import { memo } from 'react';
import { Skeleton } from '#ui/skeleton';

const StatusTabsSkeleton = memo(() => (
  <div className={'flex items-center justify-center mb-4 overflow-x-auto'}>
    <div className={"inline-flex items-center justify-center rounded-lg bg-muted p-1 text-muted-foreground gap-1"}>
      {[1, 2, 3, 4].map((item) => (
        <Skeleton
          key={item}
          className="h-8 w-24 rounded-md"
        />
      ))}
    </div>
  </div>
));

StatusTabsSkeleton.displayName = 'StatusTabsSkeleton';

export default StatusTabsSkeleton;
