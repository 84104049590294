import {Aml, Cookies, Privacy, Terms} from "#pages/docs";
import DocsLayout from "#layouts/DocsLayout";

const docsRouter = {
  path: '',
  element: <DocsLayout/>,
  children: [
    {path: "/terms", element: <Terms/>},
    {path: "/privacy", element: <Privacy/>},
    {path: "/cookies", element: <Cookies/>},
    {path: "/aml", element: <Aml/>}
  ]
}

export default docsRouter