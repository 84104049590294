import {useSelector} from "react-redux";
import {selectUser} from "#store/slices/authSlice";
import useLogout from "#hooks/auth/useLogout";
import {Trans} from "react-i18next";
import {getIconOrRandom} from "#lib/getRandomIcon";
import {Settings, SquareArrowOutUpRight} from "lucide-react";
import {memo, useMemo} from "react";
import UserAvatar from "#components/common/UserAvatar";

const CurrentStatus = memo(({user,}) => {
  return (
    user?.status ? user?.status?.name : <Trans i18nKey="user_status">Status</Trans>
  )
})


export default function useProfileNav() {
  const user = useSelector(selectUser)
  const logout = useLogout()

  const StatusIcon = getIconOrRandom(user?.status?.icon)
  const currentStatus = useMemo(() => <CurrentStatus user={user}/>, [user])
  const menuItems = useMemo(() => [
    {
      Icon: StatusIcon,
      path: "/profile/status",
      label: <Trans>Status</Trans>
    },
    {
      Icon: Settings,
      path: "/profile",
      label: <Trans>Settings</Trans>
    },
    {
      prevSeparator: true,
      Icon: SquareArrowOutUpRight,
      onClick: logout,
      label: <Trans>Log out</Trans>
    }
  ], [StatusIcon, logout])

  return useMemo(() => ({
    Avatar: (props) => <UserAvatar user={user} {...props}/>,
    menuItems,
    userEmail: user?.email,
    currentStatus,
    StatusIcon
  }), [menuItems, user, currentStatus, StatusIcon])
}