import {Trans} from "react-i18next";
import {ArrowRightIcon} from "lucide-react";
import {formatWallet} from "#lib/wallet.utils";


const ExchangeWallet = ({transaction}) => {
  const isWithdraw = transaction.type === 'withdraw'
  const fromSymbol = isWithdraw ? transaction?.currency : transaction?.toCurrency
  const toSymbol = isWithdraw ? transaction?.toCurrency : transaction?.currency
  return (
    <span className={'inline-flex items-center gap-1'}>
        {fromSymbol} <ArrowRightIcon className={'w-4 h-4'}/> {toSymbol}
      </span>
  )
}

const TransactionInfo = ({transaction}) => {
  const typeMap = {
    "deposit": {
      gateway: <Trans>Deposit</Trans>,
      wallet: formatWallet(transaction?.depositAddress),
    },
    "withdraw": {
      gateway: <Trans>Withdraw</Trans>,
      wallet: formatWallet(transaction?.withdrawAddress),
    },
    "exchange": {
      gateway: <Trans>Exchange</Trans>,
      wallet: <ExchangeWallet transaction={transaction}/>,
    },
    "referral": {
      gateway: <Trans>Referral reward</Trans>,
      wallet: null,
    },
    "staking": {
      gateway: <Trans>Staking earn</Trans>,
      wallet: null,
    },
    "bonus": {
      gateway: <Trans>Deposit</Trans>,
      wallet: null
    },
    "futures": {
      gateway:<Trans>Futures transfer</Trans>,
      wallet: null
    }
  };

  const {gateway, wallet} = typeMap[transaction?.gateway];
  if (!wallet) return gateway;

  return (
    <>
      {gateway}: <strong>{wallet}</strong>
    </>
  );
};

export default TransactionInfo;