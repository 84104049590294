import Container from "#ui/container";
import {Ban} from "lucide-react";
import {Trans} from "react-i18next";
import {memo} from "react";

const CantWithdraw = () => {
  return (
    <Container className="h-[calc(100vh-180px)] flex items-center justify-center">
      <div className="text-center flex flex-col items-center justify-center">
        <Ban size={70} className={'text-destructive mb-3'}/>
        <h1 className="text-2xl font-semibold">
          <Trans>
            You can't withdraw
          </Trans>
        </h1>
        <p className="text-gray-500">
          <Trans>
            Please contact support for more information
          </Trans>
        </p>
      </div>
    </Container>
  );
};

export default memo(CantWithdraw);