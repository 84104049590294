import {Button} from "#ui/button";
import {ChevronLeft, ChevronRight} from "lucide-react";
import {Trans} from "react-i18next";
import usePagination from "#hooks/usePagination";

const Pagination = ({
                      hasNextPage,
                      hasPreviousPage,
                    }) => {
  const {nextPageHandler, prevPageHandler} = usePagination({
    hasNextPage,
  });
  return (
    <div className="flex items-center justify-center md:justify-end space-x-2 py-4">
      <div className="flex items-center gap-3">
        <Button
          variant="ghost"
          size="sm"
          disabled={!hasPreviousPage}
          onClick={prevPageHandler}
        >
          <ChevronLeft/> <Trans>Previous</Trans>
        </Button>
        <Button
          variant="ghost"
          size="sm"
          disabled={!hasNextPage}
          onClick={nextPageHandler}
        >
          <Trans>Next</Trans> <ChevronRight/>
        </Button>
      </div>
    </div>
  )
}


export default Pagination;