import {useTranslation} from "react-i18next";
import React, {memo, useEffect, useState} from "react";
import {cn} from "#lib/utils";

// Мемоізуємо TimeUnit
const TimeUnit = memo(({value, label}) => (
  <div className={'flex flex-col items-center justify-center gap-1'}>
    <div className={'min-h-12 flex items-center justify-center rounded-md bg-secondary  min-w-12 px-1'}>
      <p className="text-center">
        {value}
      </p>
    </div>
    <span className="text-xs subtitle">{label}</span>
  </div>
));

// Виносимо calculateTimeLeft за межі компонента
const calculateTimeLeft = (targetDate, format) => {
  const now = new Date();
  const difference = targetDate - now;

  if (difference <= 0 || isNaN(difference)) {
    return null;
  }

  const months = Math.floor(difference / (1000 * 60 * 60 * 24 * 30));
  const days = Math.floor((difference % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));
  const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((difference % (1000 * 60)) / 1000);

  const timeUnits = {
    months: months.toString().padStart(2, '0'),
    days: days.toString().padStart(2, '0'),
    hours: hours.toString().padStart(2, '0'),
    minutes: minutes.toString().padStart(2, '0'),
    seconds: seconds.toString().padStart(2, '0')
  };

  return format.reduce((acc, unit) => {
    acc[unit] = timeUnits[unit];
    return acc;
  }, {});
};
const Timer = memo(({nextAvailableTime, format = ['hours', 'minutes', 'seconds'], className, ...props}) => {
  const {t} = useTranslation();
  const [timeLeft, setTimeLeft] = useState(() => {
    const targetDate = new Date(nextAvailableTime);
    return calculateTimeLeft(targetDate, format);
  });

  useEffect(() => {
    const targetDate = new Date(nextAvailableTime);
    if (isNaN(targetDate.getTime())) {
      return;
    }

    // Оновлюємо стан тільки якщо є зміни
    const newTimeLeft = calculateTimeLeft(targetDate, format);
    if (JSON.stringify(timeLeft) !== JSON.stringify(newTimeLeft)) {
      setTimeLeft(newTimeLeft);
    }

    const timer = setInterval(() => {
      setTimeLeft(prevTimeLeft => {
        const newTimeLeft = calculateTimeLeft(targetDate, format);
        if (!newTimeLeft) {
          clearInterval(timer);
        }
        return newTimeLeft;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [nextAvailableTime, format, timeLeft]);

  if (!timeLeft) {
    return null;
  }

  const labels = {
    months: t('monthsTimer', 'mos'),
    days: t('daysTimer', 'days'),
    hours: t('hoursTimer', 'hrs'),
    minutes: t('minutesTimer', 'min'),
    seconds: t('secondsTimer', 'sec')
  };

  return (
    <div className={cn("flex items-start justify-center space-x-2", className)} {...props}>
      {format.map((unit, index) => (
        <React.Fragment key={unit}>
          <TimeUnit value={timeLeft[unit]} label={labels[unit]}/>
          {index < format.length - 1 && <span className="min-h-12 flex items-center justify-center">:</span>}
        </React.Fragment>
      ))}
    </div>
  );
});
export default Timer;