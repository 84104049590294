import {memo} from 'react';
import {cn} from '#lib/utils';
import {getIconOrRandom} from "#lib/getRandomIcon";

const StatusTabIcon = memo(({icon}) => {
  const Icon = getIconOrRandom(icon);
  return (
    <span className={'block'}>
        <Icon size={14}/>
      </span>
  )
})

const StatusTabs = memo(({
                           statuses,
                           activeIndex,
                           currentStatus,
                           onTabClick
                         }) => (
  <div className={'flex items-center justify-center w-full mb-4 overflow-x-scroll md:overflow-x-auto'}>
    <div className={"inline-flex items-center justify-center rounded-lg bg-muted p-1 text-muted-foreground"}>
      {statuses.map((button, i) => (
        <button
          className={cn(
            'inline-flex items-center relative justify-center whitespace-nowrap rounded-md lg:text-lg',
            'px-3 py-1 font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
            {'bg-background text-foreground shadow': i === activeIndex},
            'dark:text-foreground'
          )}
          key={i}
          onClick={() => onTabClick(i)}
        >
          {button.id === currentStatus?.id && (
            <span className={'h-2 w-2 rounded-full bg-highlight absolute top-0 right-0'}/>
          )}
          <span className="inline-flex items-center gap-1">
                     <StatusTabIcon icon={button.icon}/> {button.name}
          </span>
        </button>
      ))}
    </div>
  </div>
));

StatusTabs.displayName = 'StatusTabs';

export default StatusTabs;
