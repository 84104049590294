import ResetPasswordForm from "#components/common/ResetPasswordForm";
import {useLocation} from "react-router-dom";
import {Trans} from "react-i18next";
import {AuthForm} from "#components/common/AuthForm";
import OtpHeader from "#components/common/OtpForm/OtpHeader";

const ResetPassword = () => {
  const {state} = useLocation();
  const {id, type, ...data} = state || {};
  return (
    <AuthForm
      title={
        <OtpHeader backTo={state?.from || '/'}>
          <Trans i18nKey={"auth_reset_password_title"}>
            Reset your password
          </Trans>
        </OtpHeader>
      }
      description={
        <Trans i18nKey={"auth_reset_password_description"}>
          We have sent you a one-time password to your email. Please enter it below to reset your password.
        </Trans>
      }
    >
      <ResetPasswordForm
        userId={id}
        type={type}
        data={data}
      />
    </AuthForm>

  );
};

export default ResetPassword;