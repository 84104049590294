import React from 'react';
import {cn} from "#lib/utils";
import {cva} from "class-variance-authority";

const directions = cva('', {
    variants: {
      direction: {
        left: 'left-0 top-0 bg-gradient-to-r w-20 md:w-52 h-full',
        right: 'right-0 top-0 bg-gradient-to-l w-20 md:w-52 h-full',
        top: 'top-0 bg-gradient-to-b w-full h-20 md:h-52',
        bottom: 'bottom-0 bg-gradient-to-t w-full h-20 md:h-52'
      }
    },
    defaultVariants: {
      direction: 'left'
    }
  }
)

export const GradientOverlay = ({direction, className}) => {
  return (
    <div
      className={cn(
        `absolute z-10`,
        'from-background via-background/50 to-transparent',
        directions({direction}),
        className
      )}
    />
  );
};