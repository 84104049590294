import FadeIn from "#ui/FadeIn";
import TextGradient from "#ui/TextGradient";

const StatsCard = ({icon, value, label}) => (
  <FadeIn asChild>
    <div
      className="py-6 px-6  lg:px-8 rounded-card backdrop-blur-xl bg-gradient-to-tr from-primary/5 relative overflow-hidden flex-col flex justify-center">
      <div className={'absolute -bottom-5  -left-5 w-20 h-20 bg-primary/50 blur-3xl rounded-full z-0'}/>
      <div className={'absolute -top-5  -right-5 w-20 h-20 bg-highlight/50 blur-3xl rounded-full z-0'}/>
      <div className={'relative z-[1]'}>
        <div className="text-primary p-2 rounded-md bg-primary/20 inline-block">{icon}</div>
        <TextGradient asChild>
          <div className="text-3xl md:text-5xl font-bold from-foreground to-primary/70">{value}</div>
        </TextGradient>
        <div className="text-sm text-foreground/50 ">{label}</div>
      </div>

    </div>
  </FadeIn>
);

export default StatsCard