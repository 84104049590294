import React, {Suspense} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import Logo from "#components/common/Logo";
import {Button} from "#ui/button";
import {Link, useRouteError} from "react-router-dom";
import Container from "#ui/container";

const errorMessage = (error, t) => {
  if (!error) return t('unknownError', 'An unknown error occurred. Please try again.');

  if (error.status === 'FETCH_ERROR') {
    return t('networkError', 'Network error. Please check your connection and try again.');
  }

  switch (error.status) {
    case 400:
      return t('badRequest', 'Bad request. Please check your input and try again.');
    case 401:
      return t('unauthorized', 'You are not authorized. Please log in and try again.');
    case 403:
      return t('forbidden', 'You don\'t have permission to access this resource.');
    case 404:
      return t('notFound', 'The requested resource was not found.');
    case 500:
      return t('serverError', 'Server error. Please try again.');
    default:
      return t('unknownError', 'An unknown error occurred. Please try again.');
  }
};

// Fallback компонент без перекладу для випадків, коли i18n ще не ініціалізований
const BasicErrorFallback = () => (
  <Container className={'h-screen flex flex-col gap-3 items-center justify-center'}>
    <Logo/>
    <h1 className={'text-center font-semibold text-xl mt-5'}>
      Oops!
    </h1>
    <p className={'text-center'}>
      Something went wrong. Please try again.
    </p>
    <Button variant={'outline'} className={'mt-2'} size={'lg'} asChild>
      <Link to={'/'}>
        Go home
      </Link>
    </Button>
  </Container>
);

export const ErrorBoundaryFallback = ({error}) => {
  const {t, ready, i18n} = useTranslation();

  // Якщо переклади ще не готові або i18n не ініціалізований
  if (!ready || !i18n) {
    return <BasicErrorFallback/>;
  }

  try {

    return (
      <Container className={'h-screen flex flex-col gap-3 items-center justify-center'}>
        <Logo/>
        <h1 className={'text-center font-semibold text-xl mt-5'}>
          <Trans>
            Oops!
          </Trans>
        </h1>
        <p className={'text-center'}>
          {errorMessage(error, t)}
        </p>
        <Button variant={'outline'} className={'mt-2'} size={'lg'} asChild>
          <Link to={'/'}>
            <Trans>
              Go home
            </Trans>
          </Link>
        </Button>
      </Container>
    );
  } catch (e) {
    console.error('Error in ErrorBoundaryFallback:', e);
    return <BasicErrorFallback/>;
  }
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false, error: null};
  }

  static getDerivedStateFromError(error) {
    return {hasError: true, error};
  }

  componentDidCatch(error, errorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  resetErrorBoundary = () => {
    this.setState({hasError: false, error: null});
  }

  render() {
    if (this.state.hasError) {
      return (
        <Suspense fallback={<BasicErrorFallback/>}>
          <ErrorBoundaryFallback
            error={this.state.error}
            resetErrorBoundary={this.resetErrorBoundary}
          />
        </Suspense>
      );
    }

    return this.props.children;
  }
}

export const ErrorRoute = () => {
  const error = useRouteError();

  return (
    <Suspense fallback={<BasicErrorFallback/>}>
      <ErrorBoundaryFallback error={error}/>
    </Suspense>
  );
};

export default ErrorBoundary;