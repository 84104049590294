import React, {useEffect} from 'react';

import {Cookie} from 'lucide-react';
import {Toast, ToastDescription, ToastProvider, ToastTitle, ToastViewport} from "#ui/toast";
import {Button} from "#ui/button";
import {Trans} from "react-i18next";
import {Link} from "react-router-dom";

const COOKIE_CONSENT_KEY = 'cookie-consent-accepted';

export const CookieConsent = () => {
  const [showConsent, setShowConsent] = React.useState(false);

  useEffect(() => {
    const hasAccepted = localStorage.getItem(COOKIE_CONSENT_KEY);
    if (!hasAccepted) {
      setShowConsent(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem(COOKIE_CONSENT_KEY, 'true');
    setShowConsent(false);
  };

  if (!showConsent) {
    return null;
  }

  return (<ToastProvider duration={Infinity}>
    <Toast>
      <div className="flex items-start gap-3">
        <Cookie className="h-5 w-5 text-primary shrink-0"/>
        <div className="grid gap-1">
          <ToastTitle className="text-sm font-semibold">
            <Trans>
              We use cookies
            </Trans>
          </ToastTitle>
          <ToastDescription className="text-sm text-muted-foreground">
            <Trans i18nKey={'cookiesMessage'}>
              This website uses cookies to improve your experience.
              By continuing to use the site, you agree to our cookie policy.
            </Trans>
          </ToastDescription>
          <div className="flex gap-2 mt-3">
            <Button
              variant="default"
              size="sm"
              onClick={handleAccept}
            >
              <Trans>
                Accept
              </Trans>
            </Button>
            <Button
              variant="ghost"
              size="sm"
              asChild
            >
              <Link to={'/cookies'}>
                <Trans>
                  Learn more
                </Trans>
              </Link>
            </Button>
          </div>
        </div>
      </div>
    </Toast>
    <ToastViewport/>
  </ToastProvider>);
};

export default CookieConsent;