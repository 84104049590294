import base from "./base";

const accountsApi = base.injectEndpoints({
  endpoints: (builder) => ({
    getAccounts: builder.query({
      query: ({page = 1, limit = 5, search = null, hasStake = null}) => {
        const params = {page, limit};
        if (search) {
          params.search = search;
        }
        if (hasStake) {
          params.hasStake = hasStake;
        }
        return {
          url: '/api/accounts',
          method: 'GET',
          params: params
        };
      },
      providesTags: ['Accounts'],
      invalidatesTags: ['Networks']
    }),

    getAccount: builder.query({
      query: (id) => {
        return {
          url: `/api/accounts/${id}`,
          method: 'GET',
        };
      },
      invalidatesTags: ['Transactions']
    }),

    getNetworks: builder.query({
      query: (accountId) => {
        return {
          url: `/api/accounts/${accountId}/networks`,
          method: 'GET'
        };
      },
      providesTags: ['Networks']
    }),
    getFiatTotal: builder.query({
      query: () => {
        return {
          url: '/api/accounts/total',
          method: 'GET'
        };
      }
    }),
    getSwapAccounts: builder.query({
      query: (currencySymbol) => {
        return {
          url: '/api/accounts/swapAccounts',
          method: 'GET',
          params: {currencySymbol}
        };
      },
      providesTags: ['SwapAccounts'],
    }),
    getAccountByCurrencySymbol: builder.query({
      query: (symbol, filters) => {
        return {
          url: `/api/accounts/${symbol}`,
          method: 'GET',
          params: filters
        };
      },
    }),
    validateAddress: builder.mutation({
      query: ({networkId, address, memo}) => {
        return {
          url: '/api/accounts/validateAddress',
          method: 'POST',
          body: {networkId, address, memo}
        };
      }
    }),
    validateWithdrawAmount: builder.mutation({
      query: ({accountId, networkId, amount}) => {
        return {
          url: '/api/accounts/validateWithdrawAmount',
          method: 'POST',
          body: {accountId, networkId, amount}
        };
      }
    }),
    validateReceiveAmount: builder.mutation({
      query: ({networkId, amount}) => {
        return {
          url: '/api/accounts/validateDepositAmount',
          method: 'POST',
          body: {networkId, amount}
        };
      }
    }),
    convertAmount: builder.mutation({
      query: ({fromAmount, toAmount, fromAccountId, toAccountId}) => {
        return {
          url: '/api/accounts/convert',
          method: 'POST',
          body: {fromAmount, toAmount, fromAccountId, toAccountId}
        };
      }
    }),
    submitConversion: builder.mutation({
      query: ({fromAccountId, toAccountId, fromAmount, toAmount}) => {
        return {
          url: '/api/accounts/submitConversion',
          method: 'POST',
          body: {fromAccountId, toAccountId, fromAmount, toAmount}
        };
      },
      invalidatesTags: ['SwapAccounts', 'Transactions', 'Accounts']
    })
  }),
});

export const {
  useGetAccountsQuery,
  useGetNetworksQuery,
  useGetAccountByCurrencySymbolQuery,
  useValidateAddressMutation,
  useValidateWithdrawAmountMutation,
  useValidateReceiveAmountMutation,
  useGetSwapAccountsQuery,
  useConvertAmountMutation,
  useSubmitConversionMutation,
  useGetFiatTotalQuery
} = accountsApi;

export default accountsApi;