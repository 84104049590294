import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accounts: [],
};

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setAccounts: (state, action) => {
      state.accounts = action.payload || [];
    },
    addAccounts: (state, action) => {
      const newAccounts = action.payload || [];
      const existingIds = new Set(state.accounts.map(acc => acc.id));
      const uniqueNewAccounts = newAccounts.filter(acc => !existingIds.has(acc.id));

      state.accounts = [...state.accounts, ...uniqueNewAccounts];
    },
    resetAccounts: (state) => {
      state.accounts = []
    },
  },
});

export const { setAccounts, addAccounts, resetAccounts } = accountsSlice.actions;

export default accountsSlice;