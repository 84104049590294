// components/FuturesBalance/AccountRadioGroup.jsx
import {useEffect} from "react";
import {Label} from "#ui/label";
import {RadioGroup, RadioGroupItem} from "#ui/radio-group";
import {cn} from "#lib/utils";
import CurrencyIcon from "#ui/crypto/CurrencyIcon";
import {Trans} from "react-i18next";

export const AccountRadioGroup = ({ accounts, accountId, setAccountId }) => {
  useEffect(() => {
    if (!accountId && accounts?.length) {
      setAccountId(accounts[0].id);
    }
  }, [accountId, accounts, setAccountId]);

  if (!accounts?.length) return null;

  return (
    <div>
      <Label className="mb-2 block text-muted-foreground">
        <Trans>Select account</Trans>
      </Label>
      <RadioGroup defaultValue={accountId} value={accountId} onValueChange={setAccountId}>
        {accounts.map((account) => (
          <div
            key={account.id}
            className={cn("flex items-center space-x-2 border p-3 rounded-md", {
              "border-primary": accountId === account.id,
            })}
          >
            <RadioGroupItem value={account.id} id={account.id} />
            <CurrencyIcon symbol={account.currency.symbol} size="small" />
            <Label htmlFor={account.id} className="w-full">
              {account.currency.name} ({account.currency.symbol})
            </Label>
          </div>
        ))}
      </RadioGroup>
    </div>
  );
};