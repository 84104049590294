import {useSelector} from "react-redux";
import {selectBrandHasStaking} from "#store/slices/brandSlice";
import useStaking from "#hooks/api/useStaking";
import {useGetUserStakesQuery} from "#services/api/staking";
import {Trans} from "react-i18next";
import {Button} from "#ui/button";
import {cn} from "#lib/utils";
import {Link} from "react-router-dom";
import {Carousel, CarouselContent, CarouselItem} from "#ui/carousel";
import {useEffect, useRef, useState} from "react";
import Autoplay from "embla-carousel-autoplay";
import AutoHeight from "embla-carousel-auto-height";
import {ChevronRight} from "lucide-react";
import {UserStakeListCard} from "#components/wallet/UserStakeListCard";

const StartStakingCard = ({className}) => {
  return (
    <Link to={'/wallet/staking'} className={cn(
      'text-secondary-foreground p-4 cursor-pointer',
      'bg-gradient-to-br animate-gradient-x from-secondary to-background',
      'rounded-card border border-secondary flex items-center justify-between',
      'hover:opacity-70 transition-opacity', className
    )}>
      <div>
        <h2 className={'text-lg font-bold'}><Trans>Start staking now!</Trans></h2>
        <p><Trans>Earn up to 20% APY on your assets.</Trans></p>
      </div>
      <Button variant={'ghost'} className={'hidden md:inline'}>
        <Trans>
          Start staking
        </Trans>
      </Button>
    </Link>
  )
}
const StakingCarouselItem = ({stake}) => {
  return (
    <Link to={'/wallet/staking/active/' + stake.id}>
      <UserStakeListCard stake={stake}/>
    </Link>
  )
}

const StakingCarousel = ({stakes}) => {
  const [api, setApi] = useState(null)
  const [current, setCurrent] = useState(0)
  const [count, setCount] = useState(0)

  useEffect(() => {
    if (!api) {
      return
    }

    setCount(api.scrollSnapList().length)
    setCurrent(api.selectedScrollSnap() + 1)

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1)
    })
  }, [api])
  const autoPlay = useRef(Autoplay({
    stopOnMouseEnter: true,
  }));
  const autoHeight = useRef(AutoHeight());
  return (
    <Carousel
      setApi={setApi}
      opts={{
        align: "center", loop: true,
      }}
      plugins={[autoPlay.current, autoHeight.current]}
      className="mx-auto">
      <CarouselContent>
        {stakes.map((stake, i) => (
          <CarouselItem key={i} className={'basis-full md:basis-1/2 lg:basis-1/3'}>
            <StakingCarouselItem stake={stake}/>
          </CarouselItem>
        ))}
      </CarouselContent>
      {count > 1 && <>
        <div className={'flex items-center justify-center mt-2 gap-2 lg:hidden'}>
          {Array.from({length: count}).map((_, i) => (
            <span key={i} className={cn(
              'w-2 h-2 rounded-full',
              i === current - 1 ? 'bg-primary' : 'bg-primary/20'
            )}/>
          ))}
        </div>
      </>}
    </Carousel>
  )
}


const StakingBlock = () => {
  const hasStaking = useSelector(selectBrandHasStaking)
  const {
    stakes,
    isLoading,
    totalItems,
  } = useStaking(
    {
      defaultLimit: 3,
      filter: 'active',
      skip: !hasStaking,
    },
    useGetUserStakesQuery
  );
  if (!hasStaking) return null
  if (!stakes.length || isLoading) {
    return <StartStakingCard className={'mt-5 '}/>
  }
  return (
    <div>
      <div className={'flex items-center justify-between'}>
        <h2 className={'mb-2 mt-5'}>
          <Trans>
            Active stakes
          </Trans>
        </h2>
        <Button className={'shrink-0'} variant={'ghost'} asChild>
          <Link to={'/wallet/staking/active'}>
            <Trans i18nKey={
              'viewAllStakes'
            } values={{totalItems}}>
              {"View all {{totalItems}}"}
            </Trans>
            <ChevronRight/>
          </Link>
        </Button>

      </div>

      <StakingCarousel stakes={stakes}/>
    </div>
  );
};

export default StakingBlock;