import {useResendOtpMutation} from "#services/api/common";
import useLastResend from "#hooks/otp/useLastResend";
import useErrorMessages from "#hooks/useErrorMessages";
import {ErrorNameSpaces} from "#src/constants/errors";

export default function useResendOtp() {
  const [resendMutation, {isLoading}] = useResendOtpMutation();
  const {lastResend, setLastResend, canResend} = useLastResend(Date.now());
  const {showError} = useErrorMessages(ErrorNameSpaces.OTP);

  const handleResend = async (type, userId, data) => {
    try {
      await resendMutation({type, userId, ...data}).unwrap()
      setLastResend()
    } catch (e) {
      showError(e.data?.code, e.data?.data || {});
    }
  }

  return {
    lastResend,
    setLastResend,
    canResend,
    handleResend,
    isLoading
  }
}