import {Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle} from "#ui/card";
import React, {memo} from "react";

const ProfileSection = ({icon: Icon, title, description, value, children}) => (
  <Card className="transition-all flex flex-col justify-between hover:shadow-md">
    <CardHeader className="space-y-1">
      <div className="flex items-center gap-2">
        <Icon className="w-5 h-5 text-muted-foreground"/>
        <CardTitle className="text-lg">
          {title}
        </CardTitle>
      </div>
      <CardDescription>
        {description}
      </CardDescription>
    </CardHeader>
    <CardContent>
      {value && (
        <p className="text-sm text-muted-foreground">
          {value}
        </p>
      )}
    </CardContent>
    <CardFooter>
      {children}
    </CardFooter>
  </Card>
);

export default memo(ProfileSection);