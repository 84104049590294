import {useMemo} from "react";
import {useResourceApi} from "#hooks/api/useResoureApi";
import {useGetTransactionsQuery} from "#services/api/transactions";

export const useTransactions = (options = {}) => {
  const {filters = {}, skip = false} = options || {};
  const defaultLimit = 10

  const queryResult = useResourceApi(useGetTransactionsQuery, {
    resource: "transactions",
    defaultLimit,
    initialFilters: {...filters},
    skip,
    refetchOnMountOrArgChange: true,
  });


  return useMemo(() => queryResult, [queryResult]);
};