import {Button} from "#ui/button";
import {Trans} from "react-i18next";

export const OrderButton = ({onBuy, onSell,isLoading}) => {
  return (
    <div className={'flex items-stretch w-full gap-2 justify-stretch text-uppercase'}>
      <Button disabled={isLoading} onClick={onBuy && onBuy} variant={'success'} size={'lg'} className={'w-1/2 py-6 text-lg text text-center'}>
        <Trans>
          Buy/Long
        </Trans>
      </Button>
      <Button disabled={isLoading} onClick={onSell && onSell} variant={'destructive'} size={'lg'} className={'w-1/2 py-6 text-lg text text-center'}>
        <Trans>
          Sell/Short
        </Trans>
      </Button>
    </div>
  );
};
