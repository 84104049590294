import {createSlice} from "@reduxjs/toolkit";
import {loadStorageDataState} from "#lib/utils";

const loadThemeState = () => {
  return loadStorageDataState(localStorage, 'ui-theme');
}

const bandInitialState = {
  id: null,
  name: null,
  supportChat: null,
  hasReferral: false,
  hasStaking: false,
  hasExchangeLimit: false,
  hasFutures: false,
  address: null,
  company: null,
  icon: null,
}

const loadBrandState = () => {
  const storedBrand = loadStorageDataState(sessionStorage, 'brand');
  return storedBrand || bandInitialState
}

const brandSlice = createSlice({
  name: 'brand',
  initialState: {
    theme: loadThemeState() || null,
    ...loadBrandState(),
    parsedColors: null,
  },
  reducers: {
    setTheme: (state, action) => {
      localStorage.setItem('ui-theme', JSON.stringify(action.payload));
      state.theme = action.payload;
    },
    setBrand: (state, action) => {
      sessionStorage.setItem('brand', JSON.stringify(action.payload));
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.supportChat = action.payload.supportChat;
      state.hasReferral = action.payload.hasReferral;
      state.hasStaking = action.payload.hasStaking;
      state.hasExchangeLimit = action.payload.hasExchangeLimit;
      state.hasFutures = action.payload.hasFutures;
      state.icon = action.payload.icon;
    },
    setParsedColors: (state, action) => {
      state.parsedColors = action.payload
    }
  },

})


export const selectTheme = state => state.brand.theme;
export const selectBrand = state => state.brand;
export const selectBrandId = state => state.brand.id;
export const selectBrandName = state => state.brand.name;
export const selectBrandSupportChat = state => state.brand.supportChat;
export const selectBrandHasReferral = state => state.brand.hasReferral;
export const selectBrandHasStaking = state => state.brand.hasStaking;
export const selectBrandIcon = state => state.brand.icon;
export const selectBrandAddress = state => state.brand.address;
export const selectBrandCompany = state => state.brand.company;
export const selectBrandHasExchangeLimit = state => state.brand.hasExchangeLimit;
export const selectParsedColors = state => state.brand.parsedColors
export const {setTheme, setBrand, setParsedColors} = brandSlice.actions;

export default brandSlice;