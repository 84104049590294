import {useState} from "react";
import {useLoginMutation} from "#services/api/auth";
import {useDispatch} from "react-redux";
import {setAuth} from "#store/slices/authSlice";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useErrorMessages from "#hooks/useErrorMessages";
import {ErrorNameSpaces} from "#src/constants/errors";
import {setTokens} from "#lib/tokenStorage";


export const useLogin = () => {

  // State for form data
  const [data, setData] = useState({});
  const {t} = useTranslation();
  const {showError} = useErrorMessages(ErrorNameSpaces.LOGIN, t("login_errors_message", "An error occurred while logging in."))
  // API mutation hook
  const [login, {isLoading, isError}] = useLoginMutation();

  // Hooks for navigation and state management
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  // Get redirect path from location state or default to home
  const from = location.state?.from?.pathname || "/";

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const {user, accessToken,refreshToken} = await login(data).unwrap();
      dispatch(
        setAuth({
          user,
          language: user.language.code,
        })
      );
      setTokens(accessToken, refreshToken);
      navigate(from);
    } catch (e) {
      const {data: errorData} = e;
      showError(errorData?.code);
    }
  };

  // Handle input changes
  const handleChange = (e) => {
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return {
    data,
    isLoading,
    isError,
    handleSubmit,
    handleChange,
  };
};