import {Trans} from "react-i18next";
import formatDecimal from "#lib/formatDecimal";
import {MAX_LIMIT} from "#src/constants/wallet";

export default function errorHandlers(data, symbol, onFix = null) {
  const fd = (v) => formatDecimal(v, data?.precision);

  const errorObj = {
    ERROR_NOT_ENOUGH_FOR_FEE: (<Trans i18nKey="errors_insufficientFee" values={{fee: fd(data.fee), symbol}}>
      {`Insufficient balance to cover the fee of {{fee}} {{symbol}}`}
    </Trans>),
    ERROR_MIN_LIMIT: (<Trans i18nKey="errors_minLimit" values={{min: fd(data.minLimit), symbol}}>
      {`Minimum amount is {{min}} {{symbol}}`}
    </Trans>),
    ERROR_NOT_ENOUGH_BALANCE: (
      <Trans i18nKey="errors_insufficientBalance" values={{amount: fd(data.needToBuy), symbol}}>
        {`Insufficient balance. Need to buy {{amount}} {{symbol}}`}
      </Trans>),
    ERROR_USER_CANNOT_WITHDRAW: (<Trans i18nKey={
      'errors_userCannotWithdraw'
    }>
      Oops! You cannot withdraw at the moment. Please contact support.
    </Trans>),
    NETWORK_NOT_FOUND: (<Trans i18nKey="errors_networkNotFound">
      {`Selected network not found`}
    </Trans>),
    CONVERSION_INSUFFICIENT_BALANCE: (
      <Trans i18nKey="errors_conversionInsufficientBalance" values={{amount: fd(data.amount), symbol}}>
        {`Insufficient balance for conversion. Available: {{amount}} {{symbol}}`}
      </Trans>),
    CONVERSION_MAX_LIMIT: (
      <Trans i18nKey="errors_maxLimit" values={{max: MAX_LIMIT}}>
        {`Maximum limit is {{max}}`}
      </Trans>),
    STAKE_INSUFFICIENT_FUNDS: (
      <Trans i18nKey="errors_stakeInsufficientFunds">
        {`Insufficient funds for staking.`}
      </Trans>),
    STAKE_ALREADY_ACTIVE: (
      <Trans i18nKey="errors_stakeAlreadyActive">
        {`You already have an active staking plan`}
      </Trans>
    ),
    STAKE_AMOUNT_LESS_THAN_MIN: (<Trans i18nKey="errors_stakeMinAmount" values={{min: data.minLimit, symbol}}>
      {`Minimum staking amount is {{min}} {{symbol}}`}
    </Trans>),
    STAKE_AMOUNT_GREATER_THAN_MAX: (<Trans i18nKey="errors_stakeMaxAmount" values={{max: data.maxLimit, symbol}}>
      {`Maximum staking amount is {{max}} {{symbol}}`}
    </Trans>),
    STAKE_PLAN_REQUIRED: (<Trans i18nKey="errors_stakePlanRequired">
      Please select a staking plan.
    </Trans>),
    STAKING_NOT_SUPPORTED: (<Trans i18nKey="errors_stakingNotSupported">
      Staking is not supported.
    </Trans>), STAKING_NOT_FOUND: (<Trans i18nKey="errors_stakingNotFound">
      Staking not found.
    </Trans>),
    STAKE_NOT_FOUND: (<Trans i18nKey="errors_stakeNotFound">
      Stake not found.
    </Trans>),
    STAKE_PLAN_NOT_FOUND: (<Trans i18nKey="errors_stakePlanNotFound">
      Stake plan not found.
    </Trans>),
    STAKE_CREATION_FAILED: (<Trans i18nKey="errors_stakeCreationFailed">
      Stake creation failed.
    </Trans>),
    STAKE_ID_REQUIRED: (<Trans i18nKey="errors_stakeIdRequired">
      Stake ID is required.
    </Trans>),
    STAKE_ALREADY_COMPLETED: (<Trans i18nKey="errors_stakeAlreadyCompleted">
      Stake already completed.
    </Trans>),
    STAKE_ALREADY_CANCELLED: (<Trans i18nKey="errors_stakeAlreadyCancelled">
      Stake already cancelled.
    </Trans>),
    FUTURES_ASSET_NOT_FOUND: (
      <Trans i18nKey="errors_futuresAssetNotFound">
        Asset not found
      </Trans>
    ),
    FUTURES_INVALID_TIMEFRAME: (
      <Trans i18nKey="errors_futuresInvalidTimeframe">
        Invalid time frame
      </Trans>
    ),
    FUTURES_FAILED_TO_GET_KLINE: (
      <Trans i18nKey="errors_futuresFailedKline">
        Failed to get klines
      </Trans>
    ),
    FUTURES_BALANCE_NOTFOUND: (
      <Trans i18nKey="errors_futuresBalanceNotFound">
        Futures balance not found
      </Trans>
    ),
    FUTURES_INSUFFICIENT_BALANCE: (
      <Trans i18nKey="errors_futuresInsufficientBalance">
        Insufficient balance to open position
      </Trans>
    ),
    FUTURES_POSITION_NOT_FOUND: (
      <Trans i18nKey="errors_futuresPositionNotFound">
        Position not found
      </Trans>
    ),
    FUTURES_LEVERAGE_TOO_HIGH: (
      <Trans i18nKey="errors_futuresLeverageTooHigh" values={{max: fd(data.maxLeverageDecimal)}}>
        {"Maximum leverage for your account is {{max}}"}
      </Trans>
    ),
    FUTURES_LEVERAGE_INVALID: (
      <Trans i18nKey="errors_futuresLeverageInvalid">
        Invalid leverage value
      </Trans>
    ),
    FUTURES_MIN_POSITION_SIZE: (
      <Trans i18nKey="errors_futuresMinPositionSize" values={{min: fd(data.minSize)}}>
        {"Minimum position size is {{min}}"}
      </Trans>
    ),
    FUTURES_SIZE_INVALID: (
      <Trans i18nKey="errors_futuresSizeInvalid">
        Invalid size value
      </Trans>
    ),
    FUTURES_PRICE_INVALID: (
      <Trans i18nKey="errors_futuresPriceInvalid">
        Invalid price value
      </Trans>
    ),
    FUTURES_STOP_LOSS_TOO_HIGH_LONG: (
      <Trans i18nKey="errors_futuresStopLossTooHighLong" values={{entry: fd(data.entryPrice)}}>
        {"Stop Loss must be lower than entry price ({{entry}}) for LONG positions"}
      </Trans>
    ),
    FUTURES_STOP_LOSS_TOO_CLOSE_LONG: (
      <Trans i18nKey="errors_futuresStopLossTooCloseLong" values={{min: fd(data.minDifference)}}>
        {"Stop Loss must be at least {{min}} below entry price for LONG positions"}
      </Trans>
    ),
    FUTURES_STOP_LOSS_TOO_HIGH_SHORT: (
      <Trans i18nKey="errors_futuresStopLossTooHighShort" values={{entry: fd(data.entryPrice)}}>
        {"Stop Loss must be higher than entry price ({{entry}}) for SHORT positions"}
      </Trans>
    ),
    FUTURES_STOP_LOSS_TOO_CLOSE_SHORT: (
      <Trans i18nKey="errors_futuresStopLossTooCloseShort" values={{min: fd(data.minDifference)}}>
        {"Stop Loss must be at least {{min}} above entry price for SHORT positions"}
      </Trans>
    ),
    FUTURES_TAKE_PROFIT_TOO_LOW: (
      <Trans i18nKey="errors_futuresTakeProfitTooLow" values={{entry: fd(data.entryPrice)}}>
        {"Take Profit must be higher than entry price ({{entry}}) for LONG positions"}
      </Trans>
    ),
    FUTURES_TAKE_PROFIT_TOO_CLOSE_LONG: (
      <Trans i18nKey="errors_futuresTakeProfitTooCloseLong" values={{min: fd(data.minDifference)}}>
        {"Take Profit must be at least {{min}} above entry price for LONG positions"}
      </Trans>
    ),
    FUTURES_TAKE_PROFIT_TOO_HIGH: (
      <Trans i18nKey="errors_futuresTakeProfitTooHigh" values={{entry: fd(data.entryPrice)}}>
        {"Take Profit must be lower than entry price ({{entry}}) for SHORT positions"}
      </Trans>
    ),
    FUTURES_TAKE_PROFIT_TOO_CLOSE_SHORT: (
      <Trans i18nKey="errors_futuresTakeProfitTooCloseShort" values={{min: fd(data.minDifference)}}>
        {"Take Profit must be at least {{min}} below entry price for SHORT positions"}
      </Trans>
    )
  };
  return {isValid: !Object.keys(errorObj).includes(data.status), message: errorObj[data.status] || ''};
};