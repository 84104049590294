import {useMemo} from "react";
import {useGetStakingQuery} from "#services/api/staking";
import {useResourceApi} from "#hooks/api/useResoureApi";

export default function useStaking(
  options = {}, useQuery = useGetStakingQuery
) {
  const {defaultLimit = 10, filter, skip = false} = options;
  const {
    items: stakes,
    isLoading,
    hasArchived,
    hasNextPage,
    totalItems,
    isFetching,
  } = useResourceApi(useQuery, {
    defaultLimit,
    resource: 'stakes',
    initialFilters: {filter},
    refetchOnMountOrArgChange: true,
    skip,
  });


  return useMemo(() => ({
    stakes,
    isLoading,
    hasArchived,
    totalItems,
    hasNextPage,
    isFetching,
  }), [
    stakes,
    isLoading,
    hasArchived,
    totalItems,
    hasNextPage,
    isFetching,
  ]);
}