import {useEffect} from 'react';
import {getIconOrRandom} from "#lib/getRandomIcon";
import ReactDOMServer from 'react-dom/server';
import {darken, hslStringToHex, isLightColor, lighten} from "#lib/colors";

const FaviconGenerator = ({bgColor = '#000000', icon = 'Wallet'}) => {
  const Icon = getIconOrRandom(icon);
  const color = hslStringToHex(isLightColor(bgColor) ? darken(bgColor, 0.8) : lighten(bgColor, 0.8));

  useEffect(() => {
    const generateIcon = (size) => {
      const canvas = document.createElement('canvas');
      canvas.width = size;
      canvas.height = size;

      const iconSize = Math.floor(size * 0.625); // 20/32 = 0.625
      const padding = Math.floor((size - iconSize) / 2);

      const iconSvg = ReactDOMServer.renderToStaticMarkup(
        <Icon
          color={color}
          size={iconSize}
          absoluteStrokeWidth
        />
      );

      const svgContent = `
        <svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 ${size} ${size}">
          <rect width="${size}" height="${size}" rx="${size / 4}" ry="${size / 4}" fill="${bgColor}"/>
          <g transform="translate(${padding}, ${padding})">
            ${iconSvg}
          </g>
        </svg>
      `;

      const blob = new Blob([svgContent], {type: 'image/svg+xml'});
      return URL.createObjectURL(blob);
    };

    // Оновлення favicon
    const updateIcon = async (url, size) => {
      const canvas = document.createElement('canvas');
      canvas.width = size;
      canvas.height = size;
      const ctx = canvas.getContext('2d');

      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
          ctx.drawImage(img, 0, 0, size, size);
          resolve(canvas.toDataURL('image/png'));
          URL.revokeObjectURL(url);
        };
        img.src = url;
      });
    };

    const updateIcons = async () => {
      const faviconUrl = generateIcon(32);
      const faviconDataUrl = await updateIcon(faviconUrl, 32);

      const appleIconUrl = generateIcon(192);
      const appleIconDataUrl = await updateIcon(appleIconUrl, 192);

      let favicon = document.querySelector("link[rel*='icon']");
      if (!favicon) {
        favicon = document.createElement('link');
        favicon.rel = 'icon';
        document.head.appendChild(favicon);
      }
      favicon.href = faviconDataUrl;

      let appleIcon = document.querySelector("link[rel*='apple-touch-icon']");
      if (!appleIcon) {
        appleIcon = document.createElement('link');
        appleIcon.rel = 'apple-touch-icon';
        document.head.appendChild(appleIcon);
      }
      appleIcon.href = appleIconDataUrl;
    };

    updateIcons();
  }, [color, Icon, bgColor]);

  return null;
};

export default FaviconGenerator;