import {getIconOrRandom} from "#lib/getRandomIcon";
import {Coins, Users} from "lucide-react";
import {useTranslation} from "react-i18next";
import {StatCard} from "#pages/wallet/Referral/components/StatCard";

export const StatsSection = ({currentLevel, referralsCount, totalEarnings, isLoading}) => {
  const {t} = useTranslation();
  const stats = [
    {title: t("Current Level"), value: currentLevel.levelName, icon: getIconOrRandom(currentLevel.icon)},
    {title: t("Total Referrals"), value: referralsCount, icon: Users},
    {title: t("Total Earnings"), value: `$ ${totalEarnings}`, icon: Coins}
  ];

  return (<div className="grid gap-4 md:grid-cols-3 mb-6">
    {stats.map((stat, index) => (<StatCard
      key={index}
      title={stat.title}
      value={stat.value}
      icon={stat.icon}
      isLoading={isLoading}
    />))}
  </div>);
};