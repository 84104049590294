import base from "#services/api/base";

const stakingApi = base.injectEndpoints({
  endpoints: (builder) => ({

    getStaking: builder.query({
      query: (
        {page = 1, limit = 5, search = null}
      ) => {
        const params = {page, limit};
        if (search) {
          params.search = search;
        }
        return {
          url: `/api/staking/`, method: 'GET', params
        }
      },
      providesTags: ['Staking'],
    }),

    getStakingBySymbol: builder.query({
      query: ({symbol}) => ({
        url: `/api/staking/${symbol}`, method: 'GET'
      }),
      providesTags: ['Staking'],
    }),

    createStaking: builder.mutation({
      query: (data) => ({
        url: '/api/staking', method: 'POST', body: data,
      }),
      invalidatesTags: ['UserStakes', 'Staking'],
    }),

    cancelStaking: builder.mutation({
      query: (id) => ({
        url: `/api/staking/${id}`, method: 'DELETE',
      }),
      invalidatesTags: ['UserStakes', 'Staking'],
    }),

    getUserStakes: builder.query({
      query: ({filter, page, limit}) => {
        return {
          url: '/api/staking/stakes', method: 'GET', params: {filter, page, limit},
        };
      },
      providesTags: ['UserStakes'],
    }),

    getUserStake: builder.query({
      query: (id) => ({
        url: `/api/staking/stakes/${id}`, method: 'GET',
      }),
      providesTags: ['Staking'],
    }),

    validateAndCalculateEarnings: builder.mutation({
      query: ({amount, planId}) => ({
        url: '/api/staking/validate', method: 'POST', body: {amount, planId},
      })
    }),

  }),
})


export const {
  useGetStakingQuery,
  useGetStakingBySymbolQuery,
  useCreateStakingMutation,
  useCancelStakingMutation,
  useGetUserStakesQuery,
  useGetUserStakeQuery,
  useValidateAndCalculateEarningsMutation,
} = stakingApi;

export default stakingApi;