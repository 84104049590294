import {EstimatedEarnings} from "#pages/wallet/Staking/components/EstimatedEarnings";
import {useTranslation} from "react-i18next";
import {cn} from "#lib/utils";
import {useParams} from "react-router-dom";
import {DetailsList, StakingTimer, TimeRange, UserStakeModalButton} from "#pages/wallet/Staking/components";


export const UserStakeContent = ({stake, isLoading}) => {
  const {t} = useTranslation();
  const {type} = useParams()

  const isActive = type === 'active';

  return (<div className={'flex flex-col gap-2'}>
    <TimeRange stake={stake} isLoading={isLoading}/>
    <StakingTimer isLoading={isLoading} isActive={isActive} endDate={stake.endDate} period={stake.period}/>
    <div className={cn('py-4 px-6 bg-muted', 'my-0 mb-2 -mx-6')}>
      <EstimatedEarnings
        label={stake?.status === 'completed' ? t('Earnings') : t('Estimated earnings')}
        precision={stake?.precision}
        symbol={stake?.symbol}
        earned={stake?.earned}
        fiatEarned={stake?.fiatEarned}
        isLoading={isLoading}
        className={cn('my-0', {'text-destructive': stake?.status === 'cancelled'})}
      />
    </div>
    <DetailsList stake={stake} isLoading={isLoading}/>
    <UserStakeModalButton isLoading={isLoading} stake={stake}/>
  </div>);
};
