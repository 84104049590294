import Banner from "#components/Banners/Banner";
import {Trans} from "react-i18next";
import {AlertCircle} from "lucide-react";
import {OTP_TYPES} from "#src/constants/auth";
import OtpModal from "#components/common/OtpForm/OtpModal";
import useActivation from "#components/Banners/NeedActivationBanner/useActivation";

const NeedActivationBanner = ({user}) => {
  const {openHandler, closeHandler, open,isLoading} = useActivation(user);

  if (user?.isActive) return null;
  return (
    <>
      <Banner
        Icon={AlertCircle}
        title={<Trans>Your account is not activated.</Trans>}
        variant={'warning'}>
        <Trans>
          Please activate your account to use all features.
        </Trans>
        <button type={'button'} className={'underline'} onClick={openHandler}>
          <Trans>Activate now</Trans>
        </button>
      </Banner>
      <OtpModal
        open={open}
        type={OTP_TYPES.EMAIL_VERIFICATION}
        setOpen={closeHandler}
        isLoading={isLoading}
        title={
          <Trans> Verify your account</Trans>
        }
        userId={user?.id}
      />
    </>
  );
};

export default NeedActivationBanner;