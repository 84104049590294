import React from 'react';
import {UserStakeListCard} from "#components/wallet/UserStakeListCard";

const StakingCard = ({staking, onStakeClick}) => {
  return (
    <button className="w-full" onClick={() => onStakeClick?.(staking)}>
      <UserStakeListCard stake={staking}/>
    </button>
  );
};

export const MobileStakingList = ({stakes,onStakeClick}) => {
  return (
    <div className={'space-y-4'}>
      {stakes.map((staking, index) => (
        <StakingCard
          key={`staking-${staking.id || index}`}
          staking={staking}
          onStakeClick={onStakeClick}
        />
      ))}
    </div>
  );
};

