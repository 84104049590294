import {createBrowserRouter} from "react-router-dom";
import Home from "#pages/Home";
import authRouter from "#src/router/authRouter";
import RequireAuth from "#layouts/RequireAuth";
import MainLayout from "#layouts/MainLayout";
import walletRouter from "#src/router/walletRouter";
import Error404 from "./Error404";
import {ErrorRoute} from "./ErrorBoundary";
import profileRouter from "#src/router/profileRouter";
import docsRouter from "#src/router/docsRouter";
import futuresRouter from "#src/router/futuresRouter";

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <MainLayout/>,
      errorElement: <ErrorRoute/>,
      children: [
        {
          index: true,
          element: <Home/>
        },
        authRouter,
        {
          path: "/",
          element: <RequireAuth/>,
          children: [
            walletRouter,
            profileRouter,
            futuresRouter,
          ]
        },
        docsRouter,
        {
          path: "*",
          element: <Error404/>
        }
      ]
    },
  ]
)

export default router;