import PageHeader from "#components/common/PageHeader";
import {Trans} from "react-i18next";
import TransactionTable from "#pages/wallet/History/components/TransactionTable";
import Container from "#ui/container";
import TransactionDetailsModal from "#pages/wallet/History/components/TransactionDetailsModal";
import {useIsMobile} from "#hooks/use-mobile";
import TransactionList from "#pages/wallet/History/components/TransactionList";
import Pagination from "#ui/pagination";
import {useTransactions} from "#hooks/api/useTransactions";
import {useSearchParams} from "react-router-dom";
import {Button} from "#ui/button";
import {useState} from "react";
import {XIcon} from "lucide-react";

const TransactionHistory = () => {
  const isMobile = useIsMobile();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);
  const accountId = searchParams.get('accountId');
  const filters = accountId ? {accountId} : {};

  const {
    items: transactions = [],
    isLoading,
    isFetching,
    hasNextPage,
    hasPrevPage,
    prevPageHandler,
    nextPageHandler,
    clearFilters,
  } = useTransactions(
    {
      filters,
    }
  );

  const clearFiltersHandler = () => {
    setSearchParams({});
    clearFilters();
  };

  const handleCloseDetails = (open) => {
    if (!open) {
      setSelectedTransactionId(null);
    }
  };

  const transactionsProps = {
    transactions,
    isLoading: isLoading || isFetching,
    onViewDetails: setSelectedTransactionId
  };

  return (
    <Container>
      <PageHeader title={<Trans>History</Trans>}>
        {accountId && (
          <Button variant={'ghost'} onClick={clearFiltersHandler}>
            <Trans>All accounts</Trans> <XIcon/>
          </Button>
        )}
      </PageHeader>

      {isMobile ? (
        <TransactionList {...transactionsProps} />
      ) : (
        <TransactionTable {...transactionsProps} />
      )}

      <Pagination
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPrevPage}
        onNextPage={nextPageHandler}
        onPreviousPage={prevPageHandler}
      />

      <TransactionDetailsModal
        transactionId={selectedTransactionId}
        onClose={handleCloseDetails}
      />
    </Container>
  );
};

export default TransactionHistory;