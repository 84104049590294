import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import useValidateAmount from "#hooks/useValidateAmount";
import {useValidateAndCalculateEarningsMutation} from "#services/api/staking";

export const useStakingForm = (plan, selectedPlan, skip) => {
  const [amount, setAmount] = useState(plan?.min ?? 0);
  const stakingFormApiRef = useRef({})
  const {
    message,
    isValid,
    validatedAmount,
    isLoading: isEarningsLoading,
    earnedInterest: earned = 0,
    fiatEarned = 0,
    validate
  } = useValidateAmount(useValidateAndCalculateEarningsMutation, {
    amount,
    skip,
    symbol: plan?.symbol,
    planId: selectedPlan?.id,
  });

  const prevPlanId = useRef(selectedPlan?.id);
  useEffect(() => {
    if (skip) return
    if (prevPlanId.current !== selectedPlan?.id) {
      validate();
      prevPlanId.current = selectedPlan?.id;
    }
  }, [selectedPlan?.id, validate, skip]);

  stakingFormApiRef.current.setAmountHandler = useCallback((value) => {
    setAmount(value);
  }, []);


  const isErrorAmount = !isValid && !!validatedAmount;
  const {min, max} = plan || {};
  const isButtonDisabled = useMemo(() => {
    const isAmountValid = amount && Number(amount) >= Number(min) && Number(amount) <= Number(max);
    const isPlanSelected = Boolean(selectedPlan?.id);
    const hasEarnings = Number(earned) > 0;
    const hasNoErrors = !isErrorAmount && !isEarningsLoading;

    return !isAmountValid || !isPlanSelected || !hasEarnings || !hasNoErrors;
  }, [amount, earned, selectedPlan, isEarningsLoading, isErrorAmount, min, max]);

  return useMemo(() => ({
    amount,
    earned,
    fiatEarned,
    isEarningsLoading,
    isErrorAmount,
    message,
    validatedAmount,
    stakingFormApiRef,
    isButtonDisabled,
  }), [amount, earned, fiatEarned, isEarningsLoading, isErrorAmount, message, validatedAmount, stakingFormApiRef, isButtonDisabled]);
};