import {QRCode} from "react-qrcode-logo";
// import {getCssVar} from "#lib/utils";
import useTheme from "#hooks/useTheme";

const sizes = {
  small: 100,
  base: 150,
  medium: 200,
  large: 300,
  xlarge: 400,
}
const AddressQrCode = ({value, symbol, size = 'base'}) => {
  const symbolFormatted = symbol.toLowerCase().trim();
  const {theme, isDark} = useTheme();
  const bgColor = isDark ? theme?.foreground : theme?.background;
  const color = isDark ? theme?.background : theme?.foreground;
  let iconPath;
  try {
    iconPath = require(`cryptocurrency-icons/128/color/${symbolFormatted}.png`);

  } catch (e) {
    iconPath = `https://nowpayments.io/images/coins/${symbolFormatted}.svg`;
  }

  return (
    <QRCode
      style={{
        borderRadius: 20,
        margin: '0 auto',
      }}
      bgColor={bgColor}
      eyeRadius={10}
      ecLevel={'H'}
      logoPadding={2}
      logoPaddingStyle='circle'
      value={value}
      logoImage={iconPath}
      eyeColor={color}
      fgColor={color}
      size={sizes[size]}
    />
  );
};

export default AddressQrCode;