import { Trans } from "react-i18next";
import PasteFormField from "#components/common/PasteFormField";

const AddressInput = ({
  isDisabled,
  address,
  isAddressValid,
  networks,
  onAddressChange
}) => {
  const message = address && !isAddressValid ? (
    <Trans>
      The address input doesn't match the network selected, please change
    </Trans>
  ) : null;

  return (
    <PasteFormField
      disabled={isDisabled}
      value={address}
      isError={address && !isAddressValid}
      messageProps={{
        message,
        variant: 'error',
        className: 'text-xs'
      }}
      onChange={(e) => onAddressChange(e.target.value, networks.filter(network => network.isWithdrawalEnabled))}
      onPaste={(v) => onAddressChange(v, networks)}
      label={'Address'}
      placeholder={'Enter Address'}
    />
  );
};

export default AddressInput;