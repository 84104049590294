import localeNumberSeparators from "#lib/localeNumberSeparators";
import removeTrailingZerosFn from "#lib/removeTrailingZeros";

export default function formatDecimal(value, decimalScale = 4, removeTrailingZeros = true) {
  decimalScale = decimalScale ?? 4;
  const numValue = Number(value ?? 0);
  const safeDecimalScale = Math.max(2, decimalScale);
  const {thousandSeparator, decimalSeparator} = localeNumberSeparators();

  // Форматуємо число з розділювачами
  const formatted = numValue.toLocaleString('en-US', {
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: safeDecimalScale
  });

  // Замінюємо розділювачі на локальні
  const withLocalSeparators = formatted
    .replace(/,/g, thousandSeparator)
    .replace(/\./g, decimalSeparator);

  // Видаляємо trailing zeros якщо потрібно
  return removeTrailingZeros
    ? removeTrailingZerosFn(withLocalSeparators, decimalSeparator)
    : withLocalSeparators;
}