import {ReceiveDirection, ReceiveInfo, ReceiveNetworkSelect} from "#pages/wallet/Receive/components";
import {memo, useMemo} from "react";
import {Card} from "#ui/card";
import FormHeader from "#ui/form-header";
import {Trans} from "react-i18next";
import SelectAccount from "#components/wallet/AccountSelector";
import useSkipFirstUnmount from "#hooks/useSkipFirstUnmount";
import useReceive from "#hooks/api/useReceive";
import Container from "#ui/container";
import useClearStateTimeout from "#hooks/useClearStateTimeout";
import {useNavigate} from "react-router-dom";
import HeaderWIthActions from "#components/common/HeaderWIthActions";

const Receive = () => {
  const {
    account, network, receiveApiRef,
  } = useReceive();
  const navigate = useNavigate();
  useSkipFirstUnmount(receiveApiRef.clearState);
  useClearStateTimeout(() => navigate('/wallet'), 2 * 60 * 1000)

  const accountId = useMemo(() => account?.id, [account]);
  return (<Container>
    <HeaderWIthActions symbol={account?.symbol}>
      <Trans>
        Receive Funds
      </Trans>
    </HeaderWIthActions>
    <div className="w-full flex gap-10 md:gap-6 items-start flex-col md:flex-row">
      <Card className="w-full space-y-3 md:w-1/2 px-4 py-6">
        <FormHeader>
          <Trans>Select Coin</Trans>
        </FormHeader>
        <SelectAccount
          path="/wallet/receive/"
          selectedAccount={account}
          onSelect={receiveApiRef.setAccount}
        />
        <ReceiveNetworkSelect
          network={network}
          setNetwork={receiveApiRef.setNetwork}
          accountId={accountId}
        />
        <ReceiveDirection
          account={account}
          network={network}
        />
      </Card>
      <ReceiveInfo/>
    </div>
  </Container>);
};

export default memo(Receive);