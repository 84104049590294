import {useState} from "react";
import {useRegisterMutation} from "#services/api/auth";
import {useTranslation} from "react-i18next";
import useErrorMessages from "#hooks/useErrorMessages";
import {ErrorNameSpaces} from "#src/constants/errors";
import useReferralCode from "#hooks/auth/useReferralCode";
import {setAuth, setShowOtp} from "#store/slices/authSlice";
import {setTokens} from "#lib/tokenStorage";
import {useDispatch} from "react-redux";


const errorsInitialState = {
  email: null, password: null, terms: null,
}
const useRegister = () => {
  const [referralCode] = useReferralCode();
  const [data, setData] = useState({referralCode});
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [errorFields, setErrorFields] = useState(errorsInitialState);
  const [register, {isLoading, isError}] = useRegisterMutation();
  const {showError, getError} = useErrorMessages(ErrorNameSpaces.REGISTER, t('error_unknown', 'Unknown error'));


  const handleSubmit = async (e) => {
    setErrorFields(errorsInitialState);
    try {
      e.preventDefault();
      const {user, accessToken, refreshToken} = await register(data).unwrap();
      dispatch(setAuth({
        user, language: user?.language?.code,
      }));
      setTokens(accessToken, refreshToken);
      dispatch(setShowOtp(true));

    } catch (e) {
      const {data: errorData} = e;
      const errors = errorData?.errors
      if (errors) {
        errors.forEach((error) => {
          setErrorFields((prev) => ({
            ...prev, [error.field]: getError(error.message),
          }))
        });
      } else {
        showError(errorData?.code);
      }

    }
  }
  const handleChange = (e) => {
    setData((prev) => ({
      ...prev, [e.target.name]: e.target.value,
    }));
  };
  return {
    data, isLoading, isError, errorFields, handleSubmit, handleChange
  };
}

export default useRegister;