import {useSearchParams} from "react-router-dom";
import {useCallback} from "react";

const usePagination = (hasNextPage) => {
  const [searchParams, setSearchParam] = useSearchParams();

  let page = parseInt(searchParams.get('page'));
  page = isNaN(page) ? 1 : page;

  const setPage = useCallback((newPage) => {
    setSearchParam(prev => {
      const currentParams = Object.fromEntries(prev.entries());
      return {
        ...currentParams,
        page: newPage
      };
    });
  }, [setSearchParam]);
  const nextPageHandler = useCallback(() => {
    if (hasNextPage) {
      setPage(page + 1);
    }
  }, [hasNextPage, page, setPage]);

  const prevPageHandler = useCallback(() => {
    if (page > 1) {
      setPage(page - 1);
    }
  }, [page, setPage]);

  const loadMore = useCallback(() => {
    if (hasNextPage) {
      setPage(page + 1);
    }
  }, [hasNextPage, page, setPage]);
  return {
    page,
    setPage,
    nextPageHandler,
    prevPageHandler,
    loadMore
  };
};

export default usePagination;