import React from "react";
import {Lock, Shield, Bell} from "lucide-react";
import {Trans} from 'react-i18next';
import Container from "#ui/container";
import FadeIn from "#ui/FadeIn";
import {SecurityHeader} from './SecurityHeader';
import {SecurityCard} from './SecurityCard';

export const Security = () => {
  const features = [
    {
      icon: <Lock/>,
      title: <Trans>Military-Grade Encryption</Trans>,
      description: <Trans>AES-256 encryption for all your data</Trans>
    },
    {
      icon: <Shield/>,
      title: <Trans>2FA Protection</Trans>,
      description: <Trans>Additional layer of account security</Trans>
    },
    {
      icon: <Bell/>,
      title: <Trans>Real-time Alerts</Trans>,
      description: <Trans>Instant notifications for all activities</Trans>
    }
  ];

  return (
    <section className="py-24 relative">
      <div className="absolute inset-0 bg-gradient-to-t z-0 from-transparent to-accent"/>
      <Container className={'z-10 relative'}>
        <SecurityHeader/>
        <FadeIn asChild>
          <div className="grid md:grid-cols-3 relative overflow-hidden rounded-card">
            <div className="absolute inset-0 bg-gradient-to-b z-0 from-transparent blur to-highlight/10"/>
            {features.map((feature, i) => (
              <SecurityCard key={i} {...feature} />
            ))}
          </div>
        </FadeIn>
      </Container>
    </section>
  );
};