import {useTranslation} from "react-i18next";
import {Progress} from "#ui/progress";

export const LevelProgress = ({
                                currentLevel,
                                referralsCount,
                                referrerLevels
                              }) => {
  const {t} = useTranslation();
  const currentLevelIndex = referrerLevels.findIndex(
    level => level.id === currentLevel.id
  );
  const nextLevel = referrerLevels[currentLevelIndex + 1];

  const calculateProgress = () => {
    if (!nextLevel) return 100;
    const progress = (referralsCount / nextLevel.minReferrals) * 100;
    return Math.min(progress, 100);
  };
  const progress = calculateProgress();
  if (!nextLevel) return null;
  return (
    <div className="space-y-2 lg:w-1/3">
      <div className="flex items-center justify-between text-sm gap-2">
        <span>{t("Progress to {{level}}", {level: nextLevel.levelName})}</span>
        <span className="text-muted-foreground">
          {referralsCount}/{nextLevel.minReferrals}
        </span>
      </div>
      <div className="relative">
        <Progress value={progress} className="h-2"/>
        {progress > 0 && (
          <div
            className="absolute top-0 left-0 h-2 w-full animate-pulse bg-primary/10 rounded-full"
            style={{
              clipPath: `inset(0 ${100 - progress}% 0 0)`
            }}
          />
        )}
      </div>
    </div>
  );
};