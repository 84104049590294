import React from 'react';
import {cn} from "#lib/utils";

// Helper function to get circle attributes based on size
const getCircleAttributes = (size) => {
  const attributes = {
    sm: {
      size: 24,
      radius: 8,
      strokeWidth: 2,
    },
    md: {
      size: 32,
      radius: 12,
      strokeWidth: 3,
    },
    lg: {
      size: 48,
      radius: 20,
      strokeWidth: 4,
    }
  };

  return attributes[size] || attributes.medium;
};

export const CircularProgress = ({
                                   size = 'md',
                                   value = 0,
                                   className, children,
                                   ...props
                                 }) => {
  const circleAttributes = getCircleAttributes(size);

  const circumference = 2 * Math.PI * circleAttributes.radius;
  const circleSize = circleAttributes.size / 2;

  // Ensure value is between 0 and 100
  const progress = Math.min(100, Math.max(0, value));

  return (
    <div className={'relative'}>
      <svg
        width={circleAttributes.size}
        height={circleAttributes.size}
        viewBox={`0 0 ${circleAttributes.size} ${circleAttributes.size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={cn(
          "transform -rotate-90 transition-transform",
          className
        )}
        {...props}
      >
        <circle
          cx={circleSize}
          cy={circleSize}
          r={circleAttributes.radius}
          className="stroke-primary/20"
          strokeWidth={circleAttributes.strokeWidth}
          fill="none"
        />

        <circle
          cx={circleSize}
          cy={circleSize}
          r={circleAttributes.radius}
          fill="none"
          className="stroke-primary"
          strokeWidth={circleAttributes.strokeWidth}
          strokeLinecap="round"
          strokeDasharray={circumference}
          strokeDashoffset={circumference * ((100 - progress) / 100)}
          style={{
            transition: 'stroke-dashoffset 0.3s ease'
          }}
        />
      </svg>
      {children && <div className={'absolute inset-0 flex items-center justify-center'}>{children}</div>}
    </div>
  );
};
