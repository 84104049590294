
const testimonials = [
  {
    name: "Victor",
    role: "Product User",
    content: "Beautiful user-friendly interface ease of use and security are the main features of a product you can trust",
    rating: 5,
    avatar: require("#assets/images/first_home/reviews/1.jpeg")
  },
  {
    name: "Kurt",
    role: "Customer",
    content: "For me the most important service is the work of the support service at the highest level. Guys, you are super.",
    rating: 5,
    avatar: require("#assets/images/first_home/reviews/2.jpeg")

  },
  {
    name: "Elena",
    role: "Investor",
    content: "I used to think that investing is not for me but when I got to know this wallet I realised that everything is much easier thanks to this platform and discovered a new source of income thank you",
    rating: 4,
    avatar: require("#assets/images/first_home/reviews/3.jpeg")

  },
  {
    name: "Den",
    role: "Trader",
    content: "I don't often leave reviews but I was very surprised by the speed of transactions quality of service minimum commission is very nice",
    rating: 5,
    avatar: require("#assets/images/first_home/reviews/4.jpeg")

  },
  {
    name: "Antony",
    role: "Customer",
    content: "If it was a bank I would put all my savings here it is in a nutshell how safe and practical it is",
    rating: 5,
    avatar: require("#assets/images/first_home/reviews/5.jpeg")
  },
  {
    name: "Sara",
    role: "Family Investor",
    content: "Guys this is a very interesting project I recommend to everyone we are working with the whole family have only positive feedback so I recommend to everyone",
    rating: 4,
    avatar: require("#assets/images/first_home/reviews/6.jpeg")

  },
  {
    name: "Gustavo",
    role: "Investor",
    content: "I finally found an affordable safe tool for investing this can be used as extra income and also as main income all depends on your capabilities and on your desire",
    rating: 4,
    avatar: require("#assets/images/first_home/reviews/7.jpeg")

  },
  {
    name: "Tomas MC",
    role: "Platform User",
    content: "Huge thanks to the developers and the whole team because they really took a very responsible approach to the development of this project and we can use a really very high quality product that is on the market and has no competition",
    rating: 5,
    avatar: require("#assets/images/first_home/reviews/8.jpeg")

  },
  {
    name: "Roberto",
    role: "Trader",
    content: "Wow you guys are super I was very pleasantly surprised when I made some transactions very good interest rate payout speed and much more I am pleasantly surprised",
    rating: 5,
    avatar: require("#assets/images/first_home/reviews/9.jpeg")

  },
  {
    name: "Jack",
    role: "Crypto Investor",
    content: "Who is interested in cryptocurrency investing you here I recommend this platform because I have personally used it I have conducted several hundred transactions and decided to share my review this project is really worth entrusting your money to it",
    rating: 4,
    avatar: require("#assets/images/first_home/reviews/10.jpeg")

  }
];

export const splitTestimonials = (testimonials) => {
  const firstHalf = testimonials.slice(0, testimonials.length / 2);
  const secondHalf = testimonials.slice(testimonials.length / 2, testimonials.length);

  return [firstHalf, secondHalf];
};
export default testimonials