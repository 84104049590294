import {TableCell, TableRow} from "#ui/table";
import {Skeleton} from "#ui/skeleton";

const OrderTableSkeleton = ({rows, cels}) => {
  return (
    Array.from({length: !!rows ? rows : 5}).map((_, i) => <TableRow key={i}>
      {Array.from({length: cels}).map((_, i) => (
        <TableCell key={i}>
          <Skeleton className={'w-full h-5'}/>
        </TableCell>))
      }
    </TableRow>)
  );
};

export default OrderTableSkeleton;