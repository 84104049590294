import {Button} from "#ui/button";
import {Layers2} from "lucide-react";
import {Link} from "react-router-dom";
import {Trans} from "react-i18next";

export const UserStakesEmpty = (
  {type}
) => {
  const isActive = type === 'active';
  return (
    <div className="flex flex-col items-center justify-center text-center py-28">
      <Layers2 className={'w-16 h-16 mb-4 text-primary'}/>
      <h2 className="text-2xl font-bold">
        <Trans>
          No stakes found
        </Trans>
      </h2>
      <p className="text-sm text-gray-500 mt-2">
        {isActive ?
          (<Trans i18nKey={'userActiveStakesEmptyDescr'}>
            You have no active stakes at the moment. Create a new stake to start earning.
          </Trans>) :
          <Trans i18nKey={'userArchivedStakesEmptyDescr'}>
            You have no archived stakes at the moment. Go to active stakes.
          </Trans>
        }
      </p>
      {isActive ? (
          <Button asChild variant="outline" size={'lg'} className="mt-5">
            <Link to={'/wallet/staking/'}>
              <Trans>
                Create a new stake
              </Trans>
            </Link>
          </Button>
        ) :
        (<Button asChild variant="outline" size={'lg'} className="mt-5">
          <Link to={'/wallet/staking/active'}>
            <Trans>
              Go to active stakes
            </Trans>
          </Link>
        </Button>)
      }
    </div>
  );
}