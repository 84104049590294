import {
  ResponsiveDialog,
  ResponsiveDialogContent,
  ResponsiveDialogDescription,
  ResponsiveDialogHeader,
  ResponsiveDialogTitle,
  ResponsiveDialogTrigger
} from "#ui/responsive-dialog";
import {Button} from "#ui/button";
import {Pencil} from "lucide-react";
import {Trans} from "react-i18next";
import React, {memo} from "react";
import ResetPasswordForm from "#components/common/ResetPasswordForm";
import {OTP_TYPES} from "#src/constants/auth";
import useResendOtp from "#hooks/otp/useResendOtp";

const ChangePassword = ({userId}) => {
  const {handleResend} = useResendOtp();
  const onDialogOpen = (isOpen) => {
    if (isOpen) {
      handleResend('PASSWORD_RESET', userId, {userId});
    }
  }
  return (
    <ResponsiveDialog onOpenChange={onDialogOpen}>
      <ResponsiveDialogTrigger className={'w-full'} asChild>
        <Button variant="outline" className="w-full">
          <Pencil className="w-4 h-4"/>
          <Trans>Change password</Trans>
        </Button>
      </ResponsiveDialogTrigger>
      <ResponsiveDialogContent>
        <ResponsiveDialogHeader>
          <ResponsiveDialogTitle>
            <Trans>
              Change Password
            </Trans>
          </ResponsiveDialogTitle>
          <ResponsiveDialogDescription>
            <Trans i18nKey={"auth_reset_password_description"}>
              We have sent you a one-time password to your email. Please enter it below to reset your password.
            </Trans>
          </ResponsiveDialogDescription>
        </ResponsiveDialogHeader>
        <div className={'p-6 pt-0 sm:p-0'}>
          <ResetPasswordForm
            userId={userId}
            type={OTP_TYPES.PASSWORD_RESET}
            data={{userId}}
          />
        </div>

      </ResponsiveDialogContent>
    </ResponsiveDialog>
  );
};

export default memo(ChangePassword);