// constants.js
import {Trans} from "react-i18next";
import {NavigationButton} from "./components/NavigationButton";
import {Layers, PackageSearch} from "lucide-react";
import {Progress} from "#ui/progress";
import CurrencyFormat from "#ui/currency-format";
import {cn} from "#lib/utils";

export const STAKING_ROUTES = {
  ACTIVE: '/wallet/staking/active',
  HISTORY: '/wallet/staking/archived',
}
export const STAKE_TYPES = {
  active: {
    title: <Trans>Active plans</Trans>,
    action: (
      <NavigationButton
        to={STAKING_ROUTES.HISTORY}
        variant="natural"
        icon={<PackageSearch/>}
      >
        <Trans>Archived</Trans>
      </NavigationButton>
    ),
    lastHeadText: <Trans>Progress</Trans>,
    getLastCell: (stake) => (
      <div className="flex items-center gap-2">
        <Progress value={stake.progress}/>
        <small className="text-muted-foreground">
          {stake.progress}%
        </small>
      </div>
    ),
  },
  archived: {
    title: <Trans>Archived plans</Trans>,
    action: (
      <NavigationButton
        to={STAKING_ROUTES.ACTIVE}
        variant="natural"
        icon={<Layers/>}
      >
        <Trans>Active plans</Trans>
      </NavigationButton>
    ),
    lastHeadText: <Trans>Earned</Trans>,
    getLastCell: (stake) => (
      <p className={cn({
        'text-success': stake.status === 'completed',
        'text-destructive': stake.status === 'cancelled',
      })}>
        {stake.status === 'completed' && '+ '}
        <CurrencyFormat
          value={stake.earnedInterest}
          decimalScale={stake.precision}
        />{" "}
        {stake.symbol}
      </p>
    ),
  },
};

