import CopyFormField from "#components/common/CopyFormField";
import {Button} from "#ui/button";
import {Share2} from "lucide-react";
import PageHeader from "#components/common/PageHeader";
import {useTranslation} from "react-i18next";
import {useToast} from "#hooks/use-toast";
import {Skeleton} from "#ui/skeleton";

export const ReferralHeader = ({
                                 referrerLink, currentLevelReward, isLoading
                               }) => {
  const {t} = useTranslation();
  const {toast} = useToast();

  const handleShare = async () => {
    try {
      await navigator.share({
        title: t("Join me"), text: t("Join using my referral link"), url: referrerLink
      });
    } catch (error) {
      if (error.name !== "AbortError") {
        toast({
          title: t("Unable to share"), description: t("Please copy the link manually"), variant: "destructive"
        });
      }
    }
  };
  const description = t("referralsDescription", 'Invite your friends and start earn from {{currentLevelReward}}% and more on their deposits', {currentLevelReward});
  return (<PageHeader
    title={t("Invite friends and earn")}
    description={isLoading ?
      <Skeleton as={'span'} className={'w-full h-6 block bg-primary-foreground/20'}/> : description}
  >
    <div className="w-full flex items-end gap-2 lg:max-w-sm">
      <div className="flex-1">
        <small className={'block mb-2'}>{t("Your referral link")}</small>
        {isLoading ? <Skeleton className={'w-full h-10 bg-primary-foreground/20'}/> : <CopyFormField
          className="border-0 bg-background text-foreground"
          value={referrerLink}
        />}
      </div>
      {isLoading ? <Skeleton className={'w-10 h-10 bg-primary-foreground/20'}/> :
        <Button
          variant="natural"
          size="icon"
          className="p-5"
          onClick={handleShare}
        >
          <Share2 size="20"/>
          <span className="sr-only">{t("Share Link")}</span>
        </Button>
      }
    </div>
  </PageHeader>);
};

