import React, {forwardRef, useEffect, useState} from "react";
import {cn} from "#lib/utils";

const BackgroundGrid = ({className}) => {
  const [rectangles, setRectangles] = useState([]);
  const gridSize = 44;

  useEffect(() => {
    const rows = Math.ceil(window.innerHeight / gridSize);
    const cols = Math.ceil(window.innerWidth / gridSize);

    const createRectangle = () => {
      const row = Math.floor(Math.random() * rows);
      const col = Math.floor(Math.random() * cols);
      const lifespan = 3000;
      const newRectangle = {
        id: `rectangle-${Date.now()}-${Math.random()}`,
        row,
        col,
        opacity: Math.random() * 0.5 + 0.25,
      };

      setRectangles(prev => [...prev, newRectangle]);

      setTimeout(() => {
        setRectangles(prev => prev.map(rect =>
          rect.id === newRectangle.id
            ? {...rect, isDisappearing: true}
            : rect
        ));

        setTimeout(() => {
          setRectangles(prev => prev.filter(rect => rect.id !== newRectangle.id));
        }, 1000);
      }, lifespan);
    };

    const addInterval = setInterval(() => {
      if (Math.random() < 0.5) {
        createRectangle();
      }
    }, 400);

    return () => {
      clearInterval(addInterval);
    };
  }, []);

  return (
    <div className={cn("absolute inset-0", className)}>
      <div
        className="absolute inset-0 bg-[linear-gradient(to_right,hsl(var(--primary))_1px,transparent_1px),linear-gradient(to_bottom,hsl(var(--primary))_1px,transparent_1px)] bg-[size:44px_44px] [mask-image:radial-gradient(ellipse_80%_80%_at_50%_50%,black,transparent)] opacity-[0.1]"
      />
      <div
        className="absolute inset-0 bg-gradient-to-tr from-primary/[0.1] via-transparent to-highlight/[0.1]"
      />
      <div className="absolute inset-0 overflow-hidden">
        {rectangles.map((rect) => (
          <div
            key={rect.id}
            className={cn(
              "absolute animate-in bg-primary/50 fade-in duration-1000 ease-in-out",
              {'animate-out fade-out duration-1000 ease-in-out': rect.isDisappearing}
            )}
            style={{
              height: `${gridSize}px`,
              width: `${gridSize}px`,
              top: `${rect.row * gridSize}px`,
              left: `${rect.col * gridSize}px`,
              opacity: rect.opacity,
            }}
          />
        ))}
      </div>
    </div>
  );
};

export const WithBackgroundGrid = forwardRef(
  ({children, className, gridProps}, ref) => {
    return (
      <div className={cn('relative min-h-screen overflow-hidden', className)} ref={ref}>
        <BackgroundGrid {...gridProps}/>
        {children}
      </div>
    );
  }
);


export default BackgroundGrid;