import RequirementsList from "#pages/profile/Profile/components/SendVerificationDialog/RequirementsList";
import ImageUploadPreview from "#ui/ImageUploadPreview";
import {Trans} from "react-i18next";
import React, {memo} from "react";

const UploadDocumentStep = ({verificationData, updateField}) => (<div className="space-y-4">
  <RequirementsList
    requirements={{
      do: [
        <Trans>Original full-size, unedited documents </Trans>,
        <Trans>Place documents against a single-colored background </Trans>,
        <Trans>Readable, well-lit, colored images </Trans>
      ],
      dont: [
        <Trans>Black and white images </Trans>,
        <Trans>Edited or expired documents </Trans>
      ]
    }}
  />
  <ImageUploadPreview
    label={<Trans>Document</Trans>}
    file={verificationData.document}
    onFileSelect={updateField('document')}
    placeHolder={<Trans>Upload a document</Trans>}
  />
</div>);

UploadDocumentStep.displayName = 'UploadDocumentStep';
export default memo(UploadDocumentStep);