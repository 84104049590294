import {AuthForm} from "#components/common/AuthForm";
import {useLocation} from "react-router-dom";
import OtpHeader from "#components/common/OtpForm/OtpHeader";
import {Trans} from "react-i18next";

import {useSelector} from "react-redux";
import {selectUserId} from "#store/slices/authSlice";
import OtpForm from "#components/common/OtpForm";


const Otp = () => {
  const {state} = useLocation();
  const storedUserId = useSelector(selectUserId);
  const {id, type, ...data} = state
  const userId = id || storedUserId;

  return (
    <AuthForm
      title={(<OtpHeader backTo={state?.from || '/'}>
        <Trans i18nKey="auth_otp_title">One-Time Password</Trans>
      </OtpHeader>)}
      description={
        <Trans i18nKey="auth_otp_description">
          Please enter the one-time password sent to your email.
        </Trans>
      }
    >
      <OtpForm
        userId={userId}
        type={type}
        data={data}
      />
    </AuthForm>
  );
};

export default Otp;