import React, {useState} from 'react';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "#ui/dialog";
import {Button} from "#ui/button";
import {Slider} from "#ui/slider";
import {Trans} from "react-i18next";
import {Minus, Plus} from "lucide-react";
import {Input} from "#ui/input";

// Utility functions
const createMarks = (min, max) => {
  return Array.from({length: max}, (_, i) => i + 1).reduce((acc, value) => {
    if (value === min || value === max || value % 5 === 0) {
      acc[value] = value.toString();
    }
    return acc;
  }, {});
};

const clampValue = (value, min, max) => {
  if (isNaN(value)) return min;
  return Math.max(min, Math.min(max, value));
};

// Sub-components
const LeverageInput = ({value, min, max, onChange}) => {
  const increment = () => onChange(value >= max ? max : value + 1);
  const decrement = () => onChange(value <= min ? min : value - 1);

  const handleInputChange = (e) => {
    e.preventDefault();
    const newValue = parseInt(e.target.value);
    onChange(clampValue(newValue, min, max));
  };

  return (
    <div className="flex justify-between items-center gap-2 mb-5 relative">
      <Button
        onClick={decrement}
        className="shrink-0 absolute left-1.5"
        variant="ghost"
        size="icon"
      >
        <Minus/>
      </Button>
      <Input
        type="number"
        onChange={handleInputChange}
        className="px-6 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none text-center py-6 !text-lg"
        value={value}
      />
      <Button
        onClick={increment}
        variant="ghost"
        className="shrink-0 absolute right-1.5"
        size="icon"
      >
        <Plus/>
      </Button>
    </div>
  );
};

const LeverageMarks = ({marks}) => (
  <div className="flex items-center justify-between mt-1">
    {Object.keys(marks).map((key) => (
      <small className="text-muted-foreground" key={key}>
        {marks[key]}x
      </small>
    ))}
  </div>
);

const LeverageDialogContent = ({leverage, currentLeverage, min, max, setLeverage, onConfirm}) => (
  <div>
    <LeverageInput
      value={leverage}
      min={min}
      max={max}
      onChange={setLeverage}
    />
    <Slider
      defaultValue={[currentLeverage]}
      min={min}
      value={[leverage]}
      onValueChange={(number) => setLeverage(number[0])}
      max={max}
      step={min}
    />
    <LeverageMarks marks={createMarks(min, max)}/>
    <DialogClose asChild>
      <Button onClick={onConfirm} className="w-full mt-4">
        <Trans>Confirm</Trans>
      </Button>
    </DialogClose>
  </div>
);

// Main component
export const LeverageSelector = ({
                                   onSelect,
                                   currentLeverage,
                                   max, min
                                 }) => {

  const [leverage, setLeverage] = useState(currentLeverage);

  if (max === min) return null;
  const handleConfirm = () => {
    onSelect(leverage);
  }
  return (
    <Dialog>
      <DialogTrigger asChild className="w-full">
        <Button className="w-full" variant="outline">
          Leverage: {currentLeverage}x
        </Button>
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle asChild>
            <h3 className="text-lg font-semibold">
              <Trans>Adjust Leverage</Trans>
            </h3>
          </DialogTitle>
          <DialogDescription className="text-destructive">
            Selecting higher leverage such as [10x] increases your liquidation risk.
            Always manage your risk levels
          </DialogDescription>
        </DialogHeader>

        <LeverageDialogContent
          leverage={leverage}
          currentLeverage={currentLeverage}
          min={min}
          max={max}
          setLeverage={setLeverage}
          onConfirm={handleConfirm}
        />
      </DialogContent>
    </Dialog>
  );
};

