import {cn} from "#lib/utils";
import {useEffect, useRef, useState} from "react";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "#ui/tooltip";

export const DetailItem = ({ title, children, className, ...props }) => {
  const textRef = useRef(null);
  const [isOverflowed, setIsOverflowed] = useState(false);

  useEffect(() => {
    const element = textRef.current;
    if (element) {
      setIsOverflowed(element.scrollWidth > element.clientWidth);
    }
  }, [children]);

  const renderText = () => {
    const text = (
      <p ref={textRef} className={'truncate'}>
        {children}
      </p>
    );

    if (isOverflowed) {
      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              {text}
            </TooltipTrigger>
            <TooltipContent>
              <p className="max-w-xs break-words">{children}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    }

    return text;
  };

  return (
    <div
      className={cn('px-3 py-1 first:pt-2 last:pb-2 border-b last:border-0 overflow-hidden', className)}
      {...props}
    >
      {title && <small className={'text-muted-foreground'}>{title}</small>}
      {renderText()}
    </div>
  );
};