import {memo, useCallback, useEffect} from "react";
import {Trans} from "react-i18next";
import {
  ResponsiveDialog,
  ResponsiveDialogContent,
  ResponsiveDialogDescription,
  ResponsiveDialogTitle
} from "#ui/responsive-dialog";
import Container from "#ui/container";
import {CONFIRMATION_DEFAULT_LIMIT} from "#src/constants/wallet";
import useCountdown from "#hooks/useCountdown";
import {ButtonWithLoading} from "#ui/button";
import SwapRate from "#pages/wallet/Swap/components/SwapRate";
import SwapSummary from "./SwapSummary";
import useSubmitConversionHandler from "#hooks/api/useSubmitConversionHandler";


const SummaryModal = memo(({
                             open,
                             onClose,
                             isLoading,
                             fromAccount,
                             toAccount,
                             fromAmount,
                             toAmount,
                             rate,
                             clearState,
                             isAccountsLoading
                           }) => {
  const onCloseHandler = useCallback((is) => {
    !is && onClose(false);
  }, [onClose]);
  const {
    countdown,
    resetCountdown,
    stopCountdown
  } = useCountdown(CONFIRMATION_DEFAULT_LIMIT, () => onClose(false), !isLoading && open);
  const {onConfirmHandler, isSending} = useSubmitConversionHandler();

  useEffect(() => {
    if (open) {
      resetCountdown();
    }
    return () => stopCountdown();
  }, [open, resetCountdown, stopCountdown]);

  const onConfirm = useCallback(async () => {
    stopCountdown();
    await onConfirmHandler();
    clearState()
    onClose(false);
  }, [clearState, onClose, onConfirmHandler, stopCountdown]);
  if (isAccountsLoading) return null
  return (
    <ResponsiveDialog open={open} defaultOpen={false} onOpenChange={onCloseHandler}>
      <ResponsiveDialogContent>
        <Container className="py-5 md:p-0">
          <ResponsiveDialogTitle>
            <Trans>Confirm Swap</Trans>
          </ResponsiveDialogTitle>
          <ResponsiveDialogDescription>
            <Trans values={{countdown}}>
              You need to confirm the exchange in {{countdown}} seconds
            </Trans>
          </ResponsiveDialogDescription>

          <div className="flex flex-col gap-3 mt-3">
            <SwapSummary
              fromAccount={fromAccount}
              toAccount={toAccount}
              fromAmount={fromAmount}
              toAmount={toAmount}
            />

            <SwapRate
              fromSymbol={fromAccount?.symbol}
              toSymbol={toAccount?.symbol}
              rate={rate}
              isLoading={isLoading}
            />

            <ButtonWithLoading onClick={onConfirm} className="w-full py-6" size="lg"
                               loading={isLoading || isSending}>
              <Trans>Confirm</Trans>
            </ButtonWithLoading>
          </div>
        </Container>
      </ResponsiveDialogContent>
    </ResponsiveDialog>
  );
});

SummaryModal.displayName = 'SummaryModal';

export default SummaryModal;
