import {Trans} from "react-i18next";
import {SectionHeader} from "#pages/Home/components";

export const AboutHeader = () => {
  return (<div className="text-center mb-20 lg:max-w-2xl mx-auto">
    <SectionHeader
      title={<Trans>Meet Our Team</Trans>}
      description={<Trans
        i18nKey={'aboutDescription'}
      >
        To be among the first. To introduce users from all over the world to our
        product, to provide quality service and to improve it.
      </Trans>}
    />
  </div>);
};