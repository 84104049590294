import {useTranslation} from "react-i18next";
import {useCallback, useEffect, useRef, useState} from "react";
import {useDebounce} from "@uidotdev/usehooks";
import errorHandlers from "#lib/errorHandlers";


export default function useValidateAmount(useMutation, {amount, symbol, skip, ...params}) {
  const {t} = useTranslation()
  const [validateAmount, {isLoading}] = useMutation()
  const [validationResult, setValidationResult] = useState({
    isValid: true,
    message: '',
  })
  const paramsRef = useRef(params)
  const debouncedValue = useDebounce(amount, 200)
  useEffect(() => {
    paramsRef.current = params;
  }, [params]);

  const validate = useCallback(async () => {
    if (debouncedValue) {
      try {
        const data = await validateAmount({amount: debouncedValue, ...paramsRef.current}).unwrap()
        const {isValid, message} = errorHandlers(data, symbol)
        setValidationResult({isValid, message, ...data})
      } catch (e) {
        const {data = {}, message: status, code} = e?.data || {}
        const {message} = errorHandlers({...data, status: code || status}, symbol)
        setValidationResult({
          isValid: false,
          message: message ? message : t('validationServerError', 'Something went wrong. Please try again later.'),
        })
      }
    }
  }, [debouncedValue, symbol, t, validateAmount])
  useEffect(() => {
    !skip && validate()
  }, [debouncedValue, validate, validateAmount, skip])
  return {
    ...validationResult, isLoading, validatedAmount: debouncedValue,
    validateAmountDirectly: async (amountToValidate) => {
      try {
        const data = await validateAmount({amount: amountToValidate, ...paramsRef.current}).unwrap()
        return data.fixedAmount || amountToValidate
      } catch (e) {
        console.error('Error validating amount directly:', e)
        return amountToValidate
      }
    },
    validate
  }
}