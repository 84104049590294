import base from "#services/api/base";


const transactionsApi = base.injectEndpoints({
  endpoints: (builder) => ({
    getTransactions: builder.query({
      query: ({page = 1, limit = 10, ...filters}) => {
        return {
          url: '/api/transactions',
          method: 'GET',
          params: {page, limit, ...filters}
        };
      },
      providesTags: ['Transactions'],
    }),
    getOftenUsedWithdrawalAddresses: builder.query({
      query: (accountId) => {
        return {
          url: '/api/transactions',
          method: 'GET',
          params: {
            limit: 3,
            accountId: accountId,
            type: 'withdraw',
            gateway: 'withdraw',
            status: 'success'
          }
        };
      },
    }),
    getTransaction: builder.query({
      query: (id) => {
        return {
          url: `/api/transactions/${id}`,
          method: 'GET',
        };
      },
    }),
    createWithdrawal: builder.mutation({
      query: ({accountId, networkId, address, amount, memo}) => {
        return {
          url: '/api/transactions/withdrawal',
          method: 'POST',
          body: {accountId, networkId, address, amount, memo}
        };
      },
    }),
    verifyWithdrawal: builder.mutation({
      query: ({transactionId, code}) => {
        return {
          url: `/api/transactions/withdrawal/${transactionId}/`,
          method: 'PATCH',
          body: {code}
        };
      },
      invalidatesTags: ['Accounts', 'Transactions']
    }),
    createDeposit: builder.mutation({
      query: ({accountId, networkId, amount}) => {
        return {
          url: '/api/transactions/deposit',
          method: 'POST',
          body: {accountId, networkId, amount}
        };
      }
    }),
    createDepositByMin: builder.query({
      query: ({accountId, networkId}) => {
        return {
          url: '/api/transactions/deposit',
          method: 'POST',
          body: {accountId, networkId}
        };
      }
    })
  }),
});

export const {
  useGetTransactionsQuery, useGetTransactionQuery,
  useCreateWithdrawalMutation, useCreateDepositMutation,
  useCreateDepositByMinQuery, useVerifyWithdrawalMutation,
  useGetOftenUsedWithdrawalAddressesQuery
} = transactionsApi;

export default transactionsApi;