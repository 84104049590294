import {cn} from "#lib/utils";
import {Slot} from "@radix-ui/react-slot";
import {useEffect, useRef, useState} from "react";
import {useInView} from "#hooks/useInView";

const FadeIn = ({
                  children,
                  className = "",
                  asChild = false,
                  threshold = 0,
                  // Add delay option for better control
                  delay = 0,
                }) => {
  const compRef = useRef();
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const isInView = useInView(compRef, {
    threshold: threshold,
    once: true
  });

  useEffect(() => {
    // Check if element is already in viewport on mount
    const checkInitialVisibility = () => {
      if (!compRef.current) return;

      const rect = compRef.current.getBoundingClientRect();
      const isAboveViewport = rect.bottom < 0;
      const isBelowViewport = rect.top > window.innerHeight;

      // If element is not above or below viewport, it's visible
      if (!isAboveViewport && !isBelowViewport) {
        setTimeout(() => {
          setShouldAnimate(true);
        }, delay);
      }
    };

    checkInitialVisibility();

    // Handle scroll events
    const handleVisibility = () => {
      if (isInView) {
        setTimeout(() => {
          setShouldAnimate(true);
        }, delay);
      }
    };

    // Add scroll listener with debounce
    let timeoutId;
    const handleScroll = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(handleVisibility, 50);
    };

    window.addEventListener('scroll', handleScroll, {passive: true});

    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isInView, delay]);

  const Comp = asChild ? Slot : "div";

  // Add will-change for better performance
  const animationClasses = cn(
    "transition-all will-change-transform opacity-0 translate-y-10",
    {'opacity-100 duration-700 translate-y-0': shouldAnimate},
    className
  );

  return (
    <Comp ref={compRef} className={animationClasses}>
      {children}
    </Comp>
  );
};

export default FadeIn;