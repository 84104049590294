import React, {memo, useEffect, useMemo, useRef} from "react";
import {useGetOHLCVQuery} from "#services/api/futures";
import {useIsConnected, useKline, useSocket, useSubscribe} from "#src/contexts/SocketContext";
import TradingViewChart from "#components/wallet/TradingViewChart";
import {useLocalStorage} from "@uidotdev/usehooks";
import {Skeleton} from "#ui/skeleton";
import {WsConnected} from "#components/common/WsConnected";
import {useDispatch} from "react-redux";
import {clearState} from "#store/slices/websocketSlice";

const SubscriptionManager = memo(({symbol, timeframe}) => {
  const SUBSCRIPTION_TYPES = useMemo(() => ["KLINE"], []);
  useSubscribe(SUBSCRIPTION_TYPES, symbol, timeframe, null, null);
  return null;
}, (prev, next) => {
  return prev.symbol === next.symbol;
});

export const ChartRender = memo(({refetch, data, isOpenEqClose, ...rest}) => {
  const kline = useKline();
  const lastCloseRef = useRef(null);
  const currentKline = useRef({});
  const {o} = kline || {};
  useEffect(() => {
    if (isOpenEqClose === false && o === true) {
      window.location.reload();
    }
  }, [o, refetch, isOpenEqClose]);

  const modifiedKline = useMemo(() => {
    if (!isOpenEqClose || !kline) {
      return kline;
    }
    if (lastCloseRef.current === null && data?.length > 0) {
      lastCloseRef.current = data[data.length - 1].open;
    }
    if (currentKline.current && kline.time > currentKline.current.time) {
      lastCloseRef.current = currentKline.current.close;
    }
    currentKline.current = kline;
    return {
      ...kline, open: lastCloseRef.current,
    };
  }, [data, isOpenEqClose, kline]);

  return (
    <div className={'w-full border border-border/50 h-96 lg:h-[30rem] overflow-hidden rounded-card flex flex-col'}>
      <TradingViewChart data={data} kline={modifiedKline} {...rest}/>
    </div>);
});

export const Chart = memo(({symbol, isOpenEqClose, isLoading, pricePrecision}) => {
  const [timeframe] = useLocalStorage('futuresTimeframe');
  const {reconnect} = useSocket();
  const dispatch = useDispatch();
  const {data, isLoading: isChartLoading, isFetching, refetch} = useGetOHLCVQuery({
    symbol: symbol, timeFrame: timeframe,
  }, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (!isChartLoading && isFetching) {
      console.log('Clearing state');
      dispatch(clearState());
    }
  }, [isFetching, isChartLoading, dispatch]);

  const refetchHandler = () => {
    refetch({
      symbol: symbol, timeFrame: timeframe,
    });
  };

  const isConnected = useIsConnected();

  if (isLoading || isChartLoading || isFetching) {
    return <Skeleton className={'w-full h-96 lg:h-[30rem]'}/>;
  }

  return (<div className={'relative'}>
    <SubscriptionManager symbol={symbol} timeframe={timeframe}/>
    <ChartRender
      refetch={refetchHandler}
      data={data}
      symbol={symbol}
      pricePrecision={pricePrecision}
      isOpenEqClose={isOpenEqClose}
    />
    <WsConnected
      onClick={reconnect}
      isConnected={isConnected}
      className={'absolute bottom-8 left-3 z-10'}
    />
  </div>);
});