import {Button} from "#ui/button";
import {ArrowRight} from "lucide-react";
import Container from "#ui/container";
import React, {forwardRef, useEffect, useState} from "react";
import Logo from "#components/common/Logo";
import {Trans} from "react-i18next";
import {cn} from "#lib/utils";
import BackgroundGrid from "#components/common/BackgroundGrid";
import HeroBackground from "#pages/Home/components/Hero/HeroBackground";
import {Link} from "react-router-dom";
import LanguageChange from "#components/common/LanguageChange";


export const Hero = forwardRef((props, ref) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
    return () => setMounted(false)
  }, []);

  return (<section
    ref={ref}
    className="relative bg-background flex items-center justify-center py-32 lg:py-16 lg:min-h-svh overflow-hidden"
  >
    <Container className={'z-30 absolute top-10 flex md:justify-end'}>
      <div>
        <LanguageChange/>
      </div>
    </Container>
    <HeroBackground/>
    <BackgroundGrid className={'z-20'}/>
    <Container
      className="gap-8 flex flex-col relative text-white z-30">
      <div
        className={cn(`transition-transform duration-1000 ease-out translate-y-10 opacity-0`, {'translate-y-0 opacity-100': mounted})}>
        <Logo className="relative"/>
      </div>

      <div className="space-y-4 ">
        <h1
          className={cn('text-4xl md:text-7xl font-black transition-all duration-700 delay-100 opacity-0 translate-y-10', {'opacity-100 translate-y-0': mounted})}>
            <span className="relative">
              <span
                className="bg-gradient-to-r from-primary to-highlight bg-clip-text text-transparent animate-gradient-x whitespace-nowrap">
                <Trans i18nKey="firstHeroTitle">
                  Next Generation
                   <span className="block text-white relative mt-2">
                      Crypto Wallet
                   </span>
                </Trans>
              </span>
            </span>
        </h1>

        <p
          className={cn(`md:text-xl text-white/70 max-w-xl transition-all duration-700 delay-200 opacity-0 translate-y-10`, {'translate-y-0 opacity-100': mounted})}>
          <Trans i18nKey={'firstHeroDescr'}>
            Experience seamless cryptocurrency management with advanced features,
            real-time analytics, and unmatched security.
          </Trans>
        </p>
      </div>

      <div
        className={cn(`flex flex-wrap  gap-4 transition-all duration-700 delay-300  opacity-0 translate-y-10`, {'translate-y-0 opacity-100': mounted})}>
        <Button
          size="lg"
          className="relative px-6 py-6 text-lg md:px-8 md:text-xl md:py-7 overflow-hidden group"
          asChild
        >
          <Link to={'/auth/register'}>
            <Trans>
              Create Wallet
            </Trans>
            <ArrowRight className="transition-transform duration-300 group-hover:translate-x-1"/>
          </Link>
        </Button>

        <Button
          size="lg"
          variant="ghost"
          className="relative px-6 py-6 text-lg md:px-8 bg-background md:text-xl md:py-7"
          asChild
        >
          <Link to={'/auth'}>
            <Trans>
              Login
            </Trans>
          </Link>
        </Button>
      </div>
    </Container>
  </section>);
});

