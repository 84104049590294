import {useCallback, useState} from "react";

import {t} from "i18next";
import useSwapConverter from "#hooks/api/useSwapConverter";
import {useSubmitConversionMutation} from "#services/api/accounts";
import {useToast} from "#hooks/use-toast";

const errors = {
  'CONVERSION_ERROR': t('conversionError', 'Conversion error'),
  'CONVERSION_ACCOUNT_NOT_FOUND': t('conversionAccountNotFound', 'Accounts not found'),
  'CONVERSION_CURRENCY_NOT_FOUND': t('conversionCurrencyNotFound', 'Currency not found'),
  'CONVERSION_INSUFFICIENT_BALANCE': t('conversionInsufficient', 'Insufficient balance'),
  "SERVER_ERROR": t('serverErr', 'Server error'),
}
export default function useSubmitConversionHandler() {
  const {toast} = useToast();

  const [isSending, setIsSending] = useState(false)

  const {
    fromAmount,
    toAmount,
    fromAccount,
    toAccount,
  } = useSwapConverter()
  const [submitConversion] = useSubmitConversionMutation()
  const onConfirmHandler = useCallback(async () => {
    setIsSending(true)
    try {
      const {status} = await submitConversion(
        {
          fromAmount,
          toAmount,
          fromAccountId: fromAccount?.id,
          toAccountId: toAccount?.id
        }
      ).unwrap()
      if (status === 'OK') {
        toast({
          variant: "success",
          title: 'Success',
          description: 'Conversion successful'
        })
      } else {
        toast({
          variant: "destructive",
          title: 'Error',
          description: errors[status] || errors['CONVERSION_ERROR']
        })
      }
      setIsSending(false)
      return true
    } catch (e) {
      toast({
        variant: "destructive",
        title: 'Error',
        description: errors[e?.data?.code] || errors['CONVERSION_ERROR']
      })
      setIsSending(false)
      return false
    }
  }, [submitConversion, fromAmount, toAmount, fromAccount?.id, toAccount?.id, toast])

  return {
    onConfirmHandler,
    isSending
  }
}