import {AboutHeader} from "./AboutHeader";
import {CompanyValues} from "./CompanyValues";
import {CompanyStats} from "./CompanyStats";
import {BackgroundElements} from "./BackgroundElements";
import BackgroundGradient from "./BackgroundGradient";
import Container from "#ui/container";
import TeamMembers from "./TeamMembers";

export const AboutSection = () => {
  return (
    <section className="py-24 relative overflow-hidden">
      <BackgroundGradient/>
      <BackgroundElements/>

      <Container>
        <AboutHeader/>
        <CompanyValues/>
      </Container>

      <Container className={"px-0"}>
        <TeamMembers/>
      </Container>

      <Container>
        <CompanyStats/>
      </Container>
    </section>
  );
};