import {Label} from "#ui/label";
import {Trans} from "react-i18next";
import Timer from "#components/common/Timer";
import {Skeleton} from "#ui/skeleton";
import {cn} from "#lib/utils";

const StakingTimerSkeleton = () => {
  return (
    <>
      <hr className={'-mx-6'}/>
      <div className={'py-2'}>
        <Skeleton className={'mb-2 block h-4 w-32'}/>
        <div className={cn("flex items-start space-x-2")}>
          <Skeleton className={'w-12 h-12 rounded-md'}/>
          <Skeleton className={'w-12 h-12 rounded-md'}/>
          <Skeleton className={'w-12 h-12 rounded-md'}/>
        </div>
      </div>
    </>)
}

export const StakingTimer = ({isLoading, isActive, endDate, period}) => {
  if (!isActive) return null;
  if (isLoading) return <StakingTimerSkeleton/>;
  const getTimerFormat = (stakePeriodDays) => {
    const format = ['hours', 'minutes', 'seconds'];
    if (stakePeriodDays >= 30) {
      return ['months', 'days'].concat(format);
    } else if (stakePeriodDays >= 7) {
      return ['days'].concat(format);
    }
    return format;
  };

  return (
    <>
      <hr className={'-mx-6'}/>
      <div className={'py-2'}>
        <Label className={'mb-2 block'}>
          <Trans>
            Time left
          </Trans>
        </Label>
        <Timer className={'justify-start'} nextAvailableTime={endDate} format={getTimerFormat(period)}/>
      </div>
    </>);
};