import useOtpHandler from "#hooks/otp/useOtpHandler";
import {useState} from "react";
import useErrorMessages from "#hooks/useErrorMessages";
import {ErrorNameSpaces} from "#src/constants/errors";


const useOtpForm = ({
                      type,
                    }) => {


  const [otp, setOtp] = useState('');
  const {showError} = useErrorMessages(ErrorNameSpaces.OTP);

  const {useMutation, onSuccess, typeCode} = useOtpHandler(type);
  const [mutation, {isLoading}] = useMutation();

  const handleSubmit = async ({userId, ...rest}) => {
    try {
      const resp = await mutation({id: userId, code: otp, ...rest}).unwrap();
      onSuccess(resp);
    } catch (e) {
      console.error(e);
      showError(e?.data?.code);
    }
  };

  return {
    otp,
    setOtp,
    handleSubmit,
    isLoading,
    typeCode,
  };
};

export default useOtpForm;