import {Card} from "#ui/card";
import Container from "#ui/container";

import SelectAccount from "#components/wallet/AccountSelector";
import {Trans} from "react-i18next";

import useSend from "#hooks/api/useSend";
import FormHeader from "#ui/form-header";
import {memo} from "react";
import useSkipFirstUnmount from "#hooks/useSkipFirstUnmount";
import {selectCanWithdraw} from "#store/slices/authSlice";
import {useSelector} from "react-redux";
import CantWithdraw from "#pages/wallet/Send/components/CantWithdraw";
import WithdrawInfo from "#pages/wallet/Send/components/WithdrawInfo";
import WithdrawDestination from "#pages/wallet/Send/components/WithdrawDestination";
import WithdrawAmount from "#pages/wallet/Send/components/WithdrawAmount";
import HeaderWIthActions from "#components/common/HeaderWIthActions";


const Send = () => {
  const {
    account, sendApiRef, isShowAmount,
  } = useSend();
  useSkipFirstUnmount(sendApiRef.clearState);

  return (<Container>
    <HeaderWIthActions symbol={account?.symbol}>
      <Trans>
        Send funds
      </Trans>
    </HeaderWIthActions>

    <div className="w-full flex gap-10 md:gap-6 items-start flex-col md:flex-row">
      <Card className="w-full space-y-3 md:w-1/2 px-4 py-6">
        <div>
          <FormHeader>
            <Trans>
              Select the account you want to send from
            </Trans>
          </FormHeader>
          <SelectAccount
            path="/wallet/send/"
            selectedAccount={account}
            onSelect={sendApiRef.setAccount}
            isSkip={isShowAmount}
          />
        </div>
        <WithdrawDestination/>
        <WithdrawAmount/>
      </Card>
      <WithdrawInfo/>
    </div>
  </Container>)
}
const SendPage = () => {
  const canWithdraw = useSelector(selectCanWithdraw);
  if (!canWithdraw) {
    return <CantWithdraw/>
  }
  return (<Send/>);
}


export default memo(SendPage);