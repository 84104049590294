// StakingPage.jsx
import {Trans} from "react-i18next";
import {Layers, PackageSearch} from 'lucide-react';

import Container from "#ui/container";
import {STAKING_ROUTES} from "./constants";
import {NavigationButton, StakingHeader, StakingTable} from "#pages/wallet/Staking/components";


export const StakingPage = () => {

  return (
    <Container>
      <StakingHeader>
        <div className="flex items-center gap-2">
          <NavigationButton
            to={STAKING_ROUTES.ACTIVE}
            variant="natural"
            icon={<Layers/>}
          >
            <Trans>Active plans</Trans>
          </NavigationButton>

          <NavigationButton
            to={STAKING_ROUTES.HISTORY}
            variant="ghost"
            icon={<PackageSearch/>}
          >
            <Trans>Archived</Trans>
          </NavigationButton>
        </div>
      </StakingHeader>

      <StakingTable/>
    </Container>
  );
};



