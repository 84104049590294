import React, {memo} from "react";
import {useGetLanguagesQuery, useSetLanguageMutation, useSetUserLanguageMutation} from "#services/api/settings";
import {Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue} from "#ui/select";
import {Trans, useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {selectUser, setLanguage} from "#store/slices/authSlice";
import {useToast} from "#hooks/use-toast";


const LanguageChange = () => {
  const {t, i18n} = useTranslation()
  const user = useSelector(selectUser)
  const [setUserLanguage, {isLoading: isUserLoading}] = useSetUserLanguageMutation()
  const [setLanguageHandler, {isLoading}] = useSetLanguageMutation()
  const dispatch = useDispatch()
  const {data: languages} = useGetLanguagesQuery()
  const {toast} = useToast()
  const setLang = !!user ? setUserLanguage : setLanguageHandler
  const currentLang = user?.language || languages?.find(lang => lang.code === i18n.language) || languages?.find(lang => lang.isDefault)
  const setSelectedLang = async (langId) => {
    try {
      const resp = await setLang(langId).unwrap()
      if (!!user) {
        dispatch(setLanguage(resp))
        i18n.changeLanguage(resp.code).catch(console.error)
      } else {
        const language = languages.find(lang => lang.id === langId)
        i18n.changeLanguage(language.code).catch(console.error)
      }

      toast({
        variant: "success",
        title: (<Trans>Language changed</Trans>),
        description: (<Trans>Language changed successfully</Trans>)
      })
    } catch (e) {
      console.log(e)
    }
  }
  return (<Select value={currentLang?.id || null} onValueChange={setSelectedLang}>
      <SelectTrigger disabled={isLoading || isUserLoading}>
        <SelectValue placeholder={currentLang?.name || t('Select language')}/>
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>
            <Trans>
              Languages
            </Trans>
          </SelectLabel>
          {languages?.map((lang) => (<SelectItem key={lang.id} value={lang.id}>{lang.name}</SelectItem>))}
        </SelectGroup>
      </SelectContent>
    </Select>

  );
};

export default memo(LanguageChange);