import { useEffect, useState } from 'react';
import { useGetStatusesQuery } from '#services/api/settings';
import {
  StatusCarousel,
  StatusCarouselSkeleton,
  StatusHeader,
  StatusTabs,
  StatusTabsSkeleton
} from "#pages/profile/Status/components";
import Container from "#ui/container";

const Status = () => {
  const [api, setApi] = useState();
  const { data: statuses = [], isLoading } = useGetStatusesQuery();
  const currentStatus = statuses.find((status) => status.current);
  const currentStatusIndex = statuses.findIndex(
    (status) => status.id === currentStatus?.id
  );
  const [activeIndex, setActiveIndex] = useState(currentStatusIndex);

  useEffect(() => {
    setActiveIndex(currentStatusIndex);
  }, [currentStatusIndex]);

  const scrollToHandle = (to) => {
    api.scrollTo(to);
    setActiveIndex(to);
  };

  return (
    <Container>
      <StatusHeader />
      {isLoading ? (
        <>
          <StatusTabsSkeleton />
          <StatusCarouselSkeleton />
        </>
      ) : (
        <>
          <StatusTabs
            statuses={statuses}
            activeIndex={activeIndex}
            currentStatus={currentStatus}
            onTabClick={scrollToHandle}
          />
          <StatusCarousel
            statuses={statuses}
            activeIndex={activeIndex}
            currentStatusIndex={currentStatusIndex}
            setApi={setApi}
          />
        </>
      )}
    </Container>
  );
};

export default Status;