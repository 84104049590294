import AuthLayout from "#layouts/AuthLayout";
import {ForgotPassword, Login, Otp, Register, ResetPassword} from "#pages/Auth";
import {ErrorRoute} from "./ErrorBoundary";

const authRouter = {
  path: "/auth",
  element: <AuthLayout/>,
  errorElement: <ErrorRoute/>,
  children: [
    {
      index: true,
      element: <Login/>
    },
    {
      path: "register",
      element: <Register/>
    },
    {
      path: "forgot-password",
      element: <ForgotPassword/>
    },
    {
      path: "reset-password",
      element: <ResetPassword/>
    },
    {
      path: 'otp',
      element: <Otp/>
    },
  ]
}

export default authRouter;