import {SwapField} from "#pages/wallet/Swap/components/index";
import {Trans} from "react-i18next";
import {Button} from "#ui/button";
import {ArrowUpDown} from "lucide-react";
import {memo} from "react";
import {Skeleton} from "#ui/skeleton";

const SwapConverterForm = (
  {
    fromAmount,
    toAmount,
    fromAccount,
    toAccount,
    swapConverterApiRef,
    convertError,
    isConverting,
    isAccountsLoading
  }
) => {
  if (isAccountsLoading) {
    return <div className={'flex flex-col gap-2'}>
      <Skeleton className="h-24 w-full"/>
      <Skeleton className="h-24 w-full"/>
    </div>
  }
  return (
    <div className={'flex flex-col gap-2'}>
      <SwapField
        label={<Trans>From</Trans>}
        value={fromAmount}
        onChange={(v) => swapConverterApiRef.handleAmountBySide(v, 'from')}
        selectedAccount={fromAccount}
        onAccountSelect={(account) =>
          swapConverterApiRef.onAccountSelect(account, 'from')
        }
        error={convertError}
      />

      <div className={'relative'}>
        <Button
          variant={'secondary'}
          size={'icon'}
          onClick={swapConverterApiRef.swapAccounts}
          className={'z-10 absolute left-1/2 -top-[22px] -translate-x-1/2'}
          disabled={isConverting}
        >
          <ArrowUpDown/>
        </Button>

        <SwapField
          label={<Trans>To</Trans>}
          value={toAmount}
          onChange={(v) => swapConverterApiRef.handleAmountBySide(v, 'to')}
          selectedAccount={toAccount}
          onAccountSelect={(account) =>
            swapConverterApiRef.onAccountSelect(account, 'to')
          }
        />
      </div>
    </div>
  );
};

export default memo(SwapConverterForm);