import {useLocalStorage} from "@uidotdev/usehooks";
import {OTP_EXPIRATION} from "#src/constants/auth";

const useLastResend = (initState) => {
  const [lastResend, setLastResend] = useLocalStorage("lastResend", initState);
  const canResend = !lastResend || Date.now() - lastResend > OTP_EXPIRATION * 1000
  return {
    lastResend,
    canResend,
    setLastResend: (v) => {
      const newValue = v === undefined || v === null ? Date.now() : v
      return setLastResend(newValue)
    }
  }
}

export default useLastResend;