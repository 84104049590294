const InfoStepList = ({title, steps}) => {
  return (
    <>
      <div className="border-b px-3 pb-2">
        <p className="font-medium">
          {title}
        </p>
      </div>
      <div className="space-y-6 pt-2 px-3">
        <div className="space-y-2">
          <ul className="space-y-2 text-sm">
            {steps.map((step, i) => (
              <li key={i} className="flex gap-3">
                <span className="text-muted-foreground">{i + 1}.</span>
                <span>{step}</span>
              </li>))}
          </ul>
        </div>
      </div>
    </>
  )
}

export default InfoStepList;