import {Button} from "#ui/button";
import {ArrowDown, ArrowRightLeft} from "lucide-react";
import {Spinner} from "#ui/spinner";
import {useGetTransferAccountsQuery} from "#services/api/futures";
import {useTransferFunds} from "#hooks/api/useTransferFunds";
import {Trans} from "react-i18next";
import {AccountType} from "./AccountType";
import {AccountRadioGroup} from "./AccountRadioGroup";
import {TransferAmountField} from "#pages/Futures/components/FuturesBalance/FuturesBalanceDialog/TransferAmountField";
import {
  ResponsiveDialog,
  ResponsiveDialogContent,
  ResponsiveDialogDescription,
  ResponsiveDialogFooter,
  ResponsiveDialogHeader,
  ResponsiveDialogTitle,
  ResponsiveDialogTrigger
} from "#ui/responsive-dialog";

export const FuturesBalanceDialog = ({freeMargin}) => {
  const {data: accounts = [], isLoading, isFetching} = useGetTransferAccountsQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const {
    isDeposit,
    amount,
    accountId,
    isSending,
    canTransfer,
    setAmount,
    setAccountId,
    switchTransferType,
    handleTransfer
  } = useTransferFunds();


  const selectedAccount = accounts?.find((account) => account.id === accountId);
  const isAccountLoading = isLoading || isFetching;

  return (
    <ResponsiveDialog>
      {isAccountLoading ? (<Spinner size={'sm'} variant={'prymary'}/>) :
        <ResponsiveDialogTrigger asChild>
          <Button>
            <Trans>Transfer</Trans>
            <ArrowRightLeft/>
          </Button>
        </ResponsiveDialogTrigger>
      }

      <ResponsiveDialogContent className="md:max-w-[425px]">
        <ResponsiveDialogHeader>
          <ResponsiveDialogTitle>
            <Trans>Transfer funds</Trans>
          </ResponsiveDialogTitle>
          <ResponsiveDialogDescription>
            <Trans>Transfer funds between your spot and futures accounts</Trans>
          </ResponsiveDialogDescription>
        </ResponsiveDialogHeader>
        <div className={'px-4 md:px-0  flex flex-col gap-4'}>

          <div className="flex flex-col items-center relative gap-2 justify-between">
            <AccountType isMain={isDeposit} side={<Trans>From</Trans>}/>
            <span
              className="absolute text-primary top-1/2 transform -translate-y-1/2 p-1 left-[23px] rounded-md bg-background">
            <ArrowDown size={18}/>
          </span>
            <Button
              onClick={switchTransferType}
              size="icon"
              className="absolute top-1/2 transform -translate-y-1/2 right-[23px]"
            >
              <ArrowRightLeft className="rotate-90"/>
            </Button>
            <AccountType side={<Trans>To</Trans>} isMain={!isDeposit}/>
          </div>

          <AccountRadioGroup
            accounts={accounts}
            accountId={accountId}
            setAccountId={setAccountId}
          />

          <TransferAmountField
            isDeposit={isDeposit}
            amount={amount}
            setAmount={setAmount}
            selectedAccount={selectedAccount}
            isAccountLoading={isAccountLoading}
            freeMargin={freeMargin}
          />
        </div>
        <ResponsiveDialogFooter>
          <Button
            onClick={handleTransfer}
            disabled={!canTransfer}
            size="lg"
            className="w-full"
          >
            <Trans>Transfer</Trans>
            {isSending ? (
              <Spinner size="sm" className="text-primary-foreground"/>
            ) : (
              <ArrowRightLeft/>
            )}
          </Button>
        </ResponsiveDialogFooter>
      </ResponsiveDialogContent>
    </ResponsiveDialog>
  );
};