import CurrencyIcon from "#ui/crypto/CurrencyIcon";
import {Trans} from "react-i18next";
import CurrencyFormat from "#ui/currency-format";
import {CircularProgress} from "#ui/circular-progress";
import {Card} from "#ui/card";
import {cn} from "#lib/utils";

export const UserStakeListCard = ({stake}) => {
  const isActive = stake.status === 'active';
  return (
    <Card className="p-4 flex gap-3">
      <div className="flex items-center">
        <CurrencyIcon symbol={stake.symbol} size="large"/>
      </div>
      <div className={'flex-1 flex items-center justify-between'}>
        <div className={'h-full flex flex-col text-left justify-between'}>
          <small className={'block text-muted-foreground'}>
            <Trans>Amount</Trans>
          </small>
          <CurrencyFormat value={stake.amount} decimalScale={stake.precision}/> {stake.symbol}
        </div>

        <div className={'text-sm text-right h-full flex flex-col justify-between'}>
          <p>
            <Trans values={{period: stake.period}}>
              {`{{period}} days`}
            </Trans>
          </p>
          <p>
            {parseFloat(stake.fixedInterest)?.toFixed(2)}%
          </p>
        </div>
      </div>
      {isActive ? (
        <CircularProgress value={stake.progress} size={'lg'}>
          <small className="text-muted-foreground">
            {stake.progress}%
          </small>
        </CircularProgress>
      ) : (
        <div className={'text-right h-full flex flex-col justify-between'}>
          <small className={'block text-muted-foreground'}>
            <Trans>
              Earned
            </Trans>
          </small>
          <p className={cn({
            'text-success': stake.status === 'completed',
            'text-destructive': stake.status === 'cancelled',
          })}>
            {stake.status === 'completed' && '+ '}
            <CurrencyFormat
              value={stake.earnedInterest}
              decimalScale={stake.precision}
            />{" "}
            {stake.symbol}
          </p>
        </div>)}
    </Card>
  )
}