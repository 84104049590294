import {Button} from "#ui/button";
import {Link} from "react-router-dom";

export const NavigationButton = ({to, variant, icon, children}) => (
  <Button variant={variant} size="lg" asChild>
    <Link to={to}>
      {icon}
      {children}
    </Link>
  </Button>
);