// components/StakingTable.jsx
import {Trans} from "react-i18next";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "#ui/table";
import CurrencyIcon from "#ui/crypto/CurrencyIcon";
import CurrencyFormat from "#ui/currency-format";
import {Button} from "#ui/button";
import {ChevronRight} from "lucide-react";
import FormatDatetime from "#components/common/format-datetime";

export const UserStakeTable = ({
  stakes,
  lastHeadText,
  getLastCell,
  onStakeClick,
}) => {
  return (
    <div className="rounded-sm border shadow font-medium">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead><Trans>Assets</Trans></TableHead>
            <TableHead><Trans>Created</Trans></TableHead>
            <TableHead><Trans>Amount</Trans></TableHead>
            <TableHead><Trans>Period</Trans></TableHead>
            <TableHead><Trans>Interest</Trans></TableHead>
            <TableHead>{lastHeadText}</TableHead>
            <TableHead />
          </TableRow>
        </TableHeader>
        <TableBody>
          {stakes.map((stake, index) => (
            <TableRow
              key={`stake-${stake.id || index}`}
              className="cursor-pointer hover:bg-muted/50"
              onClick={() => onStakeClick?.(stake)}
            >
              <TableCell>
                <div className="flex items-center gap-1">
                  <CurrencyIcon symbol={stake.symbol} size="small" />
                  {stake.name}
                </div>
              </TableCell>
              <TableCell>
                <FormatDatetime date={stake.createdAt} />
              </TableCell>
              <TableCell>
                <CurrencyFormat value={stake.amount} decimalScale={stake.precision} /> {stake.symbol}
              </TableCell>
              <TableCell>
                <Trans values={{ period: stake.period }}>
                  {`{{period}} days`}
                </Trans>
              </TableCell>
              <TableCell>
                {parseFloat(stake.fixedInterest)?.toFixed(2)}%
              </TableCell>
              <TableCell>{getLastCell(stake)}</TableCell>
              <TableCell className="flex justify-end">
                <Button size="icon">
                  <ChevronRight />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};