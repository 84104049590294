import {useLocalStorage} from "@uidotdev/usehooks";
import {useGetTimeFramesQuery} from "#services/api/futures";
import {Button} from "#ui/button";

export const TimeFrameSelector = () => {
  const [timeframe, setTimeframe] = useLocalStorage('futuresTimeframe', '4h');
  const {data: timeframes} = useGetTimeFramesQuery();
  if (!timeframes) return null;
  return (
    <div className="flex space-x-1">
      {timeframes.map(({value}) => (
        <Button
          size={'icon'}
          variant={timeframe === value ? 'secondary' : 'ghost'}
          key={value}
          onClick={() => setTimeframe(value)}

          className={'text-xs h-8 w-8'}
        >
          {value.toUpperCase()}
        </Button>
      ))}
    </div>
  );
}