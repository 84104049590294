import {Navigate, Outlet, useLocation} from "react-router-dom";
import useAuth from "#hooks/auth/useAuth";

import usePermissions from "#hooks/api/usePermissions";
import CantWithdrawBanner from "#components/Banners/CantWithdrawBanner";
import {useEffect} from "react";
import {clarity} from "react-microsoft-clarity";
import NeedActivationBanner from "#components/Banners/NeedActivationBanner/NeedActivationBanner";

function RequireAuth() {
  const user = useAuth();
  const location = useLocation();
  const {c} = usePermissions();
  useEffect(() => {
    if (user?.id && clarity.hasStarted()) {
      clarity.identify(user?.id?.toString(), {
        friendlyName: user?.email?.split('@')?.[0]
      });
    }


  }, [user]);
  if (!user?.id) {
    return <Navigate to="/auth" state={{from: location}}/>;
  }
  return <>
    <CantWithdrawBanner canWithdraw={c} verification={user?.verification}/>
    <NeedActivationBanner user={user}/>
    <Outlet/>

  </>;
}

export default RequireAuth;