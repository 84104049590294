import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  account: null,
  network: null,
}
export const receiveSlice = createSlice({
  name: 'receive',
  initialState,
  reducers: {
    setAccount: (state, action) => {
      const prevId = state.account?.id;
      const newId = action.payload?.id;
      if (prevId && newId && (prevId !== newId)) {
        console.log('clear network')
        //todo: clear all network data
        state.network = null
      }
      state.account = action.payload
    },
    setNetwork: (state, action) => {
      state.network = action.payload
    },
    clearState: () => ({
      ...initialState
    }),
  }
})

export const selectReceiveState = (state) => state.receive
export const selectAccount = (state) => state.receive.account
export const selectNetwork = (state) => state.receive.network


export const {
  setAccount,
  setNetwork,
  clearState,
} = receiveSlice.actions
export default receiveSlice