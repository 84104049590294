import {Trans} from "react-i18next";
import AmountField from "#components/wallet/AmountField";
import CurrencyFormat from "#ui/currency-format";

export const StakeAmountInput = ({amount, stake, onChange, isErrorAmount, message,onMaxClick,disabled}) => (
  <>
    <AmountField
      className={'px-4 py-6 !text-xl'}
      value={amount}
      focus={true}
      onChange={onChange}
      isError={isErrorAmount}
      messageProps={{message,variant: 'error'}}
      label={<Trans>Amount</Trans>}
      disabled={disabled}
      labelAfter={
        <button onClick={onMaxClick} disabled={disabled} className={'text-highlight underline inline-flex gap-1'}>
          <CurrencyFormat value={stake.balance} decimalScale={stake.precision}/>
          <span>{stake.symbol}</span>
        </button>
      }
    />
    <div className={'flex items-center gap-2 mt-2 text-muted-foreground justify-between'}>
      <small>
        <Trans
          i18nKey={'minStakeAmount'}
          values={{min: stake.min, symbol: stake.symbol}}
        >
          {'Min: {{min}} {{symbol}}'}
        </Trans>
      </small>
      <small>
        <Trans
          i18nKey={'maxStakeAmount'}
          values={{max: stake.max, symbol: stake.symbol}}
        >
          {'Max: {{max}} {{symbol}}'}
        </Trans>
      </small>
    </div>
  </>
);