import {memo} from "react";
import formatDecimal from "#lib/formatDecimal";
import {Trans} from "react-i18next";

const BalanceDisplay = memo(({balance, precision, symbol, onClick}) => {
  const isHaveBalance = parseFloat(balance) > 0;
  const formattedBalance = isHaveBalance ? parseFloat(balance) : 0;
  const onClickHandler = () => {
    onClick && isHaveBalance && onClick(formattedBalance);
  }
  return (
    <span onClick={onClickHandler} className={'text-muted-foreground underline cursor-pointer'}>
   <Trans values={{
     balance: formatDecimal(balance, precision),
     symbol
   }}>
      {'Balance: {{balance}} {{symbol}}'}
   </Trans>
  </span>
  )
});

export default BalanceDisplay;
