import {memo} from 'react';
import {Trans} from 'react-i18next';

const DepositWarning = memo(({memo}) => (
  <>
    {memo && (
      <div className={'rounded-md bg-highlight mb-2 text-highlight-foreground p-3 text-sm leading-none'}>
        <small>
          <Trans>Both Address and Memo is required, or you will lose your coins.</Trans>
        </small>
      </div>
    )}
    <small className={'text-highlight block text-center mt-2'}>
      <Trans>If you deposit less than the minimum amount, your deposit will not be credited.</Trans>
    </small>
  </>
));

DepositWarning.displayName = 'DepositWarning';

export default DepositWarning;
