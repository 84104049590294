import {memo} from 'react';
import {Trans} from 'react-i18next';
import {AlertTriangle} from 'lucide-react';
import {Button} from "#ui/button";

const ErrorMessage = memo(() => (
  <div
    className="rounded-md bg-destructive/10 p-3 text-sm text-destructive text-center flex flex-col justify-center items-center gap-2">
    <AlertTriangle size={'50'}/>
    <span>
      <Trans
        i18nKey={'failedToGetDepositAddress'}
      >Failed to get deposit address. Please try again later.</Trans>
    </span>
    <Button onClick={(e) => {
      e.preventDefault()
      window.location.reload()
    }} variant={'ghost'}>
      <Trans>
        Try Again
      </Trans>
    </Button>
  </div>
));

ErrorMessage.displayName = 'ErrorMessage';

export default ErrorMessage;