import Container from "#ui/container";
import {memo} from "react";
import {useSelector} from "react-redux";
import {selectBrandName} from "#store/slices/brandSlice";
import LanguageChange from "#components/common/LanguageChange";
import ThemeModeSwitch from "#ui/theme-mode-switch";
import Logo from "#components/common/Logo";
import {cn} from "#lib/utils";

const Footer = memo(({className, showThemeSwitch = true,isFull}) => {
  const brand = useSelector(selectBrandName)
  const year = new Date().getFullYear();

  return (<footer className={cn('border-t border-border/40  mt-5', className)}>
      <Container isFull={isFull} className={'py-5 flex flex-col gap-2 md:flex-row items-center justify-between'}>
        <Logo size={'sm'} path={'/'}/>

        <div className="text-center text-sm  py-2">
          © {year} <a href="/" className="text-primary">{brand}</a> All rights reserved.
        </div>
        <div className={'flex items-center gap-2'}>
          <LanguageChange/>
          {showThemeSwitch && <ThemeModeSwitch className={'shrink-0'} variant={'outline'}/>}
        </div>
      </Container>
    </footer>);
});

export default Footer;