// pages/UserStakesPage.jsx
import {Navigate, useNavigate, useParams} from "react-router-dom";
import Container from "#ui/container";
import PageHeader from "#components/common/PageHeader";
import {useIsMobile} from "#hooks/use-mobile";
import useStaking from "#hooks/api/useStaking";
import {useGetUserStakesQuery} from "#services/api/staking";
import Pagination from "#ui/pagination";
import {STAKE_TYPES} from "./constants";
import {UserStakingSkeleton} from "#components/skeletons/UserStakeSkeleton";
import {MobileStakingList, UserStakeModal, UserStakesEmpty, UserStakeTable} from "#pages/wallet/Staking/components";


const DEFAULT_LIMIT = 10;
const DEFAULT_ROUTE = "/wallet/staking/active";

export const UserStakesPage = () => {
  const {type, id} = useParams();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const hasStakeType = !STAKE_TYPES[type];
  const {
    stakes,
    isLoading,
    isFetching,
    hasNextPage,
    hasPrevPage,
  } = useStaking(
    {
      defaultLimit: DEFAULT_LIMIT,
      filter: type,
      skip: hasStakeType,
    },
    useGetUserStakesQuery
  );
  // Validate stake type and redirect if invalid
  if (hasStakeType) {
    return <Navigate to={DEFAULT_ROUTE} replace/>;
  }


  const handleStakeClick = (stake) => {
    // Navigate to stake details or handle click
    navigate(`/wallet/staking/${type}/${stake.id}`);
  };
  const handleCloseModal = () => {
    navigate(`/wallet/staking/${type}`);

  }

  const {title, lastHeadText, action, getLastCell} = STAKE_TYPES[type];

  const renderContent = () => {
    if (isLoading || isFetching) {
      return <UserStakingSkeleton isMobile={isMobile}/>;
    }

    if (!stakes.length) {
      return <UserStakesEmpty type={type}/>
    }

    return isMobile ? (
      <MobileStakingList
        stakes={stakes}
        getLastCell={getLastCell}
        lastHeadText={lastHeadText}
        onStakeClick={handleStakeClick}
      />
    ) : (
      <UserStakeTable
        stakes={stakes}
        lastHeadText={lastHeadText}
        getLastCell={getLastCell}
        onStakeClick={handleStakeClick}
      />
    );
  };

  return (
    <Container>
      <PageHeader title={title}>{action}</PageHeader>
      {renderContent()}
      <Pagination
        hasNextPage={hasNextPage}
        hasPrevPage={hasPrevPage}
      />
      <UserStakeModal id={id} onClose={handleCloseModal}/>
    </Container>
  );
};