import {useGetLeverageQuery} from "#services/api/futures";
import {useLocalStorage} from "@uidotdev/usehooks";

export const useLeverage = () => {
  const MIN_LEVERAGE = 1;
  const {data} = useGetLeverageQuery();
  const max = parseInt(data?.maxLeverage ?? MIN_LEVERAGE);

  const [currentLeverage, setCurrentLeverage] = useLocalStorage('futuresLeverage', max);
  return {
    min: MIN_LEVERAGE, max, currentLeverage, setCurrentLeverage
  }
}