import {cn} from "#lib/utils";
import {Trans} from "react-i18next";
import {useCallback} from "react";

const PlanButton = ({plan, selectedPlan, onSelectPlan, isShowInterest, isDisabled}) => {
  const buttonDisabled = isDisabled === false ? false : (isDisabled || plan.disabled);
  const isActive = !buttonDisabled && selectedPlan?.id === plan.id;

  return (
    <button
      className={cn(
        'border rounded-xs px-3.5 py-2 flex flex-col items-center',
        {
          'border-primary bg-secondary text-secondary-foreground': isActive,
          'opacity-50': buttonDisabled
        }
      )}
      onClick={(e) => onSelectPlan(e, plan)}
      disabled={buttonDisabled}
    >
      <span className={'leading-none'}>{plan.period}</span>
      <small className={'leading-none'}>
        <Trans>
          days
        </Trans>
      </small>
      {isShowInterest && (
        <p className={cn(
          'border-t mt-1 pt-1 w-full leading-none',
          {'border-primary bg-secondary text-secondary-foreground': isActive}
        )}>
          {plan.interest}%
        </p>
      )}
    </button>
  );
};

export const PlansButtons = ({
                               plans,
                               selectedPlan,
                               onSelectPlan,
                               isShowInterest = false,
                               disabled,
                               className
                             }) => {
  const onSelectHandler = useCallback((e, plan) => {
    e.stopPropagation();
    onSelectPlan(plan);
  }, [onSelectPlan]);

  return (
    <div className={cn('flex items-center text-xs gap-1', className)}>
      {plans?.map((plan, index) => (
        <PlanButton
          key={index}
          plan={plan}
          selectedPlan={selectedPlan}
          onSelectPlan={onSelectHandler}
          isShowInterest={isShowInterest}
          isDisabled={disabled}
        />
      ))}
    </div>
  );
};