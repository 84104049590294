import {twMerge} from "tailwind-merge";
import clsx from "clsx";

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}


export function loadStorageState(storage, key) {
  try {
    return storage.getItem(key);
  } catch (err) {
    console.error('Error loading state from localStorage:', err);
  }
  return undefined
}


export function loadStorageDataState(storage, key, type = 'object') {
  try {
    const storedBrand = JSON.parse(storage.getItem(key) || null);
    return storedBrand && typeof storedBrand === type ? storedBrand : undefined;
  } catch (err) {
    console.error('Error loading state from localStorage:', err);
  }
  return undefined
}


export const objectToQueryString = (obj) => {
  return Object.entries(obj)
    .filter(([_, value]) => value !== undefined && value !== null)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value
          .map(item => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`)
          .join('&');
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join('&');
};
