import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from "#ui/dropdown-menu";

import {MobileNavigation} from "#components/common/Navigation";
import useProfileNav from "#hooks/useProfileNav";
import {memo} from "react";
import {Link} from "react-router-dom";

export const MobileUserProfile = () => {
  const {
    menuItems,
    currentStatus, Avatar,
    userEmail
  } = useProfileNav()

  return (
    <>
      <div className={'flex gap-2 items-center px-5 py-4'}>
        <Avatar className={'cursor-pointer'}/>
        <div className={'flex flex-col truncate'}>
          <small>{currentStatus}</small>
          <p className={'font-semibold text-sm truncate'}>{userEmail}</p>
        </div>
      </div>
      <MobileNavigation
        menuItems={menuItems}
      />
    </>
  );
}

const UserProfileItem = memo(({Icon, label, path, onClick, prevSeparator}) => {
  const Component = path ? Link : 'button';
  return (
    <>
      {prevSeparator && <DropdownMenuSeparator/>}
      <DropdownMenuItem asChild>
        <Component to={path} className={'w-full'} onClick={onClick}>
          {Icon && <Icon/>}
          {label}
        </Component>
      </DropdownMenuItem>
    </>
  )
})
const UserProfile = () => {
  const {menuItems, Avatar} = useProfileNav()
  return (
    <DropdownMenu>
      <DropdownMenuTrigger className={'rounded-full'}>
        <Avatar className={'cursor-pointer border-2'}/>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {menuItems.map((item, i) => (<UserProfileItem key={i} {...item}/>))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default UserProfile;