import {Outlet} from "react-router-dom";
import Header from "#components/wallet/WalletHeader";
import Footer from "#components/common/Footer";

const WalletLayout = () => {
  return (<div className="min-h-screen flex flex-col">
    <Header/>
    <main className={'mt-5 flex-1'}>
      <Outlet/>
    </main>
    <Footer/>
  </div>);
};

export default WalletLayout;