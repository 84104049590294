import { memo } from 'react';
import { Trans } from 'react-i18next';
import PageHeader from '#components/common/PageHeader';

const StatusHeader = memo(() => (
  <PageHeader
    title={<Trans i18nKey={'statusTitle'}>Status wallet level</Trans>}
    description={(
      <Trans i18nKey={'statusSubtitle'}>
        Upgrade your wallet status and get access to all features and minimal commissions.
      </Trans>
    )}
  />
));

StatusHeader.displayName = 'StatusHeader';

export default StatusHeader;
