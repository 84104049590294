import {Label} from "#ui/label";
import CurrencyFormat from "#ui/currency-format";
import {cn} from "#lib/utils";
import {SkeletonWrapper} from "#ui/skeleton-wrapper";

export const EstimatedEarnings = ({
                                    label,
                                    precision, symbol, earned, fiatEarned, isLoading,
                                    className
                                  }) => (
  <div className={cn('my-4 space-y-1', {'text-success': !!earned}, className)}>
    <Label className={'text-foreground'}>
      {label}
    </Label>
    <SkeletonWrapper visible={isLoading} className={'w-2/3 h-5 md:h-10 bg-primary/20'}>
      <p className={cn('text-2xl md:text-4xl font-medium leading-none')}>
        <CurrencyFormat value={earned} decimalScale={precision}/> {symbol}
      </p>
    </SkeletonWrapper>
    <SkeletonWrapper visible={isLoading} className={'w-20 h-5 bg-muted-foreground/20'}>
      <small className={'text-muted-foreground leading-none'}>
        ≈ $<CurrencyFormat value={fiatEarned}/>
      </small>
    </SkeletonWrapper>
  </div>);
