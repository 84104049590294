import {Card} from "#ui/card";
import {Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious} from "#ui/carousel";
import {useRef} from "react";
import Autoplay from "embla-carousel-autoplay";
import AutoHeight from "embla-carousel-auto-height";
import {GradientOverlay} from "#components/common/CryptoCarousel/GradientOverlay";
import {Trans} from "react-i18next";
import FadeIn from "#ui/FadeIn";


const teamMembers = [{
  name: "Michael Banks",
  role: <Trans>CEO & Founder</Trans>,
  avatar: require("#assets/images/first_home/team/ceo.jpeg"),
  bio: <Trans
    i18nKey={'firstHomeCeoBio'}
  >Stability is a sign of mastery, this is what we strive for, putting our heart and soul into it.</Trans>,
}, {
  name: "John Bert ",
  role: <Trans>CTO</Trans>,
  avatar: require("#assets/images/first_home/team/cto.jpeg"),
  bio: <Trans
    i18nKey={'firstHomeCtoBio'}
  >We are not afraid of new challenges, the difficulty and complexity is what makes us move even faster and
    be more productive.</Trans>,
}, {
  name: "Yehuan Dong",
  role: <Trans>Lead Developer</Trans>,
  avatar: require("#assets/images/first_home/team/lead.jpeg"),
  bio: <Trans
    i18nKey={'firstHomeLeadBio'}
  >Using advanced technologies and interaction with our team has allowed us to obtain a very high-quality
    product that we are proud of.</Trans>,
}, {
  name: "Alex Miranda",
  role: <Trans>Head of Security</Trans>,
  avatar: require("#assets/images/first_home/team/head.jpeg"),
  bio: <Trans
    i18nKey={'firstHomeHeadBio'}
  >The safety guarantee is a sign of quality, the highest degree of trust between us and our
    users.</Trans>,
}];

const TeamMembers = () => {
  const autoPlay = useRef(Autoplay({
    stopOnMouseEnter: true,
  }));
  const autoHeight = useRef(AutoHeight());
  return (<FadeIn>
    <Carousel
      opts={{
        align: "center", loop: true,
      }}
      plugins={[autoPlay.current, autoHeight.current]}
      className="mx-auto">
      <CarouselContent>
        {teamMembers.map((member, i) => (<CarouselItem key={i} className={'basis-8/12 md:basis-1/3 lg:basis-1/4'}>
          <Card key={i}
                className="group flex flex-col h-full relative overflow-hidden bg-card/50 backdrop-blur-xl border border-accent/20">
            <div className="relative aspect-square">
              <img
                src={member.avatar}
                alt={member.name}
                className="object-cover w-full h-full"
                loading={'lazy'}
              />
              <div
                className="absolute inset-0 bg-gradient-to-t from-background/80 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"/>
            </div>

            {/* Member Info */}
            <div className="p-6">
              <h3 className="text-xl font-semibold mb-1">{member.name}</h3>
              <p className="text-primary mb-3">{member.role}</p>
              <p className="text-muted-foreground text-sm">{member.bio}</p>
            </div>
          </Card>
        </CarouselItem>))}
      </CarouselContent>
      <GradientOverlay className={'w-32 lg:hidden'}/>
      <GradientOverlay className={'w-32 lg:hidden'} direction={'right'}/>
      <CarouselPrevious variant={'ghost'}
                        className={'z-20 rounded-r-card rounded-l-none hover:bg-transparent inline-flex left-0 w-[4.5rem] p-0 h-full lg:hidden'}/>
      <CarouselNext variant={'ghost'}
                    className={'z-20 rounded-l-card rounded-r-none hover:bg-transparent inline-flex right-0 w-[4.5rem] p-0 h-full lg:hidden'}/>
    </Carousel>
  </FadeIn>);
};

export default TeamMembers;