import {useDomain} from "#hooks/useDomain";
import PageHeader from "#components/common/PageHeader";

export const Cookies = () => {
  const brand = useDomain();

  return (<>
    <PageHeader title={'Cookies Policy'}/>
    <div className={'text-sm'}>
      <p>This Cookies Policy explains what Cookies are and how We use them. You should read this policy so You can
        understand what type of cookies We use, or the information We collect using Cookies and how that information is
        used.
      </p>
      <br/>
      <p>Cookies do not typically contain any information that personally identifies a user, but personal information
        that we store about You may be linked to the information stored in and obtained from Cookies. For further
        information on how We use, store and keep your personal data secure, see our Privacy Policy.
      </p>
      <br/>
      <p>We do not store sensitive personal information, such as mailing addresses, account passwords, etc. in the
        Cookies We use.
      </p>
      <br/>
      <p>Interpretation and Definitions</p>
      <br/>
      <p>Interpretation</p>
      <br/>
      <p>The words of which the initial letter is capitalized have meanings defined under the following
        conditions.&nbsp;
      </p>
      <p>The following definitions shall have the same meaning regardless of whether they appear in singular or in
        plural.
      </p>
      <br/>
      <p>Definitions</p>
      <br/>
      <p>For the purposes of this Cookies Policy:</p>
      <br/>
      <p>Company (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in
        this Cookies Policy) refers to {brand} &nbsp;.
      </p>
      <p>Cookies means small files that are placed on Your computer, mobile device or any other device by a website,
        containing details of your browsing history on that website among its many uses.
      </p>
      <p>Website refers to {brand}&nbsp;</p>
      <p>You means the individual accessing or using the Website, or a company, or any legal entity on behalf of which
        such individual is accessing or using the Website, as applicable.
      </p>
      <br/>
      <p>The use of the Cookies</p>
      <br/>
      <p>Type of Cookies We Use</p>
      <br/>
      <p>Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent Cookies remain on your
        personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close
        your web browser.
      </p>
      <br/>
      <p>We use both session and persistent Cookies for the purposes set out below:
      </p>
      <p>Necessary / Essential Cookies</p>
      <br/>
      <p>Type: Session Cookies</p>
      <br/>
      <p>Administered by: Us</p>
      <br/>
      <p>Purpose: These Cookies are essential to provide You with services available through the Website and to enable
        You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts.
        Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to
        provide You with those services.
      </p>
      <br/>
      <p>Functionality Cookies</p>
      <br/>
      <p>Type: Persistent Cookies</p>
      <br/>
      <p>Administered by: Us</p>
      <br/>
      <p>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your
        login details or language preference. The purpose of these Cookies is to provide You with a more personal
        experience and to avoid You having to re-enter your preferences every time You use the Website.
      </p>
      <br/>
      <p>Your Choices Regarding Cookies</p>
      <p>If You prefer to avoid the use of Cookies on the Website, first You must disable the use of Cookies in your
        browser and then delete the Cookies saved in your browser associated with this website. You may use this option
        for preventing the use of Cookies at any time.
      </p>
      <br/>
      <p>If You do not accept Our Cookies, You may experience some inconvenience in your use of the Website and some
        features may not function properly.
      </p>
      <br/>
      <p>If You&apos;d like to delete Cookies or instruct your web browser to delete or refuse Cookies, please visit the
        help pages of your web browser.
      </p>
      <br/>
      <p>For the Chrome web browser, please visit this page from Google:&nbsp;<a
        href="https://support.google.com/accounts/answer/32050"><u>https://support.google.com/accounts/answer/32050</u></a>
      </p>
      <br/>
      <p>For the Internet Explorer web browser, please visit this page from Microsoft:&nbsp;<a
        href="http://support.microsoft.com/kb/278835"><u>http://support.microsoft.com/kb/278835</u></a></p>
      <br/>
      <p>For the Firefox web browser, please visit this page from Mozilla:&nbsp;<a
        href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"><u>https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</u></a>
      </p>
      <br/>
      <p>For the Safari web browser, please visit this page from Apple:&nbsp;<a
        href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"><u>https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</u></a>
      </p>
      <br/>
      <p>For any other web browser, please visit your web browser&apos;s official web pages.
      </p>
    </div>
  </>);
};

