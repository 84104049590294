import base from "#services/api/base";


export const authApi = base.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({
                email, password
              }) => {
        return {
          url: '/auth',
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: {
            email, password
          },
        };
      },
    }), register: builder.mutation({
      query: ({
                email, password, referralCode, languageCode, terms
              }) => {
        return {
          url: '/register', headers: {
            'Content-Type': 'application/json',
          }, method: 'POST', body: {
            email, password, referralCode, languageCode, terms
          },
        };
      },
    }), verifyEmail: builder.mutation({
      query: ({id, code}) => ({
        url: `/verify-email/`, method: 'POST', body: {id, code},
      }),
    }), logout: builder.mutation({
      query: () => ({
        url: '/logout', method: 'POST',
      }),
    }), forgotPassword: builder.mutation({
      query: ({email}) => ({
        url: '/forgot-password', method: 'POST', body: {email},
      }),
    }), resetPassword: builder.mutation({
      query: ({id, password, code}) => ({
        url: '/reset-password', method: 'POST', body: {id, password, code},
      }),
    }),
    refreshToken: builder.mutation({
      query: () => ({
        url: '/refresh', method: 'POST',
      }),
      extraOptions: {
        isRefreshRequest: true,
      }
    }),
  }),
})


export const {
  useLoginMutation,
  useRegisterMutation,
  useVerifyEmailMutation,
  useLogoutMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useRefreshTokenMutation,
} = authApi;