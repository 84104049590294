import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {clearAuth, setAuth} from "#store/slices/authSlice";
import {clearTokens, getAccessToken, getRefreshToken, setTokens} from "#lib/tokenStorage";
import {BASE_API_URL} from "#src/constants/wallet";


const baseQuery = fetchBaseQuery({
  baseUrl: BASE_API_URL,
  credentials: "include",
  tagTypes: ['Accounts', 'Transactions', 'Staking', 'UserStakes', 'Brand', 'Networks', 'SwapAccounts', 'FuturesAccount', 'FuturesTransferAccounts', 'FuturesPositions'],
  prepareHeaders: (headers, {extraOptions}) => {
    headers.set('Access-Control-Allow-Credentials', 'true');
    const token = !extraOptions?.isRefreshRequest ? getAccessToken() : getRefreshToken();
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

let isRefreshing = false;
let refreshSubscribers = [];

const onRefreshed = (tokens) => {
  refreshSubscribers.forEach(callback => callback(tokens));
  refreshSubscribers = [];
};

const baseQueryWithExit = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error?.status === 401) {
    if (!isRefreshing) {
      isRefreshing = true;
      const refreshResult = await baseQuery({
        url: '/refresh',
        method: 'POST',

      }, api, {...extraOptions, isRefreshRequest: true});
      isRefreshing = false;

      if (refreshResult.data) {
        const {accessToken, refreshToken, user} = refreshResult.data;
        setTokens(accessToken, refreshToken);
        api.dispatch(
          setAuth({
            user,
            language: user.language.code,
          })
        );

        onRefreshed({accessToken, refreshToken});
        result = await baseQuery(args, api, extraOptions);
      } else {
        clearTokens();
        api.dispatch(clearAuth());
      }
    } else {
      const tokens = await new Promise(resolve => {
        refreshSubscribers.push(resolve);
      });
      if (tokens) {
        result = await baseQuery(args, api, extraOptions);
      }
    }
  }

  return result;
};

export default createApi({
  reducerPath: "baseApi",
  baseQuery: baseQueryWithExit,
  endpoints: (builder) => ({}),
});