import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "#ui/table";
import CurrencyIcon from "#ui/crypto/CurrencyIcon";
import CurrencyFormat from "#ui/currency-format";
import ActionsButtonsNavigation from "#components/common/ActionsButtonsNavigation";
import Pagination from "#ui/pagination";

import CryptoAccountsTableSkeleton from "#components/skeletons/CryptoAccountsTableSkeleton";
import {Trans} from "react-i18next";


// CryptoAccountTableHeader.jsx

const CryptoAccountTableHeader = () => {
  const headers = [
    <Trans>
      Currency
    </Trans>,
    <Trans>
      Balance
    </Trans>,
    <Trans>
      Balance $
    </Trans>,
    <Trans>
      Rate
    </Trans>
  ]
  return (
    <TableHeader>
      <TableRow>
        {headers.map((header, index) => (
          <TableHead key={index}>
            {header}
          </TableHead>
        ))}
      </TableRow>
    </TableHeader>
  )
};


const CryptoAccountTableRow = ({account}) => {

  return (
    <TableRow>
      <TableCell>
        <div className={'flex items-center gap-2'}>
          <CurrencyIcon symbol={account.symbol}/>
          <span>{account.name}</span>
        </div>
      </TableCell>
      <TableCell>
        <CurrencyFormat
          as={'span'}
          value={account.balance}
          symbol={account.symbol}
          decimalScale={account.precision}
        />
      </TableCell>
      <TableCell>
        $ <CurrencyFormat value={account.fiatBalance}/>
      </TableCell>
      <TableCell>
        $ <CurrencyFormat as={'span'} value={account.rate}/>
      </TableCell>
      <TableCell>
        <div className={'flex justify-end'}>
          <ActionsButtonsNavigation symbol={account.symbol} variant={'outline'} size={'sm'} accountId={account.id}/>
        </div>
      </TableCell>
    </TableRow>
  );
};

// CryptoPagination.jsx

const CryptoPagination = (props) => <Pagination {...props}/>


const CryptoAccountsTableNotData = () => (
  <TableRow>
    <TableCell colSpan={5} className={'text-center p-10'}>
      <Trans>
        Oops! Accounts not found
      </Trans>
    </TableCell>
  </TableRow>
)

const CryptoAccountsTableRender = ({accounts, isLoading}) => {
  if (isLoading) {
    return <CryptoAccountsTableSkeleton/>
  }
  if (!accounts.length) {
    return <CryptoAccountsTableNotData/>
  }
  return (
    accounts.map((account) => (
      <CryptoAccountTableRow key={account.symbol} account={account}/>
    ))
  )
}

const CryptoAccountsTable = ({accounts, isLoading}) => (
  <div className="rounded-sm border shadow font-medium">
    <Table>
      <CryptoAccountTableHeader/>
      <TableBody>
        <CryptoAccountsTableRender accounts={accounts} isLoading={isLoading}/>
      </TableBody>
    </Table>
  </div>
);


export {
  CryptoAccountsTable,
  CryptoPagination,
  CryptoAccountTableRow,
  CryptoAccountTableHeader
}