// components/withdraw/WithdrawHeader.jsx
import { Trans } from "react-i18next";
import FormHeader from "#ui/form-header";

const WithdrawHeader = () => (
  <FormHeader>
    <Trans>Withdraw to</Trans>
  </FormHeader>
);

export default WithdrawHeader;