import Container from './container';
import {cn} from "#lib/utils";

const Header = ({children, className, isFull, ...props}) => {
  return (
    <header className={cn('py-4 bg-background border-b border-border/50', className)} {...props}>
      <Container isFull={isFull} className={'flex items-center justify-between'}>
        {children}
      </Container>
    </header>
  );
};

export default Header;