import {useSelector} from "react-redux";
import {selectTheme} from "#store/slices/brandSlice";
import cardVariants from "#styles/themes/cardVariants";

const useCardVariants = () => {
  const {themeStyle} = useSelector(selectTheme);

  return cardVariants({variant: themeStyle});
}

export default useCardVariants;