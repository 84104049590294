import {Button} from "#ui/button";
import {ChevronRight} from "lucide-react";
import {Link} from "react-router-dom";
import {Trans} from "react-i18next";

const CryptoAccountsHeader = ({totalItems}) => (
  <div className="flex items-center justify-between my-5">
    <h2 className="text-2xl">
      <Trans i18nKey={'crypto_accounts'}>
        Crypto Accounts
      </Trans>
    </h2>
    <Button variant="ghost" asChild>
      <Link to="/wallet/accounts">
        <Trans i18nKey={'all_accounts'} values={{
          totalItems
        }}>
          {'All Accounts ({{totalItems}})'}
        </Trans>
        <ChevronRight/>
      </Link>
    </Button>
  </div>
);

export default CryptoAccountsHeader;