import React from 'react';
import Marquee from "react-fast-marquee";
import {useInViewOnce} from "#hooks/useInViewOnce";
import {useCurrenciesQuery} from "#services/api/common";
import {CurrencyCard} from './CurrencyCard';
import {GradientOverlay} from './GradientOverlay';
import {currenciesMock} from './currenciesMock';

export const CryptoCarousel = () => {
  const compRef = React.useRef(null);
  const shouldFetch = useInViewOnce(compRef, {
    threshold: 0
  });

  const {data: currencies = currenciesMock} = useCurrenciesQuery(null, {skip: !shouldFetch});

  return (<section ref={compRef} className="py-10 relative overflow-hidden">
      <div className="mx-auto relative">
        <GradientOverlay direction="left"/>
        <GradientOverlay direction="right"/>
        <Marquee pauseOnHover>
          {(!!currencies.length ? currencies : currencies).map((currency, index) => (<CurrencyCard
              key={`${currency.symbol}-${index}`}
              currency={currency}
            />))}
        </Marquee>
      </div>
    </section>);
};

