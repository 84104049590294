import * as React from "react"
import {Slot} from "@radix-ui/react-slot"
import {cva} from "class-variance-authority";

import {cn} from "#lib/utils"
import {Loader2} from "lucide-react";


const buttonVariants = cva(
  "inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none  disabled:cursor-not-allowed disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground shadow hover:bg-primary/90",
        gradient: "bg-gradient-to-br from-primary to-primary/50 text-primary-foreground shadow hover:bg-primary/90",
        gradientAccent: "bg-gradient-to-br from-primary to-highlight text-primary-foreground shadow hover:bg-primary/90",
        destructive:
          "bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90",
        success:
          "bg-success text-success-foreground shadow-sm hover:bg-success/90",
        destructiveOutline: "border border-destructive bg-background shadow-sm hover:bg-destructive/90 text-destructive hover:text-destructive-foreground",
        outline:
          "border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80",
        natural: "bg-background text-foreground shadow-sm hover:bg-background/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "h-9 px-3 py-2",
        sm: "h-8 rounded-md px-3 text-xs",
        lg: "h-10 rounded-md px-4",
        icon: "h-9 w-9",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

const Button = React.forwardRef(({className, variant, size, asChild = false, ...props}, ref) => {
  const Comp = asChild ? Slot : "button"
  return (
    (<Comp
      className={cn(buttonVariants({variant, size, className}))}
      ref={ref}
      {...props} />)
  );
})
Button.displayName = "Button"

const ButtonWithLoading = React.forwardRef(({loading, children, disabled, ...props}, ref) => {
  return (
    <Button disabled={loading || disabled} ref={ref} {...props} >
      {loading && <Loader2 className="animate-spin"/>}
      {children}
    </Button>
  );
})

export const ButtonIcon = React.forwardRef(({children, ...props}, ref) => {
  return (
    <Button size="icon" {...props} ref={ref}>
      {children}
    </Button>
  );
})

ButtonWithLoading.displayName = "ButtonWithLoading"
export {Button, ButtonWithLoading, buttonVariants}
