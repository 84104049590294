import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  kline: null,
  price: null,
  bookTicker: {
    bidPrice: null,
    askPrice: null
  },
  maxSize: null,
  positions: {}, // Змінили на об'єкт для зберігання множини позицій
  balance: null,
  isConnected: false
};

const websocketSlice = createSlice({
  name: 'websocket',
  initialState,
  reducers: {
    setConnectionStatus: (state, action) => {
      state.isConnected = action.payload;
    },
    updateKline: (state, action) => {
      state.kline = action.payload;
    },
    updatePrice: (state, action) => {
      state.price = action.payload;
    },
    updateBookTicker: (state, action) => {
      state.bookTicker = {
        bidPrice: action.payload?.bidPrice,
        askPrice: action.payload?.askPrice
      };
    },
    updateMaxSize: (state, action) => {
      state.maxSize = action.payload;
    },
    updatePosition: (state, action) => {
      const positionId = action.payload.positionId;
      if (!positionId) {
        state.positions = {}
      }
      // Оновлюємо тільки отримані поля, зберігаючи попередні значення
      state.positions[positionId] = {
        ...state.positions[positionId], // Зберігаємо попередні значення
        ...action.payload, // Оновлюємо новими даними
      };
    },
    removePosition: (state, action) => {
      delete state.positions[action.payload];
    },
    updateBalance: (state, action) => {
      state.balance = action.payload;
    },
    clearState: (state) => {
      state.kline = null;
      state.price = null;
      state.bookTicker = {
        bidPrice: null,
        askPrice: null
      };
      state.maxSize = null;
    }
  }
});

export const {
  setConnectionStatus,
  updateKline,
  updatePrice,
  updateBookTicker,
  updateMaxSize,
  updatePosition,
  removePosition,
  updateBalance,
  clearState
} = websocketSlice.actions;

// Селектори
export const selectIsConnected = (state) => state.websocket.isConnected;
export const selectKline = (state) => state.websocket.kline;
export const selectPrice = (state) => state.websocket.price;
export const selectBookTicker = (state) => state.websocket.bookTicker;
export const selectMaxSize = (state) => state.websocket.maxSize;
export const selectAllPositions = (state) => state.websocket.positions;
export const selectPositionById = (state, id) => state.websocket.positions[id];
export const selectBalance = (state) => state.websocket.balance;
export default websocketSlice;