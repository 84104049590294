import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  fromAccount: null,
  toAccount: null,
  fromAmount: null,
  toAmount: null,
  rate: null,
  showSummary: false,
  isConverting: false,
  convertError: null,
};

export const swapConverterSlice = createSlice({
  name: 'swapConverter',
  initialState,
  reducers: {
    setShowSummary: (state, action) => {
      state.showSummary = action.payload;
    },
    setFromAccount: (state, action) => {
      state.fromAccount = action.payload;
    },
    setToAccount: (state, action) => {
      state.toAccount = action.payload;
    },
    setFromAmount: (state, action) => {
      state.fromAmount = action.payload;
    },
    setToAmount: (state, action) => {
      state.toAmount = action.payload;
    },
    swapAccounts: (state) => {
      [state.fromAccount, state.toAccount] = [state.toAccount, state.fromAccount];
      state.rate = null
    },
    setBothAccounts: (state, action) => {
      if (action.payload.fromAccount) {
        state.fromAccount = action.payload.fromAccount;
      }
      if (action.payload.toAccount) {
        state.toAccount = action.payload.toAccount;
      }
    },
    setAmountByKey(state, action) {
      const key = Object.keys(action.payload)[0];
      if (key) {
        state[key] = action.payload[key];
      }
    },
    setBothAmounts: (state, action) => {
      if (!action.payload) return;
      const {fromAmount, toAmount} = action.payload || {};
      state.fromAmount = fromAmount;
      state.toAmount = toAmount;
    },
    selectAccount: (state, action) => {
      const {account, side} = action.payload;
      if (side === 'from') {
        if (account.symbol === state.toAccount?.symbol) {
          state.toAccount = state.fromAccount;
        }
        state.fromAccount = account;
      } else if (side === 'to') {
        if (account.symbol === state.fromAccount?.symbol) {
          state.fromAccount = state.toAccount;
        }
        state.toAccount = account;
      }
      state.rate = null;
    },
    setIsConverting: (state, action) => {
      state.isConverting = action.payload;
    },
    setConvertError: (state, action) => {
      state.convertError = action.payload
    },
    setRate: (state, action) => {
      state.rate = action.payload;
    },
    clearState: () => initialState,
  },
});

export const {
  setFromAccount,
  setToAccount,
  setFromAmount,
  setToAmount,
  swapAccounts,
  setBothAccounts,
  selectAccount,
  clearState,
  setShowSummary,
  setBothAmounts,
  setAmountByKey,
  setIsConverting,
  setConvertError,
  setRate
} = swapConverterSlice.actions;

export const selectSwapConverterState = (state) => state.swapConverter;

export default swapConverterSlice;