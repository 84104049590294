import useBrand from "#hooks/useBrand";
import useTheme from "#hooks/useTheme";
import {useMemo} from "react";

const useInit = () => {
  const {isLoading: isBrandLoading, brand} = useBrand()
  const {theme, isLoading: isThemeLoading} = useTheme()
  return useMemo(()=>({isLoading: isBrandLoading || isThemeLoading, brand, theme}),[brand, isBrandLoading, isThemeLoading, theme])
}

export default useInit;