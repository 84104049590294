import Footer from "#components/common/Footer";
import React from "react";
import {
  AboutSection,
  CryptoCarousel,
  Features,
  Hero,
  LiveActivity,
  Security,
  Testimonials
} from "#pages/Home/components";
import Header from "#components/common/Header";
import useThemeMode, {getInitialTheme} from "#hooks/useThemeMode";
import {useTranslation} from "react-i18next";
import FullScreenLoading from "#components/common/FullScreenLoading";

const ExtendedLanding = () => {
  const {i18n} = useTranslation();

  const heroRef = React.useRef(null);
  const {theme, setTheme} = useThemeMode();


  React.useEffect(() => {
    localStorage.setItem('tmode', theme || getInitialTheme());
    setTheme('dark');
    return () => {
      const previousTheme = localStorage.getItem('tmode');
      setTheme(previousTheme);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!i18n.isInitialized) {
    return <FullScreenLoading variant="primary" show={true}/>;
  }


  return (<div className="min-h-screen bg-background text-foreground overflow-hidden">
    <Header heroRef={heroRef}/>
    <Hero ref={heroRef}/>
    <CryptoCarousel/>
    <Features/>
    <LiveActivity/>
    <Security/>
    <Testimonials/>
    <AboutSection/>
    <Footer className="mt-0" showThemeSwitch={false}/>
  </div>);
};

export default ExtendedLanding;