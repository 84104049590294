import {Trans, useTranslation} from "react-i18next";
import PageHeader from "#components/common/PageHeader";
import {Input} from "#ui/input";
import useSearch from "#hooks/useSearch";

const CryptoSearchHeader = () => {
  const {t} = useTranslation();
  const [search, setSearch] = useSearch()
  return (
    <PageHeader title={<Trans>Accounts</Trans>}>
      <Input
        placeholder={t('search_account', "Search account...")}
        value={search || ''}
        onChange={(e) => setSearch(e.target.value)}
        className="md:max-w-xs py-3 border-0 bg-background text-foreground"
      />
    </PageHeader>
  );
};

export default CryptoSearchHeader;