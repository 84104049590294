import {useIsMobile} from "#hooks/use-mobile";
import {Trans, useTranslation} from "react-i18next";
import {Input} from "#ui/input";
import {Table, TableBody, TableCell, TableRow} from "#ui/table";
import {StakingTableSkeleton} from "#components/skeletons/StakingTableSkeleton";
import Pagination from "#ui/pagination";
import {StakingTableHeader} from "./StakingTableHeader";
import {StakingRow} from "./StakingRow";
import useSearch from "#hooks/useSearch";
import {memo} from "react";
import useStaking from "#hooks/api/useStaking";


const StakingTableBody = ({isLoading, isFetching, stakes, isMobile}) => {
  if (isLoading || isFetching) {
    return <StakingTableSkeleton isMobile={isMobile} rowsCount={10}/>
  }
  if (!stakes.length) {
    return (
      <TableRow>
        <TableCell colSpan={isMobile ? 4 : 5} className={'text-center p-10'}>
          Oops! Plans not found
        </TableCell>
      </TableRow>)
  }
  return (stakes?.map((staking, index) => (
    <StakingRow
      key={`staking-${staking.id || index}`}
      staking={staking}
      isMobile={isMobile}
    />
  )))
}
const SearchStaking = memo(() => {
    const [search, setSearch] = useSearch()
    const {t} = useTranslation()
    return <Input
      placeholder={t('search_plan', "Search by Asset...")}
      value={search || ''}
      onChange={(e) => setSearch(e.target.value)}
      className="md:max-w-xs py-3 border-border"
    />
  }
)

export const StakingTable = memo(() => {
  const isMobile = useIsMobile();
  const {
    stakes,
    isLoading,
    isFetching,
    hasNextPage,
    hasPrevPage,
  } = useStaking();

  return (
    <>
      <div className="rounded-sm border shadow font-medium">
        <div className="p-4">
          <div className="flex flex-col items-start lg:items-end gap-2 lg:flex-row justify-between">
            <h2 className="text-2xl mb-4">
              <Trans>Plans</Trans>
            </h2>
            <SearchStaking/>
          </div>
        </div>

        <Table>
          <StakingTableHeader isMobile={isMobile}/>
          <TableBody>
            <StakingTableBody
              isLoading={isLoading}
              isFetching={isFetching}
              stakes={stakes}
              isMobile={isMobile}
            />
          </TableBody>
        </Table>

      </div>
      <Pagination
        hasNextPage={hasNextPage}
        hasPrevPage={hasPrevPage}
      />

    </>
  );
})