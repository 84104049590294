import {useTranslation} from "react-i18next";
import {getIconOrRandom} from "#lib/getRandomIcon";
import {Card, CardContent, CardHeader, CardTitle} from "#ui/card";
import {cn} from "#lib/utils";

export const LevelCard = ({level, isCurrentLevel}) => {
  const {t} = useTranslation();
  const LevelIcon = getIconOrRandom(level.icon);

  return (<Card
    className={cn(`group transition-all duration-300 hover:shadow-md`, isCurrentLevel ? "border-primary bg-primary/5" : "hover:border-primary/50")}
  >
    <CardHeader className="pb-2">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <LevelIcon className="w-4 h-4 text-primary"/>
          <CardTitle className="text-sm font-medium">
            {level.levelName}
          </CardTitle>
        </div>
        {isCurrentLevel && (<span className="text-xs bg-primary/10 text-primary px-2 py-1 rounded-full">
              {t("Current")}
            </span>)}
      </div>
    </CardHeader>
    <CardContent className="space-y-2">
      <div className="flex justify-between text-sm">
              <span className="text-muted-foreground">
                {t("min_referrals",'Min. Referrals')}
              </span>
        <span>{level.minReferrals}</span>
      </div>
      <div className="flex justify-between text-sm">
        <span className="text-muted-foreground">{t("Reward")}</span>
        <span className="font-medium text-primary">{level.reward}%</span>
      </div>
    </CardContent>
  </Card>);
};