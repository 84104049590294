export const DASHBOARD_DEFAULT_LIMIT = 12;
export const ACCOUNTS_DEFAULT_LIMIT = 20;

export const MAX_LIMIT = 999999999999;

export const CONFIRMATION_DEFAULT_LIMIT = 10;
const getApiUrl = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const HOSTNAME = window.location.hostname;
  const isLocalhost = BASE_URL?.includes('localhost') || false;
  const hasBase = !!BASE_URL
  const httpProtocol = (hasBase ? isLocalhost : window.location.protocol === 'http:') ? 'http' : 'https';
  const wsProtocol = httpProtocol === 'https' ? 'wss' : 'ws';

  const FinalUrl = hasBase ?BASE_URL: `api.${HOSTNAME}`;
  return {
    BASE_URL: FinalUrl,
    WS_URL: `${wsProtocol}://${FinalUrl}/ws`,
    BASE_API_URL: `${httpProtocol}://${FinalUrl}`
  }


};
export const DEFAULT_FUTURES_CURRENCY = 'USDT';

export const {BASE_API_URL, WS_URL} = getApiUrl();