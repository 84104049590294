import {useSelector} from "react-redux";
import {selectBrand} from "#store/slices/brandSlice";
import {useTranslation} from "react-i18next";

export default function useWalletMainNav() {
  const {t} = useTranslation()
  const brand = useSelector(selectBrand)
  const initMenuItems = [
    {path: "/wallet/accounts", label: t("Accounts"), isShow: true},
    {path: "/wallet/history", label: t("History"), isShow: true},
    {path: "/wallet/referral", label: t("Referral"), isShow: !!brand?.hasReferral},
    {path: "/wallet/staking", label: t("Staking"), isShow: !!brand?.hasStaking},
    {path: "/futures", label: t("Futures"), isShow: !!brand?.hasFutures},
  ];
  return initMenuItems.filter(item => item.isShow)
}