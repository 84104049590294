import {cn} from "#lib/utils";
import {Slot} from "@radix-ui/react-slot";
import {forwardRef} from "react";

const TextGradient = forwardRef(({asChild, className, ...rest}, ref) => {
  const Comp = asChild ? Slot : "div";
  return (
    <Comp
      ref={ref}
      className={cn('bg-gradient-to-r from-foreground to-highlight bg-clip-text text-transparent animate-gradient-x whitespace-nowrap', className)}
      {...rest}
    />
  );
})

export default TextGradient;