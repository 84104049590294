import React, { useRef, useCallback, useId } from 'react';
import { NumericFormat } from 'react-number-format';
import useLocaleNumberSeparators from "#hooks/useLocaleNumberSeparators";
import useFocus from "./useFocus";
import { Input } from "#ui/input";
import { FormFieldMessage, FormLabel } from "#ui/form-field";
import {MAX_LIMIT} from "#src/constants/wallet";

const AmountField = React.forwardRef(({
  value,
  onChange,
  after,
  decimals = 4,
  focus = false,
  maxLimit = MAX_LIMIT,
  isError = false,
  isLoading = false,
  label,
  labelAfter,
  labelProps = {},
  messageProps = {},
  ...rest
}, ref) => {
  const inputRef = useRef(null);
  const separators = useLocaleNumberSeparators();
  const id = useId();
  // Передаємо ref через forwardRef
  React.useImperativeHandle(ref, () => ({
    focus: () => inputRef.current?.focus(),
    blur: () => inputRef.current?.blur(),
    get value() {
      return inputRef.current?.value;
    },
    get input() {
      return inputRef.current;
    }
  }));

  useFocus(inputRef, focus);

  const onValueChange = useCallback((values, { source }) => {
    const { floatValue, value } = values;

    if (source !== 'event') return;

    if (!value.startsWith('0.') && value.startsWith('0') && value.length > 1) {
      const newValue = value.replace(/^0+/, '0.');
      onChange(parseFloat(newValue) || 0);
      return;
    }

    onChange(value === '' ? '' : (floatValue ?? 0));
  }, [onChange]);

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Backspace' && inputRef?.current?.value?.length === 1) {
      e.preventDefault();
      onChange('');
    }
  }, [onChange]);

  return (
    <div className="flex flex-col space-y-1.5">
      <FormLabel
        label={label}
        labelAfter={labelAfter}
        htmlFor={rest.id || id}
        isError={isError}
        {...labelProps}
      />
      <div className="flex items-center justify-between gap-2 relative">
        <NumericFormat
          {...separators}
          {...rest}
          id={id}
          getInputRef={inputRef}
          allowLeadingZeros
          value={value}
          onValueChange={onValueChange}
          onKeyDown={handleKeyDown}
          decimalScale={decimals}
          allowNegative={false}
          autoFocus={focus}
          customInput={Input}
          isAllowed={({ floatValue }) => floatValue < maxLimit}
          inputMode="decimal"
          isError={isError}
        />
        {after}
      </div>
      <FormFieldMessage {...messageProps} />
    </div>
  );
});

AmountField.displayName = 'AmountField';

export default React.memo(AmountField);