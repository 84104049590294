import useOtpForm from "#hooks/otp/useOtpForm";
import OtpInput from "./OtpInput";
import ResendOtp from "./ResendOtp";
import {AuthFormSubmit} from "#components/common/AuthForm";
import {Trans} from "react-i18next";
import {useEffect} from "react";

export {
  ResendOtp,
  OtpInput,
}

const OtpForm = (
  {
    userId,
    children,
    type,
    data,
    isShowButton = true,
    onLoading,
    onDisabled,
  }
) => {

  const {
    otp,
    setOtp,
    handleSubmit,
    isLoading,
    typeCode,
  } = useOtpForm({type});

  const onSubmit = async (e) => {
    e.preventDefault();
    await handleSubmit({userId, ...data});
  };

  useEffect(() => {
    if (onLoading && typeof onLoading === 'function') {
      onLoading(isLoading);
    }
  }, [isLoading, onLoading]);

  useEffect(() => {
    if (onDisabled && typeof onDisabled === 'function') {
      onDisabled(!otp);
    }
  }, [otp, onDisabled]);

  return (
    <form onSubmit={onSubmit}>
      <div className="grid w-full items-center gap-4">
        <div className="flex items-center  gap-2">
          <OtpInput value={otp} onChange={setOtp}/>
          <ResendOtp isLoading={isLoading} type={typeCode} userId={userId} data={data}/>
        </div>
        {children}
        {isShowButton &&
          (<AuthFormSubmit loading={isLoading} disabled={!otp}>
            <Trans i18nKey="otp_button">Submit</Trans>
          </AuthFormSubmit>)
        }
      </div>
    </form>
  )
}

export default OtpForm;