import {useMemo} from "react";
import formatDecimal from "#lib/formatDecimal";

// Основний хук для форматування валюти
export const useFormatCurrency = (options = {}) => {
  const {
    decimalScale = 4,
    removeTrailingZeros = true
  } = options;

  const safeDecimalScale = Math.max(2, decimalScale);
  return useMemo(() => {
    // Основна функція форматування
    return (value) => {
      return formatDecimal(value, safeDecimalScale, removeTrailingZeros);
    };
  }, [removeTrailingZeros, safeDecimalScale]);
};