import FadeIn from "#ui/FadeIn";
import TextGradient from "#ui/TextGradient";
import React from "react";

export const SectionHeader = ({title, description}) => {

  return (<>
    <FadeIn>
      <TextGradient asChild>
        <h2 className="text-3xl md:text-4xl font-bold mb-4">
          {title}
        </h2>
      </TextGradient>
    </FadeIn>
    <FadeIn asChild>
      {description && (<p className="text-xl text-muted-foreground">
          {description}
        </p>)}
    </FadeIn>
  </>)
}