import {OTP_TYPES} from "#src/constants/auth";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {clearAuth, setAuth} from "#store/slices/authSlice";
import {useResetPasswordMutation, useVerifyEmailMutation} from "#services/api/auth";
import {useMemo} from "react";
import {useVerifyWithdrawalMutation} from "#services/api/transactions";
import {clearState} from "#store/slices/sendSlice";
import {setTokens} from "#lib/tokenStorage";
import {useToast} from "#hooks/use-toast";
import {useTranslation} from "react-i18next";


const useOtpHandler = (type) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {toast} = useToast();

  const otpHandlers = useMemo(() => ({
    [OTP_TYPES.EMAIL_VERIFICATION]: {
      typeCode: 'EMAIL_VERIFICATION', useMutation: useVerifyEmailMutation, onSuccess: (resp) => {
        const {user, accessToken, refreshToken} = resp;
        dispatch(
          setAuth({
            user,
            language: user?.language?.code,
          })
        );
        setTokens(accessToken, refreshToken);
      },
    },
    [OTP_TYPES.PASSWORD_RESET]: {
      typeCode: 'PASSWORD_RESET', useMutation: useResetPasswordMutation, onSuccess: (resp) => {
        dispatch(clearAuth())
        navigate('/auth')
        toast({
          title: t('Password reset'),
          description: t('Your password has been reset successfully'),
          variant: 'success'
        })
      }
    },
    [OTP_TYPES.WITHDRAWAL_CONFIRMATION]: {
      typeCode: 'WITHDRAWAL_CONFIRMATION', useMutation: useVerifyWithdrawalMutation, onSuccess: (resp) => {
        dispatch(clearState())
        navigate('/wallet');
      }
    }
  }), [dispatch, navigate, t, toast]);
  return otpHandlers[type] || otpHandlers[OTP_TYPES.EMAIL_VERIFICATION];
};

export default useOtpHandler;