import TransactionTableRow from "#pages/wallet/History/components/TransactionTableRow";
import {TableBody, TableHead, TableHeader, TableRow, Table, TableCell} from "#ui/table";
import {Trans} from "react-i18next";
import TransactionTableSkeleton from "#components/skeletons/TransactionTableSkeleton";


const TransactionTableEmpty = () => {
  return (
    <TableRow>
      <TableCell colSpan={5} className="text-center py-4">
        <Trans>
          No transactions found
        </Trans>
      </TableCell>
    </TableRow>
  )
}


const TransactionTableRender = ({transactions, onViewDetails, isLoading}) => {

  if (isLoading) {
    return <TransactionTableSkeleton/>
  }
  if (!transactions.length) {
    return <TransactionTableEmpty/>
  }
  return transactions.map((transaction) => (
    <TransactionTableRow
      key={transaction.id}
      transaction={transaction}
      onViewDetails={onViewDetails}
    />
  ))
}

const TransactionTable = ({transactions, isLoading, onViewDetails}) => {

  return (
    <>
      <div className="rounded-sm border shadow font-medium">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead/>
              <TableHead><Trans>Date</Trans></TableHead>
              <TableHead><Trans>Amount</Trans></TableHead>
              <TableHead><Trans>Type</Trans></TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TransactionTableRender
              transactions={transactions}
              onViewDetails={onViewDetails}
              isLoading={isLoading}
            />
          </TableBody>
        </Table>
      </div>

    </>
  );
};


export default TransactionTable;