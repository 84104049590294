import {AuthForm, AuthFormField, AuthFormFooter, AuthFormSubmit} from "#components/common/AuthForm";
import {Trans} from "react-i18next";
import useForgotPassword from "#hooks/auth/useForgotPassword";


const ForgotPassword = () => {

  const {
    email,
    isLoading,
    handleSubmit,
    handleChange
  } = useForgotPassword();

  return (
    <AuthForm
      title={<Trans i18nKey="auth_forgot_title">
        Forgot your password?
      </Trans>}
      description={<Trans i18nKey="auth_forgot_description">
        Enter your email and we'll send you a code to reset your password.
      </Trans>}
    >
      <form onSubmit={handleSubmit}>
        <div className="grid w-full items-center gap-4">
          <AuthFormField
            name="email"
            label={<Trans i18nKey="auth_email">Email</Trans>}
            type="email"
            placeholder="eg. email@email.com"
            onChange={handleChange}
            value={email}
            required
          />

          <AuthFormSubmit loading={isLoading} disabled={!email}>
            <Trans i18nKey="auth_forgot_button">
              Send reset code
            </Trans>
          </AuthFormSubmit>
        </div>
      </form>

      <AuthFormFooter to="/auth">
        <Trans i18nKey="auth_forgot_footer">
          Remember your password? Login
        </Trans>
      </AuthFormFooter>
    </AuthForm>
  );
};

export default ForgotPassword;