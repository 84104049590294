import React from "react";

// AVIF imports
import avif512 from '#assets/images/first_home/hero/512.avif';
import avif1024 from '#assets/images/first_home/hero/1024.avif';
import avif2048 from '#assets/images/first_home/hero/2048.avif';
import avif4096 from '#assets/images/first_home/hero/4096.avif';

// WebP imports
import webp512 from '#assets/images/first_home/hero/512.webp';
import webp1024 from '#assets/images/first_home/hero/1024.webp';
import webp2048 from '#assets/images/first_home/hero/2048.webp';
import webp4096 from '#assets/images/first_home/hero/4096.webp';

// JPEG imports (fallback)
import jpeg512 from '#assets/images/first_home/hero/512.jpg';
import jpeg1024 from '#assets/images/first_home/hero/1024.jpg';
import jpeg2048 from '#assets/images/first_home/hero/2048.jpg';
import jpeg4096 from '#assets/images/first_home/hero/4096.jpg';

const HeroBackground = () => {
  // Функція для створення srcSet
  const createSrcSet = (images) => {
    return Object.entries(images)
      .map(([size, src]) => `${src} ${size}w`)
      .join(', ');
  };

  // Об'єкти з зображеннями для кожного формату
  const avifImages = {
    512: avif512,
    1024: avif1024,
    2048: avif2048,
    4096: avif4096,
  };

  const webpImages = {
    512: webp512,
    1024: webp1024,
    2048: webp2048,
    4096: webp4096,
  };

  const jpegImages = {
    512: jpeg512,
    1024: jpeg1024,
    2048: jpeg2048,
    4096: jpeg4096,
  };

  return (
    <>
      <div className="absolute -top-1/4 md:-top-24 inset-0 w-full h-full">
        <picture>
          {/* AVIF варіант */}
          <source
            type="image/avif"
            sizes="100vw"
            srcSet={createSrcSet(avifImages)}
          />

          {/* WebP варіант */}
          <source
            type="image/webp"
            sizes="100vw"
            srcSet={createSrcSet(webpImages)}
          />

          {/* JPEG як fallback */}
          <img
            src={jpeg4096}
            srcSet={createSrcSet(jpegImages)}
            alt="Hero Background"
            sizes="100vw"
            decoding="async"
            loading="eager"
            className="object-cover object-center lg:object-center w-full h-full block"
          />
        </picture>
      </div>

      <div
        className="absolute w-full h-full inset-0 bg-gradient-to-tr from-primary/70 to-highlight/70 mix-blend-multiply z-10"
        aria-hidden="true"
      />

      <div
        className="absolute w-full h-52 bottom-0 left-0 bg-gradient-to-t from-foreground dark:from-background to-transparent z-10"
        aria-hidden="true"
      />
    </>
  );
};

export default HeroBackground;