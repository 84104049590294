import {memo, useEffect} from 'react';
import {NetworkSelect, NoNetworksMessage} from "#components/wallet/NetworkSelect";
import {useGetNetworksQuery} from "#services/api/accounts";
import NetworkSelectHeader from './NetworkSelectHeader';
import {Trans} from "react-i18next";
import {useFavNetwork} from "#hooks/useFavNetwork";

const ReceiveNetworkSelect = memo(({network, setNetwork, accountId}) => {
  const {data: networks, isLoading, isFetching} = useGetNetworksQuery(accountId, {
    skip: !accountId
  });
  const {favNetworkId} = useFavNetwork('deposit');


  useEffect(() => {
    const depositNetworks = networks?.filter(({isDepositEnabled}) => isDepositEnabled);
    if (depositNetworks?.length >= 1 && !network && !isFetching) {
      const fanNetwork = depositNetworks.find(({id}) => id === favNetworkId);
      setNetwork(fanNetwork || depositNetworks[0]);
    }
  }, [favNetworkId, isFetching, network, networks, setNetwork]);

  const isDisabled = isLoading || isFetching || !accountId;
  const depositNetworks = networks?.filter(({isDepositEnabled}) => isDepositEnabled);

  return (
    <NetworkSelectHeader>
      {(!depositNetworks?.length && !isDisabled) ? (
        <NoNetworksMessage>
          <Trans>No available networks for deposit</Trans>
        </NoNetworksMessage>
      ) : (
        <NetworkSelect
          isLoading={isDisabled}
          networks={depositNetworks}
          network={network}
          setNetwork={setNetwork}
          type={'deposit'}
        />
      )}
    </NetworkSelectHeader>
  );
});

ReceiveNetworkSelect.displayName = 'ReceiveNetworkSelect';

export default ReceiveNetworkSelect;