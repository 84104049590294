import {useCallback, useMemo, useState} from "react";
import {useUploadVerificationMutation} from "#services/api/common";
import {useDispatch} from "react-redux";
import {setVerification} from "#store/slices/authSlice";
import {useToast} from "#hooks/use-toast";
import {Trans} from "react-i18next";

const useVerification = (defaultCountry, verificationSteps) => {
  const [step, setStep] = useState(0);
  const [verificationData, setVerificationData] = useState({
    country: defaultCountry, documentType: null, document: null, selfie: null,
  });
  const [uploadVerification, {isLoading: isUploading}] = useUploadVerificationMutation();
  const dispatch = useDispatch();
  const {toast} = useToast();
  // Update handlers
  const updateField = (field) => (value) => {
    setVerificationData(prev => ({...prev, [field]: value}));
  };

  // Steps configuration
  const currentStep = verificationSteps[step];
  const StepComponent = currentStep.Component;

  // Compute if all data is valid for submission
  const canSubmit = useMemo(() => {
    const {country, documentType, document, selfie} = verificationData;
    return Boolean(country && documentType && document && selfie);
  }, [verificationData]);

  // Navigation helpers
  const canGoNext = () => {
    return currentStep.isValid(verificationData);
  };

  const goToPrevStep = () => setStep(s => s - 1);
  const goToNextStep = () => setStep(s => s + 1);
  const buttonType = currentStep.navType;

  const upload = useCallback(async () => {
    try {
      await uploadVerification(verificationData).unwrap()
      dispatch(setVerification('pending'));
      goToNextStep();
    } catch (e) {
      console.error(e)
      toast({
        title: <Trans>Error</Trans>,
        description: <Trans>Something went wrong, please try again</Trans>,
        variant: 'destructive',
      })
    }
  }, [dispatch, toast, uploadVerification, verificationData]);


  const clear = useCallback(() => {
    setStep(0);
    setVerificationData({
      country: defaultCountry, documentType: null, document: null, selfie: null,
    });
  }, [defaultCountry]);
  return {
    step,
    verificationData,
    updateField,
    currentStep,
    StepComponent,
    canSubmit,
    canGoNext,
    goToPrevStep,
    goToNextStep,
    buttonType, isUploading,
    upload,
    clear,
  };
}

export default useVerification;