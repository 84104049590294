import Container from "#ui/container";
import {memo} from "react";
import {useAccounts} from "#hooks/api/useAccounts";
import {CryptoAccountsTable, CryptoPagination} from "./components/CryptoAccountsTable";
import {ACCOUNTS_DEFAULT_LIMIT} from "#src/constants/wallet";
import {useIsMobile} from "#hooks/use-mobile";
import CryptoAccountsList from "#components/wallet/CryptoAccountsList";
import CryptoSearchHeader from "./components/CryptoSearchHeader";

const CryptoAccounts = () => {
  const isMobile = useIsMobile();
  const {
    accounts,
    isLoading,
    isFetching,
    hasNextPage,
    hasPrevPage,
  } = useAccounts({
    defaultLimit: ACCOUNTS_DEFAULT_LIMIT,
  });

  const AccountsComponent = isMobile ? CryptoAccountsList : CryptoAccountsTable;
  return (
    <Container>
      <CryptoSearchHeader/>
      <div className="w-full">
        <AccountsComponent isLoading={isLoading || isFetching} accounts={accounts}/>
        <CryptoPagination
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPrevPage}
        />
      </div>
    </Container>
  );
};

export default memo(CryptoAccounts);