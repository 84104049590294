import {useEffect} from "react";

export default function useFocus(inputRef, focus) {
  useEffect(() => {
    if (focus && inputRef.current) {
      const timeoutId = setTimeout(() => {
        inputRef.current.focus();
      }, 250);
      return () => clearTimeout(timeoutId);
    }
    const isFocused = inputRef.current === document.activeElement;
    if (focus || isFocused) {
      inputRef.current.selectionStart = inputRef.current.value.length;
      inputRef.current.selectionEnd = inputRef.current.value.length
    }
  }, [focus, inputRef]);


}