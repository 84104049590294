import {AlertCircle} from "lucide-react";
import {Trans} from "react-i18next";

import {memo} from "react";

import {Link} from "react-router-dom";
import Banner from "#components/Banners/Banner";
import {useNotificationsQuery} from "#services/api/common";

const texts = {
  rejected: {
    title: <Trans i18nKey={'cantWithdrawBannerTitle'}>Withdrawal blocked</Trans>,
    description: <Trans i18nKey={'cantWithdrawBannerRejectedDescription'}>
      Please verify your account to withdraw funds
    </Trans>
  },
  pending: {
    title: <Trans i18nKey={'cantWithdrawBannerTitle'}>Withdrawal blocked</Trans>,
    description: (<Trans
      i18nKey={'cantWithdrawBannerPendingDescription'}>
      Please wait for the verification to be completed
    </Trans>)
  },
  approved: {
    title: <Trans i18nKey={'cantWithdrawBannerSuccessTitle'}>Your account is temporarily blocked.</Trans>,
    description: (
      <Trans i18nKey={
        'cantWithdrawBannerSuccessDescription'
      }>
        You need to activate a status. Contact support for more information.
      </Trans>
    )
  }
}

const CantWithdrawBanner = ({canWithdraw, verification}) => {
  const {
    data: {
      hasNotification = false,
      text = undefined,
      symbols = []
    } = {},
  } = useNotificationsQuery(null, {
    skip: canWithdraw,
  });
  if (typeof canWithdraw !== 'boolean' || canWithdraw) return null;

  if (hasNotification) {
    return (
      <Banner Icon={AlertCircle}
              title={<Trans i18nKey={'cantWithdrawBannerTitle'}>Withdrawal blocked</Trans>}
              variant={'error'}
      >
        {text}
        <div className={'inline-flex gap-2'}>
          {symbols.map((symbol, i) => (
            <Link key={i} className={'underline'} to={'/wallet/receive/' + symbol}>
              <Trans
                values={{
                  symbol
                }}
                i18nKey={'cantWithdrawBannerPay'}
              >
                Pay {'{{symbol}}'}
              </Trans>
            </Link>
          ))}
        </div>
      </Banner>
    )
  }
  const status = verification ?? 'rejected';
  const {
    title, description
  } = texts[status];
  return (
    <Banner Icon={AlertCircle} title={title} variant={'error'}>
      {description}
      {status === 'rejected' &&
        <Link className={'underline'} to={'/profile/'}>
          <Trans>
            Verify
          </Trans>
        </Link>
      }
    </Banner>
  );
};

export default memo(CantWithdrawBanner);