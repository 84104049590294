import {Trans} from "react-i18next";
import formatDecimal from "#lib/formatDecimal";
import {useGetFuturesAccountQuery} from "#services/api/futures";
import {memo, useMemo} from "react";
import {FuturesBalanceDialog} from "./FuturesBalanceDialog";
import {useInvisible} from "#hooks/useInvisible";
import {cn} from "#lib/utils";
import {useBalance, useSubscribe} from "#src/contexts/SocketContext";
import Decimal from "decimal.js";
import {DEFAULT_FUTURES_CURRENCY} from "#src/constants/wallet";

const BalanceSubscription = memo(({positionIds}) => {
  const subscriptionTypes = useMemo(() => ["BALANCE"], []);
  useSubscribe(subscriptionTypes, true, undefined, null, false);
  return null;
},)
const PRECISION = 4;

const AvailableBalance = ({freeMargin, balance}) => {
    const invisible = useInvisible(freeMargin, PRECISION, DEFAULT_FUTURES_CURRENCY);
    const {freeMargin: newFreeMargin} = useBalance() ?? {};
    const value = useMemo(() => new Decimal(newFreeMargin ?? freeMargin ?? 0), [newFreeMargin, freeMargin])
    return useMemo(() => (
      <div>
        <p className="text-muted-foreground text-xs">
          <Trans>
            Available
          </Trans>
        </p>
        <div className={'relative inline-block'}>
          <div className="invisible" aria-hidden="true">
            ${invisible}
          </div>
          <p className={cn(
            'absolute inset-0 text-sm',
            {'text-success': parseFloat(newFreeMargin) > parseFloat(balance)},
            {'text-destructive': parseFloat(newFreeMargin) < parseFloat(balance)},
            {'text-foreground': value.isNegative()},
          )}>{formatDecimal(value.isNegative() ? 0 : value, PRECISION)} {DEFAULT_FUTURES_CURRENCY}</p>
        </div>
      </div>
    ), [invisible, newFreeMargin, balance, value]);
  }
;

const MarginBalance = ({balance, marginBalance}) => {
  const invisible = useInvisible(marginBalance, PRECISION, DEFAULT_FUTURES_CURRENCY);
  const {marginBalance: newMarginBalance} = useBalance() ?? {};
  return useMemo(() => (
    <div>
      <p className="text-muted-foreground text-xs">
        <Trans>
          Balance
        </Trans>
      </p>
      <div className={'relative inline-block'}>
        <div className="invisible" aria-hidden="true">
          ${invisible}
        </div>
        <p className={cn(
          'absolute inset-0 text-sm',
          {'text-success': parseFloat(newMarginBalance) > parseFloat(balance)},
          {'text-destructive': parseFloat(newMarginBalance) < parseFloat(balance)},
        )}>{formatDecimal(newMarginBalance ?? marginBalance, PRECISION)} {DEFAULT_FUTURES_CURRENCY}</p>
      </div>
    </div>
  ), [invisible, newMarginBalance, balance, marginBalance]);

}

export const FuturesBalance = () => {
  const {data: account, refetch} = useGetFuturesAccountQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const startBalance = parseFloat(account?.balance ?? 0) + parseFloat(account?.usedMargin ?? 0);
  return (
    <>
      {/*order-first lg:order-last lg:justify-end lg:w-1/2*/}
      <BalanceSubscription/>
      <div className="flex items-center justify-between   gap-4 w-full">
        {/*<div className={'flex items-center gap-4'}>*/}
        <div className={'w-1/3'}>
          <MarginBalance balance={startBalance} marginBalance={account?.marginBalance}/>

        </div>
        <div className={'w-1/3'}>
          <AvailableBalance balance={account?.balance} freeMargin={account?.freeMargin}/>

        </div>
        {/*</div>*/}
        <div className={'w-1/3 flex justify-end'}>
          <FuturesBalanceDialog refetch={refetch} freeMargin={account?.freeMargin}/>
        </div>
      </div>
    </>
  )
}