import React, {forwardRef, useCallback, useImperativeHandle, useRef, useState} from 'react';
import {Upload} from 'lucide-react';
import {Button} from "#ui/button";
import {Label} from "#ui/label";
import {cn} from "#lib/utils";

const cropFileName = (fileName) => {
  if (fileName.length > 20) {
    return fileName.substring(0, 20) + "...";
  }
  return fileName;
}

const ImageUploadPreview = forwardRef(({label, file, onFileSelect, placeHolder, className}, ref) => {
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState(file?.name || null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(cropFileName(file.name));

      if (onFileSelect) {
        onFileSelect(file);
      }
    }
  };

  const removePreview = useCallback(() => {
    setFileName(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    if (onFileSelect) {
      onFileSelect(null);
    }
  }, [onFileSelect]);

  useImperativeHandle(ref, () => ({
    ...(fileInputRef?.current || {}),
    reset: removePreview,
  }));
  return (
    <label className={cn("cursor-pointer block", className)}>
      <input
        ref={fileInputRef}
        type="file"
        className="hidden"
        accept="image/*"
        onChange={handleFileChange}
      />
      {label && (
        <Label className={'block mb-1.5'}>
          {label}
        </Label>
      )}

      <Button
        type="button"
        variant="outline"
        className={'truncate w-full'}
        onClick={() => fileInputRef?.current?.click()}
      >
        <Upload className="w-4 h-4 mr-2"/>
        {fileName ? fileName : placeHolder}
      </Button>
    </label>
  );
});

ImageUploadPreview.displayName = 'ImageUploadPreview';

export default ImageUploadPreview;