// hooks/useOrderForm.js
import {useLocalStorage} from "@uidotdev/usehooks";
import {useState} from "react";
import {useCreatePositionMutation, useGetMinMaxQuery} from "#services/api/futures";
import {useLeverage} from "#hooks/useLeverage";
import {useToast} from "#hooks/use-toast";
import {useTranslation} from "react-i18next";
import Decimal from "decimal.js";
import formatDecimal from "#lib/formatDecimal";
import useErrorMessages from "#hooks/useErrorMessages";
import {ErrorNameSpaces} from "#src/constants/errors";

const INITIAL_FORM_STATE = {
  type: null,
  size: null,
  entryPrice: null,
  stopLoss: null,
  takeProfit: null,
};

export const useOrderForm = (asset) => {
  const {currentLeverage, setCurrentLeverage, min, max} = useLeverage();
  const [orderType, setOrderType] = useLocalStorage('orderType', 'MARKET');
  const [useTpSl, setUseTpSl] = useState(false);
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [createPosition, {isLoading}] = useCreatePositionMutation();
  const {toast} = useToast();
  const {t} = useTranslation();
  const isLimitOrder = orderType === 'LIMIT' || orderType === 'STOP_LIMIT';
  const {showError} = useErrorMessages(ErrorNameSpaces.FUTURES, t("Error"), t('Failed to create order'))
  const [errors, setErrors] = useState({})

  const {
    data: minMaxData = {}
  } = useGetMinMaxQuery(
    {symbol: asset.symbol, leverage: currentLeverage},
    {refetchOnMountOrArgChange: true}
  );

  const updateFormField = (field) => (value) => {
    setFormData((prev) => ({...prev, [field]: value}));
    if (errors[field]) {
      setErrors((prev) => ({...prev, [field]: false}))
    }
  };

  const createPositionHandler = async (type) => {
    const size = new Decimal(formData?.size ?? 0);
    const minSize = new Decimal(minMaxData?.minSize ?? 0);
    if (!isLimitOrder) {
      delete formData.entryPrice
    }
    if (isLimitOrder && !formData.entryPrice) {
      toast({
        variant: "destructive",
        title: t('Error'),
        description: t('Please enter the entry price')
      })
      setErrors((prev) => ({...prev, entryPrice: true}))
      return
    }
    if (size.lt(minSize)) {
      toast({
        variant: "destructive",
        title: t('Error'),
        description: t('The quantity should be larger than {{minSize}} {{asset}} ', {
          minSize: formatDecimal(minSize, asset.precision),
          asset: asset.baseAsset
        })
      })
      setErrors((prev) => ({...prev, size: true}))
      return
    }
    const body = {
      ...formData,
      symbol: asset.symbol,
      leverage: currentLeverage,
      type,
      orderType,
    };

    try {
      await createPosition(body).unwrap()
      toast({
        variant: "success",
        title: t('Success'),
        description: t('Order created successfully')
      })
      setFormData(INITIAL_FORM_STATE);
    } catch (e) {
      console.error(e)
      showError(e?.data?.code, e?.data?.data);

    }
  }

  return {
    leverageProps: {
      currentLeverage,
      min,
      max,
      onSelect: setCurrentLeverage,
    },
    orderTypeProps: {
      value: orderType,
      onValueChange: setOrderType,
    },
    tpSlProps: {
      useTpSl,
      setUseTpSl,
    },
    createPositionHandler,
    formData,
    updateFormField,
    minMaxData,
    isSending: isLoading,
    isLimitOrder,
    errors,
  };
};
