import OtpModal from "#components/common/OtpForm/OtpModal";
import {useSelector} from "react-redux";
import {selectUserId} from "#store/slices/authSlice";
import useSend from "#hooks/api/useSend";
import {memo} from "react";
import {OTP_TYPES} from "#src/constants/auth";
import {Trans} from "react-i18next";

const WithdrawOtpModal = () => {
  const userId = useSelector(selectUserId);
  const {openOtp, transactionId, sendApiRef} = useSend();
  return (
    <OtpModal
      open={openOtp}
      type={OTP_TYPES.WITHDRAWAL_CONFIRMATION}
      setOpen={sendApiRef.setOpenOtp}
      title={<Trans>Confirm withdrawal</Trans>}
      userId={userId}
      data={{transactionId}}
    />
  );
};

export default memo(WithdrawOtpModal);