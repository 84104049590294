import {cn} from "#lib/utils";
import {Card} from "#ui/card";
import {Skeleton} from "#ui/skeleton";

const OrderListSkeleton = ({rows}) => {

  return (
    <Card>
      {Array.from({length: !!rows ? rows : 5}).map((_, i) => (
        <div
          className={cn('px-3 py-2 first:pt-2 last:pb-2 border-b last:border-0 overflow-hidden flex justify-between items-center',)}
          key={i}><Skeleton className={'h-5 w-20'} /> <Skeleton className={'h-5 w-20'}/>
        </div>
      ))}
    </Card>
  );
};

export default OrderListSkeleton;