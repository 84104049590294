import base from "#services/api/base";

const futuresApi = base.injectEndpoints({
  endpoints: (builder) => ({
    getOHLCV: builder.query({
      query: ({symbol, timeFrame}) => {
        const timeTo = Date.now();
        return {
          url: `/api/futures/ohlcv/${symbol}?timeFrame=${timeFrame}&timeTo=${timeTo}`,
          method: 'GET',
        };
      }
    }),
    getAsset: builder.query({
      query: (symbol) => {
        return {
          url: `/api/futures/assets/${symbol}`,
          method: 'GET',
        };
      }
    }),
    getAssets: builder.query({
      query: () => {
        return {
          url: `/api/futures/assets/`,
          method: 'GET',
        };
      }
    }),

    getFuturesAccount: builder.query({
      query: () => {
        return {
          url: '/api/futures/account/',
          method: 'GET',
        };
      },
      providesTags: ['FuturesAccount']
    }),

    getTimeFrames: builder.query({
      query: () => {
        return {
          url: `/api/futures/timeframes`,
          method: 'GET',
        };
      }
    }),
    getLeverage: builder.query({
      query: () => {
        return {
          url: `/api/futures/leverage`,
          method: 'GET',
        };
      }
    }),

    getMinMax: builder.query({
      query: ({symbol, leverage}) => {
        return {
          url: `/api/futures/minMax/${symbol}?leverage=${leverage}`,
          method: 'GET',
        };
      }
    }),

    createPosition: builder.mutation({
      query: (body) => {
        return {
          url: '/api/futures/positions',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['FuturesAccount', 'FuturesPositions']
    }),
    getTransferAccounts: builder.query({
      query: () => ({
        url: '/api/futures/accounts',
        method: 'GET',
      }),
      providesTags: ['FuturesTransferAccounts']
    }),

    deposit: builder.mutation({
      query: ({amount, accountId}) => ({
        url: '/api/futures/deposit',
        method: 'POST',
        body: {amount, formBalanceId: accountId},
      }),
      invalidatesTags: ['FuturesAccount', 'FuturesTransferAccounts']

    }),
    withdraw: builder.mutation({
      query: ({amount, accountId}) => ({
        url: '/api/futures/withdraw',
        method: 'POST',
        body: {amount, toBalanceId: accountId},
      }),
      invalidatesTags: ['FuturesAccount', 'FuturesTransferAccounts']

    }),

    getPositions: builder.query({
      query: (params) => ({
        url: `/api/futures/positions`,
        method: 'GET',
        params: params,
      }),
      providesTags: ['FuturesPositions']
    }),

    closePosition: builder.mutation({
      query: (positionId) => ({
        url: `/api/futures/positions/${positionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['FuturesAccount', 'FuturesPositions']
    }),
    updatePosition: builder.mutation({
      query: ({id, ...body}) => ({
        url: `/api/futures/positions/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['FuturesPositions']
    }),
  })
})

export const {
  useGetOHLCVQuery,
  useGetAssetQuery,
  useGetAssetsQuery,
  useGetTimeFramesQuery,
  useGetFuturesAccountQuery,
  useGetLeverageQuery,
  useGetMinMaxQuery,
  useCreatePositionMutation,
  useGetTransferAccountsQuery,
  useDepositMutation,
  useWithdrawMutation,
  useGetPositionsQuery,
  useClosePositionMutation,
  useUpdatePositionMutation
} = futuresApi;


export default futuresApi;