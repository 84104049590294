import {selectBrand} from "#store/slices/brandSlice";
import {useSelector} from "react-redux";
import {CircleHelp} from "lucide-react";
import {Button} from "#ui/button";
import {Trans} from "react-i18next";
import {Link} from "react-router-dom";

const SupportButton = () => {
  const brand = useSelector(selectBrand);
  return (
    <Button variant={'outline'} asChild>
      <Link to={`https://t.me/${brand.supportChat}`}>
        <CircleHelp/>
        <Trans i18nKey="support">Support</Trans>
      </Link>
    </Button>
  );
};

export default SupportButton;