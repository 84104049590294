import {memo, useMemo} from 'react';
import {useCreateDepositByMinQuery} from "#services/api/transactions";
import DepositWarning from './DepositWarning';
import AddressDisplay from './AddressDisplay';
import MinDepositInfo from './MinDepositInfo';
import ReceiveDirectionSkeleton from './ReceiveDirectionSkeleton';
import {ErrorMessage} from "#pages/wallet/Receive/components/index";

const ReceiveDirection = memo(({account, network}) => {
  const skip = !account?.id || !network?.id;
  const {data, isLoading, isFetching, isError} = useCreateDepositByMinQuery({
    accountId: account?.id,
    networkId: network?.id,
  }, {skip});

  const {
    symbol,
    precision,
    depositMin
  } = useMemo(() => ({
    symbol: account?.symbol,
    precision: account?.precision,
    depositMin: network?.depositMin
  }), [account?.precision, account?.symbol, network?.depositMin]);

  if (skip) return null;
  if (isLoading || isFetching) return <ReceiveDirectionSkeleton/>;
  if (isError) return <ErrorMessage/>;


  const {depositAddress: address, memo, depositMin: amount} = data || {};

  return (
    <div>
      <DepositWarning/>
      <AddressDisplay address={address} memo={memo} symbol={symbol}/>
      <MinDepositInfo symbol={symbol} depositMin={amount || depositMin} precision={precision}/>
    </div>
  );
});

ReceiveDirection.displayName = 'ReceiveDirection';

export default ReceiveDirection;
