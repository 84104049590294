import {ResponsiveDialogBase} from "#ui/responsive-dialog";
import {Trans} from "react-i18next";
import {useCallback} from "react";

export const ChoosePlanModal = ({open, onClose, children}) => {
  const onOpenChangeHandler = useCallback((is) => {
    !is && onClose(false)
  }, [onClose])
  return (
    <ResponsiveDialogBase
      title={
        <Trans i18nKey={'choosePlanTitle'}>
          Choose Plan
        </Trans>
      }
      description={
        <Trans i18nKey={'choosePlanDescr'}>
          Once you open a plan, you can close it at any time, but in this case, you will instantly get back only the
          body of your deposit without interest
        </Trans>
      }
      open={open} onOpenChange={onOpenChangeHandler}
    >
      {children}
    </ResponsiveDialogBase>
  );
};

