import {memo} from 'react';
import {Trans} from 'react-i18next';
import AddressQrCode from "#components/common/AddressQrCore";
import CopyFormField from "#components/common/CopyFormField";

const AddressDisplay = memo(({address, memo, symbol}) => (
  <div className={'flex flex-col sm:flex-row items-center gap-2'}>
    <div>
      <AddressQrCode value={address} symbol={symbol}/>
    </div>
    <div className={'flex-1 w-full space-y-2'}>
      <CopyFormField
        label={<Trans>Deposit Address</Trans>}
        value={address}
      />
      {memo && (
        <CopyFormField
          label={<Trans>Memo</Trans>}
          value={memo}
          messageProps={{message: <Trans>*MEMO is required, or you will lose your coins</Trans>, variant: 'warning'}}
        />
      )}
    </div>
  </div>
));

AddressDisplay.displayName = 'AddressDisplay';

export default AddressDisplay;
