import usePositionStatus from "#hooks/usePositionStatus";
import {Card} from "#ui/card";
import {cn} from "#lib/utils";

import {CircleOff} from "lucide-react";
import OrderListSkeleton from "#components/skeletons/OrderListSkeleton";

export const OrderList = ({orders, isLoading, status}) => {
  const {headers, renders, notFound} = usePositionStatus(status);
  if (isLoading) {
    return <OrderListSkeleton rows={headers.length}/>
  }

  if (!orders.length) {
    return <Card className={'flex flex-col gap-2 p-3 justify-center items-center h-32'}>
      <CircleOff size={30}/>
      {notFound}
    </Card>
  }
  return (
    <div className={'space-y-3'}>
      {orders.map((position) => (
        <Card key={position.id}>
          {renders.map((render, i) => (
            <div
              className={cn('px-3 py-2 first:pt-2 last:pb-2 border-b last:border-0 overflow-hidden flex justify-between items-center',)}
              key={position.id + i}><span className={'text-muted-foreground'}>{headers[i]}</span> {render(position)}
            </div>
          ))}
        </Card>
      ))}
    </div>
  )
}