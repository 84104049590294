import useInit from "#hooks/useInit";
import {useEffect} from "react";
import {clarity} from "react-microsoft-clarity";
import FullScreenLoading from "#components/common/FullScreenLoading";
import FaviconGenerator from "#components/common/FaviconGenerator";

const isProduction = process.env.NODE_ENV === 'production'

const AppSetup = ({children}) => {
  const {isLoading, brand, theme} = useInit()
  const clarityId = process.env.REACT_APP_CLARITY_ID ?? null

  useEffect(() => {
    document.title = brand?.name || 'Wallet';
  }, [brand?.name]);

  useEffect(() => {
    if (clarityId && isProduction) {
      clarity.init(clarityId);
      clarity.consent();
    }
  }, [clarityId])

  if (!brand) {
    return (<div>
      <FullScreenLoading variant={'primary'} show={true}/>
    </div>)
  }


  if (isLoading) {
    return <FullScreenLoading variant={'primary'} show={isLoading}/>
  }
  return <>
    <FaviconGenerator bgColor={theme.primary} icon={brand.icon}/>
    {children}
  </>


}

export default AppSetup;