import {Tabs, TabsList, TabsTrigger} from "#ui/tabs";
import {Trans} from "react-i18next";
import {memo, useMemo, useState} from "react";
import {useIsMobile} from "#hooks/use-mobile";
import {OrderTable} from "#pages/Futures/components/Orders/OrderTable";
import {useSubscribe} from "#src/contexts/SocketContext";
import Pagination from "#ui/pagination";
import {useSearchParams} from "react-router-dom";
import {OrderList} from "#pages/Futures/components/Orders/OrderList";
import {OrdersProvider, useOrders} from "#src/contexts/OrdersContext";

export const OrderSubscription = memo(({positionIds}) => {
  const subscriptionTypes = useMemo(() => ["POSITION"], []);
  useSubscribe(subscriptionTypes, true, undefined, null, positionIds);
  return null;
}, (prev, next) => JSON.stringify(prev.positionIds) === JSON.stringify(next.positionIds));

const OrderRender = ({status}) => {
  const {
    positions, isLoading, isFetching,
    hasPrevPage,
    hasNextPage,
  } = useOrders()
  const isMobile = useIsMobile()
  return (<>
    {!isLoading && !isFetching && status === 'OPEN' && <OrderSubscription positionIds={positions?.map(({id}) => id)}/>}
    <div className={'w-full border border-border/50 mt-3 overflow-hidden rounded-card flex flex-col'}>
      {isMobile ?
        <OrderList orders={positions} isLoading={isLoading || isFetching} status={status}/> :
        <OrderTable orders={positions} isLoading={isLoading || isFetching} status={status}/>}
      <Pagination hasNextPage={hasNextPage} hasPreviousPage={hasPrevPage}/>
    </div>
  </>)
}


export const Orders = () => {
  const [status, setStatus] = useState('OPEN')
  const [, setSearchParam] = useSearchParams();
  const onValueChange = (value) => {
    setSearchParam({page: 1})
    setStatus(value)
  }
  return (
    <OrdersProvider status={status}>
      <section className={'px-4 py-4 border-t border-border/50'}>
        <div className={'flex justify-start'}>
          <Tabs defaultValue={status} value={status} onValueChange={onValueChange} className="w-full sm:w-[400px]">
            <TabsList className="grid w-full grid-cols-3 ">
              <TabsTrigger value="OPEN">
                <Trans>
                  Positions
                </Trans>
              </TabsTrigger>
              <TabsTrigger value="PENDING">
                <Trans>
                  Orders
                </Trans>
              </TabsTrigger>
              <TabsTrigger value="CLOSED">
                <Trans>
                  History
                </Trans>
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
        <OrderRender status={status}/>
      </section>
    </OrdersProvider>
  )
}