import * as React from "react"
import {forwardRef} from "react"
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger
} from "#ui/drawer"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "#ui/dialog"
import {useIsMobile} from "#hooks/use-mobile"


const ResponsiveDialogContent = forwardRef(({children, ...props}, ref) => {
  const isMobile = useIsMobile()
  const Component = isMobile ? DrawerContent : DialogContent


  return (
    <Component
      ref={ref}
      {...props}
      style={{
        ...(props.style || {}),
        ...(isMobile ? {
          display: 'flex',
          flexDirection: 'column'
        } : {})
      }}
    >
      <div
        className={isMobile ? 'flex-grow flex flex-col' : ''}
      >
        {children}
      </div>
    </Component>
  )
})

// Без змін
const ResponsiveDialogHeader = forwardRef((props, ref) => {
  const isMobile = useIsMobile()
  const Component = isMobile ? DrawerHeader : DialogHeader
  return <Component ref={ref} {...props} />
})

// Без змін
const ResponsiveDialogFooter = forwardRef((props, ref) => {
  const isMobile = useIsMobile()
  const Component = isMobile ? DrawerFooter : DialogFooter
  return <Component ref={ref} {...props} />
})

// Без змін
const ResponsiveDialog = forwardRef((props, ref) => {
  const isMobile = useIsMobile()
  const Component = isMobile ? Drawer : Dialog
  return <Component ref={ref} {...props} />
})

// Без змін
const ResponsiveDialogTitle = forwardRef((props, ref) => {
  const isMobile = useIsMobile()
  const Component = isMobile ? DrawerTitle : DialogTitle
  return <Component ref={ref} {...props} />
});

// Без змін
const ResponsiveDialogDescription = forwardRef((props, ref) => {
  const isMobile = useIsMobile()
  const Component = isMobile ? DrawerDescription : DialogDescription
  return <Component ref={ref} {...props} />
});

// Без змін
const ResponsiveDialogClose = forwardRef((props, ref) => {
  const isMobile = useIsMobile()
  const Component = isMobile ? DrawerClose : DialogClose
  return <Component ref={ref} {...props} />
});

// Без змін
const ResponsiveDialogTrigger = forwardRef((props, ref) => {
  const isMobile = useIsMobile()
  const Component = isMobile ? DrawerTrigger : DialogTrigger
  return <Component ref={ref} {...props} />
});

// Правильний фікс для ResponsiveDialogBase
const ResponsiveDialogBase = forwardRef(({title, description, children, ...props}, ref) => {
  const {
    descriptionProps = {},
    headerProps = {},
  } = props

  return (<ResponsiveDialog {...props} ref={ref}>
      <ResponsiveDialogContent>
        <ResponsiveDialogHeader {...headerProps}>
          <ResponsiveDialogTitle>
            {title}
          </ResponsiveDialogTitle>
          <ResponsiveDialogDescription {...descriptionProps}>
            {description}
          </ResponsiveDialogDescription>
        </ResponsiveDialogHeader>
        <div className="pb-10 px-5 md:p-0 overflow-y-auto">
          {children}
        </div>
      </ResponsiveDialogContent>
    </ResponsiveDialog>
  )
})

export {
  ResponsiveDialog,
  ResponsiveDialogContent,
  ResponsiveDialogClose,
  ResponsiveDialogDescription,
  ResponsiveDialogFooter,
  ResponsiveDialogHeader,
  ResponsiveDialogTitle,
  ResponsiveDialogTrigger,
  ResponsiveDialogBase
}