import {TestimonialCard} from "./TestimonialCard";
import {cn} from "#lib/utils"

export const MarqueeRow = ({testimonials, direction = 'normal'}) => (
  <div className="overflow-hidden relative mb-5">
    <div
      className={cn(
        `inline-flex hover:paused gap-5`,
        direction === 'reverse' ? 'animate-marqueeReverse' : 'animate-marquee'
      )}>
      {[...testimonials, ...testimonials].map((testimonial, i) => (
        <TestimonialCard key={i} testimonial={testimonial}/>
      ))}
    </div>
  </div>
);
