import { memo } from 'react';
import { Trans } from 'react-i18next';
import formatDecimal from "#lib/formatDecimal";

const MinDepositInfo = memo(({ symbol, depositMin, precision }) => {
  return (
    <div className={'flex items-center justify-between gap-2 mt-2'}>
      <small className={'text-muted-foreground'}>
        <Trans>Minimum deposit:</Trans>
      </small>
      <small>
        <Trans i18nKey={'minDeposit'} values={{symbol, minDeposit: formatDecimal(depositMin, precision)}}>
          {`More than {{minDeposit}} {{symbol}}`}
        </Trans>
      </small>
    </div>
  );
});

MinDepositInfo.displayName = 'MinDepositInfo';

export default MinDepositInfo;
