import {Trans} from "react-i18next";
import {TableCell, TableRow} from "#ui/table";
import TransactionStatusIcon from "#components/wallet/TransactionListItem/TransactionStatusIcon";
import TransactionInfo from "#components/wallet/TransactionListItem/TransactionInfo";
import FormatDatetime from "#components/common/format-datetime";
import TransactionValue from "#components/wallet/TransactionListItem/TransactionValue";
import TransactionDirection from "#components/wallet/TransactionListItem/TransactionDirection";
import {Button} from "#ui/button";
import {ExternalLink} from "lucide-react";

const TransactionTableRow = ({transaction, onViewDetails}) => {
  const onViewDetailsHandler = (e, id) => {
    e.preventDefault()
    onViewDetails(id);
  }
  return (
    <TableRow>
      <TableCell>
        <div className={'flex items-center gap-2'}>
          <TransactionStatusIcon transaction={transaction}/>
          <TransactionInfo transaction={transaction}/>
        </div>
      </TableCell>
      <TableCell>
        <FormatDatetime date={transaction.createdAt}/>
      </TableCell>
      <TableCell>
        <TransactionValue transaction={transaction}/>
      </TableCell>
      <TableCell>
        <TransactionDirection transaction={transaction}/>
      </TableCell>
      <TableCell>
        <div className={'flex justify-end'}>
          <Button size={'sm'} onClick={(e) => onViewDetailsHandler(e, transaction.id)} variant={'outline'}>
            <Trans>Details</Trans>
            <ExternalLink/>
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};
export default TransactionTableRow;
