import useSwapConverter from "#hooks/api/useSwapConverter";
import {useGetSwapAccountsQuery} from "#services/api/accounts";
import {memo, useEffect} from "react";
import {Card} from "#ui/card";
import {Trans} from "react-i18next";
import {SwapConverterForm, SwapInfo, SwapRate, SwapReachedLimit} from "#pages/wallet/Swap/components";
import {ButtonWithLoading} from "#ui/button";
import Container from "#ui/container";
import {useParams} from "react-router-dom";
import SummaryModal from "#pages/wallet/Swap/components/SummaryModal";
import HeaderWIthActions from "#components/common/HeaderWIthActions";
import formatDecimal from "#lib/formatDecimal";
import {useFavoriteCurrency} from "#hooks/useFavoriteCurrency";

const calculateRate = (rate, fromRate, toRate, precision) => {
  if (rate) return formatDecimal(rate, precision)
  if (fromRate && toRate) {
    return formatDecimal(fromRate / toRate, precision)
  }
}
const Swap = () => {
  const {
    fromAccount, toAccount, fromAmount, toAmount, rate, isConverting, convertError, swapConverterApiRef, showSummary
  } = useSwapConverter();
  const [favoriteCurrency] = useFavoriteCurrency();
  const {symbol} = useParams()
  const {data, isLoading, isFetching, error} = useGetSwapAccountsQuery(symbol || favoriteCurrency, {
    skip: fromAccount || toAccount
  });
  const {
    fromAccount: initFromAccount, toAccount: initToAccount,
  } = data || {};
  const errorData = error?.data?.data ?? {}
  const {hasReachedLimit, nextAvailableTime} = errorData;

  useEffect(() => {
    if ((initFromAccount || initToAccount) && !fromAccount && !toAccount) {
      swapConverterApiRef.setBothAccounts({
        fromAccount: initFromAccount, toAccount: initToAccount
      });
    }
  }, [initFromAccount, initToAccount, swapConverterApiRef, fromAccount, toAccount]);


  const isAccountsLoading = isLoading || isFetching || !fromAccount || !toAccount;

  return (<>
    <Container>
      <HeaderWIthActions symbol={fromAccount?.symbol}>
        <Trans>
          Convert
        </Trans>
      </HeaderWIthActions>
      <div className="w-full flex gap-10 md:gap-6 items-start flex-col md:flex-row">
        <Card className="w-full space-y-3 md:w-1/2 px-4 py-6">
          {hasReachedLimit ? <SwapReachedLimit nextAvailableTime={nextAvailableTime}/> : (<>
            <SwapConverterForm
              fromAmount={fromAmount}
              toAmount={toAmount}
              fromAccount={fromAccount}
              toAccount={toAccount}
              swapConverterApiRef={swapConverterApiRef}
              convertError={convertError}
              isConverting={isConverting}
              isAccountsLoading={isAccountsLoading}
            />

            <SwapRate
              fromSymbol={fromAccount?.symbol}
              toSymbol={toAccount?.symbol}
              rate={calculateRate(rate, fromAccount?.rate, toAccount?.rate, toAccount?.precision)}
              isLoading={isAccountsLoading || isConverting}
              isAccountsLoading={isAccountsLoading}
            />

            <ButtonWithLoading
              size={'lg'}
              className={'w-full py-6'}
              onClick={swapConverterApiRef.onShowSummaryHandler}
              loading={isConverting}
              disabled={!fromAmount || !toAmount || !!convertError || showSummary}
            >
              <Trans>
                Preview Conversion
              </Trans>
            </ButtonWithLoading>
          </>)}
        </Card>
        <SwapInfo/>
      </div>
    </Container>
    {!hasReachedLimit && <SummaryModal
      isAccountsLoading={isAccountsLoading}
      open={showSummary} isLoading={isConverting} onClose={swapConverterApiRef.setShowSummary}
      fromAccount={fromAccount} toAccount={toAccount} rate={rate}
      fromAmount={fromAmount} toAmount={toAmount}
      clearState={swapConverterApiRef.clearState}
    />}
  </>);
};


export default memo(Swap);