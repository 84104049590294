import {createContext, useCallback, useContext, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getAccessToken} from "#lib/tokenStorage";
import {
  selectAllPositions,
  selectBalance,
  selectBookTicker,
  selectIsConnected,
  selectKline,
  selectMaxSize,
  selectPositionById,
  selectPrice,
  setConnectionStatus,
  updateBalance,
  updateBookTicker,
  updateKline,
  updateMaxSize,
  updatePosition,
  updatePrice
} from '#store/slices/websocketSlice';
import {WS_URL} from "#src/constants/wallet";

const SocketContext = createContext(null);

export const SocketProvider = ({children}) => {
  const dispatch = useDispatch();
  const isConnected = useSelector(selectIsConnected);
  const reconnectTimeout = useRef(null);
  const reconnectAttempts = useRef(0);
  const messageQueue = useRef([]);
  const socketRef = useRef(null);
  const activeSubscriptions = useRef(new Set());
  const RECONNECT_INTERVAL = 1000;

  const addToStateByType = useCallback((type, data, rest = {}) => {
    switch (type) {
      case 'KLINE':
        dispatch(updateKline(data));
        break;
      case 'PRICE':
        dispatch(updatePrice(data));
        break;
      case 'BOOK_TICKER':
        dispatch(updateBookTicker(data));
        break;
      case 'MAX_SIZE':
        dispatch(updateMaxSize(data));
        break;
      case 'POSITION':
        dispatch(updatePosition({
          ...data,
          positionId: rest.positionId
        }));
        break;
      case 'BALANCE':
        dispatch(updateBalance(data));
        break;
      case 'CONNECTION':
        dispatch(setConnectionStatus(true));
        break;
      default:
        console.warn('Unknown message type:', type);
    }
  }, [dispatch]);

  const sendMessage = useCallback((message) => {
    if (socketRef.current?.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify(message));
      // Зберігаємо підписки для автоматичного відновлення
      if (message.action === 'subscribe') {
        const subscriptionKey = JSON.stringify({
          types: message.types,
          symbol: message.symbol,
          timeframe: message.timeframe,
          leverage: message.leverage,
          positionIds: message.positionIds
        });
        activeSubscriptions.current.add(subscriptionKey);
      } else if (message.action === 'unsubscribe') {
        const subscriptionKey = JSON.stringify({
          types: message.types,
          symbol: message.symbol,
          timeframe: message.timeframe,
          leverage: message.leverage,
          positionIds: message.positionIds
        });
        activeSubscriptions.current.delete(subscriptionKey);
      }
      return true;
    } else {
      messageQueue.current.push(message);
      return false;
    }
  }, []);

  const resubscribeAll = useCallback(() => {
    activeSubscriptions.current.forEach(subscriptionKey => {
      const subscription = JSON.parse(subscriptionKey);
      sendMessage({
        action: 'subscribe',
        ...subscription
      });
    });
  }, [sendMessage]);

  const processMessageQueue = useCallback(() => {
    while (messageQueue.current.length > 0) {
      const message = messageQueue.current[0];
      if (sendMessage(message)) {
        messageQueue.current.shift();
      } else {
        break;
      }
    }
  }, [sendMessage]);

  const connect = useCallback(() => {
    if (socketRef.current?.readyState === WebSocket.OPEN) {
      return;
    }

    const token = getAccessToken();
    if (!token) {
      console.error('No token found');
      return;
    }

    try {
      if (socketRef.current && socketRef.current.readyState !== WebSocket.CLOSED) {
        socketRef.current.close();
      }

      const ws = new WebSocket(WS_URL, [token]);
      socketRef.current = ws;

      ws.onopen = () => {
        console.log('WebSocket connected');
        dispatch(setConnectionStatus(true));
        reconnectAttempts.current = 0;
        if (reconnectTimeout.current) {
          clearTimeout(reconnectTimeout.current);
          reconnectTimeout.current = null;
        }
        processMessageQueue();
        // Відновлюємо всі активні підписки після перепідключення
        resubscribeAll();
      };

      ws.onclose = (event) => {
        console.log('WebSocket disconnected', event);
        dispatch(setConnectionStatus(false));
        if (event.code === 401 && reconnectAttempts.current > 3) {
          console.error('Unauthorized');
          window.location.reload();
        }
        if (reconnectAttempts.current >= 10) {
          window.location.reload();
        }
        console.log(`Reconnecting... Attempt ${reconnectAttempts.current + 1}`);
        reconnectTimeout.current = setTimeout(() => {
          reconnectAttempts.current += 1;
          connect();
        }, RECONNECT_INTERVAL);
      };

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      ws.onmessage = (event) => {
        try {
          const message = JSON.parse(event.data);
          addToStateByType(message.type, message.data, message);
        } catch (error) {
          console.error('Error processing message:', error);
        }
      };
    } catch (error) {
      console.error('Failed to create WebSocket:', error);
      dispatch(setConnectionStatus(false));
    }
  }, [addToStateByType, dispatch, processMessageQueue, resubscribeAll]);

  // Додаємо обробники для мобільних пристроїв та стану мережі
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        console.log('Tab became visible, checking connection...');
        if (!isConnected) {
          connect();
        }
      }
    };

    const handleOnline = () => {
      console.log('Network is online, reconnecting...');
      dispatch(setConnectionStatus(true));
      connect();
    };

    const handleOffline = () => {
      console.log('Network is offline');
      dispatch(setConnectionStatus(false));
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [connect, dispatch, isConnected]);

  useEffect(() => {
    connect();

    return () => {
      messageQueue.current = [];
      // eslint-disable-next-line react-hooks/exhaustive-deps
      activeSubscriptions?.current.clear();
      if (reconnectTimeout.current) {
        clearTimeout(reconnectTimeout.current);
      }
      if (socketRef.current) {
        socketRef.current.close();
        socketRef.current = null;
      }
    };
  }, [connect]);

  const value = {
    isConnected,
    subscribe: useCallback((types, symbol, timeframe, leverage, positionIds) => {
      if (!socketRef.current) return;

      const subscribeMessage = {
        action: 'subscribe',
        types,
        symbol,
        timeframe,
        leverage,
        positionIds,
      };

      sendMessage(subscribeMessage);

      return () => {
        const unsubscribeMessage = {
          action: 'unsubscribe',
          types,
          symbol,
          timeframe,
          leverage,
          positionIds,
        };
        sendMessage(unsubscribeMessage);
      };
    }, [sendMessage]),
    reconnect: connect
  };

  return (
    <SocketContext.Provider value={value}>
      {children}
    </SocketContext.Provider>
  );
};

// Хуки залишаються без змін
export const useSocket = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error('useSocket must be used within a SocketProvider');
  }
  return context;
};

export const useKline = () => useSelector(selectKline);
export const usePrice = () => useSelector(selectPrice);
export const useBookTicker = () => useSelector(selectBookTicker);
export const useMaxSize = () => useSelector(selectMaxSize);
export const useBalance = () => useSelector(selectBalance);
export const useIsConnected = () => useSelector(selectIsConnected);
export const usePositionById = (id) => useSelector(state => selectPositionById(state, id));
export const usePositions = () => useSelector(selectAllPositions);

export const useSubscribe = (types, symbol, timeframe, leverage, positionIds) => {
  const {subscribe} = useSocket();
  useEffect(() => {
    if (!types || !symbol) return;
    const unsubscribe = subscribe(types, symbol, timeframe, leverage, positionIds);
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [types, symbol, timeframe, subscribe, leverage, positionIds]);
};