import {ACCOUNTS_DEFAULT_LIMIT} from "#src/constants/wallet";
import {TableCell, TableRow} from "#ui/table";
import {Skeleton} from "#ui/skeleton";
import React from "react";
import {useIsMobile} from "#hooks/use-mobile";
import {cn} from "#lib/utils";

const FirstCell = ({isMobile}) => (
  <div className={'flex items-center gap-2'}>
    <Skeleton className={cn('h-8 w-8', {
      'h-6 w-6': isMobile,
    })}/>
    <Skeleton className={cn('h-4 w-20')}/>
  </div>
);

const Columns = ({isMobile}) => {
  return Array.from({length: isMobile ? 4 : 5}).map((
    _,
    index
  ) => {
    if (index === 0) {
      return (
        <TableCell key={index}>
          <FirstCell isMobile={isMobile}/>
        </TableCell>
      );
    }
    return (<TableCell key={index}>
        <Skeleton className={cn(
          'h-4 w-20',
          {'w-56': index === 4},
        )}/>
      </TableCell>
    )
  });
}

const CryptoAccountsTableSkeleton = () => {
  const rows = Array.from({length: ACCOUNTS_DEFAULT_LIMIT});
  const isMobile = useIsMobile()

  return (
    rows.map((_, index) => (
      <TableRow key={index}>
        <Columns isMobile={isMobile}/>
      </TableRow>
    ))
  )
};

export default CryptoAccountsTableSkeleton;