import {cva} from "class-variance-authority";

const cardVariants = cva(
  'w-full text-primary-foreground border-0 p-3', {
    variants: {
      variant: {
        default: 'bg-primary',
        secondary: 'bg-secondary',
        accent: 'bg-accent',
        gradientAccent: 'bg-gradient-to-br from-primary animate-gradient-x to-highlight',
        gradient: 'bg-gradient-to-br from-primary animate-gradient-x to-primary/50',
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
)
export default cardVariants;