import {TableHead, TableHeader, TableRow} from "#ui/table";
import {cn} from "#lib/utils";

export const StakingTableHeader = ({isMobile}) => (
  <TableHeader className={cn({'text-xs': isMobile})}>
    <TableRow>
      <TableHead>
        Assets
      </TableHead>
      {!isMobile &&
        <TableHead>
          Period
        </TableHead>
      }
      <TableHead>
        Rate
      </TableHead>
      <TableHead>
        Deposit Range
      </TableHead>

      <TableHead/>
    </TableRow>
  </TableHeader>
)