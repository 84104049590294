import {Button} from "#ui/button";
import {Link} from "react-router-dom";
import {cva} from "class-variance-authority";
import {cn} from "#lib/utils";

const linkButtonVariants = cva(
  'p-0 h-auto',
  {
    variants: {
      variant: {
        default: 'text-foreground',
        primary: 'text-primary',
        secondary: 'text-secondary',
        destructive: 'text-destructive',
        accent: 'text-accent',
        muted: 'text-muted-foreground',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

const LinkButton = ({variant, className, ...props}) => {
  return (
    <Button variant={'link'} className={
      cn(linkButtonVariants({variant, className}))
    } asChild>
      <Link {...props}/>
    </Button>
  );
};

export {LinkButton};