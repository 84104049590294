import {usePermissionsQuery} from "#services/api/common";
import {useEffect} from "react";
import {setPermissions} from "#store/slices/authSlice";
import {useDispatch} from "react-redux";

export default function usePermissions() {
  const dispatch = useDispatch();
  const {data: permissions} = usePermissionsQuery();
  useEffect(() => {
    if (permissions) {
      dispatch(setPermissions(permissions));
    }
  }, [dispatch, permissions]);
  return permissions || {};
}