import RequirementsList from "#pages/profile/Profile/components/SendVerificationDialog/RequirementsList";
import ImageUploadPreview from "#ui/ImageUploadPreview";
import {Trans} from "react-i18next";
import React, {memo} from "react";

const TakeSelfieStep = ({verificationData, updateField}) => (<div className="space-y-4">
  <RequirementsList
    requirements={{
      do: [
        <Trans>Take a selfie with a neutral expression</Trans>,
        <Trans>Make sure your face is visible and centered</Trans>,
        <Trans>Keep your eyes open and clearly visible</Trans>
      ],
      dont: [
        <Trans>No hats or headgear</Trans>,
        <Trans>No beauty filters or effects</Trans>
      ]
    }}
  />
  <ImageUploadPreview
    label={<Trans>Selfie</Trans>}
    file={verificationData.selfie}
    onFileSelect={updateField('selfie')}
    placeHolder={<Trans>Upload a selfie</Trans>}
  />
</div>);

TakeSelfieStep.displayName = 'TakeSelfieStep';

export default memo(TakeSelfieStep);