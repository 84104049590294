import CountryCombobox from "#components/common/CountryCombobox";
import SelectDocumentType from "#pages/profile/Profile/components/SendVerificationDialog/SelectDocumentType";
import React, {memo} from "react";

const SelectDocumentTypeStep = ({verificationData, updateField}) => (<div className="space-y-4">
  <CountryCombobox
    value={verificationData.country}
    onChange={updateField('country')}
  />
  <SelectDocumentType
    value={verificationData.documentType}
    onChange={updateField('documentType')}
  />
</div>);

SelectDocumentTypeStep.displayName = 'SelectDocumentTypeStep';

export default memo(SelectDocumentTypeStep);