import {memo} from "react";
import AccountSelectorPopover from "#components/wallet/AccountSelector/AccountSelectorPopover";


const SwapAccountSelector = memo(({selectedAccount, onSelect, children}) => {
  return (
    <AccountSelectorPopover
      align={'end'}
      alignOffset={-10}
      selectedAccount={selectedAccount}
      onSelect={onSelect}
    >
      {children}
    </AccountSelectorPopover>
  );
});

export default SwapAccountSelector;
