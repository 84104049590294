import Container from "#ui/container";
import {generateTransactionsList} from "#lib/generateTransactionsList";
import {useCurrenciesQuery} from "#services/api/common";
import StatisticsGrid from "#pages/Home/components/LiveActivity/StatisticsGrid";
import LiveTransactions from "#pages/Home/components/LiveActivity/LiveTransactions";
import {currenciesMock} from "#components/common/CryptoCarousel/currenciesMock";
import {Trans} from "react-i18next";
import {SectionHeader} from "#pages/Home/components";

export const LiveActivity = () => {
  const {currentData: currencies = currenciesMock} = useCurrenciesQuery(null, {
    skip: false, selectFromResult: ({currentData}) => ({
      currentData
    })
  });
  const transactions = generateTransactionsList(!!currencies.length ? currencies : currenciesMock);
  return (<section className="py-24 border-y border-border/40 relative bg-accent/5">
    <Container>
      <header className="mb-10">
        <SectionHeader
          title={<Trans>
            Platform Statistics
          </Trans>}
          description={<Trans>
            Here are some statistics about our platform.
          </Trans>}
        />

      </header>

      <div className="flex flex-col items-center md:flex-row gap-12">
        <StatisticsGrid/>
        <LiveTransactions transactions={transactions}/>
      </div>
    </Container>
  </section>);
};

