// SwapRate.jsx
import {memo} from "react";
import {Skeleton} from "#ui/skeleton";
import {Trans} from "react-i18next";

const SwapRate = memo(({fromSymbol, toSymbol, rate, isLoading}) => (
  <div className={'flex items-center justify-between gap-2 text-sm text-muted-foreground'}>
    <span>{<Trans>
      Exchange Rate
    </Trans>}</span>
    {isLoading ? (
      <Skeleton className="h-4 w-32"/>
    ) : (
      <span>1 {fromSymbol} ≈ {rate} {toSymbol}</span>
    )}
  </div>
));

export default SwapRate;