import Logo from "#components/common/Logo";
import Navigation, {MobileNavigation} from "#components/common/Navigation";
import UserProfile, {MobileUserProfile} from "#components/wallet/UserProfile";
import SupportButton from "#components/common/SupportButton";
import Header from "#ui/header";
import {Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger} from "#ui/sheet";
import {Separator} from "#ui/separator";
import {Button} from "#ui/button";
import {Menu} from "lucide-react";
import {useIsMobile} from "#hooks/use-mobile";
import ThemeModeSwitch from "#ui/theme-mode-switch";
import useWalletMainNav from "#hooks/useWalletMainNav";

export function MobileHeader(
  {menuItems = []}
)
{
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant={'ghost'} size={'icon'}>
          <Menu/>
        </Button>
      </SheetTrigger>
      <SheetContent side={'left'} className={'px-0 py-4'}>
        <SheetHeader className={'px-5 mb-4'}>
          <div className={'flex items-center justify-between gap-2'}>
            <div className={'sr-only'}>
              <SheetTitle>
                <Logo path={'/wallet'} size={'sm'}/>
              </SheetTitle>
            </div>
            <Logo path={'/wallet'} size={'sm'}/>
            <ThemeModeSwitch as={Button} size={'icon'}/>
          </div>
        </SheetHeader>
        <Separator/>
        <div className="">
          <MobileNavigation menuItems={menuItems}/>
          <Separator/>
          <MobileUserProfile/>
        </div>
      </SheetContent>
    </Sheet>
  )
}

const WalletHeader = ({isFull}) => {
  const isMobile = useIsMobile()
  const menuItems = useWalletMainNav()
  return (
    <Header isFull={isFull}>
      <div className={'flex items-center justify-between gap-4'}>
        <Logo path={'/wallet'}/>
        {isMobile ? <MobileHeader menuItems={menuItems}/> :
          <Navigation menuItems={menuItems}/>}
      </div>
      <div className={'flex items-center gap-3'}>
        <SupportButton/>
        {!isMobile && (
          <>
            <UserProfile/>
            <ThemeModeSwitch variant={'outline'}/>
          </>
        )}
      </div>
    </Header>
  )
    ;
};

export default WalletHeader;