export const ErrorNameSpaces = {
  LOGIN: 'LOGIN',
  REGISTER: 'REGISTER',
  OTP: 'OTP',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  WITHDRAWAL: 'WITHDRAWAL',
  STAKE: 'STAKE',
  FUTURES: 'FUTURES',
}

const commonErrors = (t) => ({
  RATE_LIMIT_EXCEEDED: t('errors_rate_limit_exceeded', 'Please wait {{retryAfter}} seconds before requesting a new code'),
  HOURLY_LIMIT_EXCEEDED: t('errors_hourly_limit_exceeded', 'Hourly request limit exceeded. Please try again later'),
  DAILY_LIMIT_EXCEEDED: t('errors_daily_limit_exceeded', 'Daily request limit exceeded. Please try again tomorrow'),
})
export const registerErrors = (t) => ({
  PASSWORD_REQUIRED: t('auth_register_password_required', 'Password is required'),
  PASSWORD_LENGTH_MUST_BE_BETWEEN_8_AND_100_CHARACTERS: t('auth_register_password_length', 'Password must be between 8 and 100 characters'),
  PASSWORD_MUST_CONTAIN_UPPERCASE_LETTER: t('auth_register_password_uppercase', 'Password must contain at least one uppercase letter'),
  PASSWORD_MUST_CONTAIN_LOWERCASE_LETTER: t('auth_register_password_lowercase', 'Password must contain at least one lowercase letter'),
  PASSWORD_MUST_CONTAIN_NUMBER: t('auth_register_password_number', 'Password must contain at least one number'),
  PASSWORD_MUST_CONTAIN_SPECIAL_CHARACTER: t('auth_register_password_special_character', 'Password must contain at least one special character'),
  PASSWORD_CANNOT_CONTAIN_WHITESPACE: t('auth_register_password_whitespace', 'Password cannot contain whitespace'),
  PASSWORD_TOO_WEAK: t('auth_register_password_weak', 'Password is too weak'),
  'Password is required': t('auth_register_password_required', 'Password is required'),
  'Please provide a valid email address': t('auth_register_email_invalid', 'Please provide a valid email address'),
  "This email is already in use": t('auth_register_email_in_use', 'This email is already in use'),
  'Validation isEmail on email failed': t('auth_register_email_invalid', 'Please provide a valid email address'),
  ...commonErrors(t),
})

export const loginErrors = (t) => ({
  VALIDATION_ERROR: t("login_errors_validation", "Please check your email and password and try again."),
  UNAUTHORIZED: t("login_errors_unauthorized", "Invalid email or password."),
})

export const otpErrors = (t) => ({
  VALIDATION_ERROR: t("otp_errors_validation", "Please check your OTP and try again."),
  UNAUTHORIZED: t("otp_errors_unauthorized", "Invalid OTP."),
  TOO_MANY_REQUESTS: t("otp_errors_too_many_requests", "Too many requests. Please try again later."),
  USER_ALREADY_ACTIVE: t("otp_errors_user_already_active", "User is already active."),
  NOT_FOUND: t("otp_errors_not_found", "User not found."),
  ...registerErrors(t),
  ...commonErrors(t),
})

export const forgotPasswordErrors = (t) => ({
  VALIDATION_ERROR: t("forgot_password_errors_validation", "Please check your email and try again."),
  UNAUTHORIZED: t("forgot_password_errors_unauthorized", "Invalid email."),
  NOT_FOUND: t("forgot_password_errors_not_found", "User not found."),
})

export const stakeErrors = (t) => ({
  STAKING_NOT_SUPPORTED: t('staking_not_supported', 'Staking is not supported'),
  STAKING_NOT_FOUND: t('staking_not_found', 'Staking not found'),
  STAKE_NOT_FOUND: t('stake_not_found', 'Stake not found'),
  STAKE_PLAN_REQUIRED: t('stake_plan_required', 'Stake plan is required'),
  STAKE_PLAN_NOT_FOUND: t('stake_plan_not_found', 'Stake plan not found'),
  STAKE_CREATION_FAILED: t('stake_creation_failed', 'Stake creation failed'),
  STAKE_ID_REQUIRED: t('stake_id_required', 'Stake ID is required'),
  STAKE_ALREADY_ACTIVE: t('stake_already_active', 'Stake is already active'),
  STAKE_ALREADY_COMPLETED: t('stake_already_completed', 'Stake is already completed'),
  STAKE_ALREADY_CANCELLED: t('stake_already_cancelled', 'Stake is already cancelled'),
  STAKE_AMOUNT_LESS_THAN_MIN: t('stake_amount_less_than_min', 'Stake amount is less than the minimum'),
  STAKE_AMOUNT_GREATER_THAN_MAX: t('stake_amount_greater_than_max', 'Stake amount is greater than the maximum'),
  STAKE_INSUFFICIENT_FUNDS: t('stake_insufficient_funds', 'Insufficient funds for staking'),
  ...commonErrors(t),
})

export const futuresErrors = (t) => ({
  FUTURES_ASSET_NOT_FOUND: t('errors_futuresAssetNotFound', 'Asset not found'),
  FUTURES_INVALID_TIMEFRAME: t('errors_futuresInvalidTimeframe', 'Invalid time frame'),
  FUTURES_FAILED_TO_GET_KLINE: t('errors_futuresFailedKline', 'Failed to get klines'),
  FUTURES_BALANCE_NOTFOUND: t('errors_futuresBalanceNotFound', 'Futures balance not found'),
  FUTURES_INSUFFICIENT_BALANCE: t('errors_futuresInsufficientBalance', 'Insufficient balance to open position'),
  FUTURES_INSUFFICIENT_BALANCE_TO_TRANSFER: t('errors_futuresInsufficientBalanceToTransfer', 'Insufficient balance to transfer'),
  FUTURES_POSITION_NOT_FOUND: t('errors_futuresPositionNotFound', 'Position not found'),
  FUTURES_LEVERAGE_TOO_HIGH: t('errors_futuresLeverageTooHigh',  'Maximum leverage for your account is {{max}}'),
  FUTURES_LEVERAGE_INVALID: t('errors_futuresLeverageInvalid', 'Invalid leverage value'),
  FUTURES_MIN_POSITION_SIZE: t('errors_futuresMinPositionSize', 'Minimum position size is {{min}}'),
  FUTURES_SIZE_INVALID: t('errors_futuresSizeInvalid', 'Invalid size value'),
  FUTURES_PRICE_INVALID: t('errors_futuresPriceInvalid', 'Invalid price value'),
  FUTURES_STOP_LOSS_TOO_HIGH_LONG: t('errors_futuresStopLossTooHighLong', 'Stop Loss must be lower than entry price ({{entry}}) for LONG positions'),
  FUTURES_STOP_LOSS_TOO_CLOSE_LONG: t('errors_futuresStopLossTooCloseLong',  'Stop Loss must be at least {{min}} below entry price for LONG positions'),
  FUTURES_STOP_LOSS_TOO_HIGH_SHORT: t('errors_futuresStopLossTooHighShort', 'Stop Loss must be higher than entry price ({{entry}}) for SHORT positions'),
  FUTURES_STOP_LOSS_TOO_CLOSE_SHORT: t('errors_futuresStopLossTooCloseShort', 'Stop Loss must be at least {{min}} above entry price for SHORT positions'),
  FUTURES_TAKE_PROFIT_TOO_LOW: t('errors_futuresTakeProfitTooLow', 'Take Profit must be higher than entry price ({{entry}}) for LONG positions'),
  FUTURES_TAKE_PROFIT_TOO_CLOSE_LONG: t('errors_futuresTakeProfitTooCloseLong', 'Take Profit must be at least {{min}} above entry price for LONG positions'),
  FUTURES_TAKE_PROFIT_TOO_HIGH: t('errors_futuresTakeProfitTooHigh', 'Take Profit must be lower than entry price ({{entry}}) for SHORT positions'),
  FUTURES_TAKE_PROFIT_TOO_CLOSE_SHORT: t('errors_futuresTakeProfitTooCloseShort', 'Take Profit must be at least {{min}} below entry price for SHORT positions'),
  FUTURES_POSITION_CLOSED: t('errors_futuresPositionClosed', 'Position is already closed'),
})

export const withdrawalErrors = (t) => ({
  'ERROR_USER_CANNOT_WITHDRAW': t('withdrawal_errors_user_cannot_withdraw', 'Oops! You cannot withdraw at the moment. Please contact support.'),
  ...commonErrors(t),
})