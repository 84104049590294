import {useGetBrandQuery} from "#services/api/common";
import {useDispatch, useSelector} from "react-redux";
import {selectBrand, setBrand} from "#store/slices/brandSlice";
import {useEffect, useMemo} from "react";

const useBrand = () => {
  const brand = useSelector(selectBrand);
  const dispatch = useDispatch();
  const skip = !!brand?.id;
  const {data, isError, isLoading} = useGetBrandQuery(
    null,
    {skip}
  );
  useEffect(() => {
    if (data && !isError) {
      dispatch(setBrand(data));
    }
  }, [data, isError, dispatch]);

  return useMemo(()=>({brand, isError, isLoading}),[brand, isError, isLoading]);
};

export default useBrand;