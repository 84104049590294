export default function removeTrailingZeros(formattedValue, decimalSeparator) {
  const [integerPart, fractionalPart] = formattedValue.split(decimalSeparator);
  if (fractionalPart) {
    const trimmedFractionalPart = fractionalPart.replace(/0+$/, '');
    return trimmedFractionalPart
      ? `${integerPart}${decimalSeparator}${trimmedFractionalPart}`
      : integerPart;
  }
  return integerPart;

}