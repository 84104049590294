import ActionsButtonsNavigation from "#components/common/ActionsButtonsNavigation";
import {Card, CardContent, CardHeader} from "#ui/card";
import {Trans} from "react-i18next";
import {memo} from "react";
import {useGetFiatTotalQuery} from "#services/api/accounts";
import {Spinner} from "#ui/spinner";
import CurrencyFormat from "#ui/currency-format";
import useCardVariants from "#hooks/useCardVariants";
import {useIsMobile} from "#hooks/use-mobile";


const TotalBalance = () => {
    const {data, isLoading} = useGetFiatTotalQuery()
    const {totalFiatAmount} = data || {}
    const cardVariant = useCardVariants()
    const isMobile = useIsMobile()

    return (
      <Card className={cardVariant}>
        <CardHeader className="pb-2">
          <small className="text-center md:text-left rtl:text-right">
            <Trans>Total Balance ≈</Trans>
          </small>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col md:flex-row items-stretch md:items-center gap-6 md:gap-10 justify-between">

            {isLoading ? <Spinner variant={'natural'}/> :

              <h1
                className="text-4xl sm:text-5xl flex items-start justify-center  md:justify-start gap-1 font-bold text-center md:text-left rtl:text-right">
                <small className={'text-muted'}>
                  $
                </small>
                <CurrencyFormat
                  value={totalFiatAmount || 0}
                  decimalScale={2}
                /></h1>
            }
            <ActionsButtonsNavigation compact={isMobile} variant="natural"/>
          </div>
        </CardContent>
      </Card>
    )
  }
;

export default memo(TotalBalance);