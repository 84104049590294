// External imports
import React, {memo} from "react";
import {Trans} from "react-i18next";
import {ArrowLeft} from "lucide-react";

// Internal imports - UI components
import {
  ResponsiveDialog,
  ResponsiveDialogContent,
  ResponsiveDialogDescription,
  ResponsiveDialogHeader,
  ResponsiveDialogTitle,
} from "#ui/responsive-dialog";
import {Button} from "#ui/button";

import StepProgress from "./StepProgress";
import SelectDocumentTypeStep from "./SelectDocumentTypeStep";
import UploadDocumentStep from "./UploadDocumentStep";
import TakeSelfieStep from "./TakeSelfieStep";
import SuccessStep from "./SuccessStep";

import {
  StartVerificationButton, ResendVerificationButton, StepActions
} from "./VerificationButtons";
import useVerification from "#hooks/useVerification";


// verificationSteps.js
export const verificationSteps = [{
  title: <Trans>Select Document Type</Trans>,
  Component: SelectDocumentTypeStep,
  isValid: ({country, documentType}) => Boolean(country && documentType),
  navType: 'next'
}, {
  title: <Trans>Upload Document</Trans>,
  Component: UploadDocumentStep,
  isValid: ({document}) => Boolean(document),
  navType: 'next'
}, {
  title: <Trans>Take Selfie</Trans>,
  Component: TakeSelfieStep,
  isValid: ({selfie}) => Boolean(selfie),
  navType: 'submit'
}, {
  title: '', Component: SuccessStep, isValid: () => true, navType: 'close'

}];

const SendVerificationDialog = memo(({defaultCountry, isRejected}) => {
  // State
  const {
    step,
    verificationData,
    updateField,
    currentStep,
    StepComponent,
    canSubmit,
    canGoNext,
    goToPrevStep,
    goToNextStep,
    buttonType,
    isUploading,
    upload,
    clear
  } = useVerification(defaultCountry, verificationSteps);

  return (<ResponsiveDialog onOpenChange={(is) => !is && clear()}>
    {isRejected ? <ResendVerificationButton/> : <StartVerificationButton/>}

    <ResponsiveDialogContent onOpenAutoFocus={(e) => e.preventDefault()}>
      <ResponsiveDialogHeader>
        <ResponsiveDialogTitle>
          <Trans>Verify your identity</Trans>
        </ResponsiveDialogTitle>

        <ResponsiveDialogDescription>
          <Trans>Complete the following steps to verify your identity</Trans>
        </ResponsiveDialogDescription>

        <StepProgress currentStep={step} totalSteps={verificationSteps.length}/>
      </ResponsiveDialogHeader>
      <div className={'px-4 pb-8 sm:px-0 sm:pb-0'}>
        <h3 className="font-bold">{currentStep.title}</h3>

        <div className="space-y-6">
          <StepComponent
            verificationData={verificationData}
            updateField={updateField}
          />

          <div className="flex justify-between gap-3">
            {step > 0 && (buttonType === 'submit' || buttonType === 'next') && (<Button
              variant="outline"
              size="icon"
              className="shrink-0"
              onClick={goToPrevStep}
            >
              <ArrowLeft className="w-4 h-4"/>
              <span className={'sr-only'}>
                <Trans>
                  Go back
                </Trans>
            </span>
            </Button>)}

            <StepActions
              type={buttonType}
              canSubmit={canSubmit}
              canNext={canGoNext()}
              onSubmit={upload}
              onNext={goToNextStep}
              isLoading={isUploading}
            />
          </div>
        </div>
      </div>
    </ResponsiveDialogContent>
  </ResponsiveDialog>);
});

export default SendVerificationDialog;