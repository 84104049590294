import base from "#services/api/base";

const common = base.injectEndpoints({
  endpoints: (builder) => ({
    getBrand: builder.query({
      query: () => ({
        url: '/api/',
        method: 'GET'
      }),
      providesTags: ['Brand']
    }),
    getTheme: builder.query({
      query: () => ({
        url: '/api/theme',
        method: 'GET'
      }),
      providesTags: ['Theme']
    }),
    resendOtp: builder.mutation({
      query: ({type, userId, ...data}) => ({
        url: '/api/resend-otp',
        method: 'POST',
        body: {
          type,
          userId,
          data: data,
        }
      }),
    }),
    currencies: builder.query({
      query: () => ({
        url: '/api/currencies',
        method: 'GET'
      }),
      providesTags: ['Currencies']
    }),
    permissions: builder.query({
      query(arg) {
        return {
          url: '/api/permissions',
          method: 'GET',
        }
      }
    }),
    notifications: builder.query({
      query(arg) {
        return {
          url: '/api/notifications',
          method: 'GET',
        }
      }
    }),
    uploadAvatar: builder.mutation({
      query: (file) => {
        const formData = new FormData();
        formData.append('avatar', file);
        return {
          url: '/api/upload-avatar',
          method: 'POST',
          body: formData,
          formData: true,
        }
      },
    }),
    uploadVerification: builder.mutation({
      query: ({document, selfie, documentType, country}) => {
        const formData = new FormData();
        formData.append('document', document);
        formData.append('selfie', selfie);
        formData.append('documentType', documentType);
        formData.append('country', country);
        return {
          url: '/api/upload-verification',
          method: 'POST',
          body: formData,
          formData: true,
        }
      },
    }),
    getReferrals: builder.query({
      query: () => {
        return {
          url: '/api/referrer',
          method: 'GET',
        };
      },
    }),


  })
})
export const {
  useGetBrandQuery,
  useGetThemeQuery,
  useResendOtpMutation,
  usePermissionsQuery,
  useNotificationsQuery,
  useUploadAvatarMutation,
  useUploadVerificationMutation,
  useCurrenciesQuery,
  useGetReferralsQuery
} = common;

