import {Card} from "#ui/card";
import {Skeleton} from "#ui/skeleton";
import TextGradient from "#ui/TextGradient";

export const StatCard = ({title, value, icon: Icon, isLoading, className}) => {
  return (
    <Card className={`${className} transition-all p-5 duration-300`}>
      <div className="flex flex-row items-center pb-0">
        <div>
          <p className="text-xs text-muted-foreground mb-1">{title}</p>
          {isLoading ? (
            <Skeleton className="h-9 w-24"/>
          ) : (
            <TextGradient asChild>
              <p className="text-4xl font-bold leading-none from-foreground to-primary/70">
                {value}
              </p>
            </TextGradient>
          )}
        </div>
        <span className="ml-auto text-4xl rounded-md p-2 inline-block bg-primary/20 text-primary">
          <Icon className="w-6 h-6"/>
        </span>
      </div>
    </Card>
  );
};