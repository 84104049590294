import {format, isToday, isYesterday, parseISO} from 'date-fns'
import {useTranslation} from "react-i18next";

const FormatDatetime = ({date:serverDate}) => {
  const {t} = useTranslation();
  if (!serverDate) {
    return ''
  }
  const date = parseISO(serverDate)
  if (isToday(date)) {
    return `${t("Today")}, ${format(date, 'HH:mm:ss',)}`
  } else if (isYesterday(date)) {
    return `${t("Yesterday")}, ${format(date, 'HH:mm:ss',)}`
  } else {
    return date.toLocaleString()
  }
};

export default FormatDatetime;