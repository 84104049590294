import {Trans} from "react-i18next";
import formatDecimal from "#lib/formatDecimal";
import {
  CloseLimitButton,
  ClosePositionButton,
  CloseReason,
  LiquidationPrice,
  MarketPrice,
  OrderType,
  PnL,
  TpSl,
  Type
} from "#pages/Futures/components/Orders/PositionDisplayComponents";
import {useMemo} from "react";
import FormatDatetime from "#components/common/format-datetime";


export default function usePositionStatus(status) {
  const statusMap = {
    OPEN: {
      notFound: <Trans>Positions not found</Trans>,
      headers: [
        <Trans>Symbol</Trans>, <Trans>Side</Trans>, <Trans>Size</Trans>, <Trans>Entry Price</Trans>,
        <Trans>Mark Price</Trans>, <Trans>PnL</Trans>, <Trans i18nKey={'LiqPrice'}>Liq. Price</Trans>,
        <Trans>TP/SL</Trans>, <Trans>Margin</Trans>, <Trans>Leverage</Trans>, true
      ],
      renders: [
        (position) => position.assetSymbol,
        (position) => <Type type={position.type}/>,
        (position) => formatDecimal(position.size, position.quantityPrecision),
        (position) => formatDecimal(position.entryPrice, position.pricePrecision),
        (position) => (
          <MarketPrice
            markPrice={position.markPrice}
            id={position.id}
            pricePrecision={position.pricePrecision}
          />
        ),
        (position) => <PnL pnl={position?.pnl} id={position?.id}/>,
        (position) => (
          <LiquidationPrice
            liquidationPrice={position?.liquidationPrice} id={position?.id}
            pricePrecision={position?.pricePrecision}
          />
        ),
        (position) => <TpSl position={position}/>,
        (position) => formatDecimal(position.margin),
        (position) => formatDecimal(position.leverage) + 'x',
        (position) => <ClosePositionButton position={position}/>
      ]
    }, PENDING: {
      notFound: <Trans>Orders not found</Trans>,
      headers: [
        <Trans>Create date</Trans>,
        <Trans>Symbol</Trans>,
        <Trans>Side</Trans>,
        <Trans>Type</Trans>,
        <Trans>Size</Trans>, <Trans>Entry Price</Trans>,
        <Trans>TP/SL</Trans>, <Trans>Margin</Trans>,
        <Trans>Leverage</Trans>,
        true
      ],
      renders: [
        (position) => <FormatDatetime date={position.createdAt}/>,
        (position) => position.assetSymbol,
        (position) => <Type type={position.type}/>,
        (position) => <OrderType orderType={position.orderType}/>,
        (position) => formatDecimal(position.size, position.quantityPrecision),
        (position) => formatDecimal(position.entryPrice, position.pricePrecision),
        (position) => <TpSl position={position}/>,
        (position) => formatDecimal(position.margin),
        (position) => formatDecimal(position.leverage) + 'x',
        (position) => <CloseLimitButton order={position}/>
      ]
    }, CLOSED: {
      notFound: <Trans>History not found</Trans>,
      headers: [
        <Trans>Create date</Trans>,
        <Trans>Close date</Trans>,
        <Trans>Close reason</Trans>,
        <Trans>Symbol</Trans>,
        <Trans>Side</Trans>,
        <Trans>Size</Trans>,
        <Trans>Entry Price</Trans>,
        <Trans>Mark Price</Trans>,
        <Trans>PnL</Trans>,
        <Trans>Margin</Trans>,
        <Trans>Leverage</Trans>,
      ],
      renders: [
        (position) => <FormatDatetime date={position.createdAt}/>,
        (position) => <FormatDatetime date={position.closedAt}/>,
        (position) => <CloseReason reason={position.closeReason}/>,
        (position) => position.assetSymbol,
        (position) => <Type type={position.type}/>,
        (position) => formatDecimal(position.size, position.quantityPrecision),
        (position) => formatDecimal(position.entryPrice, position.pricePrecision),
        (position) => formatDecimal(position.markPrice, position.pricePrecision),
        (position) => <PnL pnl={position.pnl} id={position.id}/>,
        (position) => formatDecimal(position.margin),
        (position) => formatDecimal(position.leverage) + 'x',
      ]
    }
  }
  const result = statusMap[status];

  return useMemo(() => result, [result]);
}