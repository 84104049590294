import WalletLayout from "#layouts/WalletLayout";
import {ErrorRoute} from "#src/router/ErrorBoundary";
import {Status, Profile} from "#pages/profile";

const profileRouter = {
  path: "/profile",
  element: <WalletLayout/>,
  errorElement: <ErrorRoute/>,
  children: [
    {
      index: true,
      element: <Profile/>
    },
    {
      path: "status",
      element: <Status/>
    },

  ]
}

export default profileRouter;