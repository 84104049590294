import {Landmark, Wallet} from "lucide-react";
import {Trans} from "react-i18next";

export const AccountType = ({isMain, side}) => {
  const Icon = isMain ? Landmark : Wallet;

  return (
    <div className="flex w-full items-center gap-2 rounded-md bg-secondary text-secondary-foreground p-3">
      <span className="p-2 bg-background rounded-md">
        <Icon size={18}/>
      </span>
      <div className="text-sm">
        <small>{side}</small>
        <div className="flex items-center gap-1">
          {isMain ? <Trans>Main Wallet</Trans> : <Trans>Futures Wallet</Trans>}
        </div>
      </div>
    </div>
  );
};