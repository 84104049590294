import {Card} from "#ui/card";
import TransactionListSkeleton from "#components/skeletons/TransactionListSkeleton";
import {Trans} from "react-i18next";
import {TransactionListItem} from "#components/wallet/TransactionListItem";



const TransactionList = ({transactions, isLoading, onViewDetails}) => {
  if (isLoading) {
    return <TransactionListSkeleton/>
  }
  if (!transactions.length) {
    return (
      <Card className={'text-center flex justify-center items-center p-3 h-24'}>
        <Trans>
          No transactions found
        </Trans>
      </Card>
    )
  }
  return (
    <div className={'flex flex-col gap-3'}>
      {
        transactions.map((transaction) => (
          <TransactionListItem
            key={transaction.id}
            transaction={transaction}
            onViewDetails={onViewDetails}
          />
        ))
      }
    </div>
  );
};

export default TransactionList;