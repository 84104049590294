import {memo} from "react";
import Timer from "#components/common/Timer";
import {Trans} from "react-i18next";
import {CircleOff} from "lucide-react";
import {Button} from "#ui/button";
import {Link} from "react-router-dom";

const SwapReachedLimit = ({nextAvailableTime}) => {

  return (<div className={'flex items-center py-4 justify-center flex-col gap-3'}>
    <CircleOff className={'text-destructive'} size={70}/>
    <h1 className={'text-center font-semibold text-xl'}>
      <Trans>
        You've reached the limit
      </Trans>
    </h1>
    <p className={'text-center'}>
      <Trans
        i18nKey={'swapLimitReached'}
      >
        You've reached the limit of swaps for this period. Please try again later.
      </Trans>
    </p>

    <Timer nextAvailableTime={nextAvailableTime}/>
    <Button variant={'link'} className={'mt-2'} size={'lg'} asChild>
      <Link to={'/wallet'}>
        <Trans>
          Back to Wallet
        </Trans>
      </Link>
    </Button>
  </div>);
};

export default memo(SwapReachedLimit);