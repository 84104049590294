// Constants
import {Skeleton} from "#ui/skeleton";
import {useNavigate} from "react-router-dom";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from "#ui/alert-dialog";
import {Trans} from "react-i18next";
import {ButtonWithLoading} from "#ui/button";
import {useCancelStakingMutation, useCreateStakingMutation} from "#services/api/staking";
import useErrorMessages from "#hooks/useErrorMessages";
import {ErrorNameSpaces} from "#src/constants/errors";

const STAKE_STATUSES = {
  ACTIVE: 'active'
};

// Types
/**
 * @typedef {Object} Stake
 * @property {string} id
 * @property {string} status
 * @property {string} plan
 * @property {number} amount
 */

/**
 * @typedef {Object} StakeModalButtonProps
 * @property {boolean} isLoading
 * @property {Stake} stake
 */

// Shared hooks
const useStakeErrorHandler = () => {
  const {showError} = useErrorMessages(ErrorNameSpaces.STAKE);
  const navigate = useNavigate();

  return {
    handleStakeError: (error) => {
      const errorData = error?.data;
      showError(errorData?.code);
    },
    navigateAfterSuccess: (path) => navigate(path)
  };
};

// Shared components
const StakeAlertDialog = ({
                            trigger,
                            title,
                            description,
                            confirmText,
                            confirmVariant = 'default',
                            onConfirm
                          }) => (
  <AlertDialog>
    <AlertDialogTrigger asChild>
      {trigger}
    </AlertDialogTrigger>
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>
          <Trans>Are you absolutely sure?</Trans>
        </AlertDialogTitle>
        <AlertDialogDescription>
          {description}
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancel>
          <Trans>Cancel</Trans>
        </AlertDialogCancel>
        <AlertDialogAction variant={confirmVariant} onClick={onConfirm}>
          {confirmText}
        </AlertDialogAction>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
);

// Feature components
const CancelStakeButton = ({stakeId}) => {
  const [cancelStake, {isLoading}] = useCancelStakingMutation();
  const {handleStakeError, navigateAfterSuccess} = useStakeErrorHandler();

  const handleCancel = async () => {
    try {
      await cancelStake(stakeId);
      navigateAfterSuccess(`/wallet/staking/archived/${stakeId}`);
    } catch (error) {
      handleStakeError(error);
    }
  };

  const trigger = (
    <ButtonWithLoading
      loading={isLoading}
      className="w-full mt-5 py-5"
      size="lg"
      variant="destructiveOutline"
    >
      <Trans>Cancel stake</Trans>
    </ButtonWithLoading>
  );

  return (
    <StakeAlertDialog
      trigger={trigger}
      description={<Trans i18nKey={'userStakeCancelAlertDescr'}>
        This action cannot be undone. If you cancel the stake, you will lose the rewards.
      </Trans>}
      confirmText={<Trans>Continue</Trans>}
      confirmVariant="destructive"
      onConfirm={handleCancel}
    />
  );
};

const RepeatStakeButton = ({planId, amount}) => {
  const [repeatStake, {isLoading}] = useCreateStakingMutation();
  const {handleStakeError, navigateAfterSuccess} = useStakeErrorHandler();

  const handleRepeat = async () => {
    try {
      const response = await repeatStake({planId, amount}).unwrap();
      navigateAfterSuccess(`/wallet/staking/active/${response.id}`);
    } catch (error) {
      handleStakeError(error);
    }
  };

  const trigger = (
    <ButtonWithLoading
      loading={isLoading}
      className="w-full mt-5 py-5"
      size="lg"
      variant="outline"
    >
      <Trans>Repeat stake</Trans>
    </ButtonWithLoading>
  );

  return (
    <StakeAlertDialog
      trigger={trigger}
      description={
        <Trans i18nKey={'userStakeRepeatAlertDescr'}>
          You will be charged the staking amount. You can cancel the staking and get back the principal investment.
        </Trans>
      }
      confirmText={<Trans>Repeat</Trans>}
      onConfirm={handleRepeat}
    />
  );
};

// Main component
export const UserStakeModalButton = ({isLoading, stake}) => {
  if (isLoading) {
    return <Skeleton className="w-full mt-5 h-11 rounded-md"/>;
  }

  const isActive = stake.status === STAKE_STATUSES.ACTIVE;

  return isActive ? (
    <CancelStakeButton stakeId={stake.id}/>
  ) : (
    <RepeatStakeButton planId={stake.plan} amount={stake.amount}/>
  );
};

