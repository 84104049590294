import {useSearchParams} from "react-router-dom";
import {useLocalStorage} from "@uidotdev/usehooks";
import {useEffect} from "react";

const useReferralCode = () => {
  const [params] = useSearchParams();
  const [refCode, setReferralCode] = useLocalStorage('refCode');
  useEffect(() => {
    const referralCode = params.get('refCode');
    if (referralCode && !refCode) {
      setReferralCode(referralCode);
    }
  }, [params, refCode, setReferralCode]);

  return [refCode, setReferralCode];
}

export default useReferralCode;