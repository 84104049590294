import {useTranslation} from "react-i18next";
import {useGetReferralsQuery} from "#services/api/common";
import Container from "#ui/container";
import {LevelCardList, LevelProgress, ReferralHeader, StatsSection} from "#pages/wallet/Referral/components";


const Referral = () => {
  const {t} = useTranslation();
  const {data, isLoading} = useGetReferralsQuery();

  const {
    referralsCount = 0,
    referrerLink = "",
    totalEarnings = "0",
    referrerLevels = [],
    currentLevelReward = "0.00",
    currentLevel = {
      id: 1,
      minReferrals: 0,
      reward: "0.00",
      levelName: "",
      icon: ""
    }
  } = data || {};


  return (
    <Container>
      <ReferralHeader
        referrerLink={referrerLink}
        currentLevelReward={currentLevelReward}
        isLoading={isLoading}
      />

      <StatsSection
        currentLevel={currentLevel}
        totalEarnings={totalEarnings}
        referralsCount={referralsCount}
        isLoading={isLoading}
      />

      <div>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">{t("Referral Levels")}</h2>
          <LevelProgress
            referralsCount={referralsCount}
            currentLevel={currentLevel}
            referrerLevels={referrerLevels}
          />
        </div>
        <LevelCardList
          levels={referrerLevels}
          currentLevel={currentLevel}
          isLoading={isLoading}
        />

      </div>
    </Container>
  );
};

export default Referral;