import React, {memo} from "react";
import {Check, X} from "lucide-react";
import {Trans} from "react-i18next";

const RequirementsList = memo(({requirements}) => (
  <div className="space-y-2">
    <div>
      <ul className="space-y-2">
        {requirements.do.map((req, index) => (
          <li key={index} className="flex items-start gap-2 text-sm">
            <Check className="w-4 h-4 text-success mt-0.5 flex-shrink-0"/>
            {req}
          </li>
        ))}
      </ul>
    </div>
    <div>
      <ul className="space-y-2">
        {requirements.dont.map((req, index) => (
          <li key={index} className="flex items-start gap-2 text-sm">
            <X className="w-4 h-4 text-destructive mt-0.5 flex-shrink-0"/>
            {req}
          </li>
        ))}
      </ul>
    </div>
    <div className="bg-secondary p-2 rounded text-sm text-secondary-foreground">
      <div><Trans>File size: 10KB - 1024KB</Trans></div>
      <div><Trans>Formats: JPG, JPEG, PNG</Trans></div>
    </div>
  </div>
));

export default RequirementsList;