import {Trans} from "react-i18next";
import {OrderFormInput} from "./OrderFormInput";

export const TakeProfitStopLoss = ({
                                     isTpSlVisible,
                                     precision,
                                     asset,
                                     stopLoss,
                                     takeProfit,
                                     onStopLossChange,
                                     onTakeProfitChange,
                                   }) => {
  if (!isTpSlVisible) return null;
  return (
    <div className={'flex gap-2'}>
      <OrderFormInput
        value={takeProfit}
        onChange={onTakeProfitChange}
        precision={precision}
        label={<Trans>Take Profit</Trans>}
        asset={asset}
      />
      <OrderFormInput
        value={stopLoss}
        onChange={onStopLossChange}
        precision={precision}
        label={<Trans>Stop Loss</Trans>}
        asset={asset}
      />
    </div>
  );
};

