import { memo } from 'react';
import { Skeleton } from '#ui/skeleton';
import {
  Carousel,
  CarouselContent,
  CarouselItem
} from '#ui/carousel';

const StatusCarouselSkeleton = memo(() => (
  <Carousel className="w-full">
    <CarouselContent>
      {[1, 2, 3, ].map((item) => (
        <CarouselItem
          key={item}
          className={"basis-full md:basis-1/2 lg:basis-1/3"}
        >
          <div className="p-1">
            <Skeleton className="aspect-square" />
          </div>
        </CarouselItem>
      ))}
    </CarouselContent>
  </Carousel>
));

StatusCarouselSkeleton.displayName = 'StatusCarouselSkeleton';

export default StatusCarouselSkeleton;
