import {Card} from "#ui/card";
import TransactionStatusIcon from "./TransactionStatusIcon";
import TransactionInfo from "./TransactionInfo";
import FormatDatetime from "#components/common/format-datetime";
import TransactionValue from "./TransactionValue";
import TransactionDirection from "./TransactionDirection";

export const TransactionListItem = ({
                                      transaction,
                                      onViewDetails
                                    }) => {
  return (
    <Card onClick={() => onViewDetails(transaction?.id)} className={'p-3 cursor-pointer'}>
      <div className={'flex items-center justify-between'}>
        <div className={'flex items-center gap-2'}>
          <TransactionStatusIcon transaction={transaction}/>
          <div className={'flex flex-col'}>
            <span><TransactionInfo transaction={transaction}/></span>
            <small><FormatDatetime date={transaction?.createdAt}/> </small>
          </div>
        </div>
        <div className={'flex flex-col items-end'}>
          <TransactionValue transaction={transaction}/>
          <TransactionDirection transaction={transaction}/>
        </div>
      </div>
    </Card>
  );
}