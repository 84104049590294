import React from 'react';
import {Trans} from 'react-i18next';
import {SectionHeader} from "#pages/Home/components";

export const SecurityHeader = () => (
  <div className="text-center mb-16">
    <SectionHeader
      title={<Trans>Bank-Grade Security</Trans>}
      description={<Trans>Your assets are protected by multiple layers of security</Trans>}
    />
  </div>
);