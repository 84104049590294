// components/transactions/TransactionListSkeleton.jsx
import {Card} from "#ui/card";
import {Skeleton} from "#ui/skeleton";

const TransactionListItemSkeleton = () => {
  return (
    <Card className={'p-3'}>
      <div className={'flex items-center justify-between'}>
        <div className={'flex items-center gap-2'}>
          {/* Status icon skeleton */}
          <Skeleton className="h-8 w-8 rounded-md"/>
          <div className={'flex flex-col gap-1'}>
            {/* Transaction info skeleton */}
            <Skeleton className="h-5 w-[200px]"/>
            {/* Date skeleton */}
            <Skeleton className="h-4 w-[100px]"/>
          </div>
        </div>
        <div className={'flex flex-col items-end gap-1'}>
          {/* Amount skeleton */}
          <Skeleton className="h-5 w-[120px]"/>
          {/* Direction skeleton */}
          <Skeleton className="h-4 w-[80px]"/>
        </div>
      </div>
    </Card>
  );
};

const TransactionListSkeleton = ({count = 10}) => {
  return (
    <div className={'flex flex-col gap-3'}>
      {Array(count).fill(null).map((_, index) => (
        <TransactionListItemSkeleton key={index}/>
      ))}
    </div>
  );
};

export default TransactionListSkeleton;