import {useGetUserStakeQuery} from "#services/api/staking";
import {ResponsiveDialogBase} from "#ui/responsive-dialog";
import CurrencyIcon from "#ui/crypto/CurrencyIcon";
import {Skeleton} from "#ui/skeleton";
import {Badge} from "#ui/badge";
import {Trans} from "react-i18next";
import {UserStakeContent} from "./UserStakeContent";

const Status = ({stake}) => {
  const badgeProps = {
    active: {children: <Trans>Active</Trans>, variant: 'secondary'}, completed: {
      children: <Trans>Completed</Trans>, variant: 'default'
    }, cancelled: {
      children: <Trans>Cancelled</Trans>, variant: 'destructive'
    }
  }
  return (<Badge as={'span'} className={'text-base mr-3'} {...(badgeProps?.[stake?.status] || {})} />)
}

const TitleSkeleton = () => (<p className={'flex items-center gap-2 justify-between'}>
    <span className={'flex items-center gap-1'}>
            <Skeleton as={'span'} className={'h-8 w-8 rounded-full'}/>
            <Skeleton as={'span'} className={'h-4 w-20'}/>
    </span>
  <Skeleton as={'span'} className={'h-6 w-20'}/>
</p>)
const Title = ({stake, isLoading}) => {
  if (isLoading) {
    return (<TitleSkeleton/>)
  }
  return (<p className={'flex items-center gap-2 justify-between'}>
    <span className="flex items-center gap-1">
      <CurrencyIcon symbol={stake?.symbol} size="medium"/>
      {stake.name}
    </span>
    <Status stake={stake}/>
  </p>)
}

export const UserStakeModal = ({id, onClose}) => {
  const {data: stake = {}, isLoading, isFetching} = useGetUserStakeQuery(id, {
    skip: !id, refetchOnMountOrArgChange: true
  });
  const handleClose = (is) => {
    !is && onClose();
  }
  return (<ResponsiveDialogBase
    headerProps={{className: 'pb-2'}}
    title={<Title stake={stake} isLoading={isLoading || isFetching}/>}
    description={
      <span className={'sr-only'}>
        <Trans>
          Stake details for the selected plan
        </Trans>
      </span>
    }
    open={!!id} onOpenChange={handleClose}
  >
    <UserStakeContent stake={stake} isLoading={isLoading || isFetching}/>
  </ResponsiveDialogBase>);
};

