import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "#ui/table";
import usePositionStatus from "#hooks/usePositionStatus";
import OrderTableSkeleton from "#components/skeletons/OrderTableSkeleton";
import {CircleOff} from "lucide-react";


export const OrderTable = ({orders, isLoading, status}) => {
  const {headers, renders, notFound} = usePositionStatus(status);
  return (<Table>
    <TableHeader>
      <TableRow>
        {headers.map((header, i) => (<TableHead key={i}>{header}</TableHead>))}
      </TableRow>
    </TableHeader>
    <TableBody>
      {isLoading && <OrderTableSkeleton rows={orders.length} cels={headers.length}/>}
      {!isLoading && orders.map((position) => (<TableRow key={position.id}>
        {renders.map((render, i) => (<TableCell key={position.id + i}>{render(position)}</TableCell>))}
      </TableRow>))}
      {!isLoading && orders.length === 0 && <TableRow>
        <TableCell colSpan={headers.length}>

          <div className={'flex flex-col text-muted-foreground gap-2 justify-center items-center h-20'}>
            <CircleOff size={30}/>
            <p>
              {notFound}
            </p>
          </div>
        </TableCell>
      </TableRow>}
    </TableBody>
  </Table>)
}