// components/wallet/receive/NetworkSelectHeader.jsx
import { memo } from 'react';
import { Trans } from 'react-i18next';
import FormHeader from "#ui/form-header";

const NetworkSelectHeader = memo(({ children }) => (
  <>
    <FormHeader>
      <Trans>Select Network</Trans>
    </FormHeader>
    {children}
  </>
));

NetworkSelectHeader.displayName = 'NetworkSelectHeader';

export default NetworkSelectHeader;