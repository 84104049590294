import {Spinner} from "#ui/spinner";

const FullScreenLoading = ({...props}) => {
  return (
    <div className={'fixed top-0 left-0 z-50 w-full h-svh bg-background flex items-center justify-center'}>
      <Spinner size={'lg'} {...props}/>
    </div>
  );
};

export default FullScreenLoading;