import {ButtonIcon} from "#ui/button";
import {ChevronLeft} from "lucide-react";
import {Link} from "react-router-dom";

const OtpHeader = ({backTo, children}) => (
  <div className="flex items-center gap-2">
    <ButtonIcon className="w-8 h-8" variant="outline" asChild>
      <Link to={backTo}>
        <ChevronLeft/>
      </Link>
    </ButtonIcon>
    {children}
  </div>
);
export default OtpHeader;