import OtpForm from "#components/common/OtpForm";
import {useState} from "react";
import {AuthPasswordFormField, AuthPasswordMessage} from "#components/common/AuthForm";
import {Trans} from "react-i18next";

const ResetPasswordForm = ({userId, type, data}) => {
  const [password, setPassword] = useState("");

  return (
    <OtpForm
      userId={userId}
      type={type}
      data={{password, ...data}}
    >
      <AuthPasswordFormField
        name="password"
        label={
          <Trans i18nKey="auth_reset_password_new_password">
            New Password
          </Trans>
        }
        type="password"
        placeholder="•••••••••••"
        onChange={(e) => setPassword(e.target.value)}
        value={password}
        messageProps={{
          message:<AuthPasswordMessage/>
        }}
        required
      />
    </OtpForm>
  );
};

export default ResetPasswordForm;