export const currenciesMock = [
  {
    "name": "Toncoin",
    "symbol": "TON",
    "precision": 8,
    "rate": "5.396",
    "previousRate": "5.394",
    "rateStatus": "rise"
  },
  {
    "name": "Bitcoin",
    "symbol": "BTC",
    "precision": 8,
    "rate": "96540.72",
    "previousRate": "96524",
    "rateStatus": "rise"
  },
  {
    "name": "Ripple",
    "symbol": "XRP",
    "precision": 8,
    "rate": "2.2446",
    "previousRate": "2.245",
    "rateStatus": "fall"
  },
  {
    "name": "Solana",
    "symbol": "SOL",
    "precision": 8,
    "rate": "190.71",
    "previousRate": "190.67",
    "rateStatus": "rise"
  },
  {
    "name": "Litecoin",
    "symbol": "LTC",
    "precision": 8,
    "rate": "99.44",
    "previousRate": "99.48",
    "rateStatus": "fall"
  },
  {
    "name": "Hamster Combat",
    "symbol": "HMSTR",
    "precision": 8,
    "rate": "0.002725",
    "previousRate": "0.002726",
    "rateStatus": "fall"
  },
  {
    "name": "Tether",
    "symbol": "USDT",
    "precision": 4,
    "rate": "0.9996",
    "previousRate": "0.9996",
    "rateStatus": "stable"
  },
  {
    "name": "Polkadot",
    "symbol": "DOT",
    "precision": 8,
    "rate": "7.181",
    "previousRate": "7.182",
    "rateStatus": "fall"
  },
  {
    "name": "Chainlink",
    "symbol": "LINK",
    "precision": 8,
    "rate": "22.89",
    "previousRate": "22.9",
    "rateStatus": "fall"
  },
  {
    "name": "Ethereum",
    "symbol": "ETH",
    "precision": 18,
    "rate": "3437.58",
    "previousRate": "3437.83",
    "rateStatus": "fall"
  },
  {
    "name": "Uniswap",
    "symbol": "UNI",
    "precision": 8,
    "rate": "13.45",
    "previousRate": "13.459",
    "rateStatus": "fall"
  },
  {
    "name": "Binance Coin",
    "symbol": "BNB",
    "precision": 8,
    "rate": "672.37",
    "previousRate": "672.38",
    "rateStatus": "fall"
  },
  {
    "name": "Cardano",
    "symbol": "ADA",
    "precision": 6,
    "rate": "0.9185",
    "previousRate": "0.919",
    "rateStatus": "fall"
  }
]