import {createSlice} from "@reduxjs/toolkit";
import {loadStorageDataState} from "#lib/utils";

const loadUserState = () => {
  return loadStorageDataState(localStorage, 'user');
}


const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: loadUserState() || null,
    permissions: {},
    showOtp: false,
  },
  reducers: {
    setAuth: (state, action) => {
      try {
        localStorage.setItem('user', JSON.stringify(action.payload.user));
      } catch (e) {
        console.log(e)
      }
      state.user = action.payload.user;
      state.language = action.payload.language;
    },
    setUser: (state, action) => {
      localStorage.setItem('user', JSON.stringify(action.payload));
      state.user = action.payload;
    },

    setAvatar: (state, action) => {
      state.user.avatar = action.payload;
      localStorage.setItem('user', JSON.stringify(state.user));
    },

    setVerification: (state, action) => {
      state.user.verification = action.payload;
      localStorage.setItem('user', JSON.stringify(state.user));
    },

    setLanguage: (state, action) => {
      state.user = {...state.user, language: action.payload};
      localStorage.setItem('user', JSON.stringify(state.user));
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    clearAuth: state => {
      localStorage.removeItem('user');
      state.user = null;
    },
    setShowOtp: (state, action) => {
      state.showOtp = action.payload;
    },
  },
});


export const selectUser = state => state.auth.user;
export const selectUserId = state => state.auth?.user?.id;
export const selectLanguage = state => state.auth.user?.language;
export const selectPermissions = state => state.auth.permissions;
export const selectCanWithdraw = (state) => {
  const canWithdraw = state.auth.permissions?.c
  return typeof canWithdraw !== 'boolean' || canWithdraw

};
export const selectUserStatus = (state) => state.auth.user?.status;
export const selectShowOtp = (state) => state.auth.showOtp;
export const {
  setAuth, setUser, setToken,
  clearAuth,
  setLanguage,
  setPermissions, setAvatar,
  setVerification,
  setShowOtp
} = authSlice.actions;


export default authSlice;