import {Link, useLocation} from "react-router-dom";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle
} from "#ui/navigation-menu";
import {cn} from "#lib/utils";
import {SheetClose} from "#ui/sheet";

export const MobileNavigationItem = ({label, path, onClick, currentPath, isSheet = true}) => {
  const Component = path ? Link : 'button';
  const Wrapper = isSheet ? ({children}) => <SheetClose asChild children={children}/> : 'div';
  return (
    <li>
      <Wrapper>
        <Component to={path} onClick={onClick}
                   className={cn('p-2 w-full inline-block px-5 text-md rounded-md hover:bg-secondary text-foreground/70 text-left rtl:text-right',
                     {'bg-secondary/70': path && path === currentPath})}>
          {label}
        </Component>
      </Wrapper>
    </li>
  );
}

export const MobileNavigation = ({menuItems, isSheet, className}) => {
  const currentPath = useLocation().pathname;
  return (
    <nav className={cn('w-full px-5 py-2', className)}>
      <ul className={'w-full flex flex-col'}>
        {menuItems.map((item, i) => (
          <MobileNavigationItem key={i} {...item} currentPath={currentPath} isSheet={isSheet}/>
        ))}
      </ul>
    </nav>
  );
};

const Navigation = ({menuItems, ...props}) => {
  const location = useLocation();
  const isActive = (path) => location.pathname === path;
  return (
    <NavigationMenu {...props}>
      <NavigationMenuList orientation={props.orientation}>
        {menuItems.map((item, i) => (
          <NavigationMenuItem className={'w-full'} key={i}>
            <NavigationMenuLink active={isActive(item.path)} className={navigationMenuTriggerStyle()} asChild>
              <Link to={item.path}>
                {item.label}
              </Link>
            </NavigationMenuLink>
          </NavigationMenuItem>
        ))}
      </NavigationMenuList>
    </NavigationMenu>
  );
};

export default Navigation;