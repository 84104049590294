import {useDomain} from "#hooks/useDomain";
import PageHeader from "#components/common/PageHeader";

export const Aml = () => {
  const brand = useDomain();

  return (<>
    <PageHeader title={"AML/KYC policy"}/>
    <div className={'text-sm'}>
      <p>The purposes of the {brand}&rsquo;s Anti-Money Laundering, Counter-Terrorist Financing and Know Your
        Customer Policy (hereinafter &mdash; the &ldquo;AML/KYC Policy&ldquo;) is to identify, prevent and mitigate
        possible risks of {brand} being involved in illegal activity.
      </p>
      <br/>
      <p>To confirm with international and local regulations, {brand} has implemented effective internal procedures to
        prevent money laundering, terrorist financing, drug and human trafficking, proliferation of weapons of mass
        destruction, corruption and bribery and to react correspondingly in case of any form of suspicious activity
        from
        its Users.
      </p>
      <br/>
      <p>Money Laundering can be defined as: &lsquo;the conversion or transfer of property, knowing that such property
        is derived from criminal activity, or from an act of participation in such activity, for the purposes of
        concealing or disguising the illicit origin of the property, or assisting any person who is involved in the
        commission of such activity to evade the legal consequences of his action.&rsquo;
      </p>
      <br/>
      <p>Terrorist Financing can be defined as: &lsquo;the wilful provision or collection, by any means, directly or
        indirectly, of funds with the intention that the funds should be used, or in the knowledge that they are to be
        used, in order to carry out terrorist acts.&rsquo;
      </p>
      <p>All firms must adhere to the Money Laundering, Terrorist Financing and Transfer of Funds (Information on the
        Payer) Regulations 2017 (MLRs), and be aware of the guidance set down by the Joint Money Laundering Steering
        Group (JMLSG) which provides guidelines within the United Kingdom on how firms should conduct Due Diligence on
        their customers, and the recommendations from the Financial Action Task Force (FATF), an inter-governmental
        body
        whose purpose is the development and promotion of national and international policies to combat money
        laundering
        and terrorist financing.
      </p>
      <br/>
      <p>Other legislation in relation to AML/KYC are:
      </p>
      <br/>
      <p>5th Anti-Money Laundering Directive ((EU) 2018/843)
      </p>
      <p>The Proceeds of Crime Act 2002;</p>
      <p>Terrorism Act 2000 and 2001;</p>
      <p>Counter-Terrorism Act 2008;</p>
      <p>HM Treasury Sanction Notices;</p>
      <p>FCA Handbook.</p>
      <p>AML/KYC Policy includes:</p>
      <br/>
      <p>Verification procedure;</p>
      <p>Role of the Chief Compliance Officer;</p>
      <p>Transactions Monitoring;</p>
      <p>Risk Assessment:</p>
      <p>Country of Residence.</p>
      <p>&nbsp;</p>
      <p>Verification procedures</p>
      <br/>
      <p>To help fight the funding of terrorism and money laundering activities, the Law requires all financial
        institutions to obtain, verify and record information that identifies each person or entity who opens an
        Account. We are required by Law to ask you to provide your name, address, date of birth and other information
        about you, your organisation or persons related to your organisation that will allow us to identify you before
        we approve your Account. We also may ask you to provide certain identifying documents, such as your
        driver&rsquo;s license or passport or your organisation&rsquo;s articles of incorporation and may obtain
        credit
        and other consumer reports to assist us in verifying your identity and in determining whether you satisfy our
        account criteria. Unless you provide the requested information and documentation, we may not be able to open
        your Account.
      </p>
      <br/>
      <p>By applying for an {brand} Account, you agree to provide the information and documentation requested
        by {brand} and consent to {brand} acquisition of credit and other consumer reports about you for the purposes
        described above.
      </p>
      <br/>
      <p>The {brand} Customer due diligence (&ldquo;CDD&ldquo;) procedure requires the Users to provide {brand} with
        reliable, independent source documents to identify them and confirm their residential address. Such documents
        may include, for example, a national ID card, an international passport, a bank statement, a utility bill. For
        such purposes, {brand} reserves the right to collect the Users&rsquo; identification information for the
        purposes of compliance with its AML/CFT and KYC Policy.
      </p>
      <br/>
      <p>{brand} will take steps to confirm the authenticity of documents and information provided by
        Users. {brand} will also look to verify identification information through secondary sources,
        and {brand} reserves the right to investigate further the cases of Users where {brand} is not satisfied with
        the
        documentation submitted.
      </p>
      <p>{brand} reserves the right to verify the identity of the User on an ongoing basis, especially when its
        identification information has been changed or its activities appear suspicious (unusual for a particular
        User).
        In addition, {brand} reserves the right to request from the Users current documents, even if they have been
        authenticated in the past.
      </p>
      <br/>
      <p>Information about the user&rsquo;s identification will be collected, stored, shared and protected strictly in
        accordance with the {brand} Privacy Policy and relevant rules. The User agrees that if any information
        provided
        in the Account application changes, ceases to be true or becomes materially misleading (including, but not
        limited to, the ownership of the Account) then the User will notify {brand} of the changes.
      </p>
      <br/>
      <p>After confirming the identity of the user, {brand} may refuse to provide Services to the User
        if {brand} Services are suspected to be used for the purposes of conducting illegal activities.
      </p>
      <br/>
      <p>{brand} has a regulatory requirement to verify the sources of Fiat Money and/or Cryptocurrency to know that
        the
        sources of the Funds that Users use to trade with are legitimate. {brand} will require evidence of the source
        of
        Funds; this may include bank statements for Fiat Money or a video showing wallet transaction details for
        Cryptocurrency.
      </p>
      <br/>
      <p>Users who intend to use payment cards for the purpose of consuming Services must undergo a card check in
        accordance with the instructions available on the {brand} Site.
      </p>
      <br/>
      <p>{brand} uses independent third-party companies for the following:
      </p>
      <br/>
      <p>check Users against Politically Exposed Person&rsquo;s (PEP) and Sanctions Lists as well as checks for
        Adverse
        Media.
      </p>
      <p>assess User&rsquo;s cryptocurrency wallets with regards to AML investigation and compliance.
      </p>
      <p>{brand} reserves the right to terminate or refuse Accounts on the basis of information attained by the
        third-party companies this includes Users connected to &ldquo;high-risk&rdquo; wallets.
      </p>
      <br/>
      <p>Chief Compliance Officer</p>
      <br/>
      <p>The Chief Compliance Officer is the person, duly authorised by {brand}, whose duty is to ensure the effective
        implementation and enforcement of the AML/CFT and KYC Policy. It is the Chief Compliance Officer&rsquo;s
        responsibility to supervise all aspects of {brand} anti-money laundering and counter-terrorist financing
        procedures, in particular:
      </p>
      <br/>
      <p>collection of Users&rsquo; identification information;
      </p>
      <p>establishing and updating internal policies and procedures for the completion, review, submission and
        retention
        of all reports and records required under the applicable laws and regulations;
      </p>
      <p>monitoring transactions and investigating any significant deviations from normal activity;
      </p>
      <p>implementing a records management system for appropriate storage and retrieval of documents, files, forms and
        logs, updating risk assessment regularly, providing law enforcement with information as required under the
        applicable laws and regulations.
      </p>
      <p>The Chief Compliance Officer is the contact point for {brand} with law enforcement agencies for the purposes
        of
        prevention of money laundering, terrorist financing and other illegal activities.
      </p>
      <br/>
      <p>Transaction Monitoring</p>
      <p>{brand} Users must complete the {brand} identity verification process (who you are) before trading. After
        verification is completed the User consents to transaction Monitoring, {brand} analyses User&rsquo;s
        transactional patterns (what they do).
      </p>
      <br/>
      <p>{brand} relies on data analysis as a risk-assessment and suspicious activity detection tools. These tools
        perform a variety of compliance-related tasks, including capturing data, filtering, record-keeping,
        investigation management and reporting.
      </p>
      <br/>
      <p>In connection with the AML/KYC Policy, {brand} will:
      </p>
      <br/>
      <p>monitor all transactions. {brand} reserves the right to ensure that transactions of suspicious nature are
        reported to the proper law enforcement;
      </p>
      <p>request the User to provide any additional information and documents in case of suspicious transactions;
      </p>
      <p>suspend or terminate User&rsquo;s Account when {brand} has a reasonable suspicion that such User is engaged
        in
        illegal activity.
      </p>
      <p>However, the above list is not exhaustive and the Chief Compliance Officer will monitor
        Users&rsquo; transactions on a regular basis in order to define whether such transactions are to be reported
        and
        treated as suspicious.
      </p>
      <br/>
      <br/>
      <p>Risk Assessment</p>
      <br/>
      <p>In order to meet its obligations regarding Anti-Money Laundering (AML) and Counter Terrorist Financing
        (CTF) {brand} completes an annual AML Risk Assessment. The aim of the AML Risk Assessment is to prevent
        criminals using {brand} to launder money by highlighting risks and assessing controls {brand} has put in
        place.
        A risk-based approach is taken to identify Users and monitor how they use our Services.
      </p>
      <br/>
      <p>The Chief Compliance Officer is responsible for managing financial crime risks and making improvements in
        financial crime risk management through identifying the general and specific money laundering risks
        that {brand} is facing, determining how these risks are mitigated by the {brand} AML controls and establishing
        the residual risk that remains for {brand} .
      </p>
      <br/>
      <p>Country of Residence</p>
      <br/>
      <p>{brand} AML and KYC Policy sets out {brand} risk appetite with regard to AML/KYC. To reduce this
        risk {brand} does not accept clients who are resident in countries deemed to be above the {brand} risk
        appetite.
      </p>
      <br/>
      <p>The sources used with regards to the categorisation were:
      </p>
      <br/>
      <p>Transparency International;</p>
      <p>Know Your Country;</p>
      <p>FATF High Risk Jurisdictions;</p>
      <p>EU High Risk Jurisdictions;</p>
      <p>Countries where Digital Assets are prohibited or there are restrictions on trading;
      </p>
      <p>Countries where Digital Assets are not prohibited;
      </p>
      <p>Countries subject to UNSC Sanctions Regime.
      </p>
      <p>It must me clear that {brand} policy is that all clients resident in the below countries are banned and
        cannot
        be accepted as a client. Any clients that try to open an account will be denied and any funds returned to
        source.
      </p>
      <br/>
      <p>{brand} also does not accept any clients from disputed territories as they do not produce recognised official
        documents, these include:
      </p>
      <br/>
      <p>Donetsk People&rsquo;s Republic (DPR)/Luhansk People&rsquo;s Republic (LPR)
      </p>
      <p>Pridnestrovian Moldavian Republic</p>
      <p>Nagorno-Karabakh Republic</p>
      <p>Republic of Abkhazia</p>
      <p>Republic of Somaliland</p>
      <p>Republic of South Ossetia</p>
      <p>Turkish Republic of Northern Cyprus</p>
      <p>Republic of China (Taiwan)</p>
      <p>Passports issued by the Russian Federation in Crimea and passports issued to residents of Donetsk and Luhansk
        regions of Ukraine do not qualify for verification
      </p>
      <p>Republic of Kosovo</p>
      <p>Sahrawi Arab Democratic Republic</p>
      <p>Republic of Artsakh</p>
    </div>
  </>);
};

