import {Label} from "#ui/label";
import AmountField from "#components/wallet/AmountField";
import {forwardRef} from "react";
import {cn} from "#lib/utils";

export const OrderFormInput = forwardRef(({label, afterLabel, value, onChange, precision, asset, error}, ref) => {
  return (
    <div className={cn('w-full', {'text-destructive': !!error})} ref={ref}>
      <div className={'flex gap-0 items-end justify-between text-sm mb-2'}>
        <Label className={cn('text-muted-foreground', {'text-destructive': !!error})}>
          {label}
        </Label>
        {afterLabel && afterLabel}
      </div>
      <div className={'relative'}>
        <AmountField
          isError={!!error}
          placeholder={'0.' + '0'.repeat(precision)}
          value={value}
          onChange={onChange}
          className={'pr-12s'}
          decimals={precision}
        />
        <span className={'absolute right-3 top-1/2 transform -translate-y-1/2'}>
        {asset}
      </span>
      </div>
    </div>
  );
})
