import {PopoverTrigger} from "#ui/popover";
import {Button} from "#ui/button";
import CurrencyIcon from "#ui/crypto/CurrencyIcon";
import {ChevronDown} from "lucide-react";
import {memo} from "react";

const SwapAccountTrigger = memo(({symbol}) => {
  return (
    <div
      className={'absolute top-1/2 bg-background rounded-r-md rtl:rounded-l-md h-[calc(100%-2px)] -translate-y-1/2 right-[1px] rtl:left-[1px] flex items-center justify-center'}>

      <PopoverTrigger asChild>
        <Button variant={'outline'}>
          <CurrencyIcon size={'small'} symbol={symbol}/>
          {symbol}
          <ChevronDown className="opacity-50 h-4 w-4"/>
        </Button>
      </PopoverTrigger>
    </div>
  );
});

export default SwapAccountTrigger;