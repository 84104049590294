import {memo} from "react";
import {Card} from "#ui/card";
import {DetailItem} from "#components/common/DetailItem";
import Section from "#ui/section";
import {Trans} from "react-i18next";

const StatusFeatures = memo(({features}) => {
  if (!features || features.length === 0) return null;
  return (
    <Section header={<Trans i18nKey={'statusFeatures'}>Features</Trans>}>
      <Card className={'shadow-none'}>
        {features.map((feature, i) => (
          <DetailItem key={i}>{feature}</DetailItem>
        ))}
      </Card>
    </Section>
  )
});

StatusFeatures.displayName = 'StatusFeatures';

export default StatusFeatures;
