import {usePositionById} from "#src/contexts/SocketContext";
import {useInvisible} from "#hooks/useInvisible";
import {useMemo} from "react";
import InvisibleWrap from "#ui/InvisibleWrap";
import formatDecimal from "#lib/formatDecimal";
import Decimal from "decimal.js";
import {cn} from "#lib/utils";
import {CloseModal} from "#pages/Futures/components/Orders/CloseModal";
import {Button} from "#ui/button";
import {XIcon} from "lucide-react";
import {Trans} from "react-i18next";
import {useIsMobile} from "#hooks/use-mobile";
import {AlertDialogTrigger} from "#ui/alert-dialog";
import {EditTPSLModal} from "#pages/Futures/components/Orders/EditTPSLModal";
import {DEFAULT_FUTURES_CURRENCY} from "#src/constants/wallet";

export const MarketPrice = ({markPrice, id, pricePrecision}) => {
  const position = usePositionById(id);
  const price = position?.markPrice || markPrice;
  const invisible = useInvisible(markPrice, pricePrecision);

  return useMemo(() => (<InvisibleWrap
    invisible={invisible}>{formatDecimal(price, pricePrecision)}</InvisibleWrap>), [invisible, price, pricePrecision]);
}

export const PnL = ({pnl: initialPnl, id}) => {
  const position = usePositionById(id);
  const pnl = position?.pnl || initialPnl || 0;
  const invisible = useInvisible(initialPnl, 4, DEFAULT_FUTURES_CURRENCY);
  const isNegative = useMemo(() => new Decimal(pnl).isNegative(), [pnl]);

  const formattedPnl = useMemo(() => formatDecimal(pnl), [pnl]);

  const className = useMemo(() => cn('text-success text-right md:text-left block', {'text-destructive': isNegative}), [isNegative]);

  return useMemo(() => (<InvisibleWrap invisible={invisible}>
      <span className={className}>
      {formattedPnl} {DEFAULT_FUTURES_CURRENCY}
    </span>
  </InvisibleWrap>), [className, formattedPnl, invisible]);
};

export const LiquidationPrice = ({liquidationPrice, id, pricePrecision}) => {
  const position = usePositionById(id);
  const price = position?.liquidationPrice || liquidationPrice;
  const invisible = useInvisible(liquidationPrice, pricePrecision);
  const formattedPrice = useMemo(() => formatDecimal(price, pricePrecision), [price, pricePrecision]);
  const isNegative = useMemo(() => new Decimal(price).isNegative(), [price]);
  const isZero = useMemo(() => new Decimal(price).isZero(), [price]);
  return (
    <InvisibleWrap invisible={invisible}>
      <span className={'text-highlight'}>
        {isNegative || isZero ? '--' : formattedPrice}
      </span>
    </InvisibleWrap>
  )
}


export const TpSl = ({position}) => {
  return useMemo(() => (
    <EditTPSLModal position={position}/>
  ), [position]);
}


const CloseModalTrigger = ({children}) => {
  const isMobile = useIsMobile();
  return (
    <AlertDialogTrigger asChild>
      <Button className={cn({'p-1 w-6 h-6': !isMobile}, {'w-full mt-2': isMobile})}
              size={!isMobile ? 'icon' : 'default'}
              variant={'outline'}>
        {isMobile && children}<XIcon/>
      </Button></AlertDialogTrigger>
  )

}

export const ClosePositionButton = ({position}) => {
  const buttonText = <Trans>
    Close position
  </Trans>
  return (<div className={'flex justify-end w-full'}>
    <CloseModal
      positionId={position.id}
      trigger={<CloseModalTrigger>
        {buttonText}
      </CloseModalTrigger>}
      title={<Trans>
        Are you sure you want to close this position?
      </Trans>}
      description={<Trans>
        Closing a position will result in the liquidation of the position at the current market
      </Trans>}
      buttonText={
        <Trans>
          Close position
        </Trans>
      }
    />
  </div>)
}

export const CloseLimitButton = ({order}) => {
  const buttonText = (<Trans>
    Close order
  </Trans>)
  return (
    <div className={'flex justify-end w-full'}>
      <CloseModal
        positionId={order.id}
        trigger={
          <CloseModalTrigger>
            {buttonText}
          </CloseModalTrigger>
        }
        title={<Trans>
          Are you sure you want to close this order?
        </Trans>}
        description={<Trans>
          Closing an order will result in the cancellation of the order
        </Trans>}
        buttonText={
          buttonText
        }
      />
    </div>
  )
}
export const Type = ({type}) => {
  return useMemo(() => <span
    className={cn('text-success', {'text-destructive': type === 'SHORT'},)}>{type}</span>, [type]);
}

export const OrderType = ({orderType}) => {
  const orderTypes = useMemo(() => ({
    LIMIT: <Trans>Limit</Trans>,
    MARKET: <Trans>Market</Trans>,
    STOP_LIMIT: <Trans>Stop Limit</Trans>,
  }), []);
  return useMemo(() => orderTypes[orderType] || orderType, [orderType, orderTypes]);
}

export const CloseReason = ({reason}) => {
  const closeReasons = {
    TAKE_PROFIT: {text: <Trans>Take profit</Trans>, color: 'text-success'},
    STOP_LOSS: {text: <Trans>Stop loss</Trans>, color: 'text-destructive'},
    LIQUIDATION: {text: <Trans>Liquidation</Trans>, color: 'text-destructive'},
    MANUAL: {text: <Trans>Manual</Trans>, color: 'text-muted-foreground'},
  };
  const {text, color} = closeReasons[reason] || {text: reason, color: 'text-muted-foreground'};
  return useMemo(() => <span className={color}>{text}</span>, [color, text]);
}