import {
  ResponsiveDialog,
  ResponsiveDialogContent,
  ResponsiveDialogDescription,
  ResponsiveDialogFooter,
  ResponsiveDialogHeader,
  ResponsiveDialogTitle,
  ResponsiveDialogTrigger
} from "#ui/responsive-dialog";
import {Button, ButtonWithLoading} from "#ui/button";
import {Trans} from "react-i18next";
import {Pencil, Upload} from "lucide-react";
import React, {memo, useState} from "react";
import UserAvatar from "#components/common/UserAvatar";
import {useDispatch, useSelector} from "react-redux";
import {selectUser, setAvatar} from "#store/slices/authSlice";
import {useUploadAvatarMutation} from "#services/api/common";
import {useToast} from "#hooks/use-toast";

const AvatarChangeDialog = () => {
  const [previewUrl, setPreviewUrl] = useState(null);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const fileInputRef = React.createRef();
  const [uploadAvatar, {isLoading}] = useUploadAvatarMutation();
  const {toast} = useToast();
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
    }
  };

  const handleUpload = async () => {
    const file = fileInputRef.current.files[0];
    if (file) {
      const {avatar} = await uploadAvatar(file).unwrap()
      if (avatar) {
        dispatch(setAvatar(avatar));
        toast({
          variant: "success",
          title: (<Trans>
            Avatar updated
          </Trans>),
          description: (<Trans>
            Your avatar has been updated successfully
          </Trans>)
        });
        setPreviewUrl(null);
      }
    }
  }
  return (
    <ResponsiveDialog>
      <ResponsiveDialogTrigger asChild>
        <Button variant="outline" className="w-full">
          <Pencil className="w-4 h-4"/>
          <Trans>Change avatar</Trans>
        </Button>
      </ResponsiveDialogTrigger>
      <ResponsiveDialogContent>
        <ResponsiveDialogHeader>
          <ResponsiveDialogTitle>
            <Trans>Update Profile Picture</Trans>
          </ResponsiveDialogTitle>
          <ResponsiveDialogDescription>
            <Trans>Choose a new avatar for your profile</Trans>
          </ResponsiveDialogDescription>
        </ResponsiveDialogHeader>

        <div className="space-y-6 py-4">
          <div className="flex flex-col items-center gap-4">
            <UserAvatar
              avatar={previewUrl || user.avatar}
              user={user}
              className="w-24 h-24"
            />

            <div className="flex flex-col items-center gap-2">
              <label className="cursor-pointer">
                <input
                  ref={fileInputRef}
                  type="file"
                  className="hidden"
                  accept="image/*"
                  onChange={handleFileChange}
                />
                <Button
                  type={'button'} variant="outline"
                  onClick={() => fileInputRef?.current?.click()}
                >
                  <Upload className="w-4 h-4"/>
                  <Trans>Upload new picture</Trans>
                </Button>
              </label>
              <p className="text-sm text-muted-foreground">
                <Trans
                  i18nKey={'supportedImageFormats'}
                >JPG, PNG or GIF. Max 2MB.</Trans>
              </p>
            </div>
          </div>
        </div>

        <ResponsiveDialogFooter className="flex gap-2 footer">
          <ButtonWithLoading className={'w-full'} size={'lg'} loading={isLoading} type="submit" onClick={handleUpload}
                             disabled={!previewUrl}>
            <Trans>Save changes</Trans>
          </ButtonWithLoading>

        </ResponsiveDialogFooter>
      </ResponsiveDialogContent>
    </ResponsiveDialog>
  );
};

export default memo(AvatarChangeDialog);