import React, {useState} from 'react';
import {countries} from 'countries-list';
import {Popover, PopoverContent, PopoverTrigger} from "#ui/popover";
import {Button} from "#ui/button";
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "#ui/command";
import {Check, ChevronsUpDown} from 'lucide-react';
import {cn} from "#lib/utils";
import {Trans, useTranslation} from "react-i18next";
import {Label} from "#ui/label";

// Спрощуємо дані
const countriesList = Object.entries(countries)
  .map(([code, country]) => ({
    code,
    label: `${country.name} (${code})`
  }))
  .sort((a, b) => a.label.localeCompare(b.label));

const CountryCombobox = ({value, onChange, placeholder}) => {
  const [open, setOpen] = useState(false);
  const {t} = useTranslation();
  const selectedCountry = countriesList.find(
    (country) => country.code === value
  );

  placeholder = placeholder || <Trans>Choose country</Trans>;

  return (
    <div>
      <Label>
        <Trans>
          Country
        </Trans>
      </Label>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-full justify-between"
          >
            {selectedCountry ? selectedCountry.label : placeholder}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50"/>
          </Button>
        </PopoverTrigger>
        <PopoverContent align={'start'} className="w-[300px] p-0">
          <Command>
            <CommandInput placeholder={t('searchCountry', 'Search country...')}/>
            <CommandList>
              <CommandEmpty>
                <Trans>
                  No results found
                </Trans>
              </CommandEmpty>
              <CommandGroup className="max-h-[300px] overflow-auto">
                {countriesList.map((country) => (
                  <CommandItem
                    key={country.code}
                    value={country.label}
                    onSelect={() => {
                      onChange(country.code);
                      setOpen(false);
                    }}
                  >
                    {country.label}
                    <Check
                      className={cn(
                        "ml-auto h-4 w-4",
                        value === country.code ? "opacity-100" : "opacity-0"
                      )}
                    />
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default CountryCombobox;