import {CommandInput} from "#ui/command";
import {useTranslation} from "react-i18next";

const SearchInput = ({ value, onChange }) => {
  const {t} = useTranslation()

  return (
    <CommandInput
      placeholder={t('Search account')}
      className="h-9"
      value={value}
      onValueChange={onChange}
    />
  )
}

export default SearchInput;