import * as React from "react"
import * as SwitchPrimitives from "@radix-ui/react-switch"

import {cn} from "#lib/utils"
import {cva} from "class-variance-authority";

const switchVariantsRoot = cva(
  "peer inline-flex shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=unchecked]:bg-input",
  {
    variants: {
      size: {
        sm: "h-4 w-7",
        default: "h-5 w-9",
        lg: "h-6 w-12"
      }
    },
    defaultVariants: {
      size: "default"
    }
  }
)

const switchVariantsThumb = cva(
  "pointer-events-none block rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=unchecked]:translate-x-0",
  {
    variants: {
      size: {
        sm: "h-3 w-3 data-[state=checked]:translate-x-3",
        default: "h-4 w-4 data-[state=checked]:translate-x-4",
        lg: "h-5 w-5 data-[state=checked]:translate-x-5"
      }
    },
    defaultVariants: {
      size: "default"
    }
  }
)
const Switch = React.forwardRef(({className, size, ...props}, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      switchVariantsRoot({size}),
      className
    )}
    {...props}
    ref={ref}>
    <SwitchPrimitives.Thumb
      className={switchVariantsThumb({size})}/>
  </SwitchPrimitives.Root>
))
Switch.displayName = SwitchPrimitives.Root.displayName

export {Switch}
