import {cn} from "#lib/utils";

const Container = ({as: El = 'div', className, isFull = false, ...restProps}) => {
  return (
    <El className={cn(
      'px-4 mx-auto ',
      {
        'container': !isFull,
      },
      className
    )} {...restProps}/>
  );
};

export default Container;