import {useGetNetworksQuery} from "#services/api/accounts";
import useSend from "#hooks/api/useSend";
import WithdrawHeader from "./WithdrawHeader";
import AddressInput from "./AddressInput";
import {NetworkSelect, NoNetworksMessage} from "#components/wallet/NetworkSelect";
import MemoInput from "./MemoInput";
import OftenUsed from "#pages/wallet/Send/components/OftenUsed";
import {Trans} from "react-i18next";


const WithdrawDestination = () => {
  const {
    account,
    address,
    isAddressValid,
    network,
    memo,
    isMemoValid,
    sendApiRef,
  } = useSend();

  const accountId = account?.id;

  const {data: networks, isLoading, isFetching} = useGetNetworksQuery(accountId, {
    skip: !accountId
  });

  const isDisabled = isLoading || isFetching || !accountId;

  return (
    <div>
      <WithdrawHeader/>
      {(!networks?.length && !isDisabled) ? (<NoNetworksMessage>
        <Trans>No available networks for send</Trans>
      </NoNetworksMessage>) : (
        <div className={'space-y-2'}>
          <div>
            <AddressInput
              isDisabled={isDisabled}
              address={address}
              isAddressValid={isAddressValid}
              networks={networks}
              onAddressChange={sendApiRef.setAddressHandler}
            />
            <OftenUsed networks={networks} isDisabled={isDisabled}/>
          </div>

          <NetworkSelect
            isLoading={isDisabled}
            networks={networks}
            network={network}
            address={address}
            setNetwork={sendApiRef?.setNetworkHandler}
          />

          {network?.hasMemo && (
            <MemoInput
              isDisabled={isDisabled}
              memo={memo}
              isMemoValid={isMemoValid}
              onMemoChange={sendApiRef?.setMemoHandler}
            />
          )}
        </div>
      )}
    </div>

  );
};

export default WithdrawDestination;