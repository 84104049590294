import {useSelector} from "react-redux";
import {selectBrand, selectTheme} from "#store/slices/brandSlice";
import {Link} from "react-router-dom";
import {cva} from "class-variance-authority";
import {getIconOrRandom} from "#lib/getRandomIcon";

const iconSizeVariants = {
  sm: 16,
  base: 20,
  md: 24,
  lg: 32,
  xl: 48,
}

const textVariants = cva('font-semibold', {
  variants: {
    size: {
      sm: 'text-sm',
      base: 'text-base',
      md: 'text-md',
      lg: 'text-lg',
      xl: 'text-xl',
    },
    variant: {
      default: 'text-foreground',
      primary: 'text-primary',
      secondary: 'text-secondary',
      destructive: 'text-destructive',
      muted: 'text-muted',
    }

  },
  defaultVariants: {
    size: 'md',
    variant: 'default'
  }

})

const iconVariants = cva('p-2 rounded-md', {
  variants: {
    variant: {
      default: 'text-primary-foreground bg-primary',
      secondary: 'text-secondary-foreground bg-secondary',
      accent: 'text-accent-foreground bg-accent',
      gradientAccent: 'text-primary-foreground bg-gradient-to-br from-primary to-highlight animate-gradient-x',
      gradient: 'text-primary-foreground bg-gradient-to-br from-primary to-primary/50 animate-gradient-x',
    }
  },
  defaultVariants: {
    variant: 'default'
  }

})


const Logo = ({size, variant, path = '/'}) => {
  const brand = useSelector(selectBrand);
  const {themeStyle} = useSelector(selectTheme);
  const {icon, name} = brand;
  const BrandIcon = getIconOrRandom(icon);
  const sizeVariant = iconSizeVariants[size] ?? iconSizeVariants['base'];
  const isHideText = variant === 'icon';
  return (
    <Link to={path} className={'flex items-center gap-2'}>
        <span className={iconVariants({variant: themeStyle})}>
        <BrandIcon size={sizeVariant}/>
      </span>
      {!isHideText &&
        <span className={textVariants(
          {size, variant}
        )}>
        {name}
      </span>
      }
    </Link>
  );
};

export default Logo;