import { memo } from "react";
import { ArrowDown } from "lucide-react";
import SwapAmount from "./SwapAmount";
import BalanceAfterExchange from "./BalanceAfterExchange";

const SwapSummary = memo(({ fromAccount, toAccount, fromAmount, toAmount }) => (
  <div className="flex flex-col gap-3">
    <div className="flex flex-col items-center relative gap-2 justify-between">
      <SwapAmount type="from" account={fromAccount} amount={fromAmount} />
      
      <span className="absolute text-primary top-1/2 transform -translate-y-1/2 p-1 left-[23px] rounded-md bg-background">
        <ArrowDown size={18} />
      </span>

      <SwapAmount type="to" account={toAccount} amount={toAmount} />
    </div>

    <BalanceAfterExchange
      fromAccount={fromAccount}
      toAccount={toAccount}
      fromAmount={fromAmount}
      toAmount={toAmount}
    />
  </div>
));

SwapSummary.displayName = 'SwapSummary';

export default SwapSummary;
