import {Users, DollarSign, Zap, Globe} from "lucide-react";
import StatsCard from "#pages/Home/components/LiveActivity/StatsCard";
import {Trans} from "react-i18next";

const STATS_DATA = [
  {label: <Trans>Total Users</Trans>, value: "100K+", icon: <Users/>},
  {label: <Trans>Daily Volume</Trans>, value: "$50M+", icon: <DollarSign/>},
  {label: <Trans>Uptime</Trans>, value: "99.99%", icon: <Zap/>},
  {label: <Trans>Countries</Trans>, value: "180+", icon: <Globe/>}
];

const StatisticsGrid = () => (
  <div className="flex-1 w-full self-stretch max-h-full relative">
    <div className="grid grid-cols-2 gap-4 h-full z-10 py-1 relative">
      {STATS_DATA.map((stat, i) => (
        <StatsCard key={i} {...stat} />
      ))}
    </div>
  </div>
);

export default StatisticsGrid