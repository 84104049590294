// components/FuturesBalance/BalanceDisplay.jsx
import {Skeleton} from "#ui/skeleton";
import formatDecimal from "#lib/formatDecimal";
import {useBalance} from "#src/contexts/SocketContext";
import {DEFAULT_FUTURES_CURRENCY} from "#src/constants/wallet";

const MaxButton = ({onClick, children}) => (
  <button onClick={onClick} className="text-highlight underline">
    {children}
  </button>
);

export const FreeMargin = ({freeMargin, setAmount}) => {
  const {freeMargin: newFreeMargin} = useBalance() ?? {};
  const current = newFreeMargin ?? freeMargin ?? 0;
  const handleSetMaxAmount = () => setAmount(current);

  return (
    <MaxButton onClick={handleSetMaxAmount}>
      {formatDecimal(current)} {DEFAULT_FUTURES_CURRENCY}
    </MaxButton>
  );
};

export const AccountBalance = ({account, setAmount, isLoading}) => {
  if (isLoading) {
    return <Skeleton className="w-20 h-5"/>;
  }

  const handleSetMaxAmount = () => setAmount(account?.balance);

  return (
    <MaxButton onClick={handleSetMaxAmount}>
      {formatDecimal(account?.balance)} {account?.currency?.symbol}
    </MaxButton>
  );
};

export const DisplayBalance = ({isDeposit, isAccountLoading, selectedAccount, freeMargin, setAmount}) => {
  return (
    isDeposit ? (
      <AccountBalance
        isLoading={isAccountLoading}
        account={selectedAccount}
        setAmount={setAmount}
      />
    ) : (
      <FreeMargin
        freeMargin={freeMargin}
        setAmount={setAmount}
      />
    )
  )
}
