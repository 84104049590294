import React from 'react';
import {cn} from '#lib/utils';
import {cva} from 'class-variance-authority';
import {Loader2} from 'lucide-react';

const spinnerVariants = cva('flex-col items-center justify-center', {
  variants: {
    show: {
      true: 'flex',
      false: 'hidden',
    },
  },
  defaultVariants: {
    show: true,
  },
});

const loaderVariants = cva('animate-spin text-primary', {
  variants: {
    size: {
      sm: 'size-6',
      md: 'size-8',
      lg: 'size-12',
    },
    variant: {
      default: 'text-foreground',
      primary: 'text-primary',
      secondary: 'text-secondary',
      accent: 'text-accent',
      natural: 'text-background',
    },
  },
  defaultVariants: {
    size: 'md',
    variant: 'default',
  },
});


export function Spinner({size, show, children, className, variant,...props}) {
  return (
    <span className={spinnerVariants({show})}>
      <Loader2 className={cn(loaderVariants({size,variant}), className)}/>
      {children}
    </span>
  );
}
