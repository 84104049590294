import {ChoosePlanModal} from "#pages/wallet/Staking/components/ChoosePlan/ChoosePlanModal";
import {StakeHeader} from "#pages/wallet/Staking/components/ChoosePlan/StakeHeader";
import {EstimatedEarnings} from "#pages/wallet/Staking/components/EstimatedEarnings";
import {StakeAmountInput} from "#pages/wallet/Staking/components/ChoosePlan/StakeAmountInput";
import {StakePlanSelector} from "#pages/wallet/Staking/components/ChoosePlan/StakePlanSelector";
import {useStakingForm} from "#hooks/api/useStakingForm";
import {ChoosePlanButton} from "#pages/wallet/Staking/components/ChoosePlan/ChoosePlanButton";
import {useMemo} from "react";
import {Trans} from "react-i18next";

export const ChoosePlan = ({open, onClose, stake, selectedPlan, setSelectedPlan}) => {
  const hasNotUsedPlan = useMemo(() => stake.plans.some(plan => !plan.disabled), [stake.plans])

  const {
    amount,
    earned,
    fiatEarned,
    isEarningsLoading,
    isErrorAmount,
    message,
    stakingFormApiRef,
    isButtonDisabled,
  } = useStakingForm(stake, selectedPlan, !hasNotUsedPlan || !open);

  return (
    <ChoosePlanModal onClose={onClose} open={open}>
      <StakeHeader
        stake={stake}
      />
      <StakeAmountInput
        onMaxClick={() => stakingFormApiRef.current.setAmountHandler(stake.balance)}
        amount={amount}
        isErrorAmount={isErrorAmount}
        stake={stake}
        message={message}
        disabled={!hasNotUsedPlan}
        onChange={stakingFormApiRef.current.setAmountHandler}
      />
      <StakePlanSelector
        stake={stake}
        selectedPlan={selectedPlan}
        onSelectPlan={setSelectedPlan}
        disabled={!hasNotUsedPlan}
      />
      <EstimatedEarnings
        label={<Trans>Estimated earnings</Trans>}
        precision={stake.precision}
        symbol={stake.symbol}
        earned={!!amount ? earned : 0}
        fiatEarned={!!amount ? fiatEarned : 0}
        isLoading={isEarningsLoading}
        className={'my-4'}
      />
      <ChoosePlanButton
        planId={selectedPlan?.id}
        amount={amount}
        loading={isEarningsLoading}
        disabled={isButtonDisabled || !hasNotUsedPlan || selectedPlan?.disabled}
      />
    </ChoosePlanModal>
  );
};

