import {OrderFormInput} from "#pages/Futures/components/OrderForm/OrderFormInput";
import {Trans} from "react-i18next";
import {DisplayBalance} from "#pages/Futures/components/FuturesBalance/FuturesBalanceDialog/BalanceDisplay";

export const TransferAmountField = (
  {isDeposit, amount, setAmount, selectedAccount, isAccountLoading, freeMargin}
) => {
  return (
    <OrderFormInput
      label={<Trans>Amount</Trans>}
      afterLabel={
        <DisplayBalance
          isDeposit={isDeposit}
          isAccountLoading={isAccountLoading}
          selectedAccount={selectedAccount}
          freeMargin={freeMargin}
          setAmount={setAmount}
        />
      }
      value={amount}
      onChange={setAmount}
      precision={4}
    />
  )
}