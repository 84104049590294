import {ErrorRoute} from "./ErrorBoundary";
import {Futures} from "#pages/Futures";
import FuturesLayout from "#layouts/FuturesLayout";
import {SocketProvider} from "#src/contexts/SocketContext";

const futuresRouter = {
  path: "/futures",
  element: <FuturesLayout/>,
  errorElement: <ErrorRoute/>,
  children: [
    {
      path: ':symbol?',
      element: <SocketProvider><Futures/></SocketProvider>,
    },

  ]
}

export default futuresRouter;