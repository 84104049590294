import {Skeleton} from "#ui/skeleton";
import {Card, CardContent, CardHeader} from "#ui/card";

export const LevelCardSkeleton = () => (
  <Card className="group transition-all duration-300">
    <CardHeader className="pb-2">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Skeleton className="w-4 h-4 rounded"/>
          <Skeleton className="h-5 w-24"/>
        </div>
      </div>
    </CardHeader>
    <CardContent className="space-y-2">
      <div className="flex justify-between">
        <Skeleton className="h-4 w-24"/>
        <Skeleton className="h-4 w-12"/>
      </div>
      <div className="flex justify-between">
        <Skeleton className="h-4 w-16"/>
        <Skeleton className="h-4 w-16"/>
      </div>
    </CardContent>
  </Card>
);
