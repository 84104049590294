import {memo} from "react";
import Container from "#ui/container";
import TotalBalance from "./components/TotalBalance";
import CryptoAccounts from "#pages/wallet/Dashboard/components/CryptoAccounts";
import StakingBlock from "#pages/wallet/Dashboard/components/StakingBlock";


const Dashboard = () => {
  return (
    <>
      <Container>
        <TotalBalance/>
        <StakingBlock/>
        <CryptoAccounts/>
      </Container>
    </>
  );
};

export default memo(Dashboard);