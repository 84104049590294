import {TableCell, TableRow} from "#ui/table";
import {Skeleton} from "#ui/skeleton";

const TransactionRowSkeleton = () => {
  return (
    <TableRow>
      <TableCell>
        <div className="flex items-center gap-2">
          <Skeleton className="h-8 w-8 rounded-md"/>
          <Skeleton className="h-4 w-[130px]"/>
        </div>
      </TableCell>
      <TableCell>
        <Skeleton className="h-4 w-[120px]"/>
      </TableCell>
      <TableCell>
        <Skeleton className="h-4 w-[120px]"/>
      </TableCell>
      <TableCell>
        <Skeleton className="h-4 w-[80px]"/>
      </TableCell>
      <TableCell>
        <div className="flex justify-end">
          <Skeleton className="h-6 w-[100px]"/>
        </div>
      </TableCell>
    </TableRow>
  );
};

const TransactionTableSkeleton = () => {
  const rows = Array(10).fill(null);

  return rows.map((_, index) => (
    <TransactionRowSkeleton key={index}/>
  ))
};

export default TransactionTableSkeleton;