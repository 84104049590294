import {shallowEqual, useDispatch, useSelector, useStore} from "react-redux";
import {useCallback, useMemo, useRef} from "react";
import {
  clearState,
  selectAccount,
  selectSwapConverterState,
  setBothAccounts,
  setBothAmounts,
  setShowSummary,
  swapAccounts
} from "#store/slices/swapConverterSlice";
import useConvertCurrencies from "#hooks/api/useConvertCurrencies";
import {MAX_LIMIT} from "#src/constants/wallet";
import {useNavigate} from "react-router-dom";

const maxLimit = MAX_LIMIT;

const useSwapConverter = () => {
  const store = useStore();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const swapConverterApiRef = useRef({});
  const swapConverterState = useSelector(selectSwapConverterState, shallowEqual);
  const {
    fromAccount,
    toAccount,
    fromAmount,
    toAmount,
    showSummary,
    rate,
    isConverting,
    convertError
  } = swapConverterState;
  const convertAmount = useConvertCurrencies();

  swapConverterApiRef.current.handleAmountBySide = useCallback(
    async (value, side) => {
      const fromAccountId = fromAccount?.id;
      const toAccountId = toAccount?.id;
      const isFromAmount = side === 'from';
      await convertAmount({
        amount: value, isFromAmount, fromAccountId, toAccountId, maxLimit
      });
    },
    [convertAmount, fromAccount?.id, toAccount?.id]
  );

  swapConverterApiRef.current.swapAccounts = useCallback(async () => {
    dispatch(swapAccounts());
    const fromAccountId = toAccount?.id;
    navigate('/wallet/swap/' + toAccount?.symbol);
    const toAccountId = fromAccount?.id;
    const isFromAmount = true;
    await convertAmount(
      {amount: fromAmount, isFromAmount, fromAccountId, toAccountId, maxLimit}
    );
  }, [dispatch, toAccount?.id, toAccount?.symbol, navigate, fromAccount?.id, convertAmount, fromAmount]);

  swapConverterApiRef.current.onAccountSelect = useCallback(
    async (account, side) => {
      dispatch(selectAccount({account, side}));
      const isFromAmount = side === 'from';
      if (isFromAmount) {
        navigate('/wallet/swap/' + account.symbol);
      }
      const updatedState = store.getState()?.swapConverter;
      const {fromAccount: updatedFromAccount, toAccount: updatedToAccount} = updatedState;
      const fromAccountId = updatedFromAccount?.id;
      const toAccountId = updatedToAccount?.id;
      await convertAmount(
        {amount: isFromAmount ? fromAmount : toAmount, isFromAmount, fromAccountId, toAccountId, maxLimit}
      );
    },
    [convertAmount, dispatch, fromAmount, navigate, store, toAmount]
  );

  swapConverterApiRef.current.clearState = useCallback(
    () => dispatch(clearState()),
    [dispatch]
  );

  swapConverterApiRef.current.onShowSummaryHandler = useCallback(async () => {
    const fromAccountId = fromAccount?.id;
    const toAccountId = toAccount?.id;
    const isFromAmount = true;
    await convertAmount({
      amount: fromAmount, isFromAmount, fromAccountId, toAccountId, maxLimit
    })
    dispatch(setShowSummary(true))
  }, [fromAccount?.id, toAccount?.id, convertAmount, fromAmount, dispatch]);

  swapConverterApiRef.current.setShowSummary = useCallback((value) => dispatch(setShowSummary(value)), [dispatch]);
  swapConverterApiRef.current.clearBothAmounts = useCallback(() => {
    dispatch(setBothAmounts({fromAmount: '', toAmount: ''}));
  }, [dispatch]);

  swapConverterApiRef.current.setBothAccounts = useCallback((
    fromAccount, toAccount
  ) => {
    dispatch(setBothAccounts(fromAccount, toAccount));
  }, [dispatch]);

  const apiRef = useMemo(() => swapConverterApiRef.current, [swapConverterApiRef]);
  return useMemo(
    () => ({
      convertError,
      fromAccount,
      toAccount,
      fromAmount,
      toAmount,
      rate,
      swapConverterApiRef: apiRef,
      showSummary,
      isConverting
    }),
    [convertError, fromAccount, toAccount, fromAmount, toAmount, rate, apiRef, showSummary, isConverting]
  );
};

export default useSwapConverter;