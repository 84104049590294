import {memo} from "react";
import {Trans} from "react-i18next";
import CurrencyIcon from "#ui/crypto/CurrencyIcon";
import CurrencyFormat from "#ui/currency-format";

const SwapAmount = memo(({type, account, amount}) => (
  <div className="flex w-full items-center gap-2 rounded-md bg-secondary text-secondary-foreground p-4">
    <CurrencyIcon symbol={account?.symbol} size={50}/>
    <div>
      <p className="text-sm">
        {type === 'from' ? <Trans>You pay</Trans> : <Trans>You will get</Trans>}
      </p>
      <div className="flex items-center gap-1">
        <CurrencyFormat value={amount} decimalScale={account.precision}/>
        <span className="text-muted-foreground">{account?.symbol}</span>
      </div>
    </div>
  </div>
));

SwapAmount.displayName = 'SwapAmount';

export default SwapAmount;
