import React, {memo, useMemo} from "react";
import {Progress} from "#ui/progress";

const StepProgress = memo(({currentStep, totalSteps}) => {
  const progress = useMemo(() => {
    return (currentStep / (totalSteps - 1)) * 100;
  }, [currentStep, totalSteps]);

  return (
    <Progress value={progress}/>
  );
});

export default StepProgress;