import {Spinner} from "#ui/spinner";
import {Button} from "#ui/button";
import {ChevronDown} from "lucide-react";
import {PopoverTrigger} from "#ui/popover";
import {cn} from "#lib/utils";
import CurrencyIcon from "#ui/crypto/CurrencyIcon";
import CurrencyFormat from "#ui/currency-format";
import {Trans} from "react-i18next";

const AccountButton = ({selectedAccount, className}) => {
  return (
    <div className={cn("flex items-center gap-2 w-full", className)}>
      <CurrencyIcon symbol={selectedAccount?.symbol} size="small"/>
      <div className={'flex items-center gap-2'}>
        <span>{selectedAccount?.symbol}</span>
        <small>
          {selectedAccount?.name}
        </small>
      </div>

      <span className="font-bold ml-auto">
            <CurrencyFormat value={selectedAccount.balance}/>
      </span>

    </div>
  )
}

const AccountTriggerButton = ({selectedAccount, isLoading, open,disabled}) => {
  return (
    <PopoverTrigger asChild>
      <Button
        variant="outline"
        size={'lg'}
        role="combobox"
        aria-expanded={open}
        disabled={isLoading || disabled}
        className="justify-between w-full px-3 py-5"
      >
        {selectedAccount ? (
          <AccountButton selectedAccount={selectedAccount}/>
        ) : (
          isLoading ? <Spinner/> : <Trans>Select Account</Trans>
        )}
        <ChevronDown className="opacity-50 h-4 w-4"/>
      </Button>
    </PopoverTrigger>
  )
}

export default AccountTriggerButton;