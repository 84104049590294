import AccountItem from "#components/wallet/AccountSelector/AccountItem";
import {useTranslation} from "react-i18next";
import {CommandEmpty, CommandGroup, CommandList} from "#ui/command";
import {memo} from "react";
import {CommandLoading} from "cmdk";
import {Spinner} from "#ui/spinner";

const AccountsList = ({accounts, isLoading, onSelect}) => {
  const {t} = useTranslation()

  return (
    <CommandList>
      {!isLoading && accounts.length === 0 && <CommandEmpty>{t('No accounts found')}</CommandEmpty>}
      {isLoading ?
       ( <CommandLoading>
          <div className={'flex items-center justify-center p-4'}>
          <Spinner/>
        </div>
        </CommandLoading>) :
        <CommandGroup>
          {accounts.map((account) => {
            return (
              <AccountItem
                key={account.id}
                account={account}
                onSelect={onSelect}
              />
            )
          })}
        </CommandGroup>}
    </CommandList>
  )
}

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.isLoading === nextProps.isLoading &&
    prevProps.onSelect === nextProps.onSelect &&
    prevProps.accounts?.length === nextProps.accounts?.length &&
    prevProps.accounts?.every((account, index) =>
      account.id === nextProps.accounts[index]?.id &&
      account.balance === nextProps.accounts[index]?.balance
    )
  )
}

export default memo(AccountsList, arePropsEqual);