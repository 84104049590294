import {LevelCard} from "#pages/wallet/Referral/components/LevelCard";
import {LevelCardSkeleton} from "#pages/wallet/Referral/components/LevelCardSkeleton";

const LevelCardListSkeleton = () => {
  return (<div className="space-y-8">
    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
      {[1, 2, 3].map(level => (<LevelCardSkeleton key={level}/>))}
    </div>
  </div>);
};

export const LevelCardList = ({levels, currentLevel, isLoading}) => {
  if (isLoading) {
    return <LevelCardListSkeleton/>;
  }
  return (<div className="space-y-8">
    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
      {levels.map(level => (<LevelCard
          key={level.id}
          level={level}
          isCurrentLevel={currentLevel.id === level.id}
        />
      ))}
    </div>
  </div>)
}