import {useSelector, useDispatch} from 'react-redux';
import {useMemo, useCallback, useRef} from 'react';
import {
  clearAddress,
  clearState,
  setAddress,
  setAmount,
  setIsAddressValid,
  setMemo,
  setNetwork,
  setAccount, setNetworks, onAccountChange, setTransactionId, setOpenOtp
} from "#store/slices/sendSlice";

const useSend = () => {
  const dispatch = useDispatch();
  const {
    address,
    memo,
    amount,
    isMemoValid,
    isAddressValid,
    account,
    network,
    transactionId,
    openOtp,
    networks
  } = useSelector(state => state.send);

  const sendApiRef = useRef({
    setAddressHandler: useCallback((address, networks) => dispatch(setAddress({
      address,
      networks
    })), [dispatch]),
    setMemoHandler: useCallback((value) => dispatch(setMemo(value)), [dispatch]),
    setNetworkHandler: useCallback((value) => dispatch(setNetwork(value)), [dispatch]),
    setAmountHandler: useCallback((value) => dispatch(setAmount(value)), [dispatch]),
    clearAddressHandler: useCallback(() => dispatch(clearAddress()), [dispatch]),
    setIsAddressValidHandler: useCallback((value) => dispatch(setIsAddressValid(value)), [dispatch]),
    clearState: useCallback(() => dispatch(clearState()), [dispatch]),
    setAccount: useCallback((value) => dispatch(setAccount(value)), [dispatch]),
    setNetworks: useCallback((value) => dispatch(setNetworks(value)), [dispatch]),
    onAccountChange: useCallback(() => dispatch(onAccountChange()), [dispatch]),
    setTransactionId: useCallback((value) => dispatch(setTransactionId(value)), [dispatch]),
    setOpenOtp: useCallback((v) => dispatch(setOpenOtp(v)), [dispatch]),
  });


  const isShowAmount = useMemo(() => {
      return isAddressValid && !!network?.id &&
        (!network?.hasMemo || (network?.hasMemo && isMemoValid))
    }, [isAddressValid, network?.id, network?.hasMemo, isMemoValid]
  )

  const apiRef = useMemo(() => ({
    ...sendApiRef.current
  }), []);

  return useMemo(() => ({
    isShowAmount,
    address,
    memo,
    amount,
    isMemoValid,
    isAddressValid,
    account,
    network,
    transactionId,
    openOtp,
    networks,
    sendApiRef: apiRef
  }), [
    account, address, amount, apiRef, isAddressValid, isMemoValid,
    isShowAmount, memo, network, networks, openOtp, transactionId
  ]);
};

export default useSend;