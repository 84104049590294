import React from 'react';
import {FormField} from "#ui/form-field";
import {Button} from "#ui/button";
import {Copy, Check} from "lucide-react";
import {cn} from "#lib/utils";
import {TooltipProvider, Tooltip, TooltipTrigger, TooltipContent} from "#ui/tooltip";
import {Trans} from "react-i18next";

export const CopyButton = ({inputRef, disabled}) => {
  const [copied, setCopied] = React.useState(false);

  const handleCopy = async () => {
    try {
      if (inputRef?.current) {
        inputRef.current.select();
        await navigator.clipboard.writeText(inputRef.current.value);
        inputRef.current.blur?.();

        // Показуємо статус "Скопійовано"
        setCopied(true);

        // Через 2 секунди повертаємо початковий стан
        setTimeout(() => {
          setCopied(false);
        }, 1000);
      }
    } catch (error) {
      try {
        document.execCommand('copy');
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      } catch (fallbackError) {
        console.error('Failed to copy:', fallbackError);
      }
    }
  };

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            onClick={handleCopy}
            disabled={disabled}
            className="absolute top-1/2 -translate-y-1/2 right-1"
            variant="ghost"
            size="icon"
          >
            {copied ? <Check/> : <Copy/>}
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p>{copied ? <Trans>Copied</Trans> : <Trans>Copy</Trans>}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

const CopyFormField = ({
                         className,
                         disabled,
                         ...props
                       }) => {
  const inputRef = React.useRef(null);

  return (
    <FormField
      ref={inputRef}
      {...props}
      disabled={disabled}
      readOnly={true}
      className={cn('pr-10', className)}
      after={
        <CopyButton inputRef={inputRef} disabled={disabled}/>
      }
    />
  );
};

export default CopyFormField;