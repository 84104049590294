import Container from "#ui/container";
import Logo from "#components/common/Logo";
import {Button} from "#ui/button";
import {ChevronRight} from "lucide-react";
import React, {useEffect, useState} from "react";
import {useIsMobile} from "#hooks/use-mobile";
import {Trans} from "react-i18next";
import {Link} from "react-router-dom";
import {cn} from "#lib/utils";

const Header = ({heroRef}) => {
  const isMobile = useIsMobile();
  const [showHeader, setShowHeader] = useState(false);

  const isCompact = isMobile ? 'sm' : 'base';
  const variant = isMobile ? 'icon' : 'default';
  useEffect(() => {
    const handleScroll = () => {
      const heroHeight = heroRef?.current?.offsetHeight;
      if (heroHeight) {
        setShowHeader(window.scrollY > heroHeight - 100);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [heroRef]);

  return (
    <header className={cn(
      {"fixed top-0 left-0 right-0 z-50": heroRef},
      "bg-background/30 backdrop-blur-xl",
      'supports-backdrop-blur:bg-background/60',
      "border-b border-border/40",
      "transition-all duration-300",
      "-translate-y-full",
      {"translate-y-0": showHeader || !heroRef}
    )}>
      <Container className="flex justify-between items-center py-4">
        <Logo size={isCompact} variant={variant}/>
        <div className="flex items-center gap-3">
          <Button variant="outline" asChild>
            <Link to="/auth">
              <Trans>
                Login
              </Trans>
            </Link>
          </Button>
          <Button asChild>
            <Link to="/auth/register">
              <Trans>Start Now</Trans><ChevronRight/>
            </Link>
          </Button>
        </div>
      </Container>
    </header>
  );
};

export default Header;