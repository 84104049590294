import useSend from "#hooks/api/useSend";
import {Trans, useTranslation} from "react-i18next";
import {useFormatCurrency} from "#hooks/useFormatCurrency";
import {Button, ButtonWithLoading} from "#ui/button";
import AmountField from "#components/wallet/AmountField";
import useValidateAmount from "#hooks/useValidateAmount";
import {useValidateWithdrawAmountMutation} from "#services/api/accounts";
import {cn} from "#lib/utils";
import FormHeader from "#ui/form-header";
import {useCreateWithdrawalMutation} from "#services/api/transactions";
import {memo, useCallback, useMemo, useRef} from "react";
import useErrorMessages from "#hooks/useErrorMessages";
import {ErrorNameSpaces} from "#src/constants/errors";
import useLastResend from "#hooks/otp/useLastResend";
import WithdrawOtpModal from "#pages/wallet/Send/components/WithdrawOtpModal";
import {useSelector} from "react-redux";
import {selectCanWithdraw} from "#store/slices/authSlice";


const SendButton = memo(({
                           canSend,
                           isLoading,
                           accountId,
                           networkId,
                           amount
                         }) => {
  const [createWithdrawal, {isLoading: isCreating}] = useCreateWithdrawalMutation();
  const {address, transactionId, sendApiRef} = useSend();
  const prevDataRef = useRef('');

  const data = useMemo(() => ({
    accountId,
    networkId,
    amount,
    address,
  }), [address, amount, accountId, networkId]);

  const {showError} = useErrorMessages(ErrorNameSpaces.WITHDRAWAL);
  const {setLastResend} = useLastResend();

  const createNewWithdrawal = useCallback(async () => {
    try {
      const {id} = await createWithdrawal(data).unwrap();
      setLastResend();
      sendApiRef.setTransactionId(id);
      sendApiRef.setOpenOtp(true);
      prevDataRef.current = JSON.stringify(data);
    } catch (e) {
      showError(e.data?.code, e.data?.data || {});
    }
  }, [createWithdrawal, data, sendApiRef, setLastResend, showError]);

  const sendHandler = useCallback(async () => {
    if (!canSend) return;

    const currentDataString = JSON.stringify(data);
    const isDataChanged = currentDataString !== prevDataRef.current;

    if (!transactionId || isDataChanged) {
      await createNewWithdrawal();
      return;
    }

    sendApiRef.setOpenOtp(true);
  }, [canSend, createNewWithdrawal, data, sendApiRef, transactionId]);

  return (
    <ButtonWithLoading
      onClick={sendHandler}
      disabled={!canSend}
      loading={isLoading || isCreating}
      size="lg"
      className="w-full py-6"
    >
      <Trans>Send</Trans>
    </ButtonWithLoading>
  );
});

const WithdrawAmountAfter = ({symbol, setAmountHandler, balance, isError}) => (
  <div className={'absolute bg-card px-1 right-2 flex items-center gap-1 top-1/2 -translate-y-1/2'}>
    <span className={cn(
      {'text-destructive': isError}
    )}>{symbol}</span>
    <MaxButton
      onMax={setAmountHandler}
      balance={balance}
    />
  </div>
)

const MaxButton = ({balance, onMax}) => {
  const onClickHandler = () => {
    onMax(balance);
  }
  return (
    <Button className={'px-1'} onClick={onClickHandler} variant={'link'}>
      <Trans>
        Max
      </Trans>
    </Button>
  );
}

const WithdrawAmount = () => {
  const {
    isShowAmount, amount, network, account, sendApiRef
  } = useSend();
  const canWithdraw = useSelector(selectCanWithdraw);

  const formatCurrency = useFormatCurrency({
    decimalScale: account?.precision,
  });
  const {t} = useTranslation();
  const {
    message,
    isValid,
    isLoading,
    validatedAmount,
  } = useValidateAmount(useValidateWithdrawAmountMutation, {
      amount, symbol: account?.symbol, accountId: account?.id, networkId: network?.id,
    }
  )


  if (!isShowAmount || !canWithdraw) return null;
  const placeholder = t('minimalAmount',
    'Minimal amount is {{amount}}',
    {amount: formatCurrency(network?.withdrawalMin, account.precision)}
  );
  const setAmountHandler = sendApiRef?.setAmountHandler;
  const isError = !isValid && !!validatedAmount;

  const messageText = isError ? message : (
    <Trans i18nKey={'availableWithdraw'} values={{balance: formatCurrency(account.balance), symbol: account.symbol}}>
      {`Available Withdraw {{balance}} {{symbol}}`}
    </Trans>)

  const canSend = Boolean(amount && !isError && validatedAmount && isValid && canWithdraw);


  return (
    <>
      <div>
        <div className={'flex items-center justify-between mb-2'}>
          <FormHeader className={'mb-0'}>
            <Trans>
              Withdraw amount
            </Trans>
          </FormHeader>
          <small className={'text-muted-foreground'}>
            <Trans i18nKey={'withdrawalFee'} values={{
              fee: formatCurrency(network?.withdrawalFee)
            }}>
              {`Withdrawal Fee: {{fee}}`}
            </Trans>
          </small>
        </div>
        <AmountField
          value={amount}
          isError={isError}
          placeholder={placeholder}
          onChange={setAmountHandler}
          className={'py-6 !text-lg'}
          disabled={!canWithdraw}
          decimals={account.precision}
          messageProps={{
            message: messageText,
            variant: isError ? 'error' : 'default',
          }}
          after={<WithdrawAmountAfter
            isError={isError}
            symbol={account.symbol}
            setAmountHandler={setAmountHandler}
            balance={account.balance - network?.withdrawalFee}
          />}
        />

      </div>
      {canWithdraw &&
        <SendButton
          canSend={canSend}
          accountId={account?.id}
          networkId={network?.id}
          isLoading={isLoading}
          amount={validatedAmount}
        />}
      <WithdrawOtpModal/>
    </>
  );
};

export default WithdrawAmount;