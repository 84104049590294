import {useEffect, useRef} from 'react';

/**
 * Hook that skips cleanup on first unmount and executes cleanup function on subsequent unmounts
 * @param {Function} cleanupFn - Function to be executed on unmount
 */
const useSkipFirstUnmount = (cleanupFn) => {
  const isFirstMount = useRef(true);

  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false;
      return;
    }

    return () => {
      cleanupFn();
    };
  }, [cleanupFn]);
};

export default useSkipFirstUnmount;