import {GradientOverlay} from "#components/common/CryptoCarousel/GradientOverlay";
import {TransactionListItem} from "#components/wallet/TransactionListItem";
import FadeIn from "#ui/FadeIn";

const LiveTransactions = ({transactions}) => (
  <FadeIn asChild>
    <div className="relative overflow-hidden z-0 w-full lg:w-1/3 h-96">
      <GradientOverlay direction="top" className="h-20 md:h-30"/>
      <GradientOverlay direction="bottom" className="h-20 md:h-30"/>
      <div className="absolute w-full animate-marqueeUp">
        <div className="flex flex-col gap-4 w-full py-4">
          {transactions.map((transaction) => (
            <TransactionListItem
              key={transaction?.id}
              transaction={transaction}
              onViewDetails={() => {
              }}
            />
          ))}
        </div>
        <div className="flex flex-col gap-4 w-full">
          {transactions.map((transaction) => (
            <TransactionListItem
              key={`duplicate-${transaction.id}`}
              transaction={transaction}
              onViewDetails={() => {
              }}
            />
          ))}
        </div>
      </div>
    </div>
  </FadeIn>
);

export default LiveTransactions