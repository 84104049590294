// hooks/useInViewOnce.js
import { useState, useEffect } from 'react';
import { useInView } from './useInView';

export const useInViewOnce = (ref, options = {}) => {
  const [hasTriggered, setHasTriggered] = useState(false);
  const isInView = useInView(ref, { ...options, once: true });

  useEffect(() => {
    if (isInView && !hasTriggered) {
      setHasTriggered(true);
    }
  }, [hasTriggered, isInView]);

  return hasTriggered;
};