import React from 'react';
import {Card, CardHeader, CardContent} from '#ui/card';
import {Skeleton} from '#components/ui/skeleton';

const CryptoAccountCardSkeleton = () => (
  <Card className="overflow-hidden">
    <CardHeader className="pb-2">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Skeleton className="w-8 h-8 rounded-full"/>
          <Skeleton className="h-4 w-24"/>
        </div>
        <Skeleton className="h-4 w-20"/>
      </div>
    </CardHeader>
    <CardContent>
      <div className="flex justify-between items-end">
        <div className="flex flex-col gap-2">
          <Skeleton className="h-4 w-20"/>

          <Skeleton className="h-6 w-28"/>
        </div>
        <div className="flex justify-between items-center">
          <Skeleton className="h-6 w-28"/>
        </div>
      </div>
    </CardContent>
  </Card>
);

const CryptoAccountsListSkeleton = ({length = 10}) => {
  const accounts = Array.from({length})
  return (

    accounts.map((_, index) => (
      <CryptoAccountCardSkeleton key={index}/>
    ))

  );
};

export default CryptoAccountsListSkeleton;