import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  stakingPlans: [],
  selectedPlan: null,
  stakingAmount: '',
  earnings: {
    earned: 0,
    fiatEarned: 0
  }
};

export const stakingSlice = createSlice({
  name: 'staking',
  initialState,
  reducers: {
    setStakingAmount: (state, action) => {
      state.stakingAmount = action.payload;
    },
    setSelectedPlan: (state, action) => {
      state.selectedPlan = action.payload;
    },
    resetStakingForm: (state) => {
      return initialState;
    }
  }
});

export const selectStakingPlan = state => state.staking.selectedPlan;
export const selectStakingAmount = state => state.staking.stakingAmount;

export const {
  setStakingAmount,
  setSelectedPlan,
  setEarnings,
  resetStakingForm
} = stakingSlice.actions;

export default stakingSlice;