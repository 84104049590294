import {NumericFormat} from "react-number-format";
import React, {Fragment} from "react";
import useLocaleNumberSeparators from "#hooks/useLocaleNumberSeparators";
import {cn} from "#lib/utils";

const Symbol = ({symbol}) => {
  if (!symbol) return null;
  return <small className={'subtitle'}>{symbol}</small>;
}
const RenderText = ({as: Element, ...props}) => {
  if (!Element) {
    return <Fragment {...props}/>;
  } else {
    return (
      <Element className={cn('inline-flex gap-1 items-baseline')} {...props}/>
    );
  }

}
const CurrencyFormat = ({as, value, decimalScale = 4, textProps, symbol, removeTrailingZeros = true, ...rest}) => {
  const {thousandSeparator, decimalSeparator} = useLocaleNumberSeparators();
  const safeDecimalScale = Math.max(2, decimalScale);

  const removeTrailingZerosFunction = (formattedValue) => {
    const [integerPart, fractionalPart] = formattedValue.split(decimalSeparator);
    if (fractionalPart) {
      const trimmedFractionalPart = fractionalPart.replace(/0+$/, '');
      return trimmedFractionalPart ? `${integerPart}${decimalSeparator}${trimmedFractionalPart}` : integerPart;
    }
    return integerPart;
  };

  const formatValue = removeTrailingZeros ? removeTrailingZerosFunction : (v) => v;

  return (
    <>
      <NumericFormat
        value={value ?? 0}
        displayType="text"
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        decimalScale={safeDecimalScale}
        renderText={(formattedValue) =>
          <RenderText
            as={as}
            {...textProps}
          >
            {formatValue(formattedValue) || '0'}
            <Symbol symbol={symbol}/>
          </RenderText>}
        {...rest}
      />
    </>
  );
};

export default CurrencyFormat;