import {SheetContent, SheetHeader, SheetTitle, Sheet} from "#ui/sheet";
import TransactionStatusIcon from "#components/wallet/TransactionListItem/TransactionStatusIcon";
import TransactionInfo from "#components/wallet/TransactionListItem/TransactionInfo";
import {useGetTransactionQuery} from "#services/api/transactions";
import CurrencyFormat from "#ui/currency-format";
import {Trans} from "react-i18next";
import {Card} from "#ui/card";
import Section from "#ui/section";
import {DetailItem} from "#components/common/DetailItem";
import {Button} from "#ui/button";
import {Link} from "react-router-dom";
import TransactionDetailsModalContentSkeleton from "#components/skeletons/TransactionDetailsModalSkeleton";


const statusMap = {
  'success': <Trans>Success</Trans>,
  'pending': <Trans>Pending</Trans>,
  'failed': <Trans>Failed</Trans>
}
const criteria = (value) => {
  return !!value
}

const details = (transaction) => {
  return [
    {
      title: <Trans>Status</Trans>,
      value: statusMap[transaction.status] ?? <Trans>Unknown</Trans>,
      criteria: criteria
    },
    {
      title: <Trans>From</Trans>,
      value: transaction?.depositAddress,
      criteria: criteria

    },
    {
      title: <Trans>To</Trans>,
      value: transaction?.withdrawAddress,
      criteria: criteria

    },
    {
      title: <Trans>Network</Trans>,
      value: transaction?.network,
      criteria: criteria

    },
    {
      title: <Trans>Commission</Trans>,
      value: <CurrencyFormat value={transaction?.commission}/>,
      criteria: v => !!v.props.value && parseFloat(v.props.value) !== 0
    }
  ]

}


const TransactionsDetails = ({transaction}) => {
  const detailsList = details(transaction).filter((item) => item?.criteria(item?.value));
  if (detailsList.length < 0) return null;
  return (
    <Section header={<Trans>Payment details</Trans>}>
      <Card>
        {
          detailsList?.map((item, i) => {
            return (
              <DetailItem key={i} title={item?.title}>
                {item?.value}
              </DetailItem>
            )
          })
        }
      </Card>
    </Section>
  );
};


const calcRate = (amount, toAmount) => {
  return amount / toAmount;
};

const ExchangeDetails = ({transaction}) => {

  if (!transaction?.toAmount || transaction?.toAmount <= 0) return null;
  return (
    <Section>
      <Card>
        <DetailItem title={<Trans>Paid</Trans>}>
          <CurrencyFormat
            as={'span'}
            value={transaction?.toAmount}
            decimalScale={transaction?.toPrecision}
            symbol={transaction?.toCurrency}
          />
        </DetailItem>
        <DetailItem title={<Trans>Exchange rate</Trans>}>
          <CurrencyFormat
            as={'span'}
            value={1}
            decimalScale={transaction?.precision}
            symbol={transaction?.currency}
          /> ≈ <CurrencyFormat
          value={calcRate(transaction?.amount, transaction?.toAmount)}
          decimalScale={transaction?.toPrecision}
          symbol={transaction?.toCurrency}
          as={'span'}
        />
        </DetailItem>
      </Card>
    </Section>
  );
}


const ExplorerLink = ({link}) => {
  if (!link) return null;
  return (
    <Section>
      <Button
        variant={'secondary'}
        className={'w-full'}
        asChild
        size={'lg'}
      >
        <Link to={link} target={'_blank'}>
          <Trans> Show on Blockchain Explorer</Trans>
        </Link>
      </Button>
    </Section>
  );
};

const TransactionDetailsModalContent = ({transaction, isLoading}) => {
  if (isLoading) {
    return <TransactionDetailsModalContentSkeleton/>;
  }
  return (
    <>
      <SheetHeader>
        <SheetTitle className={'mb-3'}>
          <div className={'flex items-center gap-2'}>
            <TransactionStatusIcon transaction={transaction}/>
            <TransactionInfo transaction={transaction}/>
          </div>
        </SheetTitle>
      </SheetHeader>
      <div className={'flex flex-col gap-3'}>
        <TransactionsDetails transaction={transaction}/>
        <ExchangeDetails transaction={transaction}/>
        <ExplorerLink link={transaction?.explorerLink}/>
      </div>
    </>
  )
}

const TransactionDetailsModal = ({transactionId, onClose}) => {
  const {data: transaction, isLoading,isFetching, error} = useGetTransactionQuery(transactionId, {skip: !transactionId});
  if (error) {
    throw error;
  }
  return (
    <Sheet open={!!transactionId} onOpenChange={onClose}>
      <SheetContent>
        <TransactionDetailsModalContent transaction={transaction} isLoading={isLoading || isFetching}/>
      </SheetContent>
    </Sheet>
  );
};

export default TransactionDetailsModal;